export const cancelApplicationReducer = (state = {}, action) => {
   switch (action.type) {
     case "startTime":
       return {
         ...state,
         startTime: action.data,
       };
     case "endTime":
       return {
         ...state,
         endTime: action.data,
       };
     case "searchStr":
       return {
         ...state,
         searchStr: action.data,
       };
     case "applicationData":
       return {
         ...state,
         applicationData: action.data,
       }; 
     default:
       return state;
   }
 };
 