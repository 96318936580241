import React from "react";
import {
  InputItem,
  NavBar,
  Icon,
  List,
  ImagePicker,
  Toast,
  Picker,
  TextareaItem,
} from "antd-mobile";
import axios from "axios";
import "./style/payment.css";
import { paymentAction } from "../../action/payment/payment";
import { connect } from "react-redux";

const zffs = [
  // { img: require("../img/zf1.png"), title: "支付宝", key: 0 },
  // { img: require("../img/zf2.png"), title: "微信支付", key: 1 },
  // { img: require("../img/jhsm.svg"), title: "聚合扫码付", key: 2 },
  { img: require("../img/pos.svg"), title: "POS订单付", key: 0 },
];

class Payment extends React.Component {

  constructor(props) {

    super(props);
    const params =
      (this.props.location && this.props.location.query) || props || {};
    this.state = {
      czjeSelect: props.czjeSelect || 0,
      paymentType: props.paymentType || "WECHAT",
      unSettlement: props.unSettlement || "0",
      invoiceType: props.invoiceType || "个人",
      patientName: params.patientName || props.patientName || "",
      salesBarCode: params.salesBarCode || props.salesBarCode || "",
      hospital:  params.hospital || props.hospital || "",
      czfs: props.czfs || 0,
      invoiceTitle: params.invoiceTitle || params.patientName || "",
      receiveAmount: params.receiveAmount || (params.v && params.v.receiveAmount) || "",
      salesRepresentative: params.salesRepresentative || props.salesRepresentative || "",
      v: this.props.location.query || props.v,
      status: params.status || "",
      idNumber: params.idNumber || "",
      paidRemark: params.paidRemark || "",
      isMonthlyStatement: params.isMonthlyStatement || "",
      needAdd: params.needAdd || false,
      caseNumber: params.caseNumber || props.caseNumber || "",
      id: params.id || props.id || "",
      flag: this.props.history.flag || "",
      inspectionItems: params.inspectionItems || props.inspectionItems || "",
      money: params.applyAmount || props.applyAmount || "",
      freceiveAmount: params.applyAmount || props.applyAmount || "",
      applyAmount: params.applyAmount || props.applyAmount || "",
      gender: params.gender || props.gender || "",
      patientAge: params.patientAge || props.patientAge || "",
      nation: params.nation || props.nation || "",
      discountApplyStatus: params.discountApplyStatus || props.discountApplyStatus || "",
    };
    this.check = ["invoiceTitle"];
    this.invoiceTitle = params.invoiceTitle;
  }

  componentWillMount() {
    axios
      .post(`/open/setmeal/wxpush/${this.state.salesBarCode}/getReceiveAmountByWX`,
        {
          id: this.state.id,
          salesBarCode: this.state.salesBarCode,
          status: "Logged",
          patientName: this.state.patientName,
          idNumber: this.state.idNumber,
          caseNumber: this.state.caseNumber,
          inspectionItems: this.state.inspectionItems,
          hospital: this.state.hospital,
          discountApplyStatus: this.state.discountApplyStatus
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        })
      .then((res) => {
        if (!res || !res.batchinfodto) return;

        this.setState(
          {
            inspectionItems: res.batchinfodto.inspectionItems,
            lklPrItems:  res.batchinfodto.lklPrItems,
            receiveAmount: res.batchinfodto.receiveAmount,
            totalMoney: res.batchinfodto.totalMoney + "元",
            money: res.batchinfodto.applyAmount,
            freceiveAmount: res.batchinfodto.applyAmount,
            uSFlag:res.batchinfodto.uSFlag
          },
        );
      })
      .catch(() => {
        Toast.info("获取支付金额失败，请联系管理员！", 3, () => {
          // this.props.history.push({
          //   pathname: "/createApplication",
          // });
          this.props.history.goBack();
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  setRedux = () => {
    const reduxData = [
      "czjeSelect",
      "paymentType",
      "invoiceType",
      "patientName",
      "invoiceTitle",
      "salesBarCode",
      "czfs",
      "salesRepresentative",
      "money",
      "idNumber",
      "v",
      "status",
      "paidRemark",
      "isMonthlyStatement",
      "needAdd",
    ];
    for (let pop in reduxData) {
      this.props.paymentAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  dateFormat = (fmt, date) => {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  };

  clearRedux = () => {
    const reduxData = [
      "czjeSelect",
      "paymentType",
      "invoiceType",
      "patientName",
      "invoiceTitle",
      "salesBarCode",
      "salesRepresentative",
      "v",
      "money",
      "status",
      "idNumber",
      "czfs",
      "isMonthlyStatement",
      "needAdd",
      "paidRemark",
    ];
    for (let pop in reduxData) {
      this.props.paymentAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location  &&
      this.props.history.location.pathname !== "/QRcode"
    ) {
     // this.clearRedux();
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  submit = (salesBarCode) => {
    axios
      .post(
        `/open/lkl/pay`,
        {
          salesBarCode: salesBarCode,
          amount: this.state.money,
          ip: "127.0.0.1",
          body: this.state.patientName,
          invoiceType: this.state.invoiceType,
          invoiceTitle: this.state.invoiceTitle,
          idNumber: this.state.idNumber,
          taxNo: this.state.dutyParagraph,
          paidRemark: this.state.paidRemark,
          patientName: this.state.patientName,
          
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (!res || !res.resultmap) return;
        Toast.hide();
        this.setRedux();
        this.props.paymentAction({
          type: "page",
          data: this.state.needAdd ? -4 : -3,
        });
        this.props.history.push({
          pathname: "/QRcode",
          query: {
            img: res.resultmap.imagePay,
            v: {
              ...(this.props.location.query || this.props.v),
              salesBarCode: this.state.salesBarCode,
            },
            czfs: this.state.czfs,
            money: this.state.money,
          },
        });
      })
      .catch(() => {
        Toast.info("加载失败！");
      });

  };


//远程支付remotePay
remotePay = (salesBarCode,paymentType) => {
  axios
    .post(
      `/open/fuiou/remotePay`,
      {
        salesBarCode: salesBarCode,
        amount: this.state.money,
        paymentType : paymentType,
        ip: "127.0.0.1",
        body: this.state.patientName,
        invoiceType: this.state.invoiceType,
        invoiceTitle: this.state.invoiceTitle,
        idNumber: this.state.idNumber,
        taxNo: this.state.dutyParagraph,
        paidRemark: this.state.paidRemark,
        patientName: this.state.patientName,
        
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (!res || !res.resultmap) return;
      Toast.hide();
      this.setRedux();
      this.props.paymentAction({
        type: "page",
        data: this.state.needAdd ? -4 : -3,
      });
      this.props.history.push({
        pathname: "/QRcode",
        query: {
          img: res.resultmap.imagePay,
          v: {
            ...(this.props.location.query || this.props.v),
            salesBarCode: this.state.salesBarCode,
          },
          czfs: this.state.czfs,
          money: this.state.money,
        },
      });
    })
    .catch(() => {
      Toast.info("加载失败！");
    });

};


//支付完成
payComplete = (salesBarCode) => {

  const param = {
    salesBarCode: salesBarCode,
    invoiceType: this.state.invoiceType,
    invoiceTitle: this.state.invoiceTitle,
    paidAmount: this.state.money,
    taxNo: this.state.dutyParagraph,
    chargeMethod: this.state.chargingMethod,
    chargeVoucherUrls: this.state.files,
    paidRemark: this.state.paidRemark,
    patientName: this.state.patientName,
    idNumber: this.state.idNumber,
    caseNumber: this.state.caseNumber,
    receiveAmount: this.state.receiveAmount,
    uSFlag: this.state.uSFlag,
    flag: this.props.history.location.flag,
    money: this.state.applyAmount
  };
  axios
    .post(
      `/open/setmeal/offline/payComplete`,
      param,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      Toast.success("支付完成成功", 2);
      this.props.history.push({
          pathname: "/payComplete",
          query: this.state,
          payParam: param,
      });
    })
    .catch(() => {
      Toast.info("加载失败！", 2);
    });

};


  //线下支付
  offlinePayment = (salesBarCode) => {
    const param = {
      salesBarCode: salesBarCode,
      status: "Draft_Save",
      invoiceType: this.state.invoiceType,
      invoiceTitle: this.state.invoiceTitle,
      paidAmount: this.state.money,
      taxNo: this.state.dutyParagraph,
      chargeMethod: this.state.chargingMethod,
      chargeVoucherUrls: this.state.files,
      paidRemark: this.state.paidRemark,
      patientName: this.state.patientName,
      idNumber: this.state.idNumber,
      caseNumber: this.state.caseNumber,
      receiveAmount: this.state.receiveAmount,
      money: this.state.applyAmount
    };
    axios
      .post(
        `/open/setmeal/offline/submitBatchByWx`,
        param,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (!res) return;
        Toast.hide();
        Toast.hide();
        this.setRedux();
        this.props.paymentAction({
          type: "page",
          data: this.state.needAdd ? -3 : -2,
        });
        this.props.history.push({
          pathname: "paymentSuccessful",
          query: this.state,
          payParam: param,
        });
      })
      .catch(() => {
        Toast.info("线下支付失败！", 2);
      });
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  render() {
    const params =
      (this.props.location && this.props.location.query) || this.props || {};
    return (
      <div className="chongzhi">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          支付申请单
        </NavBar>
        <div className="scroll">
          <List>
            <InputItem
              extra={
                <img
                  style={{ marginLeft: "0.1rem" }}
                  src={require("../img/sm.png")}
                  alt=""
                ></img>
              }
              disabled={
                (this.props.location &&
                  this.props.location.query &&
                  this.props.location.query.salesBarCode) ||
                this.props.salesBarCode
              }
              onBlur={() => {
                // let salesBarCode = this.state.salesBarCode || "-1";
                // axios
                //   .get(`/open/setmeal/b/-1/s/${salesBarCode}`)
                //   .then((res) => {
                //     if (!res) {
                //       this.setState({
                //         salesBarCodeError: true,
                //       });
                //     } else {
                //       if (!res || !res.person) return;
                //       this.setState({
                //         salesRepresentative: res.person.personName,
                //         salesMan: res.person.personCode,
                //         salesManPhone: res.person.mobile,
                //         salesEmail: res.person.email,
                //         salesBarCodeError: false,
                //       });
                //     }
                //   });
              }}
              defaultValue={this.state.salesBarCode || ""}
              onChange={(val) => {
                this.setState({
                  salesBarCode: val,
                  salesRepresentative: "",
                });
              }}
              error={
                (this.state.submit && !this.state.salesBarCode) ||
                this.state.salesBarCodeError
              }
              placeholder="请输入或扫描申请单条码"
            >
              <span style={{ color: "red" }}>*</span>
              申请单条码
            </InputItem>
            <InputItem
              placeholder="请填写患者姓名"
              error={this.state.submit && !this.state.patientName}
              defaultValue={this.state.patientName || ""}
              onChange={(val) => {
                this.setState({
                  patientName: val,
                  invoiceTitle: this.invoiceTitle || val,
                });
              }}
              disabled={true}
            >
              <span style={{ color: "red" }}>*</span>
              姓名
            </InputItem>
            <InputItem
              error={this.state.submit && !this.state.caseNumber}
              defaultValue={this.state.caseNumber || ""}
              onChange={(val) => {
                this.setState({
                  caseNumber: val,
                  invoiceTitle: this.invoiceTitle || val,
                });
              }}
              disabled={true}
            >
              {/* <span style={{ color: "red" }}>*</span> */}
              病案号
            </InputItem>
            <InputItem
              ref={(el) => (this.idNumber = el)}
              type="text"
              maxLength={18}
              value={this.state.idNumber}
              onChange={(el) => {
                this.setState({
                  idNumber: el,
                });
              }}
              disabled={true}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => this.idNumber.focus()}
              >
                身份证号
              </div>
            </InputItem>
            <List.Item
              extra="请选择"
              arrow="horizontal"
              disabled={true}
            >
              {true ? <span style={{ color: "red" }}>*</span> : null}
              检测项目
            </List.Item>
            {this.state.inspectionItems &&
              this.state.inspectionItems.length ? (
              <List.Item multipleLine>
                {this.state.inspectionItems
                  .map((v, k) => {
                    return (
                      <div key={k} className="select-info2">
                        <img
                          src={require("../img/xx.png")}
                          alt=""
                        ></img>
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          {v.productName ? (
                            <div className="content-item">
                              <span className="content-title">
                                检测项目：
                            </span>
                              <span className="content-lable">
                                {v.productName}
                              </span>
                            </div>
                          ) : null}
                          {v.hisFlag ? (
                            <div className="content-item">
                              <span className="content-title">
                                序惯检测：
                            </span>
                              <span className="content-lable">
                                {v.hisFlag === '1' ? ('是') : ('否')}
                              </span>
                            </div>
                          ) : null}
                          {v.inHospitalFlag ? (
                            <div className="content-item">
                              <span className="content-title">
                                院内检测：
                            </span>
                              <span className="content-lable">
                                {v.inHospitalFlag === '1' ? ('是') : ('否')}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
              </List.Item>
            ) : null}
           
            <InputItem
              placeholder="输入任意金额"
              error={this.state.submit && !this.state.money}
              extra="元"
              type="money"
              value={this.state.money}
              onVirtualKeyboardConfirm={(val) => {
                this.setState({ money: parseFloat(val) });
              }}
              onFocus={(val) => {
                this.setState({ money: null });
              }}
              onChange={(val) => {
                let values = val.split(".");
                if (values.length > 2) {
                  val = val.substring(0, val.length - 1);
                  this.setState({ money: val });
                  return;
                }
                if (val.indexOf(".") === 0) {
                  val = "0.";
                  this.setState({ money: val });
                  return;
                }
                if (val.indexOf(".") > -1) {
                  let dotNum = val.substring(val.indexOf("."), val.length);
                  if (dotNum.length > 2) {
                    val = val.substring(0, val.indexOf(".") + 3);
                  }
                  this.setState({ money: val });
                  return;
                }
                this.setState({ money: val });
              }}
            >
              <span style={{ color: "red" }}>*</span>支付金额
            </InputItem>
            <Picker
                onOk={(val) => {
                  if(this.state.uSFlag === "1"){

                    this.setState({
                      unSettlement: val[0],
                    });

                  }
                }}
                value={[this.state.unSettlement]}
                data={[
                  {
                    value: "0",
                    label: "否",
                  },
                  {
                    value: "1",
                    label: "是",
                  },
                ]}
                cols={1}
              >
                <List.Item arrow="horizontal">
                  统一结算
                </List.Item>
              </Picker>
              <Picker
                onOk={(val) => {
                  this.setState({
                    paymentType: val[0],
                  });
                }}

                value={[this.state.paymentType]}
                data={[
                  // {
                  //   value: "0",
                  //   label: "拉卡拉支付",
                  // },
                  {
                    value: "1",
                    label: "扫码支付",
                  },
                  {
                    value: "WECHAT",
                    label: "远程支付(微信)",
                  },
                  {
                    value: "ALIPAY",
                    label: "远程支付(支付宝)",
                  },
                ]}
                cols={1}
              >
                <List.Item arrow="horizontal">
                  <span style={{ color: "red" }}>*</span>支付方式
                </List.Item>
              </Picker>

            <TextareaItem
              title={
                <div>
                  <span>收费备注</span>
                </div>
              }
              rows={3}
              error={this.state.submit && !this.state.paidRemark}
              onChange={(val) => {
                this.setState({
                  paidRemark: val,
                });
              }}
              value={this.state.paidRemark}
              placeholder="请填写付款人与受检者的关系（本人，父，母，兄，弟等具体亲属关系，朋友），如：付款人为受检者本人。"
            ></TextareaItem>

           <List.Item
              arrow="horizontal"
              disabled={true}
            >
              支付记录
            </List.Item>
            {this.state.lklPrItems &&
              this.state.lklPrItems.length ? (
              <List.Item multipleLine>
                {this.state.lklPrItems
                  .map((v, k) => {
                    return (
                      <div key={k} className="select-info2">
                       
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          {v.amountAndPayStatus ? (
                            <div className="content-item">
                              <span className="content-title">
                              {v.payClass}:
                            </span>
                              <span className="content-lable">
                                {v.amountAndPayStatus}
                              </span>
                            </div>
                          ) : null}
                    
                        </div>
                      </div>
                    );
                  })}
              </List.Item>
            ) : null}
            <InputItem
              error={this.state.submit && !this.state.totalMoney}
              value={this.state.totalMoney || ""}
              disabled={true}
            >
              支付总计
            </InputItem>
          </List>
          <div className="foot">
            <span
              className="submit"
              onClick={() => {
                const { v } = this.state;
                let submit = false;
    
                // if(this.state.totalMoney.split('元')[0] < this.state.freceiveAmount) {
                //   Toast.info("支付总计不能小于支付金额！", 2);
                //   return;
                // }
                this.setState({
                  submit: true,
                });
                Toast.loading("正在加载", 0);
                if (v && v.salesBarCode) {
                  
                    this.payComplete(v.salesBarCode); // 线上支付
                  
                }
              }}
            >
              支付完成
            </span>
            <span
              className="submit"
              onClick={() => {
                const { v } = this.state;
                let submit = false;
                this.setState({
                  submit: true,
                });

                this.check.forEach((element) => {
                  if (!this.state[element]) {
                    submit = true;
                  }
                });
                if (submit) {
                  Toast.info("请填写完整信息！", 2);
                  return;
                }

                // if (
                //   parseFloat(this.state.money) === 0
                // ) {
                //   Toast.info("支付金额不能为0！", 2);
                //   return;
                // }

                if (
                  !parseFloat(this.state.money) &&
                  parseFloat(this.state.money) !== 0
                ) {
                  Toast.info("支付金额不能为空！", 2);
                  return;
                }

                // if (parseFloat(this.state.money) < parseFloat(this.state.receiveAmount)) {
                //   Toast.info("支付金额不能小于" + this.state.receiveAmount, 4);
                //   return;
                // }

                if (this.state.discountApplyStatus === 'Discount_Approve') {
                  Toast.info("当前订单处于折扣申请流程中！", 4);
                  return;
                }

                Toast.loading("正在加载", 0);
                if (v && v.salesBarCode) {
                  if (this.state.paymentType === "1") {
                    this.submit(v.salesBarCode); // 扫码支付
                  } else if (this.state.paymentType === "0") {
                    this.offlinePayment(v.salesBarCode); // 拉卡拉支付
                  } else {
                    this.remotePay(v.salesBarCode,this.state.paymentType); // 远程支付
                  }
                }
              }}
            >
              确认支付
            </span>
          </div>
        </div>
        {this.state.showImage ? (
          <div
            className="show-image"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              zIndex: "999",
              top: "0",
              left: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            onClick={() => this.setState({ showImage: false })}
          >
            <img
              alt=""
              style={{ width: "100%" }}
              src={this.state.uploadImage}
            ></img>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.paymentReducer,
    page: state.applyListInfoReducer.page,
  };
}

export default connect(mapStateToProps, { paymentAction })(Payment);
