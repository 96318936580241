import React from "react";
import { NavBar, Icon, Toast, InputItem, DatePicker, Modal } from "antd-mobile";
import "./style/quickCreation.css";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import { connect } from "react-redux";
import { quickCreationAction } from "../../action/apply/quickCreation";
const alert = Modal.alert;

class QuickCreation extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      id: "",
      status: ["Draft_Quick"],
      applicationData: this.props.applicationData || [],
      searchStr: this.props.searchStr || "",
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      loading: true,
    };
    this.applicationData = [];
    this.patStatus = {
      wx: "线上支付-微信",
      pos: "线上支付-POS机非订单付",
      yhzz: "线上支付-银行转账",
      "xxzf-zfb": "线下支付-支付宝",
      "xxzf-wx": "线下支付-微信",
      "xxzf-pos": "线下支付-POS机非订单付",
      "xxzf-yhzz": "线下支付-银行转账",
      "xxzf-yj": "线下支付-月结",
      "xxzf-mf": "线下支付-免费",
      "xxzf-yl": "线下支付-聚合码",
      zfb: "线上支付-支付宝",
      yl: "线上支付-聚合码",
    };
  }

  setRedux = () => {
    const reduxData = ["searchStr", "applicationData", "startTime", "endTime"];
    for (let pop in reduxData) {
      this.props.quickCreationAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["searchStr", "applicationData", "startTime", "endTime"];
    for (let pop in reduxData) {
      this.props.quickCreationAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  search = () => {
    let applicationData = [];
    const allData = JSON.parse(JSON.stringify(this.applicationData));
    if (allData) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );
      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );
      allData.forEach((element) => {
        if (element && element.createdTime) {
          let createdTime = parseInt(
            this.formatTime(element.createdTime).replace(/-/g, "")
          );
          if (createdTime <= endTime && createdTime >= startTime) {
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                 const searchStr = this.state.searchStr || '';
                if (element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1) {
                  applicationData.push(element);
                  break;
                }
              }
            }
          }
        }
      });
      this.setState(
        {
          applicationData,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val); 
    }
    return `${val.getFullYear()}-${
      val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
    }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  //返回首页
  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  componentDidMount() {
    this.selectBatchByDraftSave();
  }

  componentWillUnmount() {
    Toast.hide();
  }

  selectBatchByDraftSave = () => {
    const status = ["Draft_Quick"];
    axios
      .post("/open/setmeal/wxpush/selectBatchByDraftSave", status, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.batchinfodtos) return;
        this.applicationData = JSON.parse(JSON.stringify(res.batchinfodtos));
        this.setState({
          applicationData: res.batchinfodtos,
        });
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <div
        onClick={() => {
          let applicationData = this.state.applicationData.map((value) => {
            value.mask = false;
            return value;
          });
          this.setState({
            applicationData,
          });
        }}
        className="quickCreation"
      >
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
          rightContent={
            <span
              style={{
                fontSize: "0.14rem",
                display: "flex",
                width: "0.5rem",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "0.15rem",
              }}
              onClick={() => {
                this.props.history.push({
                  pathname: "payment",
                  query: {
                    status: "Draft_Quick",
                  },
                });
              }}
            >
              新建
            </span>
          }
        >
          快速创建
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.search();
              }}
              className="search-bar-top-text"
            >
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
            {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          </div>
        </div>
        {this.state.applicationData && this.state.applicationData.length ? (
          <div className="center">
            {this.state.applicationData.map((v, k) => {
              return (
                <div
                  className="item"
                  onClick={(e) => {
                    e.stopPropagation();
                    let applicationData = this.state.applicationData.map(
                      (value) => {
                        if (v === value) {
                          value.mask = !v.mask;
                        } else {
                          value.mask = false;
                        }
                        return value;
                      }
                    );
                    this.setState({
                      applicationData,
                    });
                  }}
                  key={k}
                >
                  <div className="item-row">
                    <img alt="" src={require("../img/ddbh.svg")}></img>
                    <span> {v.salesBarCode} </span>
                    {v.payStatus === 20 ? (
                      <>
                        {v.payMethod ? (
                          <span
                            className="item-status"
                            style={{ backgroundColor: "#00dcff" }}
                          >
                            {this.patStatus[v.chargeMethod]}
                          </span>
                        ) : null}
                        {v.paidAmount ? (
                          <span
                            className="item-status"
                            style={{
                              backgroundColor: "#ffa500",
                            }}
                          >
                            {parseFloat(v.paidAmount)}元
                          </span>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <span className="item-status">未支付</span>
                      </>
                    )}
                  </div>
                  <div className="content">
                    {v.patientName ? <span>{v.patientName}</span> : null}
                    {v.hospital ? <span>{v.hospital}</span> : null}
                    {v.sampleInfos && v.sampleInfos.length ? (
                      <span>
                        {(() => {
                          let sampleInfos = v.sampleInfos.map((element) => {
                            return (
                              `(${element.sampleCode})` + element.sampleTypeName
                            );
                          });
                          return sampleInfos.join("，");
                        })()}
                      </span>
                    ) : null}
                    {v.selectSetMeals && v.selectSetMeals.length ? (
                      <span>
                        {(() => {
                          let selectSetMeals = v.selectSetMeals.map(
                            (element) => {
                              return element.setMealName;
                            }
                          );
                          return selectSetMeals.join("，");
                        })()}
                      </span>
                    ) : null}
                    {v.createdTime ? <span>{v.createdTime}</span> : null}
                  </div>
                  {v.mask ? <div className="mask"></div> : null}
                  {v.mask ? (
                    <div className="foot">
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          if (v.payStatus) {
                            this.props.history.push({
                              pathname: "/quickApplyListInfo",
                              query: v,
                            });
                          } else {
                            this.props.history.push({
                              pathname: "/applyListInfo",
                              query: v,
                            });
                          }
                        }}
                        style={{ backgroundColor: "rgb(254,202,18" }}
                      >
                        填单
                      </span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          alert(
                            "提示",
                            `${
                              v.payStatus
                                ? `已支付${v.paidAmount}元，申请单号条码已占用，是否做好收款记录备份，确认作废？`
                                : "是否确认删除？"
                            }`,
                            [
                              { text: "否" },
                              {
                                text: "是",
                                onPress: () => {
                                  Toast.loading(
                                    `正在${v.payStatus ? "作废" : "删除"}`,
                                    0
                                  );
                                  axios
                                    .post(
                                      `/open/setmeal/wxpush/${v.id}/deleteBatchInfoByWx`
                                    )
                                    .then((res) => {
                                      if (!res) return;
                                      Toast.success(
                                        `${v.payStatus ? "作废" : "删除"}成功`,
                                        2
                                      );
                                      this.selectBatchByDraftSave(() => {
                                        this.search();
                                      });
                                    })
                                    .catch(() => {
                                      Toast.info(
                                        `${v.payStatus ? "作废" : "删除"}失败`,
                                        2
                                      );
                                    });
                                },
                              },
                            ]
                          );
                        }}
                        style={{
                          backgroundColor: `${
                            v.payStatus
                              ? "rgba(45, 255, 168, 0.76)"
                              : "rgb(255,107,45)"
                          }`,
                        }}
                      >
                        {v.payStatus ? "作废" : "删除"}
                      </span>
                      {!v.payStatus ? (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.history.push({
                              pathname: "/payment",
                              query: v,
                            });
                          }}
                          style={{ backgroundColor: "rgb(248,19,0" }}
                        >
                          结算
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.quickCreationReducer,
  };
}

export default connect(mapStateToProps, { quickCreationAction })(QuickCreation);
