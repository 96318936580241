export const invoiceListReducer = (state = {}, action) => {
    switch (action.type) {
      case "startTime":
        return {
          ...state,
          startTime: action.data,
        };
      case "endTime":
        return {
          ...state,
          endTime: action.data,
        };
      case "searchStr":
        return {
          ...state,
          searchStr: action.data,
        };
      case "orderData":
        return {
          ...state,
          orderData: action.data,
        };
      case "selectAll":
        return {
          ...state,
          selectAll: action.data,
        };
      case "active":
        return {
          ...state,
          active: action.data,
        };
      case "selectData":
        return {
          ...state,
          selectData: action.data,
        };
      default:
        return state;
    }
  };
  