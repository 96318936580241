import React from "react";
import { NavBar, Icon, InputItem, DatePicker, Toast } from "antd-mobile";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import "./style/sampleDelivery.css";
import { checkExpressAction } from "../../action/logistics/checkExpress";
import { connect } from "react-redux";

class CheckExpress extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      expressData: props.expressData || [],
      startTime: props.startTime || startTime,
      searchStr: props.searchStr || "",
      loading: true,
      endTime: props.endTime || new Date(),
    };
    this.expressData = [];
  }

  setRedux = () => {
    const reduxData = ["startTime", "endTime", "searchStr", "expressData"];
    for (let pop in reduxData) {
      this.props.checkExpressAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["startTime", "endTime", "searchStr", "expressData"];
    for (let pop in reduxData) {
      this.props.checkExpressAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };
  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      (this.props.history.location.pathname === "/" ||
        this.props.history.location.pathname === "/home")
    ) {
      this.clearRedux();
    }
  }

  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  search = () => {
    let expressData = [];
    const allData = JSON.parse(JSON.stringify(this.expressData));
    if (allData) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );
      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );
      allData.forEach((element) => {
        if (element && element.expressOrderDate) {
          let expressOrderDate = parseInt(
            this.formatTime(element.expressOrderDate).replace(/-/g, "")
          );
          if (expressOrderDate <= endTime && expressOrderDate >= startTime) {
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                const searchStr = this.state.searchStr || "";
                if (
                  element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1
                ) {
                  expressData.push(element);
                  break;
                }
              }
            }
            if (!element.batchInfoList) return;
            element.batchInfoList.forEach((value) => {
              for (let pop in value) {
                if (typeof value[pop] === "string") {
                  const searchStr = this.state.searchStr || "";
                  if (
                    value[pop].indexOf(searchStr) > -1 &&
                    expressData.findIndex((val) => val === element) < 0
                  ) {
                    expressData.push(element);
                    break;
                  }
                }
              }
            });
          }
        }
      });
      this.setState(
        {
          expressData,
          active: false,
          selectAll: false,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  componentDidMount() {
    // axios
    //   .get("/open/express/selectOrderDoneExpress", {
    //     Headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     if (res && res.lsibatchbeans) {
    //       const lsibatchbeans = res.lsibatchbeans;
    //       this.expressData = lsibatchbeans.map((e) => {
    //         if (!e.expressNo) return null;
    //         if (e.expressOrderDate) {
    //           e.expressOrderDate = e.expressOrderDate.substring(0, 10);
    //         }
    //         e.icon =
    //           e.expressNo && e.expressNo.toUpperCase().indexOf("SF") > -1
    //             ? require("../img/sf.svg")
    //             : require("../img/kd.svg");
    //         e.type =
    //           e.expressNo && e.expressNo.toUpperCase().indexOf("SF") > -1
    //             ? "顺丰"
    //             : "金域达";
    //         return e;
    //       });
    //     }
    //   })
    //   .finally(() => {
    //     this.setState({
    //       loading: false,
    //     });
    //   });

    axios
      .post("/open/setmeal/wxpush/express-bills/queries", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.expressbilllist) return;
        this.applicationData = res.expressbilllist;
        this.setState(
          {
            applicationData: res.expressbilllist,
          },
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  render() {
    return (
      <div className="sampleDelivery">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          查快递
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div onClick={() => this.search()} className="search-bar-top-text">
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
            {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          </div>
        </div>
        {this.state.applicationData && this.state.applicationData.length ? (
          <div className="center">
            {this.state.applicationData.map((v, k) => {
              return (
                <div
                  key={v + k}
                  className="item"
                  onClick={() => {
                    this.setRedux();
                    this.props.history.push({
                      pathname: "logisticsInformation",
                      query: v,
                    });
                  }}
                >
                  <div className="right">
                    <div className="title">
                      <div>
                        <img
                          className="logo"
                          alt=""
                          src={require("../img/ddbh.svg")}
                        ></img>
                        {v.ext$.salesBarCode ? (
                          <span style={{ 'marginRight': '20px', 'fontSize': '0.12rem' }}>
                            <span>订单条码号:</span>
                            {v.ext$.salesBarCode}
                          </span>
                        ) : null}
                        {v.ext$.sampletypename ? (
                          <span style={{ 'fontSize': '0.12rem' }}>
                            <span>样本名称:</span>
                            {v.ext$.sampletypename}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="content">
                      {v.ext$.patientname ? <span>{v.ext$.patientname}</span> : null}
                      {v.ext$.hospital ? <span>{v.ext$.hospital}</span> : null}
                      {v.createdTime ? (
                        <span>{v.createdTime.substring(0, 11)}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.checkExpressReducer,
  };
}

export default connect(mapStateToProps, { checkExpressAction })(CheckExpress);
