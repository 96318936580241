import React from 'react';
import './App.css';
import RouterConfig from './router';
 
  
function App() {
  return (
     <RouterConfig></RouterConfig>  
  );
}
export default App;
