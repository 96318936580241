import React from "react";
import {
  NavBar,
  Icon, 
  TextareaItem,
  List,
  Toast,
} from "antd-mobile";
import axios from "axios";
import "./style/satisfactionSurvey.css";

export default class SatisfactionSurvey extends React.Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
      reportEvaluationData: [
        {
          text: "报告周期：",
          id: "reportCycle",
        },
        {
          id: "reportContent",
          text: "报告内容：",
        },
        {
          id: "reportForm",
          text: "报告形式：",
        },
      ],
      serviceEvaluationData: [
        {
          id: "serviceAttitude",
          text: "服务态度",
        },
        {
          id: "logisticsPrescription",
          text: "物流时效",
        },
      ],
    };

    this.satisfactionData = [
      {
        text: "优秀",
      },
      {
        text: "良好",
      },
      {
        text: "一般",
      },
      {
        text: "差",
      },
    ];
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const query = this.props.location && this.props.location.query;
    return (
      <div className="satisfactionSurvey">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          满意度调查
        </NavBar>
        <div className="content">
          <div className="content-title">
            <div className="line"></div>
            <span>申请单条码</span>
          </div>
          <div className="tm">
            {query &&
              query.map((value, key) => {
                return (
                  <div className="tm-item" key={key}>
                    <span>
                      {value.salesBarCode}
                      {key === query.length - 1 ? "" : "，"}
                    </span>
                  </div>
                );
              })}
          </div>
          <div className="content-title">
            <div className="line"></div>
            <span>报告评价</span>
          </div>
          {this.state.reportEvaluationData.map((v, k) => {
            return (
              <div className="item" key={k}>
                <div className="text">
                  <span style={{ color: "red" }}>*</span>
                  <span
                    style={
                      !v.value && this.state.submit ? { color: "red" } : {}
                    }
                  >
                    {k + 1}.{v.text}
                  </span>
                </div>
                {this.satisfactionData.map((sv, sk) => {
                  return (
                    <div
                      key={sk}
                      className={`sitme ${v.value === sv.text ? "select" : ""}`}
                      onClick={() => {
                        let reportEvaluationData = this.state.reportEvaluationData.map(
                          (val) => {
                            if (val === v) {
                              val.value = sv.text;
                            }
                            return val;
                          }
                        );
                        this.setState({
                          reportEvaluationData,
                        });
                      }}
                    >
                      <img
                        alt=""
                        src={
                          v.value === sv.text
                            ? require("../img/my1.svg")
                            : require("../img/my2.svg")
                        }
                      ></img>
                      <span>{sv.text}</span>
                    </div>
                  );
                })}
                {v.value === "差" ? (
                  <div
                    className="input-cha"
                    style={{
                      width: "90%",
                      paddingLeft: "0.05rem",
                      marginTop: "0.16rem",
                      marginBottom: "0.16rem",
                      border:'0.01rem solid rgb(243, 243, 243)'
                    }}
                  >
                    {v.value === "差" ? ( 
                        <TextareaItem
                          rows={3}
                          onChange={(el) => {
                            let reportEvaluationData = this.state.reportEvaluationData.map(
                              (val) => {
                                if (val === v) {
                                  val[val.id + "Remark"] = el;
                                }
                                return val;
                              }
                            );
                            this.setState({
                              reportEvaluationData,
                            });
                          }}
                          error={this.state.submit && !v[v.id + "Remark"]}
                          placeholder="请填写不满意原因"
                        ></TextareaItem> 
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })}
          <List>
            <TextareaItem
              rows={3}
              placeholder="请分享您对检测报告的感受"
              onChange={(el) => {
                this.setState({
                  reportFeel: el,
                });
              }}
            ></TextareaItem>
          </List>
          <div className="content-title">
            <div className="line"></div>
            <span>服务评价</span>
          </div>
          {this.state.serviceEvaluationData.map((v, k) => {
            return (
              <div className="item" key={k}>
                <div className="text">
                  <span style={{ color: "red" }}>*</span>
                  <span
                    style={
                      !v.value && this.state.submit ? { color: "red" } : {}
                    }
                  >
                    {k + 1}.{v.text}
                  </span>
                </div>
                {this.satisfactionData.map((sv, sk) => {
                  return (
                    <div
                      key={sk}
                      className={`sitme ${v.value === sv.text ? "select" : ""}`}
                      onClick={() => {
                        let serviceEvaluationData = this.state.serviceEvaluationData.map(
                          (val) => {
                            if (val === v) {
                              val.value = sv.text;
                            }
                            return val;
                          }
                        );
                        this.setState({
                          serviceEvaluationData,
                        });
                      }}
                    >
                      <img
                        alt=""
                        src={
                          v.value === sv.text
                            ? require("../img/my1.svg")
                            : require("../img/my2.svg")
                        }
                      ></img>
                      <span>{sv.text}</span>
                    </div>
                  );
                })}
                {v.value === "差" ? (
                  <div
                    className="input-cha"
                    style={{
                      width: "90%",
                      paddingLeft: "0.05rem",
                      marginTop: "0.16rem",
                      marginBottom: "0.16rem",
                      border:'0.01rem solid rgb(243, 243, 243)'
                    }}
                  >
                    {v.value === "差" ? (
                      <TextareaItem
                        error={this.state.submit && !v[v.id + "Remark"]}
                        placeholder="请填写不满意原因"
                        onChange={(el) => {
                          let serviceEvaluationData = this.state.serviceEvaluationData.map(
                            (val) => {
                              if (val === v) {
                                val[val.id + "Remark"] = el;
                              }
                              return val;
                            }
                          );
                          this.setState({
                            serviceEvaluationData,
                          });
                        }}
                      ></TextareaItem>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })}
          <List>
            <TextareaItem
              rows={3}
              placeholder="请填写投诉和建议"
              onChange={(el) => {
                this.setState({
                  complaintsAndRecommendations: el,
                });
              }}
            ></TextareaItem>
          </List>
          <div className="foot">
            <span
              onClick={() => {
                let params = {
                  reportIds: [],
                  reportFeel: this.state.reportFeel,
                  complaintsAndRecommendations: this.state
                    .complaintsAndRecommendations,
                };
                this.setState({
                  submit: true,
                });
                const query = this.props.location && this.props.location.query;
                if (query) {
                  query.selectData.forEach((element) => {
                    params.reportIds.push(element.id);
                  });
                }
                let submit = true;
                let reportEvaluationData = this.state.reportEvaluationData;
                let serviceEvaluationData = this.state.serviceEvaluationData;
                reportEvaluationData.forEach((element) => {
                  if (element.value) {
                    params[element.id] = element.value;
                    if (element.value === "差") {
                      if (element[element.id + "Remark"]) {
                        params[element.id + "Remark"] =
                          element[element.id + "Remark"];
                      } else {
                        submit = false;
                      }
                    }
                  } else {
                    submit = false;
                  }
                });
                serviceEvaluationData.forEach((element) => {
                  if (element.value) {
                    params[element.id] = element.value;
                    if (element.value === "差") {
                      if (element[element.id + "Remark"]) {
                        params[element.id + "Remark"] =
                          element[element.id + "Remark"];
                      } else {
                        submit = false;
                      }
                    }
                  } else {
                    submit = false;
                  }
                });
                if (!submit) {
                  Toast.info("请填写完整信息！", 1);
                  return;
                }
                Toast.loading("正在提交", 0);
                axios
                  .post("/open/common/satisfactions/post", params, {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    if (res) {
                      if (res.data && res.data.code === 400) {
                        Toast.info(res.data.msg, 1);
                      } else {
                        Toast.info("提交成功！", 1, () => {
                          this.props.history.goBack();
                          // query.call &&  query.call();
                        });
                      }
                    }
                  })
                  .catch(() => {
                    Toast.info("保存失败！", 1);
                  });
              }}
            >
              提交
            </span>
          </div>
        </div>
      </div>
    );
  }
}
