// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/1.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    position: absolute;\r\n    left: 0px;\r\n    top: 0px;\r\n    flex-direction: column;\r\n    overflow: hidden;\r\n    align-items: center;\r\n    background-color: white;\r\n    background-size: 100% 100%;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-size: 100% 100%;\r\n    background-repeat: no-repeat;\r\n}\r\n\r\n.login .logo {\r\n    width: 2rem;\r\n    margin-top: 1rem;\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n}\r\n\r\n.login .item {\r\n    width: 92%;\r\n    padding: 0.15rem;\r\n    margin-top: 0.3rem;\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n}\r\n\r\n.login .am-list-item .am-input-label.am-input-label-5 {\r\n    width: 0.3rem !important;\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n}\r\n\r\n.login .item .password {\r\n    position: relative;\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n}\r\n\r\n.login .item .password .eye {\r\n    position: absolute;\r\n    right: 0.1rem;\r\n    top: 0.15rem;\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n}\r\n\r\n.login .item .password .eye img {\r\n    width: 0.25rem;\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n}\r\n\r\n.login .item .submit {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-shrink: 0;\r\n    flex-grow: 0;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 0.9rem 0 0.2rem 0;\r\n}\r\n\r\n.login .item .submit .submit-text {\r\n    background: linear-gradient(to right, #69a1fe, #4967e1);\r\n    width: 90%;\r\n    height: 0.44rem;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    color: white;\r\n    border-radius: 0.22rem;\r\n}", ""]);
// Exports
module.exports = exports;
