export const homeReducer = (state = {}, action) => {
    switch (action.type) {
      case "selectedTab":
        return {
          ...state,
          selectedTab: action.data,
        }; 
      default:
        return state;
    }
  };
  