import React from "react";
import { NavBar, Icon, InputItem, Toast, List, Accordion, SearchBar, Drawer } from "antd-mobile";
import "./style/mailed.css";
import axios from "axios";

export default class Mailed extends React.Component {
  constructor(props) {
    super(props);
    const params =
      (this.props.location && this.props.location.query) || props || [];
    this.state = {
      applicationData: this.props.location.query
        ? this.props.location.query
        : [],
      expressInfo: {},
    };
    //手风琴索引
    this.packageIndex = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
  }

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this.getBatchSamples();
  };

  getBatchSamples = () => {
    const requestParam = {
      p: {},
      b: ["lsiBatchServiceImpl", this.state.applicationData]
    }
    axios
      .post(`/open/setmeal/batch-samples/queries`,
      requestParam,
      {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res && res.batchsamplelist) {
          this.setState(
            {
              expressSamplesData: res.batchsamplelist,
            },
            () => {
              Toast.hide();
              this.expressSamplesData = JSON.parse(
                JSON.stringify(res.batchsamplelist)
              );
            }
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 0:
        return this.renderBatchSample();
      default:
        return <div></div>;
    }
  };

  //选择订单样本
  choiceBatchSample = (value, arr, k, e) => {
    let setMealData = this.state.expressSamplesData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex.push(value.id);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      setMealData,
    });
  };

  //打开订单样本抽屉
  renderBatchSample = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              batchSamplesSearchBar: "",
              submitBatchSamples: false,
            });
          }}
        >
          添加订单样本
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.batchSamplesSearchBar || ""}
            onChange={(el) => {
              let expressSamplesData = JSON.parse(
                JSON.stringify(this.expressSamplesData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                batchSamplesSearchBar: el,
                expressSamplesData,
                packageIndex: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.expressSamplesData.map((v, k) => {
              let batchSamplesSearchBar =
                this.state.batchSamplesSearchBar &&
                this.state.batchSamplesSearchBar.toLowerCase();
              if (
                !batchSamplesSearchBar ||
                (v.ext$.sampletypename &&
                  v.ext$.sampletypename
                    .toLowerCase()
                    .indexOf(batchSamplesSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceBatchSample(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span style={{ flex: "2"}}>订单条码号: {v.ext$.salesbarcode}</span>
                        <span style={{ flex: "1"}}>患者姓名: {v.ext$.patientname}</span>
                        <span style={{ flex: "2"}}>样本名称: {v.ext$.sampletypename}</span>
                      </div>
                    }
                  >
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let expressSamplesData = this.state.expressSamplesData;
              let expressSamples = this.state.expressSamples || [];

              expressSamplesData.forEach((element) => {
                if (element.checked) {
                  expressSamples.push({
                    id: element.id,
                    batchId: element.batchId,
                    sampleTypeName: element.ext$.sampletypename,
                    sampleTypeAliasName: element.sampleTypeAliasName,
                    sampleCategory: element.sampleCategory,
                    sampleTypeCode: element.sampleTypeCode,
                  });
                }
              });
              this.state.expressSamples = expressSamples;
              this.setState({
                drawer: false,
                submitBatchSamples: false,
                batchSamplesSearchBar: "",
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  render() {
    return (
      <div className="mailed">
        <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            this.setState({
              drawer: false,
              batchSamplesSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
            >
              已邮寄填单
          </NavBar>
            <div className="center">
              <span className="title">快递单号</span>
              <div className="sender">
                <List>
                  <InputItem
                    placeholder="请填写快递单号"
                    onChange={(val) => {
                      this.setState({
                        courierNumber: val,
                      });
                    }}
                    error={this.state.submit && !this.state.courierNumber}
                    defaultValue={this.state.courierNumber}
                  ></InputItem>
                </List>
              </div>
              <span className="title">订单样本</span>
              <div className="item">
                <div className="form">
                  <List.Item
                    onClick={() => {
                      if (this.state.readonly) return false;
                      this.setState({
                        drawer: true,
                        drawerType: 0,
                        packageIndex: [],
                        expressSamplesData: JSON.parse(
                          JSON.stringify(this.expressSamplesData)
                        ),
                        expressSamplesError: false,
                      });
                    }}
                    extra="请选择"
                    arrow="horizontal"
                    error={
                      (!this.state.expressSamples &&
                        this.state.submit) ||
                      (this.state.submit &&
                        !this.state.expressSamples.length) ||
                      this.state.expressSamplesError
                    }
                    disabled={this.state.readonly}
                  >
                    {true ? <span className="sign">*</span> : null}
                        选择订单样本
                      </List.Item>
                  {this.state.expressSamples &&
                    this.state.expressSamples.length ? (
                    <List.Item multipleLine>
                      {this.state.expressSamples
                        .map((v, k) => {
                          return (
                            <div key={k} className="select-info2">
                              <img
                                src={require("../img/xx.png")}
                                alt=""
                              ></img>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  flexDirection: "column",
                                  flex: "1",
                                }}
                              >
                                {!this.state.readonly ? (
                                  <img
                                    alt=""
                                    className="close"
                                    src={require("../img/close.svg")}
                                    onClick={() => {
                                      let obj = this.state;
                                      obj.expressSamples.splice(
                                        obj.expressSamples.findIndex(
                                          (value) => value === v
                                        ), 1
                                      );
                                      this.letter.push(v.sampleCode);
                                      this.setState({
                                        obj,
                                      });
                                    }}
                                    style={{
                                      position: "absolute",
                                      right: "-0.1rem",
                                      top: "-0.1rem",
                                    }}
                                  ></img>
                                ) : null}
                                {v.sampleTypeName ? (
                                  <div className="content-item">
                                    <span className="content-title">
                                      样本名称：
                                      </span>
                                    <span className="content-lable">
                                      {v.sampleTypeName}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                    </List.Item>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="foot">
              <span
                className="submit"
                onClick={() => {
                  if (!this.state.courierNumber) {
                    Toast.info("请填写完整信息！");
                    this.setState({
                      submit: true,
                    });
                    return;
                  }
                  if (!this.state.expressSamples || this.state.expressSamples.length === 0) {
                    Toast.info("请选择订单样本！");
                    this.setState({
                      submit: true,
                    });
                    return;
                  }
                  Toast.loading("正在下单", 2);
                  let param = {
                    expressNo: this.state.courierNumber,
                    expressSamples: this.state.expressSamples
                  };
                  axios
                    .post("/open/setmeal/wxpush/express-numbers/action/add", param, {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then((res) => {
                      if (res && res.return_code === '200') {
                        Toast.success("下单成功！", 1, () => {
                          this.goBack();
                        });
                      }
                    })
                    .catch((error) => {
                      Toast.fail("提交失败！", 2);
                    });
                }}
              >
                确认提交
            </span>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
