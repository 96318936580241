import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  DatePicker,
  Picker,
  Toast,
  TextareaItem,
  Modal,
} from "antd-mobile";
import { nationData } from "../data/static";
import { connect } from "react-redux";
import axios from "axios";
import { applicationDetailAction } from "../../action/apply/applicationDetail";
import "./style/applyListInfo.css";
const alert = Modal.alert;
class HyApplicationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.applyListInfo = {
      status: this.props.location.status,
      salesBarCode: "",
      patientName: "",
      idNumber: "",
      patientBirth: "",
      gender: "",
      paperReport: "",
      inspectionOrganization: "",
      dept: "",
      customerName: "",
      diagonsis: "",
      targetedTherapyHistory: "",
      familyHistory: "",
      selectSetMeals: "",
      tumorType: "",
      projectNo: "",
      sampleInfos: "",
      showHospital: true,
      showDept: false,
      showCustomerName: false,
      receiveAmount: 0,
    };
    this.submitVerificationData = [
      "salesBarCode",
      "patientName",
      "patientBirth",
      "gender",
      "paperReport",
      "inspectionOrganization",
      "dept",
      "customerName",
      "diagonsis",
      "targetedTherapyHistory",
      "familyHistory",
      "selectSetMeals",
      "tumorType",
      "sampleInfos",
    ];
    const query =
      (this.props.location && this.props.location.query) ||
      this.props.applyListInfo ||
      {};
    this.state = {
      applyListInfo: Object.assign({}, this.applyListInfo, query),
      tumorType: [],
      nationData: JSON.parse(JSON.stringify(nationData)),
      packageIndex: [],
      packageIndex2: [],
      setMealData: [],
      setMealInfo: [],
      inspectionUnitData: [],
      currentSetMeal: {},
      sampleInfos: query.sampleInfos || [],
      selectSetMeals: query.selectSetMeals || [],
      reportTypeData: [
        {
          value: "院内",
          label: "院内",
        },
        {
          value: "院外",
          label: "院外",
        },
      ],
      subjectInfo: {},
      agentInfo: {
        personName: query.salesRepresentative,
        mobile: query.salesManPhone,
        email: query.salesEmail,
      },
    };
    //手风琴索引
    this.packageIndex = [];
    this.packageIndex2 = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
    this.sampleInfos = [];
    //当前修改地址
    this.currentModificationAddress = null;
  }

  boolSwitch = (val) => {
    let show = true;
    if (val) {
      if (typeof val === "string") {
        if (val === "false") {
          show = false;
        }
      } else {
        return val;
      }
    } else {
      show = false;
    }
    return show;
  };

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  setRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.applicationDetailAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.applicationDetailAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== "/sample" &&
      this.props.history.location.pathname !== "/cancel" &&
      this.props.history.location.pathname !== "/exceptionHandlingDetail"
    ) {
      this.clearRedux();
    }
  };
  render() {
    return (
      <div className="applyListInfo">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <NavBar
            mode="light"
            icon={<Icon type="left" />}
            onLeftClick={this.goBack}
          >
            申请单详情
          </NavBar>
          <div className="center">
            <div style={{ width: "100%", position: "relative" }}>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  display: "flex",
                  flex: "1",
                  zIndex: "999",
                  height: "100%",
                }}
              ></div>
              <List>
                <div className="item">
                  {/* <span className="title">患者信息</span> */}
                  <div className="form">
                    <InputItem
                     
                      disabled={this.state.applyListInfo.readonly}
                      defaultValue={this.state.applyListInfo.salesBarCode || ""}
                     
                      error={
                        (this.state.submit &&
                          !this.state.applyListInfo.salesBarCode) ||
                        this.state.salesBarCodeError
                      }
                   
                    >
                      申请单条码
                    </InputItem>
                    <InputItem
                      ref={(el) => (this.patientName = el)}
                      value={this.state.applyListInfo.patientName}
                      onChange={(el) => {
                        this.inputChange(el, "patientName");
                      }}
                      error={
                        this.state.submit &&
                        !this.state.applyListInfo.patientName
                      }
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.patientName.focus()}
                      >
                        <span style={{ color: "red" }}>*</span>
                        姓名
                      </div>
                    </InputItem>
                    <Picker
                      onOk={(v) => {
                        let applyListInfo = this.state.applyListInfo;
                        applyListInfo.gender = v[0];
                        this.setState({
                          applyListInfo,
                        });
                      }}
                      value={[this.state.applyListInfo.gender]}
                      data={[
                        {
                          value: "男",
                          label: "男",
                        },
                        {
                          value: "女",
                          label: "女",
                        },
                        {
                          value: "未知",
                          label: "未知",
                        },
                      ]}
                      cols={1}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item
                        arrow="horizontal"
                        error={
                          this.state.submit && !this.state.applyListInfo.gender
                        }
                      >
                        <span style={{ color: "red" }}>*</span>性别
                      </List.Item>
                    </Picker>
                    <DatePicker
                      mode="date"
                      minDate={new Date(1900, 0, 1, 0, 0, 0)}
                      maxDate={new Date()}
                      value={
                        this.state.applyListInfo.patientBirth &&
                        new Date(this.state.applyListInfo.patientBirth)
                      }
                      onOk={(val) => {
                        this.inputChange(this.formatTime(val), "patientBirth");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item arrow="horizontal">
                        <span className="sign">*</span>
                        出生年月
                      </List.Item>
                    </DatePicker>
                    <List.Item
                      extra={this.state.applyListInfo.nation || "请选择"}
                      onClick={() => {
                        let applyListInfo = this.state.applyListInfo;
                        if (applyListInfo.readonly) return false;
                        let nationData = this.state.nationData.map((v) => {
                          if (v.value === applyListInfo.nation) {
                            v.checked = true;
                          } else {
                            v.checked = false;
                          }
                          return v;
                        });
                        this.setState({
                          drawer: true,
                          drawerType: 5,
                          nationData,
                        });
                      }}
                      arrow="horizontal"
                      disabled={this.state.applyListInfo.readonly}
                    >
                      {true ? <span className="sign">*</span> : null}
                        民族
                      </List.Item>
                    <InputItem
                      type="text"
                      ref={(el) => (this.nativePlace = el)}
                      value={this.state.applyListInfo.nativePlace}
                      onChange={(el) => {
                        this.inputChange(el, "nativePlace");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.nativePlace.focus()}
                      >
                        籍贯
                      </div>
                    </InputItem>
                    <InputItem
                      type="text"
                      maxLength={64}
                      ref={(el) => (this.caseNumber = el)}
                      value={this.state.applyListInfo.caseNumber}
                      onChange={(el) => {
                        this.inputChange(el, "caseNumber");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.caseNumber.focus()}
                      >
                        {true ? <span className="sign">*</span> : null}
                        病案号
                      </div>
                    </InputItem>
                    <InputItem
                      ref={(el) => (this.idNumber = el)}
                      type="text"
                      maxLength={18}
                      value={this.state.applyListInfo.idNumber}
                      onChange={(el) => {
                        const req = /(^\d{17}([0-9]|X|x)$)/;
                        if (!req.test(el)) {
                          this.setState({
                            idNumberError: true,
                          });
                        } else {
                          this.setState({
                            idNumberError: false,
                          });
                        }
                        this.getBirth(el);
                        this.inputChange(el, "idNumber");
                      }}
                      onErrorClick={() => {
                        Toast.info("请填写正确的身份证号！", 1);
                      }}
                      error={this.state.idNumberError}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.idNumber.focus()}
                      >
                        身份证号
                      </div>
                    </InputItem>
                    <InputItem
                      ref={(el) => (this.reportReceiver = el)}
                      type="text"
                      maxLength={36}
                      value={this.state.applyListInfo.reportReceiver}
                      onChange={(el) => {
                        this.inputChange(el, "reportReceiver");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.reportReceiver.focus()}
                      >
                        <span className="sign">*</span>
                        报告接收人
                      </div>
                    </InputItem>
                    <InputItem
                      type="number"
                      maxLength={12}
                      ref={(el) => (this.reportPhone = el)}
                      value={this.state.applyListInfo.reportPhone}
                      onChange={(el) => {
                        const reqSmallPhone = /^1\d{10}$/;
                        const phone = /^0\d{2,3}-?\d{7,8}$/;
                        if (reqSmallPhone.test(el) || phone.test(el)) {
                          this.setState({
                            reportPhoneError: false,
                          });
                        } else {
                          this.setState({
                            reportPhoneError: true,
                          });
                        }
                        this.inputChange(el, "reportPhone");
                      }}
                      error={this.state.receivingPhoneError}
                      onErrorClick={() => {
                        Toast.info("请填写正确的联系方式！", 1);
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.reportPhone.focus()}
                      >
                        <span className="sign">*</span>
                        接收人电话
                      </div>
                    </InputItem>
                    <InputItem
                      ref={(el) => (this.reportAddress = el)}
                      type="text"
                      maxLength={60}
                      value={this.state.applyListInfo.reportAddress}
                      onChange={(el) => {
                        this.inputChange(el, "reportAddress");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.reportAddress.focus()}
                      >
                        <span className="sign">*</span>
                        邮寄地址
                      </div>
                    </InputItem>
                    <div className="form">
                      <List.Item
                        onClick={() => {
                          if (this.state.applyListInfo.readonly) return false;
                          this.setState({
                            drawer: true,
                            drawerType: 6,
                            packageIndex3: [],
                            inspectionItemsData: JSON.parse(
                              JSON.stringify(this.inspectionItemsData)
                            ),
                            inspectionItemsError: false,
                          });
                        }}
                        arrow="horizontal"
                        error={
                          (!this.state.applyListInfo.inspectionItems &&
                            this.state.submit) ||
                          (this.state.submit &&
                            !this.state.applyListInfo.inspectionItems.length) ||
                          this.state.inspectionItemsError
                        }
                      >
                        {true ? <span className="sign">*</span> : null}
                        检测项目
                      </List.Item>
                      {this.state.applyListInfo.inspectionItems &&
                        this.state.applyListInfo.inspectionItems.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.inspectionItems
                            .map((v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    {!this.state.applyListInfo.readonly ? (
                                      <img
                                        alt=""
                                        className="close"
                                        src={require("../img/close.svg")}
                                        onClick={() => {
                                          let applyListInfo = this.state
                                            .applyListInfo;
                                          applyListInfo.inspectionItems.splice(
                                            applyListInfo.inspectionItems.findIndex(
                                              (value) => value === v
                                            ),
                                          );
                                          this.letter.push(v.sampleCode);
                                          this.setState({
                                            applyListInfo,
                                          });
                                        }}
                                        style={{
                                          position: "absolute",
                                          right: "-0.1rem",
                                          top: "-0.1rem",
                                        }}
                                      ></img>
                                    ) : null}
                                    {v.productName ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          检测项目：
                                      </span>
                                        <span className="content-lable">
                                          {v.productName}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </List.Item>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="item">
                  {/* <span className="title">患者诊疗信息</span> */}
                  <div className="form">
                    <List.Item
                      multipleLine={true}
                      extra={
                        this.state.applyListInfo.hospital ||
                        "请选择"
                      }
                      error={
                        this.state.submit &&
                        !this.state.applyListInfo.hospital
                      }
                      onClick={() => {
                        let applyListInfo = this.state.applyListInfo;
                        if (applyListInfo.readonly) return false;
                        let inspectionUnitData =
                          this.state.inspectionUnitData &&
                          this.state.inspectionUnitData.map((v) => {
                            if (
                              v.hospitalName ===
                              applyListInfo.hospital
                            ) {
                              v.checked = true;
                            } else {
                              v.checked = false;
                            }
                            return v;
                          });
                        this.setState({
                          drawer: true,
                          drawerType: 3,
                          hospital: inspectionUnitData.hospitalName,
                        });
                      }}
                      arrow="horizontal"
                      disabled={this.state.applyListInfo.readonly}
                    >
                      {true ? <span className="sign">*</span> : null}
                      送检医院
                    </List.Item>
                    {this.state.applyListInfo.hospital === '其它医院' ? (<InputItem
                      placeholder="请填写其它医院名称"
                      ref={(el) => (this.otherHospitalName = el)}
                      type="text"
                      value={this.state.applyListInfo.otherHospitalName}
                      onChange={(el) => {
                        this.inputChange(el, "otherHospitalName");
                      }}
                      error={
                        this.state.submit && !this.state.applyListInfo.otherHospitalName
                      }
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.dept.focus()}
                      >
                        {true ? <span className="sign">*</span> : null}
                            其它医院名称
                        </div>
                    </InputItem>) : null}
                    <InputItem
                      ref={(el) => (this.dept = el)}
                      type="text"
                      maxLength={64}
                      value={this.state.applyListInfo.dept}
                      onChange={(el) => {
                        this.inputChange(el, "dept");
                      }}
                      error={
                        this.state.submit && !this.state.applyListInfo.dept
                      }
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.dept.focus()}
                      >
                        {true ? <span className="sign">*</span> : null}
                        送检科室
                      </div>
                    </InputItem>
                    <InputItem
                      placeholder="请填写送检医生"
                      type="text"
                      maxLength={64}
                      ref={(el) => (this.customerName = el)}
                      value={this.state.applyListInfo.customerName}
                      error={
                        this.state.submit &&
                        !this.state.applyListInfo.customerName
                      }
                      onChange={(el) => {
                        this.inputChange(el, "customerName");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.customerName.focus()}
                      >
                        {true ? <span className="sign">*</span> : null}
                        送检医生
                      </div>
                    </InputItem>
                    <List.Item
                      extra="请选择"
                      error={
                        this.state.submit &&
                        (!this.state.applyListInfo.tumourList
                          || this.state.applyListInfo.tumourList.length == 0)
                      }
                      onClick={() => {
                        let applyListInfo = this.state.applyListInfo;
                        if (applyListInfo.readonly) return false;
                        let tumorType = this.state.tumorType.map((v) => {
                          if (v.dictTextName === applyListInfo.tumorType) {
                            v.checked = true;
                          } else {
                            v.checked = false;
                          }
                          return v;
                        });
                        this.setState({
                          drawer: true,
                          drawerType: 1,
                          tumorType,
                        });
                      }}
                      arrow="horizontal"
                      disabled={this.state.applyListInfo.readonly}
                    >
                      {true ? <span className="sign">*</span> : null}
                      肿瘤类型
                    </List.Item>

                    {this.state.applyListInfo.tumourList &&
                      this.state.applyListInfo.tumourList.length ? (
                      <List.Item multipleLine>
                        {this.state.applyListInfo.tumourList
                          .map((v, k) => {
                            return (
                              <div key={k} className="select-info2">
                                <img
                                  src={require("../img/xx.png")}
                                  alt=""
                                ></img>
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                  }}
                                >
                                  {!this.state.applyListInfo.readonly && false ? (
                                    <img
                                      alt=""
                                      className="close"
                                      src={require("../img/close.svg")}
                                      onClick={() => {
                                        let applyListInfo = this.state
                                          .applyListInfo;
                                        applyListInfo.tumourList.splice(
                                          applyListInfo.tumourList.findIndex(
                                            (value) => value === v
                                          ), 1
                                        );
                                        this.letter.push(v.tumorCode);
                                        this.setState({
                                          applyListInfo,
                                        });
                                      }}
                                      style={{
                                        position: "absolute",
                                        right: "-0.1rem",
                                        top: "-0.1rem",
                                      }}
                                    ></img>
                                  ) : null}
                                  {v.tumorName ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        肿瘤类型：
                                      </span>
                                      <span className="content-lable">
                                        {v.tumorName}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                      </List.Item>
                    ) : null}

                    <List.Item
                      extra="请选择"
                      error={
                        this.state.submit &&
                        (!this.state.applyListInfo.pathologyList
                          || this.state.applyListInfo.pathologyList.length == 0)
                      }
                      onClick={() => {
                        let applyListInfo = this.state.applyListInfo;
                        if (applyListInfo.readonly) return false;
                        let pathologyType = this.state.pathologyType.map((v) => {
                          if (v.pathologyName === applyListInfo.pathologyName) {
                            v.checked = true;
                          } else {
                            v.checked = false;
                          }
                          return v;
                        });
                        this.setState({
                          drawer: true,
                          drawerType: 8,
                          pathologyType,
                        });
                      }}
                      arrow="horizontal"
                      disabled={this.state.applyListInfo.readonly}
                    >
                      {true ? <span className="sign">*</span> : null}
                          病理类型
                      </List.Item>

                    {this.state.applyListInfo.pathologyList &&
                      this.state.applyListInfo.pathologyList.length ? (
                      <List.Item multipleLine>
                        {this.state.applyListInfo.pathologyList
                          .map((v, k) => {
                            return (
                              <div key={k} className="select-info2">
                                <img
                                  src={require("../img/xx.png")}
                                  alt=""
                                ></img>
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                  }}
                                >
                                  {!this.state.applyListInfo.readonly && false ? (
                                    <img
                                      alt=""
                                      className="close"
                                      src={require("../img/close.svg")}
                                      onClick={() => {
                                        let applyListInfo = this.state
                                          .applyListInfo;
                                        applyListInfo.pathologyList.splice(
                                          applyListInfo.pathologyList.findIndex(
                                            (value) => value === v
                                          ), 1
                                        );
                                        this.letter.push(v.pathologyCode);
                                        this.setState({
                                          applyListInfo,
                                        });
                                      }}
                                      style={{
                                        position: "absolute",
                                        right: "-0.1rem",
                                        top: "-0.1rem",
                                      }}
                                    ></img>
                                  ) : null}
                                  {v.pathologyName ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        病理类型：
                                      </span>
                                      <span className="content-lable">
                                        {v.pathologyName}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                      </List.Item>
                    ) : null}

                    <InputItem
                      ref={(el) => (this.clinicalStage = el)}
                      type="text"
                      maxLength={64}
                      value={this.state.applyListInfo.clinicalStage}
                      onChange={(el) => {
                        this.inputChange(el, "clinicalStage");
                      }}
                      error={
                        this.state.submit && !this.state.applyListInfo.clinicalStage
                      }
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => this.clinicalStage.focus()}
                      >
                        {true ? <span className="sign">*</span> : null}
                        临床分期
                      </div>
                    </InputItem>
                    <DatePicker
                      mode="date"
                      minDate={new Date(1900, 0, 1, 0, 0, 0)}
                      maxDate={new Date()}
                      value={
                        this.state.applyListInfo.firstDiagnosisTime &&
                        new Date(this.state.applyListInfo.firstDiagnosisTime)
                      }
                      onOk={(val) => {
                        this.inputChange(this.formatTime(val), "firstDiagnosisTime");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item arrow="horizontal">
                        <span className="sign">*</span>
                      初次确诊时间
                      </List.Item>
                    </DatePicker>
                    <InputItem
                      type="text"
                      maxLength={255}
                      value={this.state.applyListInfo.primarySite || ""}
                      onChange={(el) => {
                        this.inputChange(el, "primarySite");
                      }}
                      error={
                        this.state.submit &&
                        !this.state.applyListInfo.primarySite
                      }
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {true ? <span className="sign">*</span> : null}
                        原发部位
                      </div>
                    </InputItem>
                    <Picker
                      onOk={(v) => {
                        let applyListInfo = this.state.applyListInfo;
                        applyListInfo.transferBench = v[0];
                        this.setState({
                          applyListInfo,
                        });
                      }}
                      value={[this.state.applyListInfo.transferBench]}
                      data={[
                        {
                          value: "0",
                          label: "否",
                        },
                        {
                          value: "1",
                          label: "是",
                        },

                      ]}
                      cols={1}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item
                        arrow="horizontal"
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.transferBench
                        }
                      >
                        {true ? <span className="sign">*</span> : null}
                        是否转移
                      </List.Item>
                    </Picker>
                    {this.state.applyListInfo.transferBench === "1" ? (
                      <InputItem
                        placeholder="请填写转移部位"
                        type="text"
                        maxLength={255}
                        value={this.state.applyListInfo.metastases || ""}
                        onChange={(el) => {
                          this.inputChange(el, "metastases");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.metastases
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {true ? <span className="sign">*</span> : null}
                          转移部位
                        </div>
                      </InputItem>
                    ) : null}
                    <List.Item
                    onClick={() => {
                  
                      this.setState({
                        drawer: true,
                        drawerType: 7,
                        packageIndex: [],
                        hisTreatmentMethod: this.state.treatmentMethodSelectData,
                
                      });
                    }}
                    disabled={this.state.applyListInfo.readonly}
                    extra="请选择"
                    arrow="horizontal"
                    error={
                      this.state.submit &&
                      !this.state.applyListInfo.hisTreatmentMethod
                    }
                  >
                      {<span className="sign">*</span>}
                    既往治疗方式
                      </List.Item>
                    {this.state.applyListInfo.hisTreatmentMethods &&
                    this.state.applyListInfo.hisTreatmentMethods.length ? (
                    <List.Item multipleLine>
                      {this.state.applyListInfo.hisTreatmentMethods
                        .map((v, k) => {
                          return (
                            <div key={k} className="select-info2">
                              <img
                                src={require("../img/xx.png")}
                                alt=""
                              ></img>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  flexDirection: "column",
                                  flex: "1",
                                }}
                              >
                                {!this.state.readonly ? (
                                  <img
                                    alt=""
                                    className="close"
                                    src={require("../img/close.svg")}
                                    onClick={() => {
                                      let obj = this.state.applyListInfo;
                                      obj.hisTreatmentMethods.splice(
                                        obj.hisTreatmentMethods.findIndex(
                                          (value) => value === v
                                        ),
                                      );
                                      this.letter.push(v.hisTreatmentMethod);
                                      this.setState({
                                        obj,
                                      });
                                    }}
                                    style={{
                                      position: "absolute",
                                      right: "0.1rem",
                                      top: "-0.1rem",
                                    }}
                                  ></img>
                                ) : null}
                                {v.hisTreatmentMethod ? (
                                  <div className="content-item">
                                    <span className="content-title">
                                      治疗方式：
                                      </span>
                                    <span className="content-lable">
                                      {v.hisTreatmentMethod}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                    </List.Item>
                  ) : null}

                    <TextareaItem
                      type="text"
                      maxLength={255}
                      value={this.state.applyListInfo.historySurgery || ""}
                      onChange={(el) => {
                        this.inputChange(el, "historySurgery");
                      }}
                      rows={3}
                      disabled={this.state.applyListInfo.readonly}
                      title={
                        <div>
                          <span>手术史</span>
                        </div>
                      }
                    >
                    </TextareaItem>
                    <DatePicker
                      mode="date"
                      minDate={new Date(1900, 0, 1, 0, 0, 0)}
                      maxDate={new Date()}
                      value={
                        this.state.applyListInfo.hisTransfusionTime &&
                        new Date(this.state.applyListInfo.hisTransfusionTime)
                      }
                      onOk={(val) => {
                        this.inputChange(this.formatTime(val), "hisTransfusionTime");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item arrow="horizontal">
                        输血史/输血时间
                      </List.Item>
                    </DatePicker>
                    <List.Item
                      arrow="horizontal"
                      extra="请选择"
                      onClick={() => {
                        if (this.state.applyListInfo.readonly) return false;
                        this.hisDrugs = JSON.parse(
                          JSON.stringify(
                            this.state.hisDrugs || []
                          )
                        );
                        this.setState({
                          diagnosisModal: true,
                          modalType: "hisDrugs",
                        });
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    // error={
                    //   this.state.submit &&
                    //   !this.state.applyListInfo.hisDrugs
                    // }
                    >
                      既往治疗药物
                    </List.Item>
                    {this.state.applyListInfo.hisDrugs &&
                      this.state.applyListInfo.hisDrugs.length ? (
                      <List.Item multipleLine>
                        {this.state.applyListInfo.hisDrugs.map(
                          (v, k) => {
                            return (
                              <div key={k} className="select-info2">
                                <img
                                  src={require("../img/xx.png")}
                                  alt=""
                                ></img>
                                <div>
                                  {v.medicinePlan ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        用药方案：
                                      </span>
                                      <span className="content-lable">
                                        {v.medicinePlan}
                                      </span>
                                    </div>
                                  ) : null}
                                    {v.medicineStartTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          用药开始时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicineStartTime}
                                        </span>
                                      </div>
                                    ) : null}
                                      {v.medicineEndTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          用药结束时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicineEndTime}
                                        </span>
                                      </div>
                                    ) : null}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </List.Item>
                    ) : null}
                    <InputItem
                      type="text"
                      maxLength={256}
                      value={this.state.applyListInfo.hisGenetic || ""}
                      onChange={(el) => {
                        this.inputChange(el, "hisGenetic");
                      }}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        基因检测史
                      </div>
                    </InputItem>
                    <Picker
                      onOk={(v) => {
                        let applyListInfo = this.state.applyListInfo;
                        applyListInfo.geneMutationFlag = v[0];
                        this.setState({
                          applyListInfo,
                        });
                      }}
                      value={[this.state.applyListInfo.geneMutationFlag]}
                      data={[
                        {
                          value: "0",
                          label: "否",
                        },
                        {
                          value: "1",
                          label: "是",
                        },
                      ]}
                      cols={1}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item
                        arrow="horizontal"
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.geneMutationFlag
                        }
                      >
                        <span className="sign">*</span>
                        亲属是否存在特定基因胚系突变
                      </List.Item>
                    </Picker>
                    {this.state.applyListInfo.geneMutationFlag === '1' ? (
                      <InputItem
                        type="text"
                        maxLength={128}
                        value={this.state.applyListInfo.geneMutation || ""}
                        onChange={(el) => {
                          this.inputChange(el, "geneMutation");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.geneMutation
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span className="sign">*</span>
                          突变基因
                        </div>
                      </InputItem>
                    ) : null}
                    {this.state.applyListInfo.geneMutationFlag === '1' ? (
                      <InputItem
                        type="text"
                        maxLength={32}
                        value={this.state.applyListInfo.carrierRelation || ""}
                        onChange={(el) => {
                          this.inputChange(el, "carrierRelation");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.carrierRelation
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span className="sign">*</span>
                          与本人关系
                        </div>
                      </InputItem>
                    ) : null}
                    {this.state.applyListInfo.geneMutationFlag === '1' ? (
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.selfProductFlag = v[0];
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.selfProductFlag]}
                        data={[
                          {
                            value: "0",
                            label: "否",
                          },
                          {
                            value: "1",
                            label: "是",
                          },
                        ]}
                        disabled={this.state.applyListInfo.readonly}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                        // error={
                        //   this.state.submit &&
                        //   !this.state.applyListInfo.selfProductFlag
                        // }
                        >
                          突变鉴定是否为本公司检测产品
                        </List.Item>
                      </Picker>
                    ) : null}
                    {this.state.applyListInfo.geneMutationFlag === '1' && this.state.applyListInfo.selfProductFlag === '1' ? (
                      <InputItem
                        type="text"
                        maxLength={128}
                        value={this.state.applyListInfo.hisProductName || ""}
                        onChange={(el) => {
                          this.inputChange(el, "hisProductName");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.hisProductName
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span className="sign">*</span>
                          产品名称
                        </div>
                      </InputItem>
                    ) : null}
                    <Picker
                      onOk={(v) => {
                        let applyListInfo = this.state.applyListInfo;
                        applyListInfo.diseaseHistory = v[0];
                        this.setState({
                          applyListInfo,
                        });
                      }}
                      value={[this.state.applyListInfo.diseaseHistory]}
                      data={[
                        {
                          value: "无",
                          label: "无",
                        },
                        {
                          value: "肿瘤",
                          label: "肿瘤",
                        },
                        {
                          value: "其他",
                          label: "其他",
                        },
                      ]}
                      cols={1}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item
                        arrow="horizontal"
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.diseaseHistory
                        }
                      >
                        <span className="sign">*</span>
                        既往病史
                      </List.Item>
                    </Picker>
                    {this.state.applyListInfo.diseaseHistory === '其他' ? (
                      <InputItem
                        type="text"
                        value={this.state.applyListInfo.otherDiseaseHistory || ""}
                        onChange={(el) => {
                          this.inputChange(el, "otherDiseaseHistory");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.otherDiseaseHistory
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span className="sign">*</span>
                          其他病史
                        </div>
                      </InputItem>
                    ) : null}
                    {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                      <InputItem
                        type="text"
                        value={this.state.applyListInfo.hisTumorType || ""}
                        onChange={(el) => {
                          this.inputChange(el, "hisTumorType");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.hisTumorType
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          肿瘤类型
                      </div>
                      </InputItem>
                    ) : null}
                    {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                      <InputItem
                        type="text"
                        maxLength={128}
                        value={this.state.applyListInfo.hisPathologicalType || ""}
                        onChange={(el) => {
                          this.inputChange(el, "hisPathologicalType");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.hisPathologicalType
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          病理类型
                        </div>
                      </InputItem>
                    ) : null}
                    {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                      <InputItem
                        type="text"
                        maxLength={64}
                        value={this.state.applyListInfo.hisClinicalStages || ""}
                        onChange={(el) => {
                          this.inputChange(el, "hisClinicalStages");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.hisClinicalStages
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          临床分期
                        </div>
                      </InputItem>
                    ) : null}
                    {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                      <InputItem
                        type="number"
                        value={this.state.applyListInfo.hisDiagnosisAge || ""}
                        onChange={(el) => {
                          this.inputChange(el, "hisDiagnosisAge");
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.hisDiagnosisAge
                        }
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          确诊年龄
                        </div>
                      </InputItem>
                    ) : null}
                    {this.state.applyListInfo.hisTumorType && this.state.applyListInfo.hisTumorType.indexOf("乳腺癌") > -1 ? (
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.hisBreastCancerType = v[0];
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.hisBreastCancerType]}
                        data={[
                          {
                            value: "单侧",
                            label: "单侧",
                          },
                          {
                            value: "双侧",
                            label: "双侧",
                          },
                          {
                            value: "三阴性乳腺癌",
                            label: "三阴性乳腺癌",
                          },
                        ]}
                        cols={1}
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.hisBreastCancerType
                          }
                        >
                          <span className="sign">*</span>
                        乳腺癌类型
                      </List.Item>
                      </Picker>
                    ) : null}
                    <Picker
                      onOk={(v) => {
                        let applyListInfo = this.state.applyListInfo;
                        if (v[0] !== 2)
                          applyListInfo.familyHistoryInfo = null;
                        applyListInfo.familyHistory = v[0];
                        this.setState({
                          applyListInfo,
                        });
                      }}
                      value={[this.state.applyListInfo.familyHistory]}
                      data={[
                        {
                          value: "无",
                          label: "无",
                        },
                        {
                          value: "肿瘤",
                          label: "肿瘤",
                        },
                      ]}
                      cols={1}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <List.Item
                        arrow="horizontal"
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.familyHistory
                        }
                      >
                        {true ? <span className="sign">*</span> : null}
                        亲属中患有明确诊断的疾病
                      </List.Item>
                    </Picker>
                    {this.state.applyListInfo.familyHistory &&
                      this.state.applyListInfo.familyHistory === "肿瘤" ? (
                      <div className="child-item">
                        <List.Item
                          arrow="horizontal"
                          extra="请选择"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.familyHistoryInfo
                          }
                          onClick={() => {
                            if (this.state.applyListInfo.readonly) return false;
                            // this.familyHistoryInfoDak = JSON.parse(
                            //   JSON.stringify(
                            //     this.state.familyHistoryInfo || []
                            //   )
                            // );
                            this.setState({
                              familyHistoryModal: true,
                            });
                          }}
                          disabled={this.state.applyListInfo.readonly}
                        >
                          {true ? <span className="sign">*</span> : null}
                          家族史信息
                        </List.Item>
                      </div>
                    ) : null}
                    {this.state.applyListInfo.familyHistoryInfo &&
                      this.state.applyListInfo.familyHistoryInfo.length ? (
                      <List.Item multipleLine>
                        {this.state.applyListInfo.familyHistoryInfo.map(
                          (v, k) => {
                            return (
                              <div key={k} className="select-info2">
                                <img
                                  src={require("../img/xx.png")}
                                  alt=""
                                ></img>
                                <div>
                                  {v.patientRelationship ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        患肿瘤亲属：
                                      </span>
                                      <span className="content-lable">
                                        {v.patientRelationship}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.otherTumorType ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        肿瘤类型：
                                      </span>
                                      <span className="content-lable">
                                        {v.otherTumorType}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.otherBreastCancerType ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        乳腺癌类型：
                                      </span>
                                      <span className="content-lable">
                                        {v.otherBreastCancerType}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.otherPatientAge ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        确诊年龄：
                                      </span>
                                      <span className="content-lable">
                                        {v.otherPatientAge}
                                      </span>
                                    </div>
                                  ) : null}

                                  {v.otherClinicalStages ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        临床分期：
                                      </span>
                                      <span className="content-lable">
                                        {v.otherClinicalStages}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </List.Item>
                    ) : null}
                  </div>
                  <div className="item">
                    {/* <span className="title">样本信息</span> */}
                    <div className="form">
                      {this.state.applyListInfo.sampleInfos &&
                        this.state.applyListInfo.sampleInfos.length ? (
                        <List.Item
                          onClick={() => {
                            if (this.state.applyListInfo.readonly) return;
                            this.setState({
                              drawer: true,
                              drawerType: 1,
                              packageIndex: [],
                              sampleData: JSON.parse(
                                JSON.stringify(this.sampleData)
                              ),
                              sampleInfosError: false,
                            });
                          }}
                          extra="请选择"
                          arrow="horizontal"
                          error={
                            (!this.state.applyListInfo.sampleInfos &&
                              this.state.submit) ||
                            (this.state.submit &&
                              !this.state.applyListInfo.sampleInfos.length) ||
                            this.state.sampleInfosError
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                          样本信息
                        </List.Item>
                      ) : null}
                      {this.state.applyListInfo.sampleInfos &&
                        this.state.applyListInfo.sampleInfos.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.sampleInfos
                            .sort((a, b) =>
                              a.sampleCode.localeCompare(b.sampleCode)
                            )
                            .map((v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    <span>样本{v.sampleCode}</span>
                                    {v.sampleTypeName ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          样本类型：
                                        </span>
                                        <span className="content-lable">
                                          {v.sampleTypeName}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.sampleNum ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          数量：
                                        </span>
                                        <span className="content-lable">
                                          {v.sampleNum + v.numberUnit}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.spec ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          容量：
                                        </span>
                                        <span className="content-lable">
                                          {v.spec + "ml"}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.collectTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          采样时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.collectTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.diagonsisNo ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          病理号：
                                        </span>
                                        <span className="content-lable">
                                          {v.diagonsisNo}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.tissueFixationTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          组织固定开始时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.tissueFixationTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.surgicalTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          样本手术日期：
                                        </span>
                                        <span className="content-lable">
                                          {v.surgicalTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.collectLocation ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          取样部位：
                                        </span>
                                        <span className="content-lable">
                                          {v.collectLocation}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.returnFlag ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          归还样本：
                                        </span>
                                        <span className="content-lable">
                                          {v.returnFlag}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.returnReceiver ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          归还联系人：
                                        </span>
                                        <span className="content-lable">
                                          {v.returnReceiver}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.returnPhone ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          联系人电话：
                                        </span>
                                        <span className="content-lable">
                                          {v.returnPhone}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.returnAddress ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          归还地址：
                                        </span>
                                        <span className="content-lable">
                                          {v.returnAddress}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </List.Item>
                      ) : null}
                    </div>
                    <InputItem
                      ref={(el) => (this.sampleBoxNo = el)}
                      type="text"
                      value={this.state.applyListInfo.sampleBoxNo}
                      disabled={this.state.applyListInfo.readonly}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        样本盒编号
                        </div>
                    </InputItem>
                  </div>
                </div>
              </List>
            </div>
          </div>
          <div className="foot">
           
            {(
              <span
                className="submit"
                onClick={(e) => {
                  if (!this.state.applyListInfo.sampleInfos || this.state.applyListInfo.sampleInfos.length === 0) {
                    Toast.info("请录入样本信息！", 3);
                    return;
                  }
                  alert("提示", "请确认是否采集完毕?", [
                    { text: "否" },
                    {
                      text: "是",
                      onPress: () => {
                        Toast.loading("正在提交", 1);
                        axios
                          .put(
                            `/open/setmeal/wxpush/sample-collections/action/finish`,
                            this.state.applyListInfo,
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          )
                          .then((res) => {
                            if (res) {
                              Toast.info("提交成功", 2, () => {
                               
                                this.props.history.goBack();

                              });

                            }
                          })
                          .catch(() => {
                            Toast.info("提交失败！", 1);
                          });
                      },
                    },
                  ]);
                }}
              >
                样本采集完毕
              </span>
            ) }
        
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.applicationDetailReducer,
  };
}

export default connect(mapStateToProps, { applicationDetailAction })(HyApplicationDetails);

