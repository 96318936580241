import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  DatePicker,
  Modal,
  Accordion,
  Picker,
  Drawer,
  Toast,
  SearchBar,
  TextareaItem,
} from "antd-mobile";
import { nationData } from "../data/static";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import wx from "weixin-js-sdk";
import { connect } from "react-redux";
import "./style/applyListInfo.css";
import { brochureApplyAction } from "../../action/apply/brochureApply";
const alert = Modal.alert;
class BrochureApply extends React.Component {
  constructor(props) {
    super(props);
    this.applyListInfo = {
      status: this.props.location.status,
      id: "",
      consignee: "",
      consigneePhone: "",
      consigneeAddress: "",
      consigneeNum: "",
      createdByName: "",
      createdTime: "",
    };
    this.submitVerificationData = [
      "id", // 条码
      "consignee", // 收件人
      "consigneePhone", // 收件电话
      "consigneeAddress", // 收件地址
      "consigneeNum", // 收件单号
      "createdByName", // 制单人名称
      "createdTime", // 制单时间
    ];
    const query =
      (this.props.location && this.props.location.query) ||
      this.props.applyListInfo ||
      {};
    this.state = {
      applyListInfo: Object.assign({}, this.applyListInfo, query),
      tumorType: [],
      pathologyType: [],
      nationData: JSON.parse(JSON.stringify(nationData)),
      packageIndex2: [], // 样本类型
      setMealData: [],
      setMealInfo: [],
      inspectionUnitData: [],
      currentSetMeal: {},
      brochureApplyList: query.brochureApplyList || [],
    };
    this.state.applyListInfo.createdByName = localStorage.username;
    this.state.applyListInfo.createdById = localStorage.userId;
    //手风琴索引
    this.packageIndex = [];
    this.packageIndex2 = [];
    this.packageIndex3 = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
    this.brochureApplyList = [];
    //当前修改地址
    this.currentModificationAddress = null;
  }

  setRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.brochureApplyAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.brochureApplyAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  submitVerification = () => {
    const applyListInfo = this.state.applyListInfo;
    applyListInfo.selectSetMeals = this.state.selectSetMeals;
    this.setState({
      applyListInfo,
    });
    return true;
  };

  //返回首页
  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  //录入输入框信息
  inputChange = (v, k) => {
    let newApplyListInfo = this.state.applyListInfo;
    newApplyListInfo[k] = v;
    this.setState({
      applyListInfo: newApplyListInfo,
    });
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== "/payment" &&
      this.props.history.location.pathname !== "/discount"
    ) {
      this.clearRedux();
    }
  }

  //选择样本类型
  choiceSampleType = (value, arr, k, e) => {
    let brochureData = this.state.brochureData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex2 = this.state.packageIndex2;
    const indexOf = packageIndex2.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex2.push(value.id);
    } else {
      packageIndex2.splice(indexOf, 1);
    }
    this.setState({
      packageIndex2,
      brochureData,
    });
  };


  choosePackage = (value, item, select) => {
    let setMealInfo = this.state.setMealInfo;
    setMealInfo = setMealInfo.map((v) => {
      if (value === v) {
        v.checked = !v.checked;
      }
      return v;
    });

    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.setMealCode + value.templateId);
    if (indexOf < 0) {
      packageIndex.push(value.setMealCode + value.templateId);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      setMealInfo,
    });
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  formatDateTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()} ${val.getHours() < 10 ? "0" + val.getHours() : val.getHours()}:${val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes()}:${val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds()}`;
  };

  boolSwitch = (val) => {
    let show = true;
    if (val) {
      if (typeof val === "string") {
        if (val === "false") {
          show = false;
        }
      } else {
        return val;
      }
    } else {
      show = false;
    }
    return show;
  };

  setSetmealInfo = (v, key, val) => {
    let setMealInfo = this.state.setMealInfo;
    setMealInfo.map((element) => {
      if (element === v) {
        element[key] = val;
      }
      return element;
    });
    this.setState({
      setMealInfo,
    });
  };

  componentDidMount() {
    Toast.loading("正在加载", 2);
    if (!this.state.applyListInfo.id) {
      axios
        .get("/open/brochure/wxpush/selectMaxId")
        .then((res) => {
          if (res && res.id) {
            const applyListInfo = this.state.applyListInfo;
            applyListInfo.id = res.id;
            //this.setMealData = JSON.parse(JSON.stringify(res.id));
            this.setState(
              {
                applyListInfo,
              },
              () => Toast.hide()
            );
          }
        })
        .catch((e) => {
          Toast.fail("加载失败", 2);
        });

    }


    this.setState({
      familyHistoryInfo:
        this.state.applyListInfo.familyHistoryInfo &&
        this.state.applyListInfo.familyHistoryInfo.map((v) => {
          v.checked = true;
          return v;
        }),
    });

    axios
      .get("/open/brochure/getSampleTypeInfo")
      .then((res) => {
        if (res && res.dicttextlist) {
          this.brochureData = JSON.parse(JSON.stringify(res.dicttextlist));
          this.setState(
            {
              brochureData: res.dicttextlist,
            },
            () => Toast.hide()
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  }



  setSampleInput = (id, key, val) => {
    let brochureData = this.state.brochureData.map((value) => {
      if (value.id.toString() === id) {
        value[key] = val;
      }
      return value;
    });
    this.setState({
      brochureData,
    });
  };

  //打开样本类型抽屉
  renderSampleInformation = () => {
    return (
      <div className="sample-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              sampleInformationSearchBar: "",
              submitSampleInfos: false,
            });
          }}
        >
          添加宣传册信息
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.sampleInformationSearchBar || ""}
            onChange={(el) => {
              let setMealData = JSON.parse(
                JSON.stringify(this.setMealData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                sampleInformationSearchBar: el,
                packageIndex: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex2}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.brochureData.map((v, k) => {
              let sampleInformationSearchBar =
                this.state.sampleInformationSearchBar &&
                this.state.sampleInformationSearchBar.toLowerCase();
              if (
                !sampleInformationSearchBar ||
                (v.dictTextName &&
                  v.dictTextName
                    .toLowerCase()
                    .indexOf(sampleInformationSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceSampleType(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.dictTextName}</span>
                      </div>
                    }
                  >
                    <div className="showItem">
                      <List>
                        <InputItem
                          placeholder="请填写宣传册数量"
                          value={v.num || ""}
                          onChange={(val) => {
                            this.setSampleInput(v.id, "num", val);
                          }}
                          error={
                            v.checked &&
                            this.state.submitSampleInfos &&
                            !v.num
                          }
                          type="number"
                        >
                          <div>
                            <span style={{ color: "red" }}>*</span>数量
                          </div>
                        </InputItem>
                      </List>
                    </div>
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let setMealData = this.state.brochureData;
              let applyListInfo = this.state.applyListInfo;
              let brochureApplyList = applyListInfo.brochureApplyList || [];
              let submit = false;
              setMealData.forEach((element) => {
                if (element.checked) {
                  if (!element["num"]) {
                    submit = true;
                  }

                }
              });
              if (submit) {
                Toast.info("请填写必填信息！", 1);
                this.setState({
                  submitSampleInfos: true,
                });
                return;
              }
              setMealData.forEach((element) => {
                if (element.checked) {
                  brochureApplyList.push({
                    pamphletName: element.dictTextName,
                    num: element.num

                  });
                }
              });
              applyListInfo.brochureApplyList = brochureApplyList;
              this.setState({
                drawer: false,
                submitSampleInfos: false,
                applyListInfo,
                sampleInformationSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 1:
        return this.renderSampleInformation();
      default:
        return <div></div>;
    }
  };

  getPathologyType = (id) => {
    axios
      .get(`/open/basedata/pathologies/${id}/queries`)
      .then((res) => {
        if (res && res.data) {
          this.setState({
            pathologyType: res.data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  submit = (res, applyListInfo) => {
    axios
      .post(
        `/open/setmeal/wxpush/${res.id}/submitBatchInfoByWx`,
        applyListInfo,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        Toast.success("提交成功", 2);
      })
      .catch(() => {
        Toast.info("加载失败！", 2);
      });
  };

  render() {
    return (
      <div className="applyListInfo">
        <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            if (this.state.drawerType === 1) {
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.selectSetMeals = this.selectSetMeals;
              this.setState({
                applyListInfo,
              });
            }
            this.setState({
              drawer: false,
              setMealSearchBar: "",
              sampleInformationSearchBar: "",
              nationSearchBar: "",
              inspectionUnitSearchBar: "",
              inspectionItemsSearchBar: "",
              tumorTypeSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexShrink: "0",
            flexGrow: "0",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
            >
              在线申请
            </NavBar>
            <div className="center">
              <div style={{ width: "100%" }}>
                <List>
                  <div className="item">
                    <div className="form">
                      <InputItem
                        value={this.state.applyListInfo.id || ""}
                        editable={false}>
                        申请编号
                      </InputItem>
                      <DatePicker
                        mode="date"
                        value={
                          new Date()
                        }
                        onChange={(el) => {
                          this.inputChange(el, "createdTime");
                        }}
                        disabled={true}
                      >
                        <List.Item arrow="horizontal">
                          申请日期
                          </List.Item>
                      </DatePicker>

                      <InputItem
                        placeholder="请填写申请人"
                        type="text"
                        maxLength={64}
                        value={this.state.applyListInfo.createdByName || ''}
                        disabled={this.state.applyListInfo.readonly}
                        onChange={(el) => {
                          this.inputChange(el, "createdByName");
                        }}
                      >
                        申请人
                      </InputItem>

                      <InputItem
                        placeholder="请填写收件人"
                        type="text"
                        value={this.state.applyListInfo.consignee}
                        maxLength={36}
                        onChange={(el) => {
                          this.inputChange(el, "consignee");
                        }}
                      >
                        <span className="sign">*</span>
                          收件人
                      </InputItem>
                      <InputItem
                        placeholder="请填写收件人电话"
                        type="number"
                        maxLength={11}
                        ref={(el) => (this.consigneePhone = el)}
                        value={this.state.applyListInfo.consigneePhone || ""}
                        onChange={(val) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.consigneePhone = val;
                          this.setState({
                            applyListInfo: applyListInfo,
                          });
                        }}
                        error={this.state.consigneePhone}
                        onErrorClick={() => {
                          Toast.info("请填写正确的联系方式！", 1);
                        }}
                      >

                        <span className="sign">*</span>
                          收件人电话

                      </InputItem>
                      <InputItem
                        placeholder="请填写报告收件地址"
                        ref={(el) => (this.consigneeAddress = el)}
                        type="text"
                        maxLength={60}
                        value={this.state.applyListInfo.consigneeAddress}
                        onChange={(el) => {
                          this.inputChange(el, "consigneeAddress");
                        }}
                        disabled={this.state.applyListInfo.readonly}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.consigneeAddress.focus()}
                        >
                          <span className="sign">*</span>
                          收件地址
                        </div>
                      </InputItem>
                    </div>

                    <div className="form">
                      <List.Item
                        onClick={() => {
                          this.setState({
                            drawer: true,
                            drawerType: 1,
                            packageIndex: [],
                            brochureData: JSON.parse(
                              JSON.stringify(this.brochureData)
                            ),
                            brochuresError: false,
                          });
                        }}
                        extra="请选择"
                        arrow="horizontal"
                        disabled={this.state.applyListInfo.readonly}
                      >
                        {true ? <span className="sign">*</span> : null}
                添加宣传册信息
              </List.Item>
                      {this.state.applyListInfo.brochureApplyList &&
                        this.state.applyListInfo.brochureApplyList.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.brochureApplyList
                            .sort((a, b) =>
                              a.pamphletName.localeCompare(b.pamphletName)
                            )
                            .map((v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >


                                    <span>宣传册{v.pamphletName}</span>

                                    {v.num ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          数量：
                                </span>
                                        <span className="content-lable">
                                          {v.num}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </List.Item>
                      ) : null}
                    </div>
                  </div>
                </List>
              </div>
            </div>


            <div className="foot">
              { this.state.applyListInfo.status === "Draft_Save" ||
                this.state.applyListInfo.processStatus === "draft" ? (<span
                className="submit"
                onClick={() => {

                  let applyListInfo = this.state.applyListInfo;

                  if (applyListInfo.reportPhone && applyListInfo.reportPhone.length != 11) {
                    Toast.info("请填写11位接收人电话！", 2);
                    return;
                  }

                  Toast.loading("正在保存", 0);
                  let salesBarCode = applyListInfo.salesBarCode || "-1";
                  axios
                    .post(
                      "/open/brochure/wxpush/getBatchInfoByWx",
                      applyListInfo,
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      if (res) {
                        this.setState({
                          applyListInfo,
                        });
                        Toast.info("保存成功！", 1);
                      }
                    })
                    .catch(() => {
                      Toast.info("保存失败！", 1);
                    });
                }}
              >
                保存
              </span>) : null}


              {this.state.applyListInfo.status === "Draft_Save" ||
                this.state.applyListInfo.processStatus === "draft" ? (<span
                className="submit"
                onClick={() => {

                  let applyListInfo = this.state.applyListInfo;
                  Toast.loading("正在提交", 0);
                  axios
                    .post(
                      "/open/brochure/wxpush/brochureSub",
                      applyListInfo,
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      if (res) {
                        this.setState({
                          applyListInfo,
                        });
                        Toast.info("提交成功！", 1);
                      }
                    })
                    .catch(() => {
                      Toast.info("提交失败！", 1);
                    });
                }}
              >
                提交
              </span>) : null}

            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.applyListInfoReducer,
  };
}

export default connect(mapStateToProps, { brochureApplyAction })(BrochureApply);
