import React from "react";
import { NavBar, Icon } from "antd-mobile";
import "./style/invoiceDetails.css";

export default class InvoiceDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      reportData: [],
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this.setState({
      reportData: [
        {
          invoiceName: "xxxx发票",
        },
        {
          invoiceName: "xxxx发票",
        },
        {
          invoiceName: "xxxx发票",
        },
        {
          invoiceName: "xxxx发票",
        },
        {
          invoiceName: "xxxx发票",
        },
        {
          invoiceName: "xxxx发票",
        },
        {
          invoiceName: "xxxx发票",
        },
      ],
    });
  }
  render() {
    return (
      <div className="invoiceDetails">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          发票详情
        </NavBar>
        {this.state.reportData && this.state.reportData.length ? (
          <div className="center">
            {this.state.reportData.map((v, k) => {
              return (
                <div
                  key={k}
                  className="item"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "reportDownload",
                      query: {
                        title: "发票下载",
                        fileName: v.invoiceName,
                      },
                    })
                  }
                >
                  <img
                    className="logo"
                    src={require("../img/pdf.svg")}
                    alt=""
                  ></img>
                  <div className="right">
                    <div className="title">
                      <span>{v.invoiceName}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="center">
            <div className="empty">
              <img alt="" src={require("../img/kk.svg")}></img>
              <span>请搜索</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
