import React from "react";
import axios from "axios";
import { InputItem, Toast, ActivityIndicator } from "antd-mobile";
import "./style/login.css";
import md5 from "js-md5";

export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      password: "",
      username: localStorage.getItem('username') ||'',
      show: false,
    };
  }

  render() {
    return (
      <div className="login">
        <img className="logo" alt="" src={require("../img/purui_logo.png")}></img>
        <div className="item">
          <InputItem
            placeholder="请输入账号"
            onChange={(val) => {
              this.setState({
                username: val,
              });
            }}
            defaultValue={this.state.username}
          >
            <img alt="" src={require("../img/zh.svg")}></img>
          </InputItem>
          <div style={{ marginTop: "0.25rem" }}></div>
          <div className="password">
            <InputItem
              placeholder="请输入密码"
              onChange={(val) => {
                this.setState({
                  password: val,
                });
              }}
              defaultValue={this.state.password}
              type={this.state.inputType ? "text" : "password"}
            >
              <img alt="" src={require("../img/mm.svg")}></img>
            </InputItem>
            <div
              className="eye"
              onClick={() =>
                this.setState({ inputType: !this.state.inputType })
              }
            >
              <img
                alt=""
                src={
                  this.state.inputType
                    ? require("../img/by2.svg")
                    : require("../img/by1.svg")
                }
              ></img>
            </div>
          </div>
          <div style={{ marginTop: "0.25rem" }}></div>
          <div className="submit">
            <div
              className="submit-text"
              onClick={() => {
                if (!this.state.username) {
                  Toast.info("请输入用户名！", 1);
                  return;
                }

                if (!this.state.password) {
                  Toast.info("请输入密码！", 1);
                  return;
                }
                this.setState({
                  show: true,
                });
                axios
                  .post(
                    `/open/login?userName=${this.state.username}&password=${md5(
                      this.state.password
                    )}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then((res) => {
                    if (!res) return;
                    localStorage.setItem("token", res.token);
                    localStorage.setItem("username", res.userinfo.userName);
                    localStorage.setItem("password", md5(this.state.password));
                    localStorage.setItem("userId", res.userinfo.userId);
                    localStorage.setItem("orgId", res.userinfo.orgId);
                    this.props.history.push("home");
                  })
                  .catch((e) => {
                    Toast.info("系统异常！");
                  })
                  .finally(() => {
                    this.setState({
                      show: false,
                    });
                  });
              }}
              style={
                this.state.show
                  ? {
                      pointerEvents: "none",
                      background: "rgb(245,245,245)",
                      color: "#69a1fe",
                    }
                  : {}
              }
            >
              <ActivityIndicator
                animating={this.state.show}
              ></ActivityIndicator>
              <span style={this.state.show ? { marginLeft: "0.15rem" } : {}}>
                登录
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
