import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  DatePicker,
  Picker,
  Toast,
  TextareaItem,
  Modal,
} from "antd-mobile";
import { nationData } from "../data/static";
import { connect } from "react-redux";
import axios from "axios";
import { brochureApplyDetailAction } from "../../action/apply/brochureApplyDetail";
import "./style/applyListInfo.css";
const alert = Modal.alert;
class BrochureApplyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.applyListInfo = {
      status: this.props.location.status,
      salesBarCode: "",
      patientName: "",
      idNumber: "",
      patientAge: "",
      ageUnit: "岁",
      gender: "",
      paperReport: "",
      inspectionOrganization: "",
      dept: "",
      customerName: "",
      diagonsis: "",
      targetedTherapyHistory: "",
      familyHistory: "",
      selectSetMeals: "",
      tumorType: "",
      projectNo: "",
      sampleInfos: "",
      showHospital: true,
      showDept: false,
      showCustomerName: false,
      receiveAmount: 0,
    };
    this.submitVerificationData = [
      "salesBarCode",
      "patientName",
      "patientAge",
      "gender",
      "paperReport",
      "inspectionOrganization",
      "dept",
      "customerName",
      "diagonsis",
      "targetedTherapyHistory",
      "familyHistory",
      "selectSetMeals",
      "tumorType",
      "sampleInfos",
    ];
    const query =
      (this.props.location && this.props.location.query) ||
      this.props.applyListInfo ||
      {};
    this.state = {
      applyListInfo: Object.assign({}, this.applyListInfo, query),
      tumorType: [],
      nationData: JSON.parse(JSON.stringify(nationData)),
      packageIndex: [],
      packageIndex2: [],
      setMealData: [],
      setMealInfo: [],
      inspectionUnitData: [],
      currentSetMeal: {},
      sampleInfos: query.sampleInfos || [],
      selectSetMeals: query.selectSetMeals || [],
      reportTypeData: [
        {
          value: "院内",
          label: "院内",
        },
        {
          value: "院外",
          label: "院外",
        },
      ],
      subjectInfo: {},
      agentInfo: {
        personName: query.salesRepresentative,
        mobile: query.salesManPhone,
        email: query.salesEmail,
      },
    };
    //手风琴索引
    this.packageIndex = [];
    this.packageIndex2 = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
    this.sampleInfos = [];
    //当前修改地址
    this.currentModificationAddress = null;
  }

  boolSwitch = (val) => {
    let show = true;
    if (val) {
      if (typeof val === "string") {
        if (val === "false") {
          show = false;
        }
      } else {
        return val;
      }
    } else {
      show = false;
    }
    return show;
  };

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  setRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.brochureApplyDetailAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.brochureApplyDetailAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== "/sample" &&
      this.props.history.location.pathname !== "/cancel" &&
      this.props.history.location.pathname !== "/exceptionHandlingDetail"
    ) {
      this.clearRedux();
    }
  };
  render() {
    return (
      <div className="applyListInfo">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <NavBar
            mode="light"
            icon={<Icon type="left" />}
            onLeftClick={this.goBack}
          >
            申请单详情
          </NavBar>
          <InputItem
            disabled={this.state.applyListInfo.readonly}
            defaultValue={this.state.applyListInfo.salesBarCode || ""}
            onChange={(val) => {
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.salesBarCode = val;
              this.setState({
                applyListInfo,
              });
            }}
          >
            申请编号
          </InputItem>
          <DatePicker
            mode="date"
            minDate={new Date(1900, 0, 1, 0, 0, 0)}
            maxDate={new Date()}
            value={
              this.state.applyListInfo.hisTransfusionTime &&
              new Date(this.state.applyListInfo.hisTransfusionTime)
            }
            onOk={(val) => {
              this.inputChange(this.formatTime(val), "hisTransfusionTime");
            }}
            disabled={this.state.applyListInfo.readonly}
          >
            <List.Item arrow="horizontal">
             申请日期
            </List.Item>
          </DatePicker>
          <InputItem
            ref={(el) => (this.patientName = el)}
            value={this.state.applyListInfo.patientName}
            onChange={(el) => {
              this.inputChange(el, "patientName");
            }}
            error={
              this.state.submit &&
              !this.state.applyListInfo.patientName
            }
            disabled={this.state.applyListInfo.readonly}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => this.patientName.focus()}
            >
              申请人
            </div>
          </InputItem>
          <InputItem
            ref={(el) => (this.reportReceiver = el)}
            type="text"
            maxLength={36}
            value={this.state.applyListInfo.reportReceiver}
            onChange={(el) => {
              this.inputChange(el, "reportReceiver");
            }}
            disabled={this.state.applyListInfo.readonly}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => this.reportReceiver.focus()}
            >
              <span className="sign">*</span>
              收件人
            </div>
          </InputItem>
          <InputItem
            type="number"
            maxLength={12}
            ref={(el) => (this.reportPhone = el)}
            value={this.state.applyListInfo.reportPhone}
            onChange={(el) => {
              const reqSmallPhone = /^1\d{10}$/;
              const phone = /^0\d{2,3}-?\d{7,8}$/;
              if (reqSmallPhone.test(el) || phone.test(el)) {
                this.setState({
                  reportPhoneError: false,
                });
              } else {
                this.setState({
                  reportPhoneError: true,
                });
              }
              this.inputChange(el, "reportPhone");
            }}
            error={this.state.receivingPhoneError}
            onErrorClick={() => {
              Toast.info("请填写正确的联系方式！", 1);
            }}
            disabled={this.state.applyListInfo.readonly}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => this.reportPhone.focus()}
            >
              <span className="sign">*</span>
              收件电话
            </div>
          </InputItem>
          <InputItem
            ref={(el) => (this.reportAddress = el)}
            type="text"
            maxLength={60}
            value={this.state.applyListInfo.reportAddress}
            onChange={(el) => {
              this.inputChange(el, "reportAddress");
            }}
            disabled={this.state.applyListInfo.readonly}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => this.reportAddress.focus()}
            >
              <span className="sign">*</span>
              收件地址
            </div>
          </InputItem>
          <div className="item">
            {/* <span className="title">样本信息</span> */}
            <div className="form">
              {this.state.applyListInfo.sampleInfos &&
                this.state.applyListInfo.sampleInfos.length ? (
                <List.Item
                  onClick={() => {
                    if (this.state.applyListInfo.readonly) return;
                    this.setState({
                      drawer: true,
                      drawerType: 1,
                      packageIndex: [],
                      sampleData: JSON.parse(
                        JSON.stringify(this.sampleData)
                      ),
                      sampleInfosError: false,
                    });
                  }}
                  extra="请选择"
                  arrow="horizontal"
                  error={
                    (!this.state.applyListInfo.sampleInfos &&
                      this.state.submit) ||
                    (this.state.submit &&
                      !this.state.applyListInfo.sampleInfos.length) ||
                    this.state.sampleInfosError
                  }
                >
                  {true ? <span className="sign">*</span> : null}
                  样本信息
                </List.Item>
              ) : null}
              {this.state.applyListInfo.sampleInfos &&
                this.state.applyListInfo.sampleInfos.length ? (
                <List.Item multipleLine>
                  {this.state.applyListInfo.sampleInfos
                    .sort((a, b) =>
                      a.sampleCode.localeCompare(b.sampleCode)
                    )
                    .map((v, k) => {
                      return (
                        <div key={k} className="select-info2">
                          <img
                            src={require("../img/xx.png")}
                            alt=""
                          ></img>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              flex: "1",
                            }}
                          >
                            <span>样本{v.sampleCode}</span>
                            {v.sampleTypeName ? (
                              <div className="content-item">
                                <span className="content-title">
                                  样本类型：
                                </span>
                                <span className="content-lable">
                                  {v.sampleTypeName}
                                </span>
                              </div>
                            ) : null}
                            {v.sampleNum ? (
                              <div className="content-item">
                                <span className="content-title">
                                  数量：
                                </span>
                                <span className="content-lable">
                                  {v.sampleNum + v.numberUnit}
                                </span>
                              </div>
                            ) : null}

                          </div>
                        </div>
                      );
                    })}
                </List.Item>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.applicationDetailReducer,
  };
}

export default connect(mapStateToProps, { brochureApplyDetailAction })(
  BrochureApplyDetails
);

