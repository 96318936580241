import { Toast, List,NavBar,Icon, ImagePicker } from "antd-mobile";
import React from "react";
import { connect } from "react-redux";
import { applyListInfoAction } from "../../action/apply/applyListInfo";
import axios from "axios";
import "./style/paymentSuccessful.css";
class PayComplete extends React.Component {
  goBack = () => {

    this.props.history.goBack();
    // alert("提示", "确定要取消支付吗？", [
    //   { text: "否" },
    //   {
    //     text: "是",
    //     onPress: () => {
    //       this.props.history.goBack();
    //     },
    //   },
    // ]);
  };
  constructor(props) {
    super(props);
    this.state = {
    };
    this.payParam = (this.props.location && this.props.location.payParam) || props || {};
  }

  clearRedux = () => {
    const reduxData = [
      "applyListInfo",
      "czjeSelect",
      "paymentType",
      "invoiceType",
      "patientName",
      "invoiceTitle",
      "salesBarCode",
      "czfs",
      "salesRepresentative",
      "money",
      "v",
      "status",
      "paidRemark",
      "isMonthlyStatement",
      "needAdd",
    ];
    for (let pop in reduxData) {
      this.props.applyListInfoAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
    this.clearRedux();
  }

  render() {
    return (
      <div className="paymentSuccessful">
          <NavBar
          mode="light"
          icon={<Icon type="left"/>}
          onLeftClick={this.goBack}
        >
        </NavBar>
        <img
          style={{ width: "1rem", marginTop: "0.5rem" }}
          alt=""
          src={require("../img/zfcg.svg")}
        ></img>
        <span style={{ marginTop: "0.2rem" }}>支付完成</span>
        <div className="content">
          <div className="item">
            <span style={{ color: "#999999" }}>支付总金额 </span>
            <span>
              {this.props.location.query && this.props.location.query.totalMoney}
            </span>
          </div>
          <div className="item">
            <span style={{ color: "#999999" }}>申请单条码</span>
            <span>
              {this.props.location.query &&
                this.props.location.query.salesBarCode}
            </span>
          </div>
        </div>
        <div className="zffs-main2">
          <List>
            <List.Item>
              <span>上传凭证</span>
              <ImagePicker
                files={this.state.files}
                onImageClick={(index, files) => {
                  this.setState({
                    uploadImage: files[index].url,
                    showImage: true,
                  });
                }}
                multiple={true}
                onChange={(files) => {
                  this.setState({
                    files,
                  });
                }}
              />
            </List.Item>
          </List>
        </div>

        <div className="foot">
          <span
            className="submit"
            onClick={() => {
              this.payParam.chargeVoucherUrls = this.state.files;
              var flag = this.payParam.chargeVoucherUrls;
               if(!flag){
                Toast.info("请选择文件");
                return;
               }
                axios
                    .post(
                      `/open/setmeal/payment-certificates/action/upload`,
                      this.payParam,
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      if (!res) return;
                      Toast.info("上传支付凭证成功");
                     })
                    .catch(() => {
                      Toast.info("上传支付凭证失败！", 2);
                    });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.applyListInfoReducer,
    ...state.paymentReducer,
  };
}

export default connect(mapStateToProps, { applyListInfoAction })(
  PayComplete
);
