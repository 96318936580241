import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import { Provider } from "react-redux";
import reducers from "./reducers";
const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

const ua = navigator.userAgent.toLowerCase();
const isMicromessenger =  !(
  ua.match(/MicroMessenger/i) &&
  ua.match(/MicroMessenger/i)[0] === "micromessenger"
);

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    {isMicromessenger ? (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <img
          alt=""
          src={require("./pages/img/ts2.svg")}
          style={{ width: "1rem", marginTop: "1rem" }}
        />
        <span style={{ marginTop: "1rem", fontSize: "0.16rem" }}>
          请在微信客户端打开链接
        </span>
      </div>
    ) : (
      <App />
    )}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
