export const checkExpressReducer = (state = {}, action) => {
  switch (action.type) {
    case "startTime":
      return {
        ...state,
        startTime: action.data,
      };
    case "endTime":
      return {
        ...state,
        endTime: action.data,
      };
    case "searchStr":
      return {
        ...state,
        searchStr: action.data,
      };
    case "expressData":
      return {
        ...state,
        expressData: action.data,
      }; 
    default:
      return state;
  }
};
