import React from "react";
import {
  NavBar,
  Icon,
  List,
  InputItem,
  TextareaItem,
  Drawer,
  Toast,
  Picker,
  Accordion,
  SearchBar,
} from "antd-mobile";
import "./style/deliveryInformation.css";
import axios from "axios";
import { cityData } from "../data/cityData";

export default class DeliveryInformation extends React.Component {
  constructor(props) {
    super(props);
    const params =
      (this.props.location && this.props.location.query) || props || [];
    this.state = {
      applicationData: this.props.location.query
        ? this.props.location.query
        : [],
      expressInfo: {
        destProvince: this.getCityData("110000", cityData),
        destCity: this.getCityData("110000", cityData),
        destDistrict: this.getCityData("110113", cityData),
        depositumInfo: "样本",
        sendStartTime: new Date(),
      },
      destName: "",
      destPhone: "",
      destAddress:"",
      address:"",
      packageIndex: [], // 订单样本
      addressPicker: ["110000", "110000", "110113"],
      temperateZoneData: [
        {
          text: "常温(15-25℃)",
        },
        {
          text: "冷藏(2-8℃)",
        },
      ],
      val: 1,
    };
    this.doneResult = "";
    //手风琴索引
    this.packageIndex = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
  }

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  findLable = (val, data) => {
    let item = "";
    data.forEach((element) => {
      if (val === element.value) {
        item = element.label;
      } else if (element.children) {
        item = this.findLable(val, element.children);
      }
    });
    return item;
  };

  getCityData = (val, data) => {
    //设置结果
    let result;
    if (!data) {
      return; //如果data传空，直接返回
    }
    for (var i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.value === val) {
        result = data[i].label;
        //找到id相等的则返回父id
        this.doneResult = result;
        return result;
      } else if (item.children && item.children.length > 0) {
        //如果有子集，则把子集作为参数重新执行本方法
        result = this.getCityData(val, item.children);
        //关键，千万不要直接return本方法，不然即使没有返回值也会将返回return，导致最外层循环中断，直接返回undefined,要有返回值才return才对
        if (result) {
          return result;
        }
      }
    }
    //如果执行循环中都没有return，则在此return
    return result;
  };

  dateFormat = (fmt, date) => {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  };

  rendersetAddressee = () => {
    return (
      <div className="renderset-addressee" key="2">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
            });
          }}
        >
          收件人信息
        </NavBar>
        <div className="center">
          <div className="item">
            <div className="head">
              <img alt="" src={require("../img/shou.png")}></img>
              <span>收件人</span>
            </div>
            <List>
              <InputItem
                placeholder="请填写收件人姓名"
                clear={true}
                onChange={(val) => {
                  let destName = this.state.destName;
                  destName = val;
                  this.setState({
                    destName,
                  });
                }}
                error={
                  this.state.addressSubmit && !this.state.destName
                }
                defaultValue={this.state.destName}
              >
                <span style={{ color: "red" }}>*</span>
                收件人
              </InputItem>
              <InputItem
                placeholder="请填写收件人手机号"
                type="number"
                clear={true}
                error={
                  (this.state.addressSubmit &&
                    !this.state.destPhone) ||
                  this.state.destPhoneErro
                }
                onChange={(val) => {
                  let destPhone = this.state.destPhone;
                  destPhone = val;
                  this.setState({
                    destPhone,
                    destPhoneErro: false,
                  });
                }}
                maxLength={12}
                defaultValue={this.state.destPhone}
              >
                <span style={{ color: "red" }}>*</span>
                手机号码
              </InputItem>
              <InputItem
                placeholder="请填写省、市、区"
                type="number"
                clear={true}
                error={
                  (this.state.addressSubmit &&
                    !this.state.address)
                }
                onChange={(val) => {
                  let address = this.state.address;
                  address = val;
                  this.setState({
                    address,
                  });
                }}
                defaultValue={this.state.address}
              >
                <span style={{ color: "red" }}>*</span>
                省/市/区
              </InputItem>
            

              <TextareaItem
                title={
                  <>
                    <span style={{ color: "red" }}>*</span>详细地址
                  </>
                }
                error={
                  this.state.addressSubmit &&
                  !this.state.destAddress
                }
                placeholder="请填写详细地址"
                rows={3}
                clear={true}
                onChange={(val) => {
                  let destAddress = this.state.destAddress;
                  destAddress = val;
                  this.setState({
                    destAddress,
                  });
                }}
                defaultValue={ this.state.destAddress}
              />
            </List>
          </div>
        </div>
        <div className="renderset-addressee-foot">
          <span
            onClick={() => {
              if (
                !(
                  this.state.destAddress &&
                  this.state.destPhone &&
                  this.state.destName &&
                  this.state.address
                )
              ) {
                Toast.info("请完整填完所有信息！", 1);
                this.setState({
                  addressSubmit: true,
                });
                return;
              }
              const el = this.state.destPhone;
              const reqSmallPhone = /^1\d{10}$/;
              const phone = /^0\d{2,3}-?\d{7,8}$/;
              if (!reqSmallPhone.test(el) && !phone.test(el)) {
                Toast.info("手机号码格式有误，请重新填写！", 1);
                this.setState({
                  destPhoneErro: true,
                });
                return;
              }

              this.setState({
                drawer: false,
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  renderSender = () => {
    return (
      <div className="renderset-addressee" key="1">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
            });
          }}
        >
          寄件人信息
        </NavBar>
        <div className="center">
          <div className="item">
            <div className="head">
              <img alt="" src={require("../img/ji.png")}></img>
              <span>寄件人</span>
            </div>
            <List>
              <InputItem
                placeholder="请填写寄件人姓名"
                clear={true}
                onChange={(val) => {
                  let expressInfo = this.state.expressInfo;
                  expressInfo.srcName = val;
                  this.setState({
                    expressInfo,
                  });
                }}
                error={
                  this.state.senderSubmit && !this.state.expressInfo.srcName
                }
                defaultValue={this.state.expressInfo.srcName}
              >
                <span style={{ color: "red" }}>*</span>寄件人
              </InputItem>
              <InputItem
                placeholder="请填写寄件人手机号"
                type="number"
                clear={true}
                error={
                  (this.state.senderSubmit &&
                    !this.state.expressInfo.srcPhone) ||
                  this.state.srcPhoneError
                }
                maxLength={12}
                onChange={(val) => {
                  let expressInfo = this.state.expressInfo;
                  expressInfo.srcPhone = val;
                  this.setState({
                    expressInfo,
                    srcPhoneError: false,
                  });
                }}
                defaultValue={this.state.expressInfo.srcPhone}
              >
                <span style={{ color: "red" }}>*</span> 手机号码
              </InputItem>
              <Picker
                data={cityData}
                value={this.state.senderPicker}
                onOk={(val) => {
                  let expressInfo = this.state.expressInfo;
                  expressInfo.srcProvince = this.getCityData(val[0], cityData);
                  expressInfo.srcCity = this.getCityData(val[1], cityData);
                  expressInfo.srcDistrict = this.getCityData(val[2], cityData);
                  this.setState({
                    senderPicker: val,
                    expressInfo,
                  });
                }}
              >
                <List.Item
                  error={this.state.senderSubmit && !this.state.senderPicker}
                  arrow="horizontal"
                >
                  <span style={{ color: "red" }}>*</span>选择地区
                </List.Item>
              </Picker>
              <TextareaItem
                clear={true}
                title={
                  <>
                    <span style={{ color: "red" }}>*</span>详细地址
                  </>
                }
                error={
                  this.state.senderSubmit && !this.state.expressInfo.srcAddress
                }
                placeholder="请填写详细地址"
                rows={3}
                onChange={(val) => {
                  let expressInfo = this.state.expressInfo;
                  expressInfo.srcAddress = val;
                  this.setState({
                    expressInfo,
                  });
                }}
                defaultValue={this.state.expressInfo.srcAddress}
              />
            </List>
          </div>
        </div>
        <div className="renderset-addressee-foot">
          <span
            onClick={() => {
              if (
                !(
                  this.state.expressInfo.srcAddress &&
                  this.state.senderPicker &&
                  this.state.expressInfo.srcName &&
                  this.state.expressInfo.srcPhone
                )
              ) {
                Toast.info("请完整填完所有信息!", 1);
                this.setState({
                  senderSubmit: true,
                });
                return;
              }
              const el = this.state.expressInfo.srcPhone;
              const reqSmallPhone = /^1\d{10}$/;
              const phone = /^0\d{2,3}-?\d{7,8}$/;
              if (!reqSmallPhone.test(el) && !phone.test(el)) {
                Toast.info("手机号码格式有误，请重新填写！", 1);
                this.setState({
                  srcPhoneError: true,
                });
                return;
              }
              this.setState({
                drawer: false,
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this.getBatchSamples();
  };

  getBatchSamples = () => {
    const requestParam = {
      p: {},
      b: ["lsiBatchServiceImpl", this.state.applicationData]
    }
    axios
      .post(`/open/setmeal/batch-samples/queries`,
      requestParam,
      {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res && res.batchsamplelist) {
          this.setState(
            {
              expressSamplesData: res.batchsamplelist,
              destAddress : res.destaddress,
              destName: res.addressee,
              destPhone: res.telephone,
              address: res.address
            },
            () => {
              Toast.hide();
              this.expressSamplesData = JSON.parse(
                JSON.stringify(res.batchsamplelist)
              );

            }
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 0:
        return this.rendersetAddressee();
      case 1:
        return this.renderSender();
      case 2:
        return this.renderBatchSample();
      default:
        return <div></div>;
    }
  };

  //选择订单样本
  choiceBatchSample = (value, arr, k, e) => {
    let setMealData = this.state.expressSamplesData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex.push(value.id);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      setMealData,
    });
  };

  //打开订单样本抽屉
  renderBatchSample = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              batchSamplesSearchBar: "",
              submitBatchSamples: false,
            });
          }}
        >
          添加订单样本
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.batchSamplesSearchBar || ""}
            onChange={(el) => {
              let expressSamplesData = JSON.parse(
                JSON.stringify(this.expressSamplesData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                batchSamplesSearchBar: el,
                expressSamplesData,
                packageIndex: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.expressSamplesData.map((v, k) => {
              let batchSamplesSearchBar =
                this.state.batchSamplesSearchBar &&
                this.state.batchSamplesSearchBar.toLowerCase();
              if (
                !batchSamplesSearchBar ||
                (v.ext$.sampletypename &&
                  v.ext$.sampletypename
                    .toLowerCase()
                    .indexOf(batchSamplesSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceBatchSample(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        
                        <span>订单条码号: {v.ext$.salesbarcode}</span>
                        <span>患者姓名: {v.ext$.patientname}</span>
                        <span>样本名称: {v.ext$.sampletypename}</span>
                      </div>
                    }
                  >
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let expressSamplesData = this.state.expressSamplesData;
              let expressSamples = this.state.expressSamples || [];

              expressSamplesData.forEach((element) => {
                if (element.checked) {
                  expressSamples.push({
                    id: element.id,
                    batchId: element.batchId,
                    sampleTypeName: element.ext$.sampletypename,
                    sampleTypeAliasName: element.sampleTypeAliasName,
                    sampleCategory: element.sampleCategory,
                    sampleTypeCode: element.sampleTypeCode,
                  });
                }
              });
              this.state.expressSamples = expressSamples;
              this.setState({
                drawer: false,
                submitBatchSamples: false,
                batchSamplesSearchBar: "",
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="deliveryInformation">
        <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            this.setState({
              drawer: false,
              batchSamplesSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        // sidebar={
        //   this.state.drawerType === 1
        //     ? this.renderSender()
        //     : this.rendersetAddressee()
        // }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
            >
              样本寄送
            </NavBar>
            <div className="center">
              <div
                style={{
                  color: "red",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: "0.1rem 0.1rem 0 0.1rem",
                }}
              >
              </div>
              <div className="sender">
                <img alt="" src={require("../img/js2.png")}></img>
                <div className="sender-content">
                  <div
                    onClick={() => {
                      this.setState({
                        drawer: true,
                        drawerType: 1,
                      });
                    }}
                    className="sender-info"
                  >
                    <div className="sender-info-title">
                      <span>
                        {this.state.expressInfo.srcName || "寄件人信息"}
                      </span>
                      <span style={{ marginLeft: "0.2rem" }}>
                        {this.state.expressInfo.srcPhone}
                      </span>
                    </div>
                    <span className="sender-info-detaie">
                      {this.state.expressInfo.srcAddress || "请输入真实姓名"}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({
                        drawer: true,
                        drawerType: 0,
                      });
                    }}
                    className="sender-info"
                  >
                    <div className="sender-info-title">
                      <span>
                        {this.state.destName || "收件人信息"}
                      </span>
                      <span style={{ marginLeft: "0.2rem" }}>
                        {this.state.destPhone}
                      </span>
                    </div>
                    <span className="sender-info-detaie">
                      {this.state.destAddress || "请输入真实地址"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="item">
                <List>
                  <InputItem
                    placeholder="请填写托运物名称"
                    onChange={(val) => {
                      let expressInfo = this.state.expressInfo;
                      expressInfo.depositumInfo = val;
                      this.setState({
                        expressInfo,
                      });
                    }}
                    defaultValue={this.state.expressInfo.depositumInfo}
                  >
                    托运物
                  </InputItem>
                </List>
              </div>


              <div className="item">
                <div className="form">
                  <List.Item
                    onClick={() => {
                      if (this.state.readonly) return false;
                      this.setState({
                        drawer: true,
                        drawerType: 2,
                        packageIndex: [],
                        expressSamplesData: JSON.parse(
                          JSON.stringify(this.expressSamplesData)
                        ),
                        expressSamplesError: false,
                      });
                    }}
                    extra="请选择"
                    arrow="horizontal"
                    error={
                      (!this.state.expressSamples &&
                        this.state.submit) ||
                      (this.state.submit &&
                        !this.state.expressSamples.length) ||
                      this.state.expressSamplesError
                    }
                    disabled={this.state.readonly}
                  >
                    {true ? <span className="sign">*</span> : null}
                        选择订单样本
                      </List.Item>



                  {this.state.expressSamples &&
                    this.state.expressSamples.length ? (
                    <List.Item multipleLine>
                      {this.state.expressSamples
                        .map((v, k) => {
                          return (
                            <div key={k} className="select-info2">
                              <img
                                src={require("../img/xx.png")}
                                alt=""
                              ></img>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  flexDirection: "column",
                                  flex: "1",
                                }}
                              >
                                {!this.state.readonly ? (
                                  <img
                                    alt=""
                                    className="close"
                                    src={require("../img/close.svg")}
                                    onClick={() => {
                                      let obj = this.state;
                                      obj.expressSamples.splice(
                                        obj.expressSamples.findIndex(
                                          (value) => value === v
                                        ),
                                      );
                                      this.letter.push(v.sampleCode);
                                      this.setState({
                                        obj,
                                      });
                                    }}
                                    style={{
                                      position: "absolute",
                                      right: "-0.1rem",
                                      top: "-0.1rem",
                                    }}
                                  ></img>
                                ) : null}
                                {v.sampleTypeName ? (
                                  <div className="content-item">
                                    <span className="content-title">
                                      样本名称：
                                      </span>
                                    <span className="content-lable">
                                      {v.sampleTypeName}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                    </List.Item>
                  ) : null}






                  <InputItem
                    placeholder="请填写件数"
                    type="number"
                    clear={true}
                    error={
                      (this.state.addressSubmit &&
                        !this.state.expressInfo.packagesNo) ||
                      this.state.packagesNoErro
                    }
                    onChange={(val) => {
                      let packagesNo = this.state.expressInfo.packagesNo;
                      packagesNo = val;
                      this.setState({
                        packagesNo,
                        packagesNoErro: false,
                      });
                    }}
                    defaultValue={1}
                  >
                    <span style={{ color: "red" }}>*</span>
                件数
              </InputItem>
                </div>
              </div>
            </div>
            <div className="foot">
              <span
                className="submit"
                onClick={() => {
                  if (
                    !(
                      this.state.expressInfo.srcAddress &&
                      this.state.senderPicker &&
                      this.state.expressInfo.srcName &&
                      this.state.expressInfo.srcPhone &&
                      this.state.destAddress &&
                      this.state.destPhone &&
                      this.state.destName &&
                      this.state.address

                    )
                  ) {
                    Toast.info("请完整填完所有信息!", 1);
                    this.setState({
                      senderSubmit: true,
                      submit: true,
                      addressSubmit: true,
                    });
                    return;
                  }
                  const el1 = this.state.expressInfo.srcPhone;
                  const reqSmallPhone = /^1\d{10}$/;
                  const phone = /^0\d{2,3}-?\d{7,8}$/;
                  if (!reqSmallPhone.test(el1) && !phone.test(el1)) {
                    Toast.info("寄件人手机号码格式有误，请重新填写！", 1);
                    this.setState({
                      srcPhoneError: true,
                    });
                    return;
                  }

                  const el2 = this.state.destPhone;
                  if (!reqSmallPhone.test(el2) && !phone.test(el2)) {
                    Toast.info("收件人手机号码格式有误，请重新填写！", 1);
                    this.setState({
                      destPhoneError: true,
                    });
                    return;
                  }

                  if (!this.state.expressSamples || this.state.expressSamples.length === 0) {
                    Toast.info("请选择订单样本！");
                    this.setState({
                      submit: true,
                    });
                    return;
                  }
                  
                  Toast.loading("正在下单", 1);
                  axios
                    .post("/open/setmeal/wxpush/batch-samples/action/send", this.state, {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then((res) => {
                      if (res && res.return_code === '200') {
                        Toast.success("下单成功！", 1, () => {
                          this.goBack();
                        });
                      }
                    })
                    .catch(() => {
                      Toast.fail("提交失败！", 2);
                    });
                }}
              >
                提交订单
              </span>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
