export const discountReducer = (state = {}, action) => {
  switch (action.type) {
    case "salesBarCode":
      return {
        ...state,
        salesBarCode: action.data,
      };
    case "patientName":
      return {
        ...state,
        patientName: action.data,
      };
    case "batchinfodto":
      return {
        ...state,
        batchinfodto: action.data,
      };
    case "discount":
      return {
        ...state,
        discount: action.data,
      };
    case "applyReason":
      return {
        ...state,
        applyReason: action.data,
      };
    case "v":
      return {
        ...state,
        v: action.data,
      };
    case "status":
      return {
        ...state,
        status: action.data,
      };
    case "page":
      return {
        ...state,
        page: action.data,
      };
    case "idNumber":
      return {
        ...state,
        idNumber: action.data,
      };
    default:
      return state;
  }
};
