import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  DatePicker,
  Toast,
  Accordion,
} from "antd-mobile";
import "./style/reportQuery.css";
import { BoxLoading } from "react-loadingg";
import axios from "axios";
import { reportQueryAction } from "../../action/report/reportQuery";
import { connect } from "react-redux";

class ReportQuery extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
    this.state = {
      reportData: this.props.reportData || [],
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      searchStr: this.props.searchStr || "",
      selectAll: this.props.selectAll || false,
      active: this.props.active || false,
      selectData: this.props.selectData || [],
      needSearch: props.needSearch || true,
      loading: true,
    };
    this.reportData = [];
  }

  goBack = () => {
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${
      val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
    }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      (this.props.history.location.pathname === "/" ||
        this.props.history.location.pathname === "/home")
    ) {
      this.clearRedux();
    }
  }

  componentDidMount() {
    //查询报告列表
    this.selectReportList();
  }

  selectReportList = () => {
    axios
      .post("/open/report/getReportList", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.reportbeans) return;
        this.reportData = res.reportbeans;
        if (this.state.needSearch) {
          this.search();
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  search = () => {
    let reportData = [];
    const allData =
      (this.reportData && JSON.parse(JSON.stringify(this.reportData))) || [];
      if (allData) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );
      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );
      allData.forEach((element) => {
        if (element && element.createdTime) {
          let createdTime = parseInt(
            this.formatTime(element.createdTime).replace(/-/g, "")
          );

          if (createdTime <= endTime && createdTime >= startTime) {
            const searchStr = this.state.searchStr || "";
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                if (
                  element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1
                ) {
                  reportData.push(element);
                  break;
                }
              }
            }
            if (element["reportFileList"]) {
              element["reportFileList"].forEach((value) => {
                if (
                  value.reportName.indexOf(searchStr) > -1 &&
                  reportData.findIndex((value) => value === element) < 0
                ) {
                  reportData.push(element);
                }
              });
            }
          }
        }
      });
      this.setState(
        {
          reportData,
          selectData: [],
          active: false,
          selectAll: false,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  setRedux = () => {
    const reduxData = [
      "startTime",
      "endTime",
      "searchStr",
      "reportData",
      "selectAll",
      "active",
      "selectData",
    ];
    for (let pop in reduxData) {
      this.props.reportQueryAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = [
      "startTime",
      "endTime",
      "searchStr",
      "reportData",
      "selectAll",
      "active",
      "selectData",
      "needSearch",
    ];
    for (let pop in reduxData) {
      this.props.reportQueryAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  renderFileTypeImg = (filename) => {
    let src;
    if (!filename)
      return <img className="logo" src={require("../img/wj.svg")} alt=""></img>;
    if (filename.indexOf(".pdf") > -1) {
      src = require("../img/pdf.svg");
    } else if (
      filename.indexOf(".doc") > -1 ||
      filename.indexOf(".docx") > -1
    ) {
      src = require("../img/word.svg");
    } else if (filename.indexOf(".png") > -1 || filename.indexOf(".jpg") > -1) {
      src = require("../img/zp.svg");
    } else if (filename.indexOf(".xls") > -1) {
      src = require("../img/xls.svg");
    } else if (filename.indexOf(".txt") > -1) {
      src = require("../img/txt.svg");
    } else {
      src = require("../img/wj.svg");
    }
    return <img className="logo" src={src} alt=""></img>;
  };

  render() {
    return (
      <div className="reportQuery">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.clearRedux();
            this.goBack();
          }}
        >
          报告查询
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div onClick={() => this.search()} className="search-bar-top-text">
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
            {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          </div>
        </div>
        {this.state.reportData && this.state.reportData.length ? (
          <>
            <div className="center">
              <Accordion accordion={true} activeKey={this.state.activeKey}>
                {this.state.reportData.map((v, k) => {
                  return (
                    <Accordion.Panel
                      key={v.id.toString()}
                      header={
                        <div
                          className="item"
                          onClick={() => {
                            let selectData = this.state.selectData;
                            let reportData = this.state.reportData.map(
                              (value) => {
                                if (value === v) {
                                  if (value.check) {
                                    selectData.push(value);
                                  } else {
                                    selectData.splice(
                                      selectData.findIndex(
                                        (element) => element === v
                                      ),
                                      1
                                    );
                                  }
                                  value.check = !value.check;
                                }
                                return value;
                              }
                            );

                            this.setState({
                              reportData,
                              selectData,
                              active: reportData.findIndex((v) => v.check) > -1,
                              selectAll:
                                reportData.findIndex((v) => !v.check) < 0,
                            });
                          }}
                        >
                          <React.Fragment>
                            <div className="check">
                              {/* <img
                                alt=""
                                src={
                                  v.check
                                    ? require("../img/xz.svg")
                                    : require("../img/wxz.svg")
                                }
                              ></img> */}
                            </div>
                            <div style={{ display: "flex", flex: "1" }}>
                              <div className="right">
                                <div className="title">
                                  {this.renderFileTypeImg(v.reportName)}
                                  <span className="title-content">
                                    {v.reportName}
                                  </span>
                                </div>
                                <div className="content">
                                  {v.salesBarCode ? (
                                    <span>{v.salesBarCode}</span>
                                  ) : null}
                                  {v.patientName ? (
                                    <span>{v.patientName}</span>
                                  ) : null}
                                  {v.hospital ? (
                                    <span>{v.hospital}</span>
                                  ) : null}

                                  {v.productName ? (
                                    <span>{v.productName}</span>
                                  ) : null}
                                  {v.createdTime ? (
                                    <span>{v.createdTime}</span>
                                  ) : null}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  flex: "1",
                                  height: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="title-pj"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let reportData = this.state.reportData.map(
                                      (element) => {
                                        element.activeKey = false;
                                        return element;
                                      }
                                    );
                                    this.setState({
                                      reportData,
                                    });
                                    this.setRedux();
                                    this.props.history.push({
                                      pathname: "reportDownload",
                                      query: {
                                        title: "报告下载",
                                        ...v,
                                      },
                                    });
                                  }}
                                >
                                  查看
                                </span>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "0.4rem",
                                    flexShrink: "0",
                                    flexGrow: "0",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let reportData = this.state.reportData.map(
                                      (element) => {
                                        if (element === v) {
                                          element.activeKey = !element.activeKey;
                                        } else {
                                          element.activeKey = false;
                                        }
                                        return element;
                                      }
                                    );
                                    this.setState({
                                      activeKey: v.activeKey
                                        ? v.id.toString()
                                        : "",
                                      reportData,
                                    });
                                  }}
                                >
                                  {/* <img
                                    style={{
                                      width: "0.2rem",
                                      transform: `${
                                        v.activeKey
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)"
                                      }`,
                                    }}
                                    className="zk"
                                    alt=""
                                    src={require("../img/zk.svg")}
                                  ></img> */}
                                </div>
                              </div>
                            </div>
                            </React.Fragment>
                        </div>
                      }
                    >
                      {/* {v.reportDTOList && v.reportDTOList.length
                        ? v.reportDTOList.map((value, key) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0.1rem 0 0 0.35rem",
                                }}
                                key={key}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    alt=""
                                    style={{ width: "0.3rem" }}
                                    src={require("../img/cys.svg")}
                                  ></img>
                                  <span style={{ marginLeft: "0.07rem" }}>
                                    {value.reportSendCarrier}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "0.07rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {value.reportSendOrderNumber}
                                  </span>
                                </div>
                                <div
                                  className="content"
                                  style={{ marginTop: "0.07rem" }}
                                >
                                  {value &&
                                  value.ext$ &&
                                  value.ext$.receiver ? (
                                    <span>{value.ext$.receiver}</span>
                                  ) : null}
                                  {value.reportSendTime ? (
                                    <span>
                                      {this.formatTime(value.reportSendTime)}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })
                        : null} */}
                    </Accordion.Panel>
                  );
                })}
              </Accordion>
            </div>
            {/* <div className="foot">
              <div
                className="foot-check"
                onClick={() => {
                  if (!this.state.reportData.length) return;
                  let selectData = [];
                  const reportData = this.state.reportData.map((value) => {
                    value.check = this.state.selectAll ? false : true;
                    if (!this.state.selectAll) {
                      selectData.push(value);
                    }
                    return value;
                  });
                  this.setState({
                    selectAll: !this.state.selectAll,
                    active: !this.state.selectAll,
                    reportData,
                    selectData,
                  });
                }}
              >
                <img
                  alt=""
                  className="check"
                  src={
                    this.state.selectAll
                      ? require("../img/xz.svg")
                      : require("../img/wxz.svg")
                  }
                />
                <span>全选</span>
              </div>
              <span
                className="submit"
                style={{
                  backgroundColor: `${
                    this.state.active ? " #EE4943" : "rgb(220,220,220)"
                  }`,
                }}
                onClick={() => {
                  if (!this.state.active) return;
                  this.setRedux();
                  this.props.reportQueryAction({
                    type: "needSearch",
                    data: true,
                  });
                  this.props.history.push({
                    pathname: "satisfactionSurvey",
                    query: this.state.selectData,
                  });
                }}
              >
                满意度调查
              </span>
            </div> */}
          </>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.reportQueryReducer,
  };
}

export default connect(mapStateToProps, { reportQueryAction })(ReportQuery);
