import React from "react";
import {
  NavBar,
  Icon,
  List,
  InputItem,
  TextareaItem,
  Drawer,
  Toast,
  Picker,
  Accordion,
  SearchBar,
} from "antd-mobile";
import "./style/cancelDeliveryDetail.css";
import axios from "axios";

export default class CancelDeliveryDetail extends React.Component {
  constructor(props) {
    super(props);
    const params =
      (this.props.location && this.props.location.query) || props || {};
    this.applicationData = params || {}
    this.state = {};
  }

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="cancelDeliveryDetail">

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <NavBar
            mode="light"
            icon={<Icon type="left" />}
            onLeftClick={this.goBack}
          >
            取消寄送
            </NavBar>
          <div className="center">
            <div
              style={{
                color: "red",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "0.1rem 0.1rem 0 0.1rem",
              }}
            >
            </div>
            {this.applicationData.sendType === '0' ? (<div className="sender">
              <img alt="" src={require("../img/js2.png")}></img>
              <div className="sender-content">
                <div
                  className="sender-info"
                >
                  <div className="sender-info-title">

                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem", marginBottom: "0.2rem" }}>
                        寄件人姓名: {this.applicationData.sender}
                      </span>
                    </div>
                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem" }}>
                        联系电话: {this.applicationData.senderPhone}
                      </span>
                    </div>
                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem" }}>
                        地址: {this.applicationData.sendAddress}
                      </span>
                    </div>
                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem" }}>
                        详细地址: {this.applicationData.sendDetailAddress}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="sender-info"
                >
                  <div className="sender-info-title">
                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem" }}>
                        收件人姓名: {this.applicationData.consignee}
                      </span>
                    </div>
                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem" }}>
                        联系电话: {this.applicationData.consigneePhone}
                      </span>
                    </div>
                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem" }}>
                        地址: {this.applicationData.consigneeAddress}
                      </span>
                    </div>
                    <div style={{ marginBottom: "0.2rem" }}>
                      <span style={{ marginLeft: "0.2rem" }}>
                        详细地址: {this.applicationData.consigneeDetailAddress}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>) : null}
            <div className="item">
              <List>
                <InputItem
                  defaultValue={this.applicationData.otmsOrderId}
                  editable={false}
                >
                  快递单号
                  </InputItem>
              </List>
              {/* <span>
                快递单号: {this.applicationData.orderId}
              </span> */}
            </div>


          </div>
          <div className="foot">
            <span
              className="submit"
              onClick={() => {
                const requestParam = {
                  p: {},
                  b: ["lsiBatchSampleSendServiceImpl", [this.applicationData]]
                }
                Toast.loading("正在取消", 2);
                axios
                  .post("/open/setmeal/wxpush/batch-samples/cancel", requestParam, {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    if (res && res.return_code === '200') {
                      Toast.success("取消订单成功！", 1, () => {
                        this.goBack();
                      });
                    }
                    if (res && res.return_code === '400') {
                      Toast.fail(res.return_msg, 2);
                      return
                    }
                  })
                  .catch(() => {
                    Toast.fail("取消订单失败！", 2);
                  });
              }}
            >
              取消订单
              </span>
          </div>
        </div>
      </div>
    );
  }
}
