import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  DatePicker,
  Modal,
  Accordion,
  Picker,
  Drawer,
  Toast,
  SearchBar,
  TextareaItem,
  ImagePicker,
  Switch,
} from "antd-mobile";
import { nationData } from "../data/static";
import axios from "axios";
import wx from "weixin-js-sdk";
import { connect } from "react-redux";
import { BoxLoading } from "react-loadingg";
import "./style/applyListInfo.css";
import { applyListInfoAction } from "../../action/apply/applyListInfo";

class ApplyListInfo extends React.Component {
  constructor(props) {
    super(props);
    this.applyListInfo = {
      status: this.props.location.status,
      orderType: "临床",
      salesBarCode: "",
      patientName: "",
      idNumber: "",
      patientAge: "",
      ageUnit: "岁",
      gender: "",
      paperReport: "",
      inspectionOrganization: "",
      dept: "",
      customerName: "",
      diagonsis: "",
      targetedTherapyHistory: "",
      familyHistory: "",
      selectSetMeals: "",
      tumorType: "",
      projectNo: "",
      sampleInfos: [],
      reportType: "院内",
      showHospital: true,
      showDept: false,
      showCustomerName: false,
      receiveAmount: 0,
    };
    this.submitVerificationData = [
      "orderType",
      "salesBarCode",
      "patientName",
      "patientAge",
      "gender",
      "paperReport",
      "inspectionOrganization",
      "dept",
      "customerName",
      "diagonsis",
      "targetedTherapyHistory",
      "familyHistory",
      "selectSetMeals",
      "tumorType",
      "sampleInfos",
    ];
    const query =
      (this.props.location && this.props.location.query) ||
      this.props.applyListInfo ||
      {};
    this.state = {
      applyListInfo: Object.assign({}, this.applyListInfo, query),
      tumorType: [],
      nationData: JSON.parse(JSON.stringify(nationData)),
      packageIndex: [],
      packageIndex2: [],
      setMealData: [],
      setMealInfo: [],
      inspectionUnitData: [],
      currentSetMeal: {},
      sampleInfos: query.sampleInfos || [],
      selectSetMeals: query.selectSetMeals || [],
      reportTypeData: [
        {
          value: "院内",
          label: "院内",
        },
        {
          value: "院外",
          label: "院外",
        },
      ],
      agentInfo:
        query.paperReport === "受检者"
          ? {
              personName: query.patientName,
              mobile: query.contactNumber,
            }
          : {
              personName: query.salesRepresentative,
              mobile: query.salesManPhone,
              email: query.salesEmail,
            },
    };
    //手风琴索引
    this.packageIndex = [];
    this.packageIndex2 = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
    this.sampleInfos = [];
    //当前修改地址
    this.currentModificationAddress = null;
  }

  setRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.applyListInfoAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.applyListInfoAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  submitVerification = () => {
    const applyListInfo = this.state.applyListInfo;
    applyListInfo.selectSetMeals = this.state.selectSetMeals;
    this.setState({
      applyListInfo,
    });
    for (let pop of this.submitVerificationData) {
      if (!applyListInfo[pop]) {
        return false;
      }
      if (JSON.stringify(applyListInfo[pop]) === "[]") {
        return false;
      }
    }
    if (
      (applyListInfo.paperReport !== "无需" && !applyListInfo.address) ||
      (applyListInfo.paperReport !== "无需" && !applyListInfo.address.length)
    ) {
      return false;
    }
    if (
      (applyListInfo.familyHistory === "有" &&
        !applyListInfo.familyHistoryInfo) ||
      (applyListInfo.familyHistory === "有" &&
        !applyListInfo.familyHistoryInfo.length)
    ) {
      return false;
    }
    if (
      (applyListInfo.targetedTherapyHistory === "药物名" &&
        !applyListInfo.targetedTherapyHistoryInfo) ||
      (applyListInfo.targetedTherapyHistory === "药物名" &&
        !applyListInfo.targetedTherapyHistoryInfo.length)
    ) {
      return false;
    }
    if (
      (applyListInfo.historyRadiation === "药物名" &&
        !applyListInfo.historyRadiationInfo) ||
      (applyListInfo.historyRadiation === "药物名" &&
        !applyListInfo.historyRadiationInfo.length)
    ) {
      return false;
    }
    if (
      (applyListInfo.historyChemotherapy === "药物名" &&
        !applyListInfo.historyChemotherapyInfo) ||
      (applyListInfo.historyChemotherapy === "药物名" &&
        !applyListInfo.historyChemotherapyInfo.length)
    ) {
      return false;
    }
    if (applyListInfo.smokeHistory === "有" && !applyListInfo.smokeDuration) {
      return false;
    }

    if (applyListInfo.diagonsis === "确诊" && !applyListInfo.diagonsisContent) {
      return false;
    }

    return true;
  };

  //返回首页
  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  //录入输入框信息
  inputChange = (v, k) => {
    let newApplyListInfo = this.state.applyListInfo;
    newApplyListInfo[k] = v;
    this.setState({
      applyListInfo: newApplyListInfo,
    });
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname === "/quickCreation"
    ) {
      this.clearRedux();
    }
  }

  //选择样本类型
  choiceSampleType = (value, arr, k, e) => {
    let setMealData = this.state.setMealData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex2 = this.state.packageIndex2;
    const indexOf = packageIndex2.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex2.push(value.id);
    } else {
      packageIndex2.splice(indexOf, 1);
    }
    this.setState({
      packageIndex2,
      setMealData,
    });
  };

  renderTitle = () => {
    let title;
    switch (this.state.modalType) {
      case "targetedTherapyHistoryInfo":
        title = "靶向治疗";
        break;
      case "historyChemotherapyInfo":
        title = "化疗";
        break;
      case "historyRadiationInfo":
        title = "放疗治疗";
        break;
      default:
        break;
    }
    return title;
  };

  choosePackage = (value, item, select) => {
    let setMealInfo = this.state.setMealInfo;
    setMealInfo = setMealInfo.map((v) => {
      if (value === v) {
        v.checked = !v.checked;
      }
      return v;
    });

    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.setMealCode + value.templateId);
    if (indexOf < 0) {
      packageIndex.push(value.setMealCode + value.templateId);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      setMealInfo,
    });
  };

  //搜索套餐
  searchSetmeal = () => {
    const applyListInfo = this.state.applyListInfo;
    let params = [];
    applyListInfo.sampleInfos &&
      applyListInfo.sampleInfos.forEach((element, index) => {
        if (!element.checked) {
          params.push(element.id);
        }
      });
    if (!params.length) {
      return;
    } else {
      params.join(",");
    }
    this.setState({
      searchSetmealLoad: true,
    });
    axios
      .get(`/open/setmeal/${params}/sysncSetMealInfo`)
      .then((res) => {
        if (res && res.finalwxsetmealdtos) {
          this.setMealInfo = JSON.parse(JSON.stringify(res.finalwxsetmealdtos));
          this.setState({
            setMealInfo: res.finalwxsetmealdtos,
          });
        }
      })
      .finally(() => {
        this.setState({
          searchSetmealLoad: false,
        });
      });
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${
      val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
    }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  scanQRCode = () => {
    wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res) {
        const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        let newApplyListInfo = this.state.applyListInfo;
        newApplyListInfo.salesBarCode = result;
        this.setState({
          applyListInfo: newApplyListInfo,
        });
      },
    });
  };

  //打开肿瘤类型选择抽屉
  renderTumourDrawer = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              tumorTypeSearchBar: "",
            });
          }}
        >
          肿瘤类型
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.tumorTypeSearchBar || ""}
            ref={(v) => (this.tumorTypeSearchBar = v)}
            onChange={(val) => {
              this.setState({
                tumorTypeSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox1">
            {this.state.tumorType &&
              this.state.tumorType.map((v, k) => {
                let tumorTypeSearchBar = this.state.tumorTypeSearchBar;
                if (
                  !tumorTypeSearchBar ||
                  v.dictTextName.indexOf(tumorTypeSearchBar) > -1
                ) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let tumorType = this.state.tumorType.map((value) => {
                          if (v === value && !v.checked) {
                            value.checked = true;
                          } else {
                            value.checked = false;
                          }
                          return value;
                        });
                        this.setState({
                          tumorType,
                        });
                      }}
                    >
                      {v.dictTextName}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let tumorType = this.state.tumorType.find((v) => {
                return v.checked;
              });
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.tumorType = tumorType
                ? tumorType.dictTextName
                : null;
              if (tumorType && tumorType.dictTextName === "其他肿瘤") {
                this.submitVerificationData.push("primaryFocus");
              } else {
                this.submitVerificationData.splice(
                  this.submitVerificationData.findIndex(
                    (v) => v === "primaryFocus"
                  ),
                  1
                );
              }
              this.setState({
                drawer: false,
                applyListInfo,
                tumorTypeSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //打开民族选择抽屉
  renderNation = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              nationSearchBar: "",
            });
          }}
        >
          民族
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.nationSearchBar || ""}
            onChange={(val) => {
              this.setState({
                nationSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox1">
            {this.state.nationData &&
              this.state.nationData.map((v, k) => {
                let nationSearchBar = this.state.nationSearchBar;
                if (!nationSearchBar || v.value.indexOf(nationSearchBar) > -1) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let nationData = this.state.nationData.map((value) => {
                          if (v === value && !v.checked) {
                            value.checked = true;
                          } else {
                            value.checked = false;
                          }
                          return value;
                        });
                        this.setState({
                          nationData,
                        });
                      }}
                    >
                      {v.value}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let nationDataItem = this.state.nationData.find((v) => {
                return v.checked;
              });
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.nation = nationDataItem
                ? nationDataItem.value
                : null;
              this.setState({
                drawer: false,
                applyListInfo,
                nationData: JSON.parse(JSON.stringify(nationData)),
                nationSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //打开送检机构选择抽屉
  renderInspectionUnitDrawer = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              inspectionUnitSearchBar: "",
            });
          }}
        >
          送检单位
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.inspectionUnitSearchBar || ""}
            onChange={(val) => {
              this.setState({
                inspectionUnitSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox1">
            {this.state.inspectionUnitData &&
              this.state.inspectionUnitData.map((v, k) => {
                const inspectionUnitSearchBar = this.state
                  .inspectionUnitSearchBar;
                if (
                  !inspectionUnitSearchBar ||
                  v.orgName.indexOf(inspectionUnitSearchBar) > -1
                ) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let inspectionUnitData = this.state.inspectionUnitData.map(
                          (value) => {
                            if (v === value && !v.checked) {
                              value.checked = true;
                            } else {
                              value.checked = false;
                            }
                            return value;
                          }
                        );
                        this.setState({
                          inspectionUnitData,
                        });
                      }}
                    >
                      {v.orgName}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let inspectionUnitData = this.state.inspectionUnitData.find(
                (v) => {
                  return v.checked;
                }
              );
              let applyListInfo = this.state.applyListInfo;
              if (inspectionUnitData) {
                applyListInfo.inspectionOrganization = inspectionUnitData
                  ? inspectionUnitData.orgName
                  : null;
                applyListInfo.dept = inspectionUnitData
                  ? inspectionUnitData.dept
                  : null;
                applyListInfo.customerName = inspectionUnitData
                  ? inspectionUnitData.customerName
                  : null;

                this.getReportTypeData(inspectionUnitData);
              } else {
                applyListInfo.inspectionOrganization = "";
                const reportTypeData = [
                  {
                    value: "院内",
                    label: "院内",
                  },
                  {
                    value: "院外",
                    label: "院外",
                  },
                ];
                this.setState({
                  reportTypeData,
                });
              }

              this.setState({
                drawer: false,
                applyListInfo,
                inspectionUnitSearchBar: "",
                inspectionUnitData: JSON.parse(
                  JSON.stringify(this.inspectionUnitData)
                ),
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  boolSwitch = (val) => {
    let show = true;
    if (val) {
      if (typeof val === "string") {
        if (val === "false") {
          show = false;
        }
      } else {
        return val;
      }
    } else {
      show = false;
    }
    return show;
  };

  setSetmealInfo = (v, key, val) => {
    let setMealInfo = this.state.setMealInfo;
    setMealInfo.map((element) => {
      if (element === v) {
        element[key] = val;
      }
      return element;
    });
    this.setState({
      setMealInfo,
    });
  };

  //打开套餐抽屉
  rendersetMealDrawer = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              setMealSearch: "",
              setMealInfo: [],
              submitSampleInfos: false,
            });
          }}
        >
          添加套餐
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.setMealSearch}
                  onChange={(el) => {
                    let setMealInfo = JSON.parse(
                      JSON.stringify(this.setMealInfo)
                    ).map((v) => {
                      v.checked = false;
                      return v;
                    });
                    this.setState({
                      setMealSearch: el,
                      setMealInfo,
                      packageIndex: [],
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="search-bar-top-text"
            >
              <span>搜索</span>
            </div>
          </div>
        </div>
        <div className="setMealDrawer-center" style={{ overflow: "hidden" }}>
          <div className="setMealDrawer-center-top">
            {this.state.applyListInfo.sampleInfos &&
            this.state.applyListInfo.sampleInfos.length ? (
              <>
                <div className="search-bar">
                  <div
                    style={{ padding: "unset", justifyContent: "center" }}
                    className="search-bar-bottom"
                  >
                    <div className="option">
                      {this.state.applyListInfo.sampleInfos.map(
                        (value, key) => {
                          return (
                            <div
                              className="option-item"
                              key={key}
                              onClick={() => {
                                let applyListInfo = this.state.applyListInfo;
                                applyListInfo.sampleInfos = applyListInfo.sampleInfos.map(
                                  (v, k) => {
                                    if (v === value) {
                                      v.checked = !v.checked;
                                    }
                                    return v;
                                  }
                                );
                                this.setState(
                                  {
                                    applyListInfo,
                                    setMealInfo: [],
                                    packageIndex: [],
                                  },
                                  () => {
                                    this.searchSetmeal();
                                  }
                                );
                              }}
                            >
                              <img
                                alt=""
                                src={
                                  value.checked
                                    ? require("../img/wxz.svg")
                                    : require("../img/xz.svg")
                                }
                              ></img>
                              <span>
                                ({value.sampleCode}){value.sampleTypeName}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div
            id="setMealDrawer_center_right"
            className="setMealDrawer-center-bottom"
          >
            {this.state.searchSetmealLoad ? (
              <div
                id="setMealDrawer_anima"
                style={{
                  display: "flex",
                  flex: "1",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <BoxLoading></BoxLoading>
              </div>
            ) : this.state.setMealInfo && this.state.setMealInfo.length ? (
              <Accordion
                activeKey={this.state.packageIndex}
                className="my-accordion"
                onChange={this.onChange}
              >
                {this.state.setMealInfo.map((v, k) => {
                  let setMealSearch = this.state.setMealSearch;
                  if (
                    !setMealSearch ||
                    (v.setMealName &&
                      v.setMealName
                        .toLocaleLowerCase()
                        .indexOf(setMealSearch.toLowerCase()) > -1)
                  ) {
                    return (
                      <Accordion.Panel
                        key={v.setMealCode + v.templateId}
                        header={
                          <div
                            onClick={(e) => {
                              this.choosePackage(v, this.state.currentSampType);
                            }}
                            className="accordion2"
                          >
                            <img
                              alt=""
                              style={{ width: "0.15rem" }}
                              src={
                                v.checked
                                  ? require("../img/xz.svg")
                                  : require("../img/wxz.svg")
                              }
                            ></img>
                            <span>{v.setMealName}</span>
                          </div>
                        }
                      >
                        <div className="showItem InspectionTimes">
                          <List>
                            <InputItem
                              placeholder="终身版必填次数"
                              type="number"
                              value={v.InspectionTimes || ""}
                              onChange={(val) => {
                                this.setSetmealInfo(v, "InspectionTimes", val);
                              }}
                            >
                              终身版送检次数
                            </InputItem>
                            {v.setMealCode === "A0033" ? (
                              <>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "eR", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.eR]}
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.eR &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    雌激素受体ER
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "pR", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.pR]}
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.pR &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    孕激素受体PR
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "hER2", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.hER2]}
                                  error={
                                    v.setMealCode === "A0033" &&
                                    !v.hER2 &&
                                    this.state.setMealSubmit
                                  }
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                    {
                                      value: "不确定",
                                      label: "不确定",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.hER2 &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    HER2扩充
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(
                                      v,
                                      "lymphTransfer",
                                      val[0]
                                    );
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.lymphTransfer]}
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.lymphTransfer &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    淋巴结转移
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "menopause", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.menopause]}
                                  data={[
                                    {
                                      value: "是",
                                      label: "是",
                                    },
                                    {
                                      value: "否",
                                      label: "否",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.menopause &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    绝经
                                  </List.Item>
                                </Picker>
                              </>
                            ) : null}
                          </List>
                        </div>
                      </Accordion.Panel>
                    );
                  } else {
                    return null;
                  }
                })}
              </Accordion>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ width: "80%" }}
                  alt=""
                  src={require("../img/kk.svg")}
                ></img>
                <span style={{ marginTop: "0.1rem", color: "#ddd" }}>
                  请搜索
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="setMealDrawer-foot2">
          <span
            onClick={() => {
              let applyListInfo = this.state.applyListInfo;
              let sampleInfos = this.state.applyListInfo.sampleInfos || [];
              sampleInfos = sampleInfos.map((element) => {
                element.checked = true;
                return element;
              });
              applyListInfo.sampleInfos = sampleInfos;
              this.setState({
                drawer: false,
                applyListInfo,
                setMealInfo: [],
                setMealSearch: "",
              });
            }}
            className="submit"
            style={{
              background: "white",
              border: "1px solid #ddd",
              color: "black",
            }}
          >
            取消
          </span>
          <span
            onClick={() => {
              let selectSetMeals = this.state.selectSetMeals || [];
              let setMealInfo = this.state.setMealInfo || [];
              let applyListInfo = this.state.applyListInfo;
              let sampleInfos = this.state.applyListInfo.sampleInfos || [];
              let comboSamInfo = [];
              let sampleTypeName = [];
              let submit = true;
              setMealInfo.forEach((element) => {
                if (element.checked) {
                  if (element.setMealCode === "A0033") {
                    if (
                      !(
                        element.lymphTransfer &&
                        element.pR &&
                        element.menopause &&
                        element.hER2 &&
                        element.eR
                      )
                    ) {
                      submit = false;
                    }
                  }
                }
              });
              if (!submit) {
                Toast.info("请填写完整信息！", 1);
                this.setState({
                  setMealSubmit: true,
                });
                return;
              }
              sampleInfos = sampleInfos.map((element) => {
                if (!element.checked) {
                  comboSamInfo.push({
                    id: element.id,
                    setMealCode: element.sampleCode,
                  });
                  sampleTypeName.push(
                    `(${element.sampleCode})${element.sampleTypeName}`
                  );
                }
                element.checked = true;
                return element;
              });
              setMealInfo.forEach((element) => {
                if (element.checked) {
                  selectSetMeals.push({
                    sampleTypeName: sampleTypeName.join("，"),
                    eR: element.eR,
                    menopause: element.menopause,
                    hER2: element.hER2,
                    pR: element.pR,
                    lymphTransfer: element.lymphTransfer,
                    InspectionTimes: element.InspectionTimes,
                    setMealName: element.setMealName,
                    setMealCode: element.setMealCode,
                    setMealId: element.templateId,
                    amount: element.amount,
                    comboSamInfo: comboSamInfo,
                  });
                }
              });
              applyListInfo.sampleInfos = sampleInfos;
              this.setState({
                drawer: false,
                selectSetMeals,
                applyListInfo,
                setMealInfo: [],
                setMealSearch: "",
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  setSampleInput = (id, key, val) => {
    let setMealData = this.state.setMealData.map((value) => {
      if (value.id.toString() === id) {
        value[key] = val;
      }
      return value;
    });
    this.setState({
      setMealData,
    });
  };

  //获取生日
  getBirth = (el) => {
    const date = new Date();
    let applyListInfo = this.state.applyListInfo;
    const req = /(^\d{17}([0-9]|X|x)$)/;
    if (req.test(el)) {
      if (applyListInfo.ageUnit === "岁") {
        applyListInfo.patientAge =
          date.getFullYear() - parseInt(el.substring(6, 10));
      } else {
        applyListInfo.patientAge =
          (date.getFullYear() - parseInt(el.substring(6, 10))) * 12;
      }
    }
    this.setState({
      applyListInfo,
    });
  };

  //打开样本类型抽屉
  renderSampleInformation = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              sampleInformationSearchBar: "",
              submitSampleInfos: false,
            });
          }}
        >
          添加样本
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.sampleInformationSearchBar || ""}
            onChange={(el) => {
              let setMealData = JSON.parse(
                JSON.stringify(this.setMealData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                sampleInformationSearchBar: el,
                setMealData,
                packageIndex2: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex2}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.setMealData.map((v, k) => {
              let sampleInformationSearchBar =
                this.state.sampleInformationSearchBar &&
                this.state.sampleInformationSearchBar.toLowerCase();
              if (
                !sampleInformationSearchBar ||
                (v.sampleTypeName &&
                  v.sampleTypeName
                    .toLowerCase()
                    .indexOf(sampleInformationSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceSampleType(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.sampleTypeName}</span>
                      </div>
                    }
                  >
                    <div className="showItem">
                      <List>
                        <InputItem
                          placeholder="请填写样本数量"
                          value={v.sampleNum || ""}
                          onChange={(val) => {
                            this.setSampleInput(v.id, "sampleNum", val);
                          }}
                          error={
                            v.checked &&
                            this.state.submitSampleInfos &&
                            !v.sampleNum
                          }
                          type="number"
                        >
                          <div>
                            <span style={{ color: "red" }}>*</span>样本数量
                          </div>
                        </InputItem>
                        <DatePicker
                          mode="date"
                          minDate={new Date(1900, 0, 1, 0, 0, 0)}
                          maxDate={new Date()}
                          value={v.collectTime && new Date(v.collectTime)}
                          onOk={(val) => {
                            this.setSampleInput(
                              v.id,
                              "collectTime",
                              this.formatTime(val)
                            );
                          }}
                        >
                          <List.Item
                            arrow="horizontal"
                            error={
                              v.checked &&
                              this.state.submitSampleInfos &&
                              !v.collectTime
                            }
                          >
                            <span style={{ color: "red" }}>*</span>采样时间
                          </List.Item>
                        </DatePicker>
                        {v.sampleCategory === "FFPE001" ? (
                          <InputItem
                            placeholder="石蜡组织样本必填"
                            value={v.diagonsisNo || ""}
                            error={
                              v.checked &&
                              this.state.submitSampleInfos &&
                              !v.diagonsisNo
                            }
                            onChange={(val) => {
                              this.setSampleInput(v.id, "diagonsisNo", val);
                            }}
                          >
                            <div>
                              <span style={{ color: "red" }}>*</span> 病理号
                            </div>
                          </InputItem>
                        ) : null}
                        <Picker
                          onOk={(val) => {
                            this.setSampleInput(v.id, "samplingMode", val[0]);
                          }}
                          value={[v.samplingMode || ""]}
                          data={[
                            {
                              value: "手术",
                              label: "手术",
                            },
                            {
                              value: "穿刺",
                              label: "穿刺",
                            },
                            {
                              value: "支气管镜",
                              label: "支气管镜",
                            },
                            {
                              value: "肠镜",
                              label: "肠镜",
                            },
                            {
                              value: "胃镜",
                              label: "胃镜",
                            },
                            {
                              value: "其他",
                              label: "其他",
                            },
                          ]}
                          extra="组织样本必填"
                          cols={1}
                        >
                          <List.Item
                            arrow="horizontal"
                            error={
                              v.checked &&
                              this.state.submitSampleInfos &&
                              !v.samplingMode &&
                              (v.sampleCategory === "ZZ001" ||
                                v.sampleCategory === "FFPE001")
                            }
                          >
                            {v.sampleCategory === "ZZ001" ||
                            v.sampleCategory === "FFPE001" ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : null}
                            采样方式
                          </List.Item>
                        </Picker>
                        <InputItem
                          placeholder="请填写采集部位"
                          value={v.collectLocation || ""}
                          onChange={(val) => {
                            this.setSampleInput(v.id, "collectLocation", val);
                          }}
                          error={
                            v.checked &&
                            this.state.submitSampleInfos &&
                            !v.collectLocation &&
                            (v.sampleCategory === "ZZ001" ||
                              v.sampleCategory === "FFPE001")
                          }
                        >
                          <div>
                            {v.sampleCategory === "ZZ001" ||
                            v.sampleCategory === "FFPE001" ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : null}
                            采集部位
                          </div>
                        </InputItem>
                        <TextareaItem
                          placeholder="请填写备注"
                          value={v.remark || ""}
                          onChange={(val) => {
                            this.setSampleInput(v.id, "remark", val);
                          }}
                          rows={3}
                          title="备注"
                        ></TextareaItem>
                      </List>
                    </div>
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let setMealData = this.state.setMealData;
              let applyListInfo = this.state.applyListInfo;
              let sampleInfos = applyListInfo.sampleInfos || [];
              let submit = false;
              setMealData.forEach((element) => {
                if (element.checked) {
                  if (!element["sampleNum"] || !element["collectTime"]) {
                    submit = true;
                  }
                  if (
                    element.sampleCategory === "FFPE001" &&
                    !(
                      element.diagonsisNo &&
                      element.collectLocation &&
                      element.samplingMode
                    )
                  ) {
                    submit = true;
                  }
                  if (
                    element.sampleCategory === "ZZ001" &&
                    !(element.collectLocation && element.samplingMode)
                  ) {
                    submit = true;
                  }
                }
              });
              if (submit) {
                Toast.info("请填写必填信息！", 1);
                this.setState({
                  submitSampleInfos: true,
                });
                return;
              }

              setMealData.forEach((element) => {
                if (element.checked) {
                  sampleInfos.push({
                    ...element,
                    sampleCode: this.letter.pop(),
                  });
                }
              });
              applyListInfo.sampleInfos = sampleInfos;
              this.setState({
                drawer: false,
                submitSampleInfos: false,
                applyListInfo,
                sampleInformationSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 1:
        return this.renderTumourDrawer();
      case 2:
        return this.rendersetMealDrawer();
      case 3:
        return this.renderInspectionUnitDrawer();
      case 4:
        return this.renderSampleInformation();
      case 5:
        return this.renderNation();
      default:
        return <div></div>;
    }
  };

  componentDidMount() {
    Toast.loading("正在加载", 0);
    axios
      .get("/open/setmeal/getSampleTypeInfo")
      .then((res) => {
        if (res && res.lsisampletypebeans) {
          this.setMealData = JSON.parse(JSON.stringify(res.lsisampletypebeans));
          this.setState(
            {
              setMealData: res.lsisampletypebeans,
            },
            () => Toast.hide()
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });

    this.setState({
      address:
        this.state.applyListInfo.address &&
        this.state.applyListInfo.address.map((v) => {
          v.checked = true;
          return v;
        }),
      targetedTherapyHistoryInfo:
        this.state.applyListInfo.targetedTherapyHistoryInfo &&
        this.state.applyListInfo.targetedTherapyHistoryInfo.map((v) => {
          v.checked = true;
          return v;
        }),
      historyChemotherapyInfo:
        this.state.applyListInfo.historyChemotherapyInfo &&
        this.state.applyListInfo.historyChemotherapyInfo.map((v) => {
          v.checked = true;
          return v;
        }),
      familyHistoryInfo:
        this.state.applyListInfo.familyHistoryInfo &&
        this.state.applyListInfo.familyHistoryInfo.map((v) => {
          v.checked = true;
          return v;
        }),
      historyRadiationInfo:
        this.state.applyListInfo.historyRadiationInfo &&
        this.state.applyListInfo.historyRadiationInfo.map((v) => {
          v.checked = true;
          return v;
        }),
    });
    this.getInspectionUnit();
    this.getTumorType();
    let sampleInfos = this.state.applyListInfo.sampleInfos;
    let idNumber = this.state.applyListInfo.idNumber;
    if (idNumber) {
      this.getBirth(idNumber);
    }
    if (sampleInfos) {
      sampleInfos.forEach((element) => {
        this.letter.splice(this.letter.indexOf(element.sampleCode), 1);
      });
    }
    this.getPersonInfo();
  }

  getPersonInfo = () => {
    let applyListInfo = this.state.applyListInfo;
    let salesBarCode = applyListInfo.salesBarCode || "-1";
    axios
      .get(
        `/open/setmeal/b/${
          applyListInfo.id ? applyListInfo.id : "-1"
        }/s/${salesBarCode}`
      )
      .then((res) => {
        if (!res) {
        } else {
          if (!res || !res.person) return;
          let applyListInfo = this.state.applyListInfo;
          applyListInfo.salesManPhone = res.person.mobile;
          applyListInfo.salesEmail = res.person.email;
          applyListInfo.salesRepresentative = res.person.personName;
          applyListInfo.contactadress = res.person.contactadress;
          this.setState({
            salesBarCodeError: false,
            applyListInfo,
          });
          if (applyListInfo.paperReport === "迈景经办人") {
            this.setState({
              agentInfo: {
                personName: res.person.personName,
                mobile: res.person.mobile,
                email: res.person.email,
                contactadress: res.person.contactadress,
              },
            });
          }
        }
      })
      .finally(() => {
        Toast.hide();
      });
  };

  getTumorType = () => {
    axios
      .get("/open/basedata/dict/肿瘤类型")
      .then((res) => {
        if (res && res.data) {
          this.setState({
            tumorType: res.data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };
  getInspectionUnit = () => {
    axios
      .get("/open/setmeal/getInspectionUnit")
      .then((res) => {
        if (res && res.lsicustomerbeans) {
          this.setState(
            {
              inspectionUnitData: res.lsicustomerbeans,
            },
            () => {
              Toast.hide();
              this.inspectionUnitData = JSON.parse(
                JSON.stringify(res.lsicustomerbeans)
              );
              const applyListInfo = this.state.applyListInfo;
              if (applyListInfo.inspectionOrganization) {
                const inspectionOrganizationItem = this.inspectionUnitData.find(
                  (element) =>
                    element.orgName === applyListInfo.inspectionOrganization
                );
                this.getReportTypeData(inspectionOrganizationItem);
              }
            }
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getReportTypeData = (inspectionOrganizationItem) => {
    let reportTypeData;
    let applyListInfo = this.state.applyListInfo;
    if (inspectionOrganizationItem.isHospitalTemplate === "0") {
      applyListInfo.reportType = "院外";
      reportTypeData = [
        {
          value: "院外",
          label: "院外",
        },
      ];
    } else {
      reportTypeData = [
        {
          value: "院内",
          label: "院内",
        },
        {
          value: "院外",
          label: "院外",
        },
      ];
    }
    applyListInfo.isMonthlyStatement =
      inspectionOrganizationItem.isMonthlyStatement;
    this.setState({
      reportTypeData,
      applyListInfo,
      isMonthlyStatement:
        inspectionOrganizationItem.inspectionOrganizationItem === "1",
    });
  };

  submit = (res, applyListInfo) => {
    axios
      .post(
        `/open/setmeal/wxpush/${res.id}/submitBatchInfoByWx`,
        applyListInfo,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        Toast.success("提交成功", 2);
      })
      .catch(() => {
        Toast.info("加载失败！", 2);
      });
  };

  render() {
    return (
      <div className="applyListInfo">
        <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            if (this.state.drawerType === 2) {
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.selectSetMeals = this.selectSetMeals;
              this.setState({
                applyListInfo,
              });
            }
            this.setState({
              drawer: false,
              setMealSearchBar: "",
              sampleInformationSearchBar: "",
              nationSearchBar: "",
              inspectionUnitSearchBar: "",
              tumorTypeSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexShrink: "0",
            flexGrow: "0",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
            >
              在线申请
            </NavBar>
            <div className="center">
              <div style={{ width: "100%" }}>
                <List>
                  <div className="item">
                    {/* <span className="title">患者信息</span> */}
                    <div className="form">
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.orderType = v[0];
                          if (v[0] === "临床") {
                            this.submitVerificationData.push("projectNo");
                          } else {
                            this.submitVerificationData.splice(
                              this.submitVerificationData.findIndex(
                                (v) => v === "projectNo"
                              ),
                              1
                            );
                          }
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.orderType]}
                        data={[
                          {
                            value: "临床",
                            label: "临床",
                          },
                          {
                            value: "科研",
                            label: "科研",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item arrow="horizontal">
                          {true ? <span className="sign">*</span> : null}
                          订单类型
                        </List.Item>
                      </Picker>
                      {this.state.applyListInfo.orderType === "科研" ? (
                        <InputItem
                          placeholder="请填项目编号"
                          value={this.state.applyListInfo.projectNo}
                          onChange={(el) => {
                            this.inputChange(el, "projectNo");
                          }}
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.projectNo
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span className="sign">*</span>
                            项目编号
                          </div>
                        </InputItem>
                      ) : null}
                      <InputItem
                        extra={
                          <div className="camare">
                            <img
                              style={{ marginLeft: "0.1rem" }}
                              src={require("../img/sm.png")}
                              alt=""
                              onClick={this.scanQRCode}
                            ></img>
                          </div>
                        }
                        disabled={
                          (this.props.location &&
                            this.props.location.query &&
                            this.props.location.query.salesBarCode) ||
                          (this.props.applyListInfo &&
                            this.props.applyListInfo.salesBarCode)
                        }
                        defaultValue={
                          this.state.applyListInfo.salesBarCode || ""
                        }
                        onChange={(val) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.salesBarCode = val;
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        onErrorClick={() => {
                          Toast.info("申请单条码有误！", 1);
                        }}
                        onBlur={() => {
                          let applyListInfo = this.state.applyListInfo;
                          let salesBarCode = applyListInfo.salesBarCode || "-1";
                          axios
                            .get(
                              `/open/setmeal/b/${
                                applyListInfo.id ? applyListInfo.id : "-1"
                              }/s/${salesBarCode}`
                            )
                            .then((res) => {
                              if (!res) {
                                this.setState({
                                  salesBarCodeError: true,
                                });
                              } else {
                                if (!res || !res.person) return;
                                let applyListInfo = this.state.applyListInfo;
                                applyListInfo.salesManPhone = res.person.mobile;
                                applyListInfo.salesEmail = res.person.email;
                                applyListInfo.salesRepresentative =
                                  res.person.personName;
                                applyListInfo.contactadress =
                                  res.person.contactadress;
                                this.setState({
                                  salesBarCodeError: false,
                                  applyListInfo,
                                });
                              }
                            });
                        }}
                        onExtraClick={() => {}}
                        error={
                          (this.state.submit &&
                            !this.state.applyListInfo.salesBarCode) ||
                          this.state.salesBarCodeError
                        }
                        placeholder="请输入或扫描申请单条码"
                      >
                        <span style={{ color: "red" }}>*</span>
                        申请单条码
                      </InputItem>
                      <InputItem
                        placeholder="请填写患者姓名"
                        ref={(el) => (this.patientName = el)}
                        value={this.state.applyListInfo.patientName}
                        onChange={(el) => {
                          this.inputChange(el, "patientName");
                        }}
                        onBlur={(val) => {
                          const applyListInfo = this.state.applyListInfo;
                          if (applyListInfo.paperReport === "受检者") {
                            this.setState({
                              agentInfo: {
                                personName: val,
                                mobile: applyListInfo.contactNumber,
                              },
                            });
                          }
                        }}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.patientName
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.patientName.focus()}
                        >
                          {true ? <span className="sign">*</span> : null}
                          患者姓名
                        </div>
                      </InputItem>
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.gender = v[0];
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.gender]}
                        data={[
                          {
                            value: "男",
                            label: "男",
                          },
                          {
                            value: "女",
                            label: "女",
                          },
                          {
                            value: "未知",
                            label: "未知",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.gender
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                          性别
                        </List.Item>
                      </Picker>
                      <InputItem
                        placeholder="请填写身份证号"
                        ref={(el) => (this.idNumber = el)}
                        type="text"
                        maxLength={18}
                        value={this.state.applyListInfo.idNumber}
                        onChange={(el) => {
                          this.getBirth(el);
                          this.inputChange(el, "idNumber");
                        }}
                        onErrorClick={() => {
                          Toast.info("请填写正确的身份证号！", 1);
                        }}
                        error={this.state.idNumberError}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.idNumber.focus()}
                        >
                          身份证号
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写年龄"
                        type="number"
                        maxLength={3}
                        ref={(el) => (this.patientAge = el)}
                        value={this.state.applyListInfo.patientAge}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.patientAge
                        }
                        onChange={(el) => {
                          this.inputChange(el, "patientAge");
                        }}
                        extra={this.state.applyListInfo.ageUnit}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.patientAge.focus()}
                        >
                          {true ? <span className="sign">*</span> : null}
                          年龄
                        </div>
                      </InputItem>
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.ageUnit = v[0];
                          if (applyListInfo.patientAge) {
                            this.getBirth(applyListInfo.idNumber);
                          }
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.ageUnit]}
                        data={[
                          {
                            value: "岁",
                            label: "岁",
                          },
                          {
                            value: "月",
                            label: "月",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item arrow="horizontal">年龄单位</List.Item>
                      </Picker>
                      <InputItem
                        placeholder="请填写联系电话"
                        type="number"
                        maxLength={12}
                        onBlur={(val) => {
                          const applyListInfo = this.state.applyListInfo;
                          if (applyListInfo.paperReport === "受检者") {
                            this.setState({
                              agentInfo: {
                                personName: applyListInfo.patientName,
                                mobile: val,
                              },
                            });
                          }
                        }}
                        ref={(el) => (this.contactNumber = el)}
                        value={this.state.applyListInfo.contactNumber}
                        onChange={(el) => {
                          const reqSmallPhone = /^1\d{10}$/;
                          const phone = /^0\d{2,3}-?\d{7,8}$/;
                          if (reqSmallPhone.test(el) || phone.test(el)) {
                            this.setState({
                              contactNumberError: false,
                            });
                          } else {
                            this.setState({
                              contactNumberError: true,
                            });
                          }
                          this.inputChange(el, "contactNumber");
                        }}
                        error={this.state.contactNumberError}
                        onErrorClick={() => {
                          Toast.info("请填写正确的联系方式！", 1);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.contactNumber.focus()}
                        >
                          联系电话
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写籍贯"
                        type="text"
                        ref={(el) => (this.nativePlace = el)}
                        value={this.state.applyListInfo.nativePlace}
                        onChange={(el) => {
                          this.inputChange(el, "nativePlace");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.nativePlace.focus()}
                        >
                          籍贯
                        </div>
                      </InputItem>
                      <List.Item
                        extra={this.state.applyListInfo.nation || "请选择"}
                        onClick={() => {
                          let applyListInfo = this.state.applyListInfo;
                          let nationData = this.state.nationData.map((v) => {
                            if (v.value === applyListInfo.nation) {
                              v.checked = true;
                            } else {
                              v.checked = false;
                            }
                            return v;
                          });
                          this.setState({
                            drawer: true,
                            drawerType: 5,
                            nationData,
                          });
                        }}
                        arrow="horizontal"
                      >
                        民族
                      </List.Item>
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.paperReport = v[0];

                          if (v[0] === "无需") applyListInfo.address = null;
                          if (v[0] === "迈景经办人") {
                            this.setState({
                              agentInfo: {
                                personName: applyListInfo.salesRepresentative,
                                mobile: applyListInfo.salesManPhone,
                                email: applyListInfo.salesEmail,
                                contactadress: applyListInfo.contactadress,
                              },
                            });
                          } else if (v[0] === "受检者") {
                            this.setState({
                              agentInfo: {
                                personName: applyListInfo.patientName,
                                mobile: applyListInfo.contactNumber,
                              },
                            });
                          }
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.paperReport]}
                        data={[
                          {
                            value: "无需",
                            label: "无需",
                          },
                          {
                            value: "迈景经办人",
                            label: "迈景经办人",
                          },
                          {
                            value: "受检者",
                            label: "受检者",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.paperReport
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                          纸质报告
                        </List.Item>
                      </Picker>
                      {this.state.applyListInfo.paperReport &&
                      this.state.applyListInfo.paperReport !== "无需" ? (
                        <div className="child-item">
                          <List.Item
                            arrow="horizontal"
                            onClick={() => {
                              this.address = JSON.parse(
                                JSON.stringify(this.state.address || [])
                              );
                              this.setState({
                                addressModal: true,
                              });
                            }}
                            extra="请选择"
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.address
                            }
                          >
                            {true ? <span className="sign">*</span> : null}
                            寄送地址
                          </List.Item>
                        </div>
                      ) : null}
                      {this.state.applyListInfo.address &&
                      this.state.applyListInfo.address.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.address.map((v, k) => {
                            return (
                              <div key={k} className="select-info2">
                                <img
                                  src={require("../img/dz2.png")}
                                  alt=""
                                ></img>
                                <div>
                                  {v.consigneeName ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        报告收件人：
                                      </span>
                                      <span className="content-lable">
                                        {v.consigneeName}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.receivingPhone ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        收件人电话：
                                      </span>
                                      <span className="content-lable">
                                        {v.receivingPhone}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.location ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        收件人地址：
                                      </span>
                                      <span className="content-lable">
                                        {v.location}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.mailCopies ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        邮寄份数：
                                      </span>
                                      <span className="content-lable">
                                        {v.mailCopies}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.mailingNotes ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        邮寄备注：
                                      </span>
                                      <span className="content-lable">
                                        {v.mailingNotes}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.email ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        电子邮箱：
                                      </span>
                                      <span className="content-lable">
                                        {v.email}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                        </List.Item>
                      ) : null}
                    </div>
                  </div>
                  <div className="item">
                    {/* <span className="title">患者诊疗信息</span> */}
                    <div className="form">
                      <List.Item
                        multipleLine={true}
                        extra={
                          this.state.applyListInfo.inspectionOrganization ||
                          "请选择"
                        }
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.inspectionOrganization
                        }
                        onClick={() => {
                          let applyListInfo = this.state.applyListInfo;
                          let inspectionUnitData =
                            this.state.inspectionUnitData &&
                            this.state.inspectionUnitData.map((v) => {
                              if (
                                v.orgName ===
                                applyListInfo.inspectionOrganization
                              ) {
                                v.checked = true;
                              } else {
                                v.checked = false;
                              }
                              return v;
                            });
                          this.setState({
                            drawer: true,
                            drawerType: 3,
                            inspectionOrganization: inspectionUnitData.orgName,
                          });
                        }}
                        arrow="horizontal"
                      >
                        {true ? <span className="sign">*</span> : null}
                        送检单位
                      </List.Item>
                      <InputItem
                        placeholder="请填写送检科室"
                        ref={(el) => (this.dept = el)}
                        type="text"
                        value={this.state.applyListInfo.dept}
                        onChange={(el) => {
                          this.inputChange(el, "dept");
                        }}
                        error={
                          this.state.submit && !this.state.applyListInfo.dept
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.dept.focus()}
                        >
                          {true ? <span className="sign">*</span> : null}
                          送检科室
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写送检医生"
                        type="text"
                        ref={(el) => (this.customerName = el)}
                        value={this.state.applyListInfo.customerName}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.customerName
                        }
                        onChange={(el) => {
                          this.inputChange(el, "customerName");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.customerName.focus()}
                        >
                          {true ? <span className="sign">*</span> : null}
                          送检医生
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写住院号"
                        type="text"
                        ref={(el) => (this.admissionNumber = el)}
                        value={this.state.applyListInfo.admissionNumber}
                        onChange={(el) => {
                          this.inputChange(el, "admissionNumber");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.admissionNumber.focus()}
                        >
                          住院(门诊)号
                        </div>
                      </InputItem>
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.reportType = v[0];
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.reportType || "院内"]}
                        data={this.state.reportTypeData}
                        cols={1}
                      >
                        <List.Item arrow="horizontal">报告类型</List.Item>
                      </Picker>
                      <List.Item
                        extra={
                          <Switch
                            checked={this.boolSwitch(
                              this.state.applyListInfo.showHospital
                            )}
                            onChange={() => {
                              let applyListInfo = this.state.applyListInfo;
                              applyListInfo.showHospital = !applyListInfo.showHospital;
                              this.setState({
                                applyListInfo,
                              });
                            }}
                          />
                        }
                      >
                        送检单位是否显示在报告中
                      </List.Item>
                      <List.Item
                        extra={
                          <Switch
                            checked={this.boolSwitch(
                              this.state.applyListInfo.showDept
                            )}
                            onChange={() => {
                              let applyListInfo = this.state.applyListInfo;
                              applyListInfo.showDept = !applyListInfo.showDept;
                              this.setState({
                                applyListInfo,
                              });
                            }}
                          />
                        }
                      >
                        送检科室是否显示在报告中
                      </List.Item>
                      <List.Item
                        extra={
                          <Switch
                            checked={this.boolSwitch(
                              this.state.applyListInfo.showCustomerName
                            )}
                            onChange={() => {
                              let applyListInfo = this.state.applyListInfo;
                              applyListInfo.showCustomerName = !applyListInfo.showCustomerName;
                              this.setState({
                                applyListInfo,
                              });
                            }}
                          />
                        }
                      >
                        送检医生是否显示在报告中
                      </List.Item>
                      <List.Item
                        extra={this.state.applyListInfo.tumorType || "请选择"}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.tumorType
                        }
                        onClick={() => {
                          let applyListInfo = this.state.applyListInfo;
                          let tumorType = this.state.tumorType.map((v) => {
                            if (v.dictTextName === applyListInfo.tumorType) {
                              v.checked = true;
                            } else {
                              v.checked = false;
                            }
                            return v;
                          });
                          this.setState({
                            drawer: true,
                            drawerType: 1,
                            tumorType,
                          });
                        }}
                        arrow="horizontal"
                      >
                        {true ? <span className="sign">*</span> : null}
                        肿瘤类型
                      </List.Item>
                      {this.state.applyListInfo.tumorType === "其他肿瘤" ? (
                        <InputItem
                          placeholder="请填写原发灶"
                          type="text"
                          value={this.state.applyListInfo.primaryFocus || ""}
                          onChange={(el) => {
                            this.inputChange(el, "primaryFocus");
                          }}
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.primaryFocus
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {true ? <span className="sign">*</span> : null}
                            原发灶
                          </div>
                        </InputItem>
                      ) : null}
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.diagonsis = v[0];
                          if (v[0] === "确诊") {
                            this.submitVerificationData.push(
                              "diagonsisContent"
                            );
                          } else {
                            this.submitVerificationData.splice(
                              this.submitVerificationData.indexOf(
                                "diagonsisContent"
                              ),
                              1
                            );
                            applyListInfo.diagonsisContent = "";
                            applyListInfo.diagonsisDate = "";
                          }
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.diagonsis]}
                        data={[
                          {
                            value: "未知",
                            label: "未知",
                          },
                          {
                            value: "未确诊",
                            label: "未确诊",
                          },
                          {
                            value: "确诊",
                            label: "确诊",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.diagonsis
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                          病理诊断
                        </List.Item>
                      </Picker>
                      {this.state.applyListInfo.diagonsis &&
                      this.state.applyListInfo.diagonsis === "确诊" ? (
                        <div className="child-item child-item2">
                          <InputItem
                            placeholder="请填写病理诊断结果"
                            type="text"
                            value={this.state.applyListInfo.diagonsisContent}
                            onChange={(el) => {
                              this.inputChange(el, "diagonsisContent");
                            }}
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.diagonsisContent
                            }
                          >
                            {true ? <span className="sign">*</span> : null}
                            病理诊断结果
                          </InputItem>
                        </div>
                      ) : null}
                      {this.state.applyListInfo.diagonsis &&
                      this.state.applyListInfo.diagonsis === "确诊" ? (
                        <div className="child-item">
                          <DatePicker
                            mode="date"
                            minDate={new Date(1900, 0, 1, 0, 0, 0)}
                            maxDate={new Date()}
                            value={
                              this.state.applyListInfo.diagonsisDate &&
                              new Date(this.state.applyListInfo.diagonsisDate)
                            }
                            onOk={(val) => {
                              let applyListInfo = this.state.applyListInfo;
                              applyListInfo.diagonsisDate = this.formatTime(
                                val
                              );
                              this.setState({
                                applyListInfo,
                              });
                            }}
                          >
                            <List.Item arrow="horizontal">
                              病理诊断日期
                            </List.Item>
                          </DatePicker>
                        </div>
                      ) : null}
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.targetedTherapyHistory = v[0];
                          if (v[0] !== "药物名")
                            applyListInfo.targetedTherapyHistoryInfo = null;
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[
                          this.state.applyListInfo.targetedTherapyHistory,
                        ]}
                        data={[
                          {
                            value: "未知",
                            label: "未知",
                          },
                          {
                            value: "无",
                            label: "无",
                          },
                          {
                            value: "药物名",
                            label: "药物名",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.targetedTherapyHistory
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                          靶向治疗史
                        </List.Item>
                      </Picker>
                      {this.state.applyListInfo.targetedTherapyHistory &&
                      this.state.applyListInfo.targetedTherapyHistory ===
                        "药物名" ? (
                        <div className="child-item">
                          <List.Item
                            arrow="horizontal"
                            extra="请选择"
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo
                                .targetedTherapyHistoryInfo
                            }
                            onClick={() => {
                              this.targetedTherapyHistoryInfo = JSON.parse(
                                JSON.stringify(
                                  this.state.targetedTherapyHistoryInfo || []
                                )
                              );
                              this.setState({
                                diagnosisModal: true,
                                modalType: "targetedTherapyHistoryInfo",
                              });
                            }}
                          >
                            {true ? <span className="sign">*</span> : null}
                            靶向治疗史信息
                          </List.Item>
                        </div>
                      ) : null}
                      {this.state.applyListInfo.targetedTherapyHistoryInfo &&
                      this.state.applyListInfo.targetedTherapyHistoryInfo
                        .length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.targetedTherapyHistoryInfo.map(
                            (v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div>
                                    {v.medicine ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          靶向治疗药物名称：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicine}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.treatmenStartTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          靶向治疗开始时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.treatmenStartTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.treatmenEndTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          靶向治疗结束时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.treatmenEndTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.effect ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          靶向治疗效果：
                                        </span>
                                        <span className="content-lable">
                                          {v.effect}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </List.Item>
                      ) : null}
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.historyChemotherapy = v[0];
                          if (v[0] !== "药物名")
                            applyListInfo.historyChemotherapyInfo = null;
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.historyChemotherapy]}
                        data={[
                          {
                            value: "未知",
                            label: "未知",
                          },
                          {
                            value: "无",
                            label: "无",
                          },
                          {
                            value: "药物名",
                            label: "药物名",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item arrow="horizontal">化疗史</List.Item>
                      </Picker>
                      {this.state.applyListInfo.historyChemotherapy &&
                      this.state.applyListInfo.historyChemotherapy ===
                        "药物名" ? (
                        <div className="child-item">
                          <List.Item
                            arrow="horizontal"
                            extra="请选择"
                            onClick={() => {
                              this.historyChemotherapyInfo = JSON.parse(
                                JSON.stringify(
                                  this.state.historyChemotherapyInfo || []
                                )
                              );
                              this.setState({
                                diagnosisModal: true,
                                modalType: "historyChemotherapyInfo",
                              });
                            }}
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.historyChemotherapyInfo
                            }
                          >
                            {true ? <span className="sign">*</span> : null}
                            化疗史信息
                          </List.Item>
                        </div>
                      ) : null}
                      {this.state.applyListInfo.historyChemotherapyInfo &&
                      this.state.applyListInfo.historyChemotherapyInfo
                        .length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.historyChemotherapyInfo.map(
                            (v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div>
                                    {v.medicine ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          化疗药物名称：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicine}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.treatmenStartTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          化疗开始时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.treatmenStartTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.treatmenEndTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          化疗结束时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.treatmenEndTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.effect ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          化疗治疗效果：
                                        </span>
                                        <span className="content-lable">
                                          {v.effect}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </List.Item>
                      ) : null}
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.historyRadiation = v[0];
                          if (v[0] !== "药物名")
                            applyListInfo.historyRadiationInfo = null;
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.historyRadiation]}
                        data={[
                          {
                            value: "未知",
                            label: "未知",
                          },
                          {
                            value: "无",
                            label: "无",
                          },
                          {
                            value: "药物名",
                            label: "药物名",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item arrow="horizontal">放射治疗史</List.Item>
                      </Picker>
                      {this.state.applyListInfo.historyRadiation &&
                      this.state.applyListInfo.historyRadiation === "药物名" ? (
                        <div className="child-item">
                          <List.Item
                            arrow="horizontal"
                            extra="请选择"
                            onClick={() => {
                              this.historyRadiationInfo = JSON.parse(
                                JSON.stringify(
                                  this.state.historyRadiationInfo || []
                                )
                              );
                              this.setState({
                                diagnosisModal: true,
                                modalType: "historyRadiationInfo",
                              });
                            }}
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.historyRadiationInfo
                            }
                          >
                            {true ? <span className="sign">*</span> : null}
                            放射治疗史信息
                          </List.Item>
                        </div>
                      ) : null}
                      {this.state.applyListInfo.historyRadiationInfo &&
                      this.state.applyListInfo.historyRadiationInfo.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.historyRadiationInfo.map(
                            (v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div>
                                    {v.medicine ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          放疗药物名称：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicine}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.treatmenStartTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          放疗开始时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.treatmenStartTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.treatmenEndTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          放疗结束时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.treatmenEndTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.effect ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          放疗治疗效果：
                                        </span>
                                        <span className="content-lable">
                                          {v.effect}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </List.Item>
                      ) : null}
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          if (v[0] !== 2)
                            applyListInfo.familyHistoryInfo = null;
                          applyListInfo.familyHistory = v[0];
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.familyHistory]}
                        data={[
                          {
                            value: "无",
                            label: "无",
                          },
                          {
                            value: "未知",
                            label: "未知",
                          },
                          {
                            value: "有",
                            label: "有",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.familyHistory
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                          家族史
                        </List.Item>
                      </Picker>
                      {this.state.applyListInfo.familyHistory &&
                      this.state.applyListInfo.familyHistory === "有" ? (
                        <div className="child-item">
                          <List.Item
                            arrow="horizontal"
                            extra="请选择"
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.familyHistoryInfo
                            }
                            onClick={() => {
                              this.familyHistoryInfoDak = JSON.parse(
                                JSON.stringify(
                                  this.state.familyHistoryInfo || []
                                )
                              );
                              this.setState({
                                familyHistoryModal: true,
                              });
                            }}
                          >
                            {true ? <span className="sign">*</span> : null}
                            家族史信息
                          </List.Item>
                        </div>
                      ) : null}
                      {this.state.applyListInfo.familyHistoryInfo &&
                      this.state.applyListInfo.familyHistoryInfo.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.familyHistoryInfo.map(
                            (v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div>
                                    {v.patientRelationship ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          亲属关系：
                                        </span>
                                        <span className="content-lable">
                                          {v.patientRelationship}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.otherTumorType ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          亲属确诊肿瘤：
                                        </span>
                                        <span className="content-lable">
                                          {v.otherTumorType}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.otherPatientAge ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          亲属确诊年龄：
                                        </span>
                                        <span className="content-lable">
                                          {v.otherPatientAge}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </List.Item>
                      ) : null}
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.smokeHistory = v[0];
                          if (v[0] === "有") {
                            this.submitVerificationData.push("smokeHistory");
                          } else {
                            this.submitVerificationData.splice(
                              this.submitVerificationData.findIndex(
                                (v) => v === "smokeHistory"
                              ),
                              1
                            );
                          }
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.smokeHistory]}
                        data={[
                          {
                            value: "无",
                            label: "无",
                          },
                          {
                            value: "有",
                            label: "有",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item arrow="horizontal">吸烟史</List.Item>
                      </Picker>
                      {this.state.applyListInfo.smokeHistory &&
                      this.state.applyListInfo.smokeHistory === "有" ? (
                        <div className="child-item">
                          <InputItem
                            placeholder="请填写吸烟时长"
                            extra="年"
                            type="number"
                            value={this.state.applyListInfo.smokeDuration}
                            onChange={(el) => {
                              this.inputChange(el, "smokeDuration");
                            }}
                            error={
                              !this.state.applyListInfo.smokeDuration &&
                              this.state.submit
                            }
                          >
                            {true ? <span className="sign">*</span> : null}
                            吸烟时长
                          </InputItem>
                        </div>
                      ) : null}
                      <TextareaItem
                        placeholder="临床背景、需求等"
                        defaultValue={
                          this.state.applyListInfo.inspectRemarks || ""
                        }
                        multiple={true}
                        rows={3}
                        onChange={(el) => {
                          this.inputChange(el, "inspectRemarks");
                        }}
                        title="送检备注"
                      ></TextareaItem>
                      <List.Item>
                        申请单上传
                        <ImagePicker
                          multiple={true}
                          onImageClick={(index, files) => {
                            this.setState({
                              uploadImage: files[index].url,
                              showImage: true,
                            });
                          }}
                          files={this.state.applyListInfo.applyOrderUrls}
                          onChange={(files) => {
                            let applyListInfo = this.state.applyListInfo;
                            applyListInfo.applyOrderUrls = files;
                            this.setState({
                              applyListInfo,
                            });
                          }}
                        />
                      </List.Item>
                      <List.Item>
                        临床资料上传
                        <ImagePicker
                          files={this.state.applyListInfo.clinicalDataUrls}
                          onImageClick={(index, files) => {
                            this.setState({
                              uploadImage: files[index].url,
                              showImage: true,
                            });
                          }}
                          onChange={(files) => {
                            let applyListInfo = this.state.applyListInfo;
                            applyListInfo.clinicalDataUrls = files;
                            this.setState({
                              applyListInfo,
                            });
                          }}
                        />
                      </List.Item>
                    </div>
                  </div>
                  <div className="item">
                    {/* <span className="title">样本信息</span> */}
                    <div className="form">
                      <List.Item
                        onClick={() => {
                          this.setState({
                            drawer: true,
                            drawerType: 4,
                            packageIndex2: [],
                            setMealData: JSON.parse(
                              JSON.stringify(this.setMealData)
                            ),
                            sampleInfosError: false,
                          });
                        }}
                        extra="请选择"
                        arrow="horizontal"
                        error={
                          (!this.state.applyListInfo.sampleInfos &&
                            this.state.submit) ||
                          (this.state.submit &&
                            !this.state.applyListInfo.sampleInfos.length) ||
                          this.state.sampleInfosError
                        }
                      >
                        {true ? <span className="sign">*</span> : null}
                        添加样本
                      </List.Item>
                      {this.state.applyListInfo.sampleInfos &&
                      this.state.applyListInfo.sampleInfos.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.sampleInfos
                            .sort((a, b) =>
                              a.sampleCode.localeCompare(b.sampleCode)
                            )
                            .map((v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      className="close"
                                      src={require("../img/close.svg")}
                                      onClick={() => {
                                        let applyListInfo = this.state
                                          .applyListInfo;
                                        let selectSetMeals =
                                          this.state.selectSetMeals || [];
                                        let submit = false;
                                        selectSetMeals.forEach((element) => {
                                          if (element.comboSamInfo) {
                                            element.comboSamInfo.forEach(
                                              (val) => {
                                                if (v.id === val.id) {
                                                  submit = true;
                                                }
                                              }
                                            );
                                          }
                                        });
                                        if (submit) {
                                          Toast.info("请先删除相关套餐！", 1);
                                          return;
                                        }
                                        applyListInfo.sampleInfos.splice(
                                          applyListInfo.sampleInfos.findIndex(
                                            (value) => value === v
                                          ),
                                          1
                                        );
                                        this.letter.push(v.sampleCode);
                                        this.setState({
                                          applyListInfo,
                                        });
                                      }}
                                      style={{
                                        position: "absolute",
                                        right: "-0.1rem",
                                        top: "-0.1rem",
                                      }}
                                    ></img>
                                    <span>样本{v.sampleCode}</span>
                                    {v.sampleTypeName ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          样本类型：
                                        </span>
                                        <span className="content-lable">
                                          {v.sampleTypeName}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.collectTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          采样时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.collectTime}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.sampleNum ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          数量：
                                        </span>
                                        <span className="content-lable">
                                          {v.sampleNum}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.diagonsisNo ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          病理号：
                                        </span>
                                        <span className="content-lable">
                                          {v.diagonsisNo}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.samplingMode ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          采样方式：
                                        </span>
                                        <span className="content-lable">
                                          {v.samplingMode}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.collectLocation ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          采集部位：
                                        </span>
                                        <span className="content-lable">
                                          {v.collectLocation}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.remark ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          备注：
                                        </span>
                                        <span className="content-lable">
                                          {v.remark}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </List.Item>
                      ) : null}
                    </div>
                  </div>
                  <div className="item">
                    {/* <span className="title">套餐信息</span> */}
                    <div className="form">
                      <List.Item
                        onClick={() => {
                          let applyListInfo = this.state.applyListInfo;
                          if (
                            !applyListInfo.sampleInfos ||
                            !applyListInfo.sampleInfos.length
                          ) {
                            this.setState({
                              sampleInfosError: true,
                            });
                            Toast.info("请先选择套餐！", 1);
                            return;
                          }
                          applyListInfo.sampleInfos = applyListInfo.sampleInfos.map(
                            (v) => {
                              v.checked = true;
                              return v;
                            }
                          );
                          this.setState({
                            drawer: true,
                            packageIndex: [],
                            drawerType: 2,
                            selectSetMealsError: false,
                            applyListInfo,
                          });
                        }}
                        arrow="horizontal"
                        extra="请选择"
                        error={
                          (this.state.submit && !this.state.selectSetMeals) ||
                          (this.state.submit &&
                            !this.state.selectSetMeals.length) ||
                          this.state.selectSetMealsError
                        }
                      >
                        {true ? <span className="sign">*</span> : null}
                        添加套餐
                      </List.Item>
                      {this.state.selectSetMeals &&
                      this.state.selectSetMeals.length ? (
                        <List.Item multipleLine>
                          {this.state.selectSetMeals.map((v, k) => {
                            return (
                              <div key={k} className="select-info2">
                                <img
                                  src={require("../img/xx.png")}
                                  alt=""
                                ></img>
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="close"
                                    src={require("../img/close.svg")}
                                    onClick={() => {
                                      let selectSetMeals = this.state
                                        .selectSetMeals;
                                      selectSetMeals.splice(
                                        selectSetMeals.findIndex(
                                          (value) => value === v
                                        ),
                                        1
                                      );
                                      this.setState({
                                        selectSetMeals,
                                      });
                                    }}
                                    style={{
                                      position: "absolute",
                                      right: "-0.1rem",
                                      top: "-0.1rem",
                                    }}
                                  ></img>
                                  {v.setMealName ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        套餐名字：
                                      </span>
                                      <span className="content-lable">
                                        {v.setMealName}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.sampleTypeName ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        样本类型：
                                      </span>
                                      <span className="content-lable">
                                        {v.sampleTypeName}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.InspectionTimes ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        终身版第几次送检：
                                      </span>
                                      <span className="content-lable">
                                        {v.InspectionTimes}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.eR ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        雌激素受体ER：
                                      </span>
                                      <span className="content-lable">
                                        {v.eR}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.pR ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        孕激素受体PR：
                                      </span>
                                      <span className="content-lable">
                                        {v.pR}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.hER2 ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        HER2扩增：
                                      </span>
                                      <span className="content-lable">
                                        {v.hER2}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.lymphTransfer ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        淋巴结转移：
                                      </span>
                                      <span className="content-lable">
                                        {v.lymphTransfer}
                                      </span>
                                    </div>
                                  ) : null}
                                  {v.menopause ? (
                                    <div className="content-item">
                                      <span className="content-title">
                                        绝经：
                                      </span>
                                      <span className="content-lable">
                                        {v.menopause}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                        </List.Item>
                      ) : null}
                    </div>
                  </div>
                </List>
              </div>
            </div>
            <div className="foot">
              <span
                className="submit"
                onClick={() => {
                  if (this.state.contactNumberError) {
                    Toast.info("联系电话有误！", 1);
                    return;
                  }
                  let applyListInfo = this.state.applyListInfo;
                  if (!applyListInfo.salesBarCode) {
                    this.setState({
                      salesBarCodeError: true,
                    });
                    Toast.info("申请单条码不能为空！", 1);
                    return;
                  }

                  Toast.loading("正在保存", 0);

                  let salesBarCode = applyListInfo.salesBarCode || "-1";
                  axios
                    .get(
                      `/open/setmeal/b/${
                        applyListInfo.id ? applyListInfo.id : "-1"
                      }/s/${salesBarCode}`
                    )
                    .then((res) => {
                      if (!res) {
                        this.setState({
                          salesBarCodeError: true,
                        });
                      } else {
                        this.setState(
                          {
                            salesBarCodeError: false,
                          },
                          () => {
                            applyListInfo.selectSetMeals = this.state.selectSetMeals;
                            if (res.person) {
                              applyListInfo.salesMan = res.person.personCode;
                              applyListInfo.salesManPhone = res.person.mobile;
                              applyListInfo.salesEmail = res.person.email;
                              applyListInfo.salesRepresentative =
                                res.person.personName;
                            }
                            axios
                              .post(
                                "/open/setmeal/wxpush/getBatchInfoByWx",
                                applyListInfo,
                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                              .then((res) => {
                                if (res) {
                                  applyListInfo.id = res.id;
                                  this.setState({
                                    applyListInfo,
                                  });
                                  Toast.info("保存成功！", 1);
                                }
                              })
                              .catch(() => {
                                Toast.info("保存失败！", 1);
                              });
                          }
                        );
                      }
                    });
                }}
              >
                保存
              </span>
              <span
                className="submit"
                onClick={() => {
                  this.setState({
                    submit: true,
                  });
                  // const submitVerification = this.submitVerification();
                  // if (!submitVerification) {
                  //   Toast.info("请填写完整信息！", 1);
                  //   return;
                  // }
                  if (this.state.contactNumberError) {
                    Toast.info("联系电话有误！", 1);
                    return;
                  }
                  let applyListInfo = this.state.applyListInfo;
                  if (!applyListInfo.salesBarCode) {
                    this.setState({
                      salesBarCodeError: true,
                    });
                    Toast.info("申请单条码不能为空！", 1);
                    return;
                  }

                  let salesBarCode = applyListInfo.salesBarCode || "-1";

                  Toast.loading("正在提交", 0);
                  axios
                    .get(
                      `/open/setmeal/b/${
                        applyListInfo.id ? applyListInfo.id : "-1"
                      }/s/${salesBarCode}`
                    )
                    .then((res) => {
                      if (!res) {
                        this.setState({
                          salesBarCodeError: true,
                        });
                      } else {
                        this.setState(
                          {
                            salesBarCodeError: false,
                          },
                          () => {
                            applyListInfo.selectSetMeals = this.state.selectSetMeals;
                            if (
                              !applyListInfo.sampleInfos ||
                              !applyListInfo.sampleInfos.length
                            ) {
                              this.setState({
                                sampleInfosError: true,
                              });
                            }
                            if (
                              !applyListInfo.selectSetMeals ||
                              !applyListInfo.selectSetMeals.length
                            ) {
                              this.setState({
                                selectSetMealsError: true,
                              });
                            }
                            if (res.person) {
                              applyListInfo.salesMan = res.person.personCode;
                              applyListInfo.salesManPhone = res.person.mobile;
                              applyListInfo.salesEmail = res.person.email;
                              applyListInfo.salesRepresentative =
                                res.person.personName;
                            }
                            let totalAoumnt = 0;
                            applyListInfo.selectSetMeals.forEach((v, i) => {
                              totalAoumnt += v.amount;
                            });
                            applyListInfo.receiveAmount = totalAoumnt;
                            this.setState({
                              applyListInfo,
                            });

                            //计算申请单流转方向
                            if (
                              this.state.isMonthlyStatement &&
                              applyListInfo.payMethod === "线下"
                            ) {
                              applyListInfo.auditStatus = "AuditC";
                              applyListInfo.status = "Approve";
                            } else if (
                              applyListInfo.payMethod !== "线下" &&
                              parseFloat(applyListInfo.paidAmount) >=
                                parseFloat(applyListInfo.receiveAmount)
                            ) {
                              applyListInfo.auditStatus = "AuditC";
                              applyListInfo.status = "Approve";
                            } else {
                              applyListInfo.auditStatus = "Audit";
                              applyListInfo.status = "Approve";
                            }
                            applyListInfo.submitFlag = 1;
                            if (!applyListInfo.idNumber) {
                              applyListInfo.idNumber =
                                applyListInfo.salesBarCode;
                            }
                            axios
                              .post(
                                "/open/setmeal/wxpush/getBatchInfoByWx",
                                applyListInfo,
                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                              .then((res) => {
                                if (!res) return;
                                Toast.info("提交成功！", 1, () => {
                                  this.props.history.goBack();
                                });
                              })
                              .catch(() => {
                                Toast.info("提交失败！", 1);
                              });
                          }
                        );
                      }
                    });
                }}
              >
                提交
              </span>
            </div>
            <Modal
              popup
              visible={this.state.addressModal}
              onClose={() => {
                this.setState({ addressModal: false, address: this.address });
              }}
              animationType="slide-up"
            >
              <div className="applyListInfo-addressModal">
                <div className="head">
                  <div
                    onClick={() => {
                      this.setState({
                        addressModal: false,
                        address: this.address,
                      });
                    }}
                    className="close"
                  >
                    <span>取消</span>
                  </div>
                  <span>选择地址</span>
                  <div
                    className="close"
                    onClick={() => {
                      let applyListInfo = this.state.applyListInfo;
                      applyListInfo.address = [];
                      this.state.address &&
                        this.state.address.forEach((v) => {
                          if (v.checked) {
                            applyListInfo.address.push(v);
                          }
                        });
                      this.setState({
                        applyListInfo,
                        addressModal: false,
                      });
                    }}
                  >
                    <span
                      style={{
                        justifyContent: "flex-end",
                        marginRight: ".15rem",
                      }}
                    >
                      确定
                    </span>
                  </div>
                </div>
                <div className="center">
                  {this.state.address &&
                    this.state.address.map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="item"
                          onClick={() => {
                            let address = this.state.address.map((value) => {
                              if (v === value) {
                                value.checked = v.checked ? false : true;
                              }
                              return value;
                            });
                            this.setState({
                              address,
                            });
                          }}
                        >
                          <img
                            src={
                              v.checked
                                ? require("../img/selectm(2).png")
                                : require("../img/selectm(1).png")
                            }
                            alt=""
                          ></img>
                          <div className="right">
                            <div className="content">
                              <div className="info">
                                <span>{v.consigneeName}</span>
                                <span>{v.receivingPhone}</span>
                              </div>
                              <span className="address">{v.location}</span>
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.currentModificationAddress = v;
                                this.setState({
                                  addAddressModal: true,
                                });
                              }}
                              className="update"
                            >
                              <img src={require("../img/xg.png")} alt=""></img>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="foot">
                  <div
                    onClick={() => {
                      this.setState({
                        addAddressModal: true,
                      });
                    }}
                    className="content"
                  >
                    <img alt="" src={require("../img/tj.svg")}></img>
                    <span>新增地址</span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal visible={this.state.addAddressModal}>
              <div className="applyListInfo-add-addressModal">
                <NavBar
                  mode="light"
                  onClick={() => {
                    this.setState(
                      {
                        addAddressModal: false,
                      },
                      () => {
                        this.currentModificationAddress = null;
                      }
                    );
                  }}
                  icon={<Icon type="left" />}
                >
                  新增地址
                </NavBar>
                <div className="center">
                  <List>
                    <InputItem
                      placeholder="请填写报告收件人"
                      ref={(v) => (this.consigneeName = v)}
                      error={
                        this.state.consigneeNameError &&
                        !this.consigneeName.state.value
                      }
                      onChange={(el) => {
                        this.setState({
                          consigneeNameError: false,
                        });
                      }}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.consigneeName) ||
                        (this.state.agentInfo &&
                          this.state.agentInfo.personName)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ color: "red" }}>*</span>
                        报告收件人
                      </div>
                    </InputItem>
                    <InputItem
                      type="number"
                      placeholder="请填写收件人电话"
                      error={
                        this.state.currentModificationAddressError &&
                        !this.receivingPhone.state.value
                      }
                      ref={(v) => (this.receivingPhone = v)}
                      onChange={() => {
                        this.setState({
                          currentModificationAddressError: false,
                        });
                      }}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.receivingPhone) ||
                        (this.state.agentInfo && this.state.agentInfo.mobile)
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      收件人电话
                    </InputItem>
                    <TextareaItem
                      placeholder="详细地址,例1层101室"
                      ref={(ref) => {
                        this.houseNumber = ref;
                      }}
                      error={
                        this.state.houseNumberError &&
                        !this.houseNumber.state.value
                      }
                      onChange={() => {
                        this.setState({
                          houseNumberError: false,
                        });
                      }}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.location) ||
                        (this.state.agentInfo &&
                          this.state.agentInfo.contactadress)
                      }
                      rows={3}
                      title={
                        <div>
                          <span style={{ color: "red" }}>*</span>
                          <span>收件人地址</span>
                        </div>
                      }
                    ></TextareaItem>
                    <InputItem
                      placeholder="请填写邮寄份数"
                      ref={(v) => (this.mailCopies = v)}
                      defaultValue={
                        this.currentModificationAddress &&
                        this.currentModificationAddress.mailCopies
                      }
                      type="number"
                      extra="份"
                    >
                      邮寄份数
                    </InputItem>
                    <TextareaItem
                      placeholder="请填写邮寄备注"
                      ref={(ref) => {
                        this.mailingNotes = ref;
                      }}
                      defaultValue={
                        this.currentModificationAddress &&
                        this.currentModificationAddress.mailingNotes
                      }
                      rows={3}
                      title="邮寄备注"
                    ></TextareaItem>
                    <InputItem
                      placeholder="请填写电子邮箱"
                      ref={(v) => (this.email = v)}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.email) ||
                        (this.state.agentInfo && this.state.agentInfo.email)
                      }
                    >
                      电子邮箱
                    </InputItem>
                  </List>
                  <div className="submit">
                    <span
                      onClick={() => {
                        let address = this.state.address || [];
                        if (
                          !(
                            this.receivingPhone.state.value &&
                            this.consigneeName.state.value &&
                            this.houseNumber.state.value
                          )
                        ) {
                          Toast.info("请填写完整信息！", 1);
                          this.setState({
                            consigneeNameError: true,
                            houseNumberError: true,
                            currentModificationAddressError: true,
                          });
                          return;
                        }
                        if (this.currentModificationAddress) {
                          address = address.map((v, k) => {
                            if (v === this.currentModificationAddress) {
                              v = {
                                receivingPhone: this.receivingPhone.state.value,
                                consigneeName: this.consigneeName.state.value,
                                location: this.houseNumber.state.value,
                                email: this.email.state.value,
                                mailingNotes: this.mailingNotes.state.value,
                                mailCopies: this.mailCopies.state.value,
                                checked: v.checked,
                              };
                            }
                            return v;
                          });
                        } else {
                          address.push({
                            receivingPhone: this.receivingPhone.state.value,
                            consigneeName: this.consigneeName.state.value,
                            location: this.houseNumber.state.value,
                            email: this.email.state.value,
                            mailingNotes: this.mailingNotes.state.value,
                            mailCopies: this.mailCopies.state.value,
                          });
                          this.address.push({
                            receivingPhone: this.receivingPhone.state.value,
                            consigneeName: this.consigneeName.state.value,
                            location: this.houseNumber.state.value,
                            email: this.email.state.value,
                            mailingNotes: this.mailingNotes.state.value,
                            mailCopies: this.mailCopies.state.value,
                          });
                        }
                        this.setState(
                          {
                            address,
                            addAddressModal: false,
                          },
                          () => {
                            this.currentModificationAddress = null;
                          }
                        );
                      }}
                    >
                      保存地址
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              popup
              visible={this.state.diagnosisModal}
              onClose={() => {
                this.setState({
                  diagnosisModal: false,
                  [this.state.modalType]: this[this.state.modalType],
                });
              }}
              animationType="slide-up"
            >
              <div className="applyListInfo-diagnosisModal">
                <div className="head">
                  <div
                    onClick={() => {
                      this.setState({
                        diagnosisModal: false,
                        [this.state.modalType]: this[this.state.modalType],
                      });
                    }}
                    className="close"
                  >
                    <span>取消</span>
                  </div>
                  <span>{this.renderTitle()}</span>
                  <div
                    className="close"
                    onClick={() => {
                      let applyListInfo = this.state.applyListInfo;
                      applyListInfo[this.state.modalType] = [];
                      this.state[this.state.modalType] &&
                        this.state[this.state.modalType].forEach((v) => {
                          if (v.checked) {
                            applyListInfo[this.state.modalType].push(v);
                          }
                        });
                      this.setState({
                        applyListInfo,
                        diagnosisModal: false,
                      });
                    }}
                  >
                    <span
                      style={{
                        justifyContent: "flex-end",
                        marginRight: ".15rem",
                      }}
                    >
                      确定
                    </span>
                  </div>
                </div>
                <div className="center">
                  {this.state[this.state.modalType] &&
                    this.state[this.state.modalType].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="item"
                          onClick={() => {
                            let data = this.state[this.state.modalType].map(
                              (value) => {
                                if (v === value) {
                                  value.checked = v.checked ? false : true;
                                }
                                return value;
                              }
                            );
                            this.setState({
                              [this.state.modalType]: data,
                            });
                          }}
                        >
                          <img
                            src={
                              v.checked
                                ? require("../img/selectm(2).png")
                                : require("../img/selectm(1).png")
                            }
                            alt=""
                          ></img>
                          <div className="right">
                            <div className="content">
                              {v.medicine ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    {this.renderTitle()}药物名称：
                                  </span>
                                  <span className="content-lable">
                                    {v.medicine}
                                  </span>
                                </div>
                              ) : null}
                              {v.treatmenStartTime ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    {this.renderTitle()}开始时间：
                                  </span>
                                  <span className="content-lable">
                                    {v.treatmenStartTime}
                                  </span>
                                </div>
                              ) : null}

                              {v.treatmenEndTime ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    {this.renderTitle()}结束时间：
                                  </span>
                                  <span className="content-lable">
                                    {v.treatmenEndTime}
                                  </span>
                                </div>
                              ) : null}
                              {v.effect ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    {this.renderTitle()}效果：
                                  </span>
                                  <span className="content-lable">
                                    {v.effect}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.treatmentInfo = v;
                                this.setState({
                                  diagnosisAddModal: true,
                                  treatmenStartTime: v.treatmenStartTime,
                                  treatmenEndTime: v.treatmenEndTime,
                                });
                              }}
                              className="update"
                            >
                              <img src={require("../img/xg.png")} alt=""></img>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="foot">
                  <div
                    onClick={() => {
                      this.setState({
                        diagnosisAddModal: true,
                      });
                    }}
                    className="content"
                  >
                    <img alt="" src={require("../img/tj.svg")}></img>
                    <span>新增信息</span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal visible={this.state.diagnosisAddModal}>
              <div className="applyListInfo-add-diagnosisModal">
                <NavBar
                  mode="light"
                  onClick={() => {
                    this.setState(
                      {
                        diagnosisAddModal: false,
                        treatmenStartTime: null,
                        treatmenEndTime: null,
                      },
                      () => {
                        this.treatmentInfo = null;
                      }
                    );
                  }}
                  icon={<Icon type="left" />}
                >
                  新增{this.renderTitle()}史信息
                </NavBar>
                <div
                  className="center"
                  style={{
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                  }}
                >
                  <List>
                    <InputItem
                      placeholder="请填写使用药物的名称"
                      ref={(ref) => {
                        this.medicine = ref;
                      }}
                      defaultValue={
                        this.treatmentInfo && this.treatmentInfo.medicine
                      }
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            medicineError: false,
                          });
                        }
                      }}
                      error={
                        this.state.medicineError && !this.medicine.state.value
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      {this.renderTitle()}药物名称
                    </InputItem>
                    <DatePicker
                      mode="date"
                      minDate={new Date(1900, 0, 1, 0, 0, 0)}
                      maxDate={new Date()}
                      value={
                        this.state.treatmenStartTime &&
                        new Date(this.state.treatmenStartTime)
                      }
                      onOk={(val) => {
                        const treatmenStartTime = val;
                        this.setState({
                          treatmenStartTime,
                        });
                      }}
                    >
                      <List.Item arrow="horizontal">
                        {this.renderTitle()}开始时间
                      </List.Item>
                    </DatePicker>
                    <DatePicker
                      mode="date"
                      minDate={new Date(1900, 0, 1, 0, 0, 0)}
                      maxDate={new Date()}
                      value={
                        this.state.treatmenEndTime &&
                        new Date(this.state.treatmenEndTime)
                      }
                      onOk={(val) => {
                        const treatmenEndTime = val;
                        this.setState({
                          treatmenEndTime,
                        });
                      }}
                    >
                      <List.Item arrow="horizontal">
                        {this.renderTitle()}结束时间
                      </List.Item>
                    </DatePicker>
                    <InputItem
                      placeholder="请填写治疗效果"
                      ref={(v) => (this.effect = v)}
                      defaultValue={
                        this.treatmentInfo && this.treatmentInfo.effect
                      }
                    >
                      {this.renderTitle()}效果
                    </InputItem>
                  </List>
                  <div className="submit">
                    <span
                      onClick={() => {
                        if (this.medicine.state.value) {
                          let treatmentData =
                            this.state[this.state.modalType] || [];
                          if (this.treatmentInfo) {
                            treatmentData = treatmentData.map((v, k) => {
                              if (v === this.treatmentInfo) {
                                v = {
                                  medicine: this.medicine.state.value,
                                  treatmenStartTime: this.formatTime(
                                    this.state.treatmenStartTime
                                  ),
                                  treatmenEndTime: this.formatTime(
                                    this.state.treatmenEndTime
                                  ),
                                  effect: this.effect.state.value,
                                  checked: v.checked,
                                };
                              }
                              return v;
                            });
                          } else {
                            treatmentData.push({
                              medicine: this.medicine.state.value,
                              treatmenStartTime: this.formatTime(
                                this.state.treatmenStartTime
                              ),
                              treatmenEndTime: this.formatTime(
                                this.state.treatmenEndTime
                              ),
                              effect: this.effect.state.value,
                            });
                            this[this.state.modalType].push({
                              medicine: this.medicine.state.value,
                              treatmenStartTime: this.formatTime(
                                this.state.treatmenStartTime
                              ),
                              treatmenEndTime: this.formatTime(
                                this.state.treatmenEndTime
                              ),
                              effect: this.effect.state.value,
                            });
                          }
                          this.setState(
                            {
                              [this.state.modalType]: treatmentData,
                              diagnosisAddModal: false,
                              treatmenStartTime: null,
                              treatmenEndTime: null,
                            },
                            () => {
                              this.treatmentInfo = null;
                            }
                          );
                        } else {
                          this.setState({
                            medicineError: true,
                          });
                          Toast.info("请填写完整信息", 1);
                        }
                      }}
                    >
                      保存信息
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              popup
              visible={this.state.familyHistoryModal}
              onClose={() => {
                this.setState({
                  familyHistoryModal: false,
                  familyHistoryInfo: this.familyHistoryInfoDak,
                });
              }}
              animationType="slide-up"
            >
              <div className="applyListInfo-diagnosisModal">
                <div className="head">
                  <div
                    onClick={() => {
                      this.setState({
                        familyHistoryModal: false,
                        familyHistoryInfo: this.familyHistoryInfoDak,
                      });
                    }}
                    className="close"
                  >
                    <span>取消</span>
                  </div>
                  <span>家族史信息</span>
                  <div
                    className="close"
                    onClick={() => {
                      let applyListInfo = this.state.applyListInfo;
                      applyListInfo.familyHistoryInfo = [];
                      this.state.familyHistoryInfo &&
                        this.state.familyHistoryInfo.forEach((v) => {
                          if (v.checked) {
                            applyListInfo.familyHistoryInfo.push(v);
                          }
                        });
                      this.setState({
                        applyListInfo,
                        familyHistoryModal: false,
                      });
                    }}
                  >
                    <span
                      style={{
                        justifyContent: "flex-end",
                        marginRight: ".15rem",
                      }}
                    >
                      确定
                    </span>
                  </div>
                </div>
                <div className="center">
                  {this.state.familyHistoryInfo &&
                    this.state.familyHistoryInfo.map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="item"
                          onClick={() => {
                            let familyHistoryInfo = this.state.familyHistoryInfo.map(
                              (value) => {
                                if (v === value) {
                                  value.checked = v.checked ? false : true;
                                }
                                return value;
                              }
                            );
                            this.setState({
                              familyHistoryInfo,
                            });
                          }}
                        >
                          <img
                            src={
                              v.checked
                                ? require("../img/selectm(2).png")
                                : require("../img/selectm(1).png")
                            }
                            alt=""
                          ></img>
                          <div className="right">
                            <div className="content">
                              <div className="content-item">
                                <span className="content-title">
                                  亲属关系：
                                </span>
                                <span className="content-lable">
                                  {v.patientRelationship}
                                </span>
                              </div>
                              {v.otherPatientAge ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    亲属确诊年龄：
                                  </span>
                                  <span className="content-lable">
                                    {v.otherPatientAge}
                                  </span>
                                </div>
                              ) : null}
                              <div className="content-item">
                                <span className="content-title">
                                  亲属确诊肿瘤：
                                </span>
                                <span className="content-lable">
                                  {v.otherTumorType}
                                </span>
                              </div>
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.familyHistoryInfo = v;
                                this.setState({
                                  familyHistoryAddModal: true,
                                });
                              }}
                              className="update"
                            >
                              <img src={require("../img/xg.png")} alt=""></img>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="foot">
                  <div
                    onClick={() => {
                      this.setState({
                        familyHistoryAddModal: true,
                      });
                    }}
                    className="content"
                  >
                    <img alt="" src={require("../img/tj.svg")}></img>
                    <span>新增信息</span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal visible={this.state.familyHistoryAddModal}>
              <div className="applyListInfo-add-diagnosisModal">
                <NavBar
                  mode="light"
                  onClick={() => {
                    this.setState(
                      {
                        familyHistoryAddModal: false,
                      },
                      () => {
                        this.familyHistoryInfo = null;
                      }
                    );
                  }}
                  icon={<Icon type="left" />}
                >
                  新增家族史信息
                </NavBar>
                <div className="center">
                  <List>
                    <InputItem
                      placeholder="亲属与受检者血缘关系"
                      ref={(ref) => {
                        this.patientRelationship = ref;
                      }}
                      defaultValue={
                        this.familyHistoryInfo &&
                        this.familyHistoryInfo.patientRelationship
                      }
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            familyHistoryInfoSubmit: false,
                          });
                        }
                      }}
                      error={
                        this.state.familyHistoryInfoSubmit &&
                        !this.patientRelationship.state.value
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      亲属关系
                    </InputItem>
                    <InputItem
                      placeholder="亲属确诊肿瘤类型"
                      error={
                        this.state.familyHistoryInfoSubmit &&
                        !this.otherTumorType.state.value
                      }
                      ref={(v) => (this.otherTumorType = v)}
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            familyHistoryInfoSubmit: false,
                          });
                        }
                      }}
                      defaultValue={
                        this.familyHistoryInfo &&
                        this.familyHistoryInfo.otherTumorType
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      亲属确诊肿瘤
                    </InputItem>
                    <InputItem
                      placeholder="亲属确诊年龄"
                      extra="岁"
                      type="number"
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            familyHistoryInfoSubmit: false,
                          });
                        }
                      }}
                      ref={(v) => (this.otherPatientAge = v)}
                      defaultValue={
                        this.familyHistoryInfo &&
                        this.familyHistoryInfo.otherPatientAge
                      }
                    >
                      亲属确诊年龄
                    </InputItem>
                  </List>
                  <div className="submit">
                    <span
                      onClick={() => {
                        this.setState({
                          familyHistoryInfoSubmit: true,
                        });
                        if (
                          this.patientRelationship.state.value &&
                          this.otherTumorType.state.value
                        ) {
                          let familyHistoryInfo =
                            this.state.familyHistoryInfo || [];
                          if (this.familyHistoryInfo) {
                            familyHistoryInfo = familyHistoryInfo.map(
                              (v, k) => {
                                if (v === this.familyHistoryInfo) {
                                  v = {
                                    patientRelationship: this
                                      .patientRelationship.state.value,
                                    otherPatientAge: this.otherPatientAge.state
                                      .value,
                                    otherTumorType: this.otherTumorType.state
                                      .value,
                                    checked: v.checked,
                                    ageUnit: "岁",
                                  };
                                }
                                return v;
                              }
                            );
                          } else {
                            familyHistoryInfo.push({
                              patientRelationship: this.patientRelationship
                                .state.value,
                              otherPatientAge: this.otherPatientAge.state.value,
                              ageUnit: "岁",
                              otherTumorType: this.otherTumorType.state.value,
                            });
                            this.familyHistoryInfoDak.push({
                              patientRelationship: this.patientRelationship
                                .state.value,
                              otherPatientAge: this.otherPatientAge.state.value,
                              ageUnit: "岁",
                              otherTumorType: this.otherTumorType.state.value,
                            });
                          }
                          this.setState(
                            {
                              familyHistoryInfo,
                              familyHistoryAddModal: false,
                            },
                            () => {
                              this.familyHistoryInfo = null;
                            }
                          );
                        } else {
                          Toast.info("请填写完整信息", 1);
                        }
                      }}
                    >
                      保存信息
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </Drawer>
        {this.state.showImage ? (
          <div
            className="show-image"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              zIndex: "999",
              top: "0",
              left: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            id="video"
            onClick={() => this.setState({ showImage: false })}
          >
            <img
              alt=""
              style={{ width: "100%" }}
              src={this.state.uploadImage}
            ></img>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.applyListInfoReducer,
  };
}

export default connect(mapStateToProps, { applyListInfoAction })(ApplyListInfo);
