import React from "react";
import { NavBar, Icon, Toast, InputItem, DatePicker } from "antd-mobile";
import "./style/quickCreation.css";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import { connect } from "react-redux";
import { cancelApplicationAction } from "../../action/apply/cancelApplication";

class CancelApplication extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      id: "",
      // status: ["Draft_Reject"],
      applicationData: this.props.applicationData || [],
      searchStr: this.props.searchStr || "",
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      loading: true,
    };
    this.applicationData = [];
  }

  setRedux = () => {
    const reduxData = ["searchStr", "applicationData", "startTime", "endTime"];
    for (let pop in reduxData) {
      this.props.cancelApplicationAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["searchStr", "applicationData", "startTime", "endTime"];
    for (let pop in reduxData) {
      this.props.cancelApplicationAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
  }

  search = () => {
    let applicationData = [];
    const allData = JSON.parse(JSON.stringify(this.applicationData));
    if (allData) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );
      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );
      allData.forEach((element) => {
        if (element && element.createdTime) {
          let createdTime = parseInt(
            this.formatTime(element.createdTime).replace(/-/g, "")
          );
          if (createdTime <= endTime && createdTime >= startTime) {
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                const searchStr = this.state.searchStr || '';
                if (element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1) {
                  applicationData.push(element);
                  break;
                }
              }
            }
          }
        }
      });
      this.setState(
        {
          applicationData,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  //返回首页
  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  componentDidMount() {
    this.selectBatchByDraftSave();
  }

  selectBatchByDraftSave = () => {
    const status = ["Logged", "Discount_Approval", "Modify_Approval", "Pending_Sample", "Draft", "Done", "Reported", "Waiting_For_Send"];
    axios
      .post("/open/setmeal/wxpush/selectBatchByDraftSave", status, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.batchinfodtos) return;
        this.applicationData = res.batchinfodtos;
        this.setState({
          applicationData: res.batchinfodtos,
        });
        this.setState({
          loading: false,
        });
      });
  };

  getBatchStatus = (v, k) => {
    let status;
    switch (v) {
      case "Logged":
        status = "检测中";
        if (k && k.toUpperCase().indexOf("REJECT") > 0) {
          status = "修改信息审核拒绝";
        }
        break;
      case "Discount_Approval":
        status = "折扣申请审核通过";
        break;
      case "Modify_Approval":
        status = "修改申请审核通过";
        break;
      case "Pending_Sample":
        status = "样本待处理";
        break;
      case "Cancel_Approve":
        status = "取消申请待审核";
        break;
      case "Canceled":
        status = "取消申请审核通过";
        break;
      case "Draft":
        status = "新建";
        break;
      case "Done":
        status = "检测完成";
        break;
      case "Reported":
        status = "已出报告";
        break;
      case "Waiting_For_Send":
        status = "样本待寄送";
        break;
      default:
        break;
    }
    return status;
  };

  render() {
    return (
      <div
        onClick={() => {
          let applicationData = this.state.applicationData.map((value) => {
            value.mask = false;
            return value;
          });
          this.setState({
            applicationData,
          });
        }}
        className="quickCreation"
      >
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          申请单取消
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.search();
              }}
              className="search-bar-top-text"
            >
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
            {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          </div>
        </div>
        {this.state.applicationData && this.state.applicationData.length ? (
          <div className="center">
            {this.state.applicationData.map((v, k) => {
              return (
                <div
                  className="item"
                  onClick={(e) => {
                    e.stopPropagation();
                    let applicationData = this.state.applicationData.map(
                      (value) => {
                        if (v === value) {
                          value.mask = !v.mask;
                        } else {
                          value.mask = false;
                        }
                        return value;
                      }
                    );
                    this.setState({
                      applicationData,
                    });
                    return;
                  }}
                  key={k}
                >
                  <div className="item-row">
                    <img alt="" src={require("../img/ddbh.svg")}></img>
                    <span> {v.salesBarCode} </span>
                    {/* 状态 */}
                    <span
                      className="item-status"
                      style={{
                        backgroundColor: "#0089FFDB",
                        display: "flex",
                        padding: "0.03rem 0.05rem",
                        marginLeft: "0.1rem",
                        borderRadius: "0.05rem",
                        color: "white",
                        fontSize: "0.1rem"
                      }}
                    >
                      状态：{this.getBatchStatus(v.status, v.processStatus)}
                    </span>
                  </div>
                  <div className="content">
                    {v.patientName ? <span>{v.patientName}</span> : null}
                    {v.hospital ? <span>{v.hospital}</span> : null}
                    {v.sampleInfos && v.sampleInfos.length ? (
                      <span>
                        {(() => {
                          let sampleInfos = v.sampleInfos.map((element) => {
                            return (
                              `(${element.sampleCode})` + element.sampleTypeName
                            );
                          });
                          return sampleInfos.join("，");
                        })()}
                      </span>
                    ) : null}
                    {v.inspectionItems && v.inspectionItems.length ? (
                      <span>
                        {(() => {
                          let inspectionItems = v.inspectionItems.map(
                            (element) => {
                              return element.productName;
                            }
                          );
                          return inspectionItems.join("，");
                        })()}
                      </span>
                    ) : null}
                    {v.createdTime ? <span>{v.createdTime}</span> : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "0.15rem",
                      paddingBottom: "0.1rem",
                      flexShrink: "0",
                      flexGrow: "0",
                    }}
                  >
                  </div>
                  {v.mask ? <div className="mask"></div> : null}
                  <div className="foot">
                    {v.mask ? (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          v.readonly = true;
                          if (v.status !== 'Canceled') {
                            v.action = "cancel";
                          }
                          this.props.history.push({
                            pathname: "/applicationDetails",
                            query: v,
                          });
                        }}
                        style={{ backgroundColor: "rgb(254, 202, 18)" }}
                      >
                        详情
                      </span>

                    ) : null}
                    {v.mask && (v.status !== "Cancel_Approve" && v.status !== "Canceled") ? (
                      <span
                        style={{ backgroundColor: "red" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push({
                            pathname: "/cancel",
                            query: v,
                          });
                        }}
                      >
                        取消
                      </span>

                    ) : null}
                  </div>


                </div>
              );
            })}
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.cancelApplicationReducer,
  };
}
export default connect(mapStateToProps, { cancelApplicationAction })(
  CancelApplication
);
