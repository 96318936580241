import React from "react";
import {
  InputItem,
  NavBar,
  Icon,
  List,
  Toast,
  Modal,
  Picker,
  Accordion,
  SearchBar,
  Drawer,

} from "antd-mobile";
import axios from "axios";
import { connect } from "react-redux";
import "../apply/style/applyListInfo.css";
import { exceptionHandlingDetailAction } from "../../action/exception/exceptionHandlingDetail";
const alert = Modal.alert;
const zffs = [
  // { img: require("../img/zf1.png"), title: "支付宝", key: 0 },
  // { img: require("../img/zf2.png"), title: "微信支付", key: 1 },
  // { img: require("../img/jhsm.svg"), title: "聚合扫码付", key: 2 },
  { img: require("../img/pos.svg"), title: "POS订单付", key: 0 },
];

class ExceptionHandlingDetail extends React.Component {
  constructor(props) {
    super(props);
    const params =
      (this.props.location && this.props.location.query) || props || {};
    this.state = {
      id: params.id || props.id || "",
      salesBarCode: params.salesBarCode || props.salesBarCode || "",
      status: params.status || "",
	  paymentType: props.paymentType || "1",
      patientName: params.patientName || props.patientName || "",
      caseNumber: params.caseNumber || props.caseNumber || "",
      idNumber: params.idNumber || "",
	  czfs: props.czfs || 0,
      needAdd: params.needAdd || false,
      inspectionItems: params.inspectionItems || props.inspectionItems || "",
      inspectionStage: params.inspectionStage || props.inspectionStage || "病理评估",
      paidAmount: params.paidAmount || props.paidAmount || "",
      excepHandlMethod: params.excepHandlMethod || props.excepHandlMethod || "",
      costHandlMode: params.costHandlMode || props.costHandlMode || "",
      refunder: params.refunder || props.refunder || "",
      redundAccount: params.redundAccount || props.redundAccount || "",
      redundOpenBank: params.redundOpenBank || props.redundOpenBank || "",
      redundPhone: params.redundPhone || props.redundPhone || "",
      refundAmount: params.refundAmount || props.refundAmount || "",
      supplementaryAmount: params.supplementaryAmount || props.supplementaryAmount || "",
      money: params.money || props.money || "",
      hisInspectionItems: []
    };

    //手风琴索引
    this.packageIndex = [];
    this.packageIndex2 = [];
    this.packageIndex3 = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];

    this.check = ["excepHandlMethod"];
    this.makeUpCheck = ["supplementaryAmount"];
    this.refundCheck = ["refunder", "redundAccount", "redundOpenBank", "redundPhone", "refundAmount"];

    var _this = this;
    this.state.inspectionItems && this.state.inspectionItems.map(function (k, v) {
      _this.state.hisInspectionItems.push(k);
    })
    this.state.inspectionItems = [];
  }

  //选择检测项目
  choiceInspectionItem = (value, arr, k, e) => {
    let setMealData = this.state.inspectionItemsData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex3 = this.state.packageIndex3;
    const indexOf = packageIndex3.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex3.push(value.id);
    } else {
      packageIndex3.splice(indexOf, 1);
    }
    this.setState({
      packageIndex3,
      setMealData,
    });
  };

  getInspectionItems = () => {
    axios
      .get("/open/setmeal/getInspectionItems")
      .then((res) => {
        if (res && res.lsiproductmainbeans) {
          this.setState(
            {
              inspectionItemsData: res.lsiproductmainbeans,
            },
            () => {
              Toast.hide();
              this.inspectionItemsData = JSON.parse(
                JSON.stringify(res.lsiproductmainbeans)
              );
            }
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  //打开检测项目抽屉
  renderInspectionItems = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              inspectionItemsSearchBar: "",
              submitInspectionItems: false,
            });
          }}
        >
          添加检测项目
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.inspectionItemsSearchBar || ""}
            onChange={(el) => {
              let inspectionItemsData = JSON.parse(
                JSON.stringify(this.inspectionItemsData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                inspectionItemsSearchBar: el,
                inspectionItemsData,
                packageIndex3: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex3}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.inspectionItemsData.map((v, k) => {
              let inspectionItemsSearchBar =
                this.state.inspectionItemsSearchBar &&
                this.state.inspectionItemsSearchBar.toLowerCase();
              if (
                !inspectionItemsSearchBar ||
                (v.productName &&
                  v.productName
                    .toLowerCase()
                    .indexOf(inspectionItemsSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceInspectionItem(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.productName}</span>
                      </div>
                    }
                  >
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let inspectionItemsData = this.state.inspectionItemsData;
              let inspectionItems = this.state.inspectionItems || [];

              inspectionItemsData.forEach((element) => {
                if (element.checked) {
                  inspectionItems.push({
                    productCode: element.productCode,
                    productId : element.id,
                    producttype : element.ext$.producttype,
                    productName: element.productName,
                    amount: element.chargeAmount,
                  });
                }
              });
              this.state.inspectionItems = inspectionItems;
              this.setState({
                drawer: false,
                submitInspectionItems: false,
                inspectionItemsSearchBar: "",
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 1:
        return this.renderTumourDrawer();
      case 2:
        return this.rendersetMealDrawer();
      case 3:
        return this.renderInspectionUnitDrawer();
      case 4:
        return this.renderSampleInformation();
      case 5:
        return this.renderNation();
      case 6:
        return this.renderInspectionItems();
      case 7:
        return this.renderTreatmentMethodDrawer();
      default:
        return <div></div>;
    }
  };

  componentDidMount() {
    Toast.loading("正在加载", 2);

    this.getInspectionItems();
  };


  setRedux = () => {
    const reduxData = [
      "patientName",
	  "czfs",
      "salesBarCode",
	  "needAdd",
      "idNumber",
      "v",
      "status",
    ];
    for (let pop in reduxData) {
      this.props.exceptionHandlingDetailAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  dateFormat = (fmt, date) => {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  };

  clearRedux = () => {
    const reduxData = [
      "patientName",
      "salesBarCode",
      "status",
	  "needAdd",
      "idNumber",
      "isMonthlyStatement",
    ];
    for (let pop in reduxData) {
      this.props.exceptionHandlingDetailAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== "/QRcode"
    ) {
      this.clearRedux();
    }
  }


  submit = (salesBarCode) => {
    axios
      .post(
        `/open/lkl/pay`,
        {
          salesBarCode: salesBarCode,
          amount: this.state.supplementaryAmount,
          ip: "127.0.0.1",
          body: this.state.patientName,
          idNumber: this.state.idNumber,
          patientName: this.state.patientName,
          
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (!res || !res.resultmap) 
        {
        return;

        }
        Toast.hide();
        this.setRedux();
        this.props.exceptionHandlingDetailAction({
          type: "page",
          data: this.state.needAdd ? -4 : -3,
        });
        this.props.history.push({
          pathname: "/QRcode",
          query: {
            img: res.resultmap.imagePay,
            v: {
              ...(this.props.location.query || this.props.v),
              salesBarCode: this.state.salesBarCode,
            },
            czfs: this.state.czfs,
            money: this.state.supplementaryAmount,
          },
        });
      })
      .catch(() => {
        Toast.info("加载失败！");
      });

  };

 //线下支付
  offlinePayment = (salesBarCode) => {

  this.state.money = this.state.supplementaryAmount;
  const param = {
    salesBarCode: salesBarCode,
    status: "Exception_Handling",
    paidAmount: this.state.supplementaryAmount,
    patientName: this.state.patientName,
    idNumber: this.state.idNumber,
    caseNumber: this.state.caseNumber
  };
  axios
    .post(
      `/open/setmeal/offline/submitBatchByWx`,
      param,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (!res) return;
      Toast.hide();
      this.props.history.push({
        pathname: "paymentSuccessful",
        query: this.state,
        payParam: param,
      });
    })
    .catch(() => {
      Toast.info("线下支付失败！", 2);
    });
};



//远程支付remotePay
remotePay = (salesBarCode,paymentType) => {
  axios
    .post(
      `/open/fuiou/remotePay`,
      {
        salesBarCode: salesBarCode,
        amount: this.state.supplementaryAmount,
        paymentType : paymentType,
        ip: "127.0.0.1",
        body: this.state.patientName,
        idNumber: this.state.idNumber,
        patientName: this.state.patientName,
        
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (!res || !res.resultmap) 
      {
      return;

      }
      Toast.hide();
      this.setRedux();
      this.props.exceptionHandlingDetailAction({
        type: "page",
        data: this.state.needAdd ? -4 : -3,
      });
      this.props.history.push({
        pathname: "/QRcode",
        query: {
          img: res.resultmap.imagePay,
          v: {
            ...(this.props.location.query || this.props.v),
            salesBarCode: this.state.salesBarCode,
          },
          czfs: this.state.czfs,
          money: this.state.supplementaryAmount,
        },
      });
    })
    .catch(() => {
      Toast.info("加载失败！");
    });

};




  goBack = () => {
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };



  render() {
    const params =
      (this.props.location && this.props.location.query) || this.props || {};
    return (
      <div className="applyListInfo">
        <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            if (this.state.drawerType === 2) {
              let state = this.state;
              state.selectSetMeals = this.selectSetMeals;
              this.setState({
                state,
              });
            }
            this.setState({
              drawer: false,
              setMealSearchBar: "",
              sampleInformationSearchBar: "",
              nationSearchBar: "",
              inspectionUnitSearchBar: "",
              inspectionItemsSearchBar: "",
              tumorTypeSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexShrink: "0",
            flexGrow: "0",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
            >
              异常处理
        </NavBar>
            <div className="center">
              <div style={{ width: "100%" }}>
                <List>
                  <div className="item">
                    <div className="form">
                      <InputItem
                        extra={
                          <img
                            style={{ marginLeft: "0.1rem" }}
                            src={require("../img/sm.png")}
                            alt=""
                          ></img>
                        }
                        disabled={true}
                        defaultValue={this.state.salesBarCode || ""}
                        onChange={(val) => {
                          this.setState({
                            salesBarCode: val,
                            salesRepresentative: "",
                          });
                        }}
                        error={
                          (this.state.submit && !this.state.salesBarCode) ||
                          this.state.salesBarCodeError
                        }
                      >
                        <span style={{ color: "red" }}>*</span>
              申请单条码
            </InputItem>
                      <InputItem
                        error={this.state.submit && !this.state.patientName}
                        defaultValue={this.state.patientName || ""}
                        onChange={(val) => {
                          this.setState({
                            patientName: val,
                          });
                        }}
                        disabled={true}
                      >
                        <span style={{ color: "red" }}>*</span>
              姓名
            </InputItem>
                      <InputItem
                        error={this.state.submit && !this.state.caseNumber}
                        defaultValue={this.state.caseNumber || ""}
                        onChange={(val) => {
                          this.setState({
                            caseNumber: val,
                          });
                        }}
                        disabled={true}
                      >
                        <span style={{ color: "red" }}>*</span>
              病案号
            </InputItem>
                      <InputItem
                        ref={(el) => (this.idNumber = el)}
                        type="text"
                        maxLength={18}
                        value={this.state.idNumber}
                        onChange={(el) => {
                          this.setState({
                            idNumber: el,
                          });
                        }}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.idNumber.focus()}
                        >
                          身份证号
              </div>
                      </InputItem>

                      {this.state.hisInspectionItems &&
                        this.state.hisInspectionItems.length ? (
                        <List.Item
                          // onClick={() => {
                          //   this.setState({
                          //     drawer: true,
                          //     drawerType: 1,
                          //     packageIndex: [],
                          //     sampleData: JSON.parse(
                          //       JSON.stringify(this.sampleData)
                          //     ),
                          //   });
                          // }}
                          // extra="请选择"
                          // arrow="horizontal"
                          disabled={true}
                        >
                          {true ? <span className="sign">*</span> : null}
                            现有检测项目
                        </List.Item>
                      ) : null}

                      {this.state.hisInspectionItems &&
                        this.state.hisInspectionItems.length ? (
                        <List.Item multipleLine>
                          {this.state.hisInspectionItems
                            .map((v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    {v.productName ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          检测项目：
                            </span>
                                        <span className="content-lable">
                                          {v.productName}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </List.Item>
                      ) : null}

                      {this.state.excepHandlMethod === "changeProject" ? (<List.Item
                        onClick={() => {
                          if (this.state.readonly) return false;
                          this.setState({
                            drawer: true,
                            drawerType: 6,
                            packageIndex3: [],
                            inspectionItemsData: JSON.parse(
                              JSON.stringify(this.inspectionItemsData)
                            ),
                            inspectionItemsError: false,
                          });
                        }}
                        extra="请选择"
                        arrow="horizontal"
                        error={
                          (!this.state.inspectionItems &&
                            this.state.submit) ||
                          (this.state.submit &&
                            !this.state.inspectionItems.length) ||
                          this.state.inspectionItemsError
                        }
                        disabled={this.state.readonly}
                      >
                        {true ? <span className="sign">*</span> : null}
                        添加检测项目
                      </List.Item>) : null}

                      {this.state.excepHandlMethod === "changeProject" && this.state.inspectionItems &&
                        this.state.inspectionItems.length ? (
                        <List.Item multipleLine>
                          {this.state.inspectionItems
                            .map((v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    {!this.state.readonly ? (
                                      <img
                                        alt=""
                                        className="close"
                                        src={require("../img/close.svg")}
                                        onClick={() => {
                                          let obj = this.state;
                                          obj.inspectionItems.splice(
                                            obj.inspectionItems.findIndex(
                                              (value) => value === v
                                            ),
                                          );
                                          this.letter.push(v.sampleCode);
                                          this.setState({
                                            obj,
                                          });
                                        }}
                                        style={{
                                          position: "absolute",
                                          right: "-0.1rem",
                                          top: "-0.1rem",
                                        }}
                                      ></img>
                                    ) : null}
                                    {v.productName ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          检测项目：
                                      </span>
                                        <span className="content-lable">
                                          {v.productName}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </List.Item>
                      ) : null}
                      <Picker
                        onOk={(v) => {
                          this.setState({
                            excepHandlMethod: v[0],
                          });
                        }}
                        value={[this.state.excepHandlMethod]}
                        data={[
                          {
                            value: "riskCheck",
                            label: "风险检测",
                          },
                          {
                            value: "endCheck",
                            label: "终止检测",
                          },
                          {
                            value: "resendSample",
                            label: "重新送样",
                          },
                          {
                            value: "changeProject",
                            label: "更改项目",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.excepHandlMethod
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                            异常处理方式
                          </List.Item>
                      </Picker>
                      {this.state.excepHandlMethod === "changeProject" ? (
                        <Picker
                          onOk={(v) => {
                            this.setState({
                              costHandlMode: v[0],
                            });
                          }}
                          value={[this.state.costHandlMode]}
                          data={[
                            {
                              value: "makeUp",
                              label: "补交",
                            },
                            {
                              value: "refund",
                              label: "退款",
                            },
                            {
                              value: "none",
                              label: "无补退款",
                            },
                          ]}
                          cols={1}
                        >
                          <List.Item
                            arrow="horizontal"
                            error={
                              this.state.submit &&
                              !this.state.costHandlMode
                            }
                          >
                            {true ? <span className="sign">*</span> : null}
                                    费用处理方式
                                  </List.Item>
                        </Picker>
                      ) : null}

                      {this.state.excepHandlMethod === "changeProject" && this.state.costHandlMode === "refund" ? (
                        <InputItem
                          placeholder="请填写收款人姓名"
                          type="text"
                          value={this.state.refunder || ""}
                          onChange={(el) => {
                            this.setState({
                              refunder: el,
                            });
                          }}
                          error={
                            this.state.submit &&
                            !this.state.refunder
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                  收款人姓名
                          </div>
                        </InputItem>
                      ) : null}

                      {this.state.excepHandlMethod === "changeProject" && this.state.costHandlMode === "refund" ? (
                        <InputItem
                          placeholder="请填写银行账号"
                          type="text"
                          value={this.state.redundAccount || ""}
                          onChange={(el) => {
                            this.setState({
                              redundAccount: el,
                            });
                          }}
                          error={
                            this.state.submit &&
                            !this.state.redundAccount
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                  银行账号
                          </div>
                        </InputItem>
                      ) : null}

                      {this.state.excepHandlMethod === "changeProject" && this.state.costHandlMode === "refund" ? (
                        <InputItem
                          placeholder="请填写开户行信息"
                          type="text"
                          value={this.state.redundOpenBank || ""}
                          onChange={(el) => {
                            this.setState({
                              redundOpenBank: el,
                            });
                          }}
                          error={
                            this.state.submit &&
                            !this.state.redundOpenBank
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                  开户行信息
                          </div>
                        </InputItem>
                      ) : null}

                      {this.state.excepHandlMethod === "changeProject" && this.state.costHandlMode === "refund" ? (
                        <InputItem
                          placeholder="请填写收款人电话"
                          type="phone"
                          value={this.state.redundPhone || ""}
                          onChange={(el) => {
                            this.setState({
                              redundPhone: el,
                            });
                          }}
                          error={
                            this.state.submit &&
                            !this.state.redundPhone
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                  收款人电话
                          </div>
                        </InputItem>
                      ) : null}

                      {this.state.excepHandlMethod === "changeProject" && this.state.costHandlMode === "refund" ? (
                        <InputItem
                          placeholder="请填写退款金额"
                          type="money"
                          value={this.state.refundAmount || ""}
                          onChange={(el) => {
                            this.setState({
                              refundAmount: el,
                            });
                          }}
                          error={
                            this.state.submit &&
                            !this.state.refundAmount
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                      退款金额
                          </div>
                        </InputItem>
                      ) : null}

				{this.state.excepHandlMethod === "changeProject" && this.state.costHandlMode === "makeUp" ? (
					<Picker
						onOk={(val) => {

						this.setState({
						paymentType: val[0],
					});
					}}

					value={[this.state.paymentType]}
				  data={[
            // {
            //   value: "0",
            //   label: "拉卡拉支付",
            // },
            {
              value: "1",
              label: "扫码支付",
            },
            {
              value: "WECHAT",
              label: "远程支付(微信)",
            },
            {
              value: "ALIPAY",
              label: "远程支付(支付宝)",
            },
          ]}
						cols={1}
					>
              <List.Item arrow="horizontal">
                <span style={{ color: "red" }}>*</span>支付方式
              </List.Item>
            </Picker>
            ) : null}
						
                      {this.state.excepHandlMethod === "changeProject" && this.state.costHandlMode === "makeUp" ? (
                        <InputItem
                          placeholder="请填写补交金额"
                          type="money"
                          value={this.state.supplementaryAmount || ""}
                          onChange={(el) => {
                            this.setState({
                              supplementaryAmount: el,
                            });
                          }}
                          error={
                            this.state.submit &&
                            !this.state.supplementaryAmount
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                      补交金额
                         </div>
                        </InputItem>
                      ) : null}
                    </div>
                  </div>
                </List>
              </div>
            </div>
            <div className="foot">
              {this.state.costHandlMode !== "makeUp" ? (<span
                className="submit"
                onClick={() => {
                  let submit = false;
                  this.setState({
                    submit: true,
                  });

                  this.check.forEach((element) => {
                    if (!this.state[element]) {
                      submit = true;
                    }
                  });

                  if (this.state.excepHandlMethod === 'changeProject') {
                    if (!this.state.costHandlMode) {
                      submit = true;
                    }
                    if (this.state.inspectionItems.length === 0) {
                      Toast.info("请添加检测项目！", 2);
                      return;
                    }
                    if (this.state.costHandlMode === 'refund') {
                      this.refundCheck.forEach((element) => {
                        if (!this.state[element]) {
                          submit = true;
                        }
                      });
                    }
                  }

                  if (submit) {
                    Toast.info("请填写完整信息！", 2);
                    return;
                  }

                  const requestParam = {
                    p: {},
                    b: ["lsiBatchServiceImpl", [this.state]]
                  }
                  alert("提示", "是否要提交该异常处理？", [
                    { text: "否" },
                    {
                      text: "是",
                      onPress: () => {
                        let excepHandlMethod = this.state.excepHandlMethod;
                        if (excepHandlMethod === 'riskCheck') {
                          Toast.loading(`正在提交`, 2);
                          axios
                            .post(
                              `/open/setmeal/wxpush/batch-risks/action/check`,
                              requestParam,
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                },
                              }
                            )
                            .then((res) => {
                              if (!res) return;
                              Toast.info("提交成功！", 1, () => {
                                this.props.history.push({
                                  pathname: "/exceptionHandling",
                                });
                              });
                            })
                            .catch(() => {
                              Toast.info("提交失败！", 1);
                            });
                        } else if (excepHandlMethod === 'endCheck') {
                          Toast.info("提交成功！", 1, () => {
                            this.state.action = 'endCheck';
                            this.props.history.push({
                              pathname: "/cancel",
                              query: this.state
                            });
                          });
                        } else if (excepHandlMethod === 'resendSample') {
                          this.state.status = 'Logged';
                          this.state.sampleCollectComplete = '0';
                          const newParam = {
                            p: {},
                            b: ["lsiBatchServiceImpl", [this.state]]
                          }
                          Toast.loading(`正在提交`, 2);
                          axios
                            .put(
                              `/open/setmeal/wxpush/batch-statuses/action/update`,
                              newParam,
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                },
                              }
                            )
                            .then((res) => {
                              if (!res) return;
                              Toast.info("提交成功！", 1, () => {
                                this.props.history.push({
                                  pathname: "/exceptionHandling",
                                });
                              });
                            })
                            .catch(() => {
                              Toast.info("提交失败！", 1);
                            });
                        } else if (excepHandlMethod === 'changeProject') {
                          if (this.state.costHandlMode === 'refund') {
                            Toast.loading(`正在提交`, 2);
                            axios
                              .post(
                                `/open/setmeal/wxpush/batchs/action/refund`,
                                this.state,
                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                              .then((res) => {
                                if (!res) return;
                                Toast.info("提交成功！", 1, () => {

                                  axios
                                    .post(
                                      `/open/setmeal/wxpush/batch-excep-records`,
                                      this.state,
                                      {
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                      }
                                    )

                                  this.props.history.push({
                                    pathname: "/exceptionHandling",
                                  });
                                });
                              })
                              .catch(() => {
                                Toast.info("提交失败！", 1);
                              });
                          } else if (this.state.costHandlMode === 'none') {
                            Toast.loading(`正在提交`, 2);
                            axios
                              .post(
                                `/open/setmeal/wxpush/batch-products/action/modify`,
                                this.state,
                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                              .then((res) => {
                                if (!res) return;
                                Toast.info("提交成功！", 1, () => {

                                  axios
                                    .post(
                                      `/open/setmeal/wxpush/batch-excep-records`,
                                      this.state,
                                      {
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                      }
                                    )

                                  this.props.history.push({
                                    pathname: "/exceptionHandling",
                                  });
                                });
                              })
                              .catch(() => {
                                Toast.info("提交失败！", 1);
                              });
                          }
                        }

                      },
                    },
                  ]);

                }}
              >
                提交
              </span>) : null}

              {this.state.costHandlMode === "makeUp" ? (<span
                className="submit"
                onClick={() => {
                  let submit = false;
                  this.setState({
                    submit: true,
                  });
                  this.makeUpCheck.forEach((element) => {
                    if (!this.state[element]) {
                      submit = true;
                    }
                  });
                  if (submit) {
                    Toast.info("请填写完整信息！", 2);
                    return;
                  }

                  const requestParam = {
                    p: {},
                    b: ["lsiBatchServiceImpl", [this.state]]
                  }
                  alert("提示", "是否支付?", [
                    { text: "否" },
                    {
                      text: "是",
                      onPress: () => {
                  if (this.state.paymentType === "1") {
                    this.submit(this.state.salesBarCode); // 扫码支付
                  } else if (this.state.paymentType === "0") {
                    this.offlinePayment(this.state.salesBarCode); // 拉卡拉支付
                  } else {
                    this.remotePay(this.state.salesBarCode,this.state.paymentType); // 远程支付
                  }
                      },
                    },
                  ]);

                }}
              >
                支付
              </span>) : null}

            </div>
            {this.state.showImage ? (
              <div
                className="show-image"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  zIndex: "999",
                  top: "0",
                  left: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                onClick={() => this.setState({ showImage: false })}
              >
                <img
                  alt=""
                  style={{ width: "100%" }}
                  src={this.state.uploadImage}
                ></img>
              </div>
            ) : null}
          </div>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.exceptionHandlingDetailReducer,
    page: state.applyListInfoReducer.page,
  };
}


export default connect(mapStateToProps, { exceptionHandlingDetailAction })(ExceptionHandlingDetail);
