import React from "react";
import { NavBar, Icon, Toast, InputItem, DatePicker, Modal } from "antd-mobile";
import axios from "axios";
import "./style/payManage.css";
import { connect } from "react-redux";
import { BoxLoading } from "react-loadingg";
import { payManageAction  } from "../../action/payment/payManage";
const alert = Modal.alert;
class PayManage extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      applicationData: [],
      searchStr: this.props.searchStr || "",
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      loading: true,
      needSearch: props.needSearch || false,
    };
    this.applicationData = [];
  }

  setRedux = () => {
    const reduxData = [
      "searchStr",
      "applicationData",
      "startTime",
      "endTime",
      "needSearch",
    ];
    for (let pop in reduxData) {
      this.props.payManageAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = [
      "searchStr",
      "applicationData",
      "startTime",
      "endTime",
      "needSearch",
    ];
    for (let pop in reduxData) {
      this.props.payManageAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  search = () => {
    let applicationData = [];
    const allData = JSON.parse(JSON.stringify(this.applicationData));
    if (allData) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );
      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );
      allData.forEach((element) => {
        if (element && element.createdTime) {
          let createdTime = parseInt(
            this.formatTime(element.createdTime).replace(/-/g, "")
          );

          if (createdTime <= endTime && createdTime >= startTime) {
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                const searchStr = this.state.searchStr || '';
                if (element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1) {
                  applicationData.push(element);
                  break;
                }
              }
            }
          }
        }
      });
      this.setState(
        {
          applicationData,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };
  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      (this.props.history.location.pathname === "/" ||
        this.props.history.location.pathname === "/home")
    ) {
      this.clearRedux();
    }
  }

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  getBatchStatus = (v, k) => {
    let status;
    switch (v) {
      case "Draft_Save":
        status = "新建";
        if (k && k.toUpperCase().indexOf("REJECT") > 0) {
          status = "折扣申请审核拒绝";
        }
        break;
      case "Discount_Approve":
        status = "折扣申请审核中";
        break;
      case "Discount_Approval":
        status = "折扣申请审核通过";
        break;
      case "Waiting_For_Send":
        status = "样本待寄送";
        break;
      case "Already_Been_Sent":
        status = "样本已寄送";
        break;
      case "Pending_Sample":
        status = "样本待录入";
        break;
      default:
        break;
    }
    return status;
  };

  selectBatchByDraftSave = () => {
    const status = ["Draft_Save", "Discount_Approve", "Discount_Approval", "Waiting_For_Send", "Already_Been_Sent", "Pending_Sample"];
    axios
      .post("/open/setmeal/wxpush/selectBatchByUnPayComplete", status, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.batchinfodtos) return;
        this.applicationData = res.batchinfodtos;
        this.setState(
          {
            applicationData: res.batchinfodtos,
          },
          () => {
            if (this.state.needSearch) {
              this.search();
            }
          }
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.selectBatchByDraftSave();
  }

  //返回首页
  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  render() {
    return (
      <div
        className="payManage"
        onClick={() => {
          let applicationData = this.state.applicationData.map((value) => {
            value.mask = false;
            return value;
          });
          this.setState({
            applicationData,
          });
        }}
      >
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          支付管理
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.search();
              }}
              className="search-bar-top-text"
            >
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
          </div>
        </div>
        {this.state.applicationData && this.state.applicationData.length ? (
          <div className="center">
            {this.state.applicationData.map((v, k) => {
              return (
                <div
                  className="item"
                  onClick={(e) => {
                    e.stopPropagation();
                    let applicationData = this.state.applicationData.map(
                      (value) => {
                        if (v === value) {
                          value.mask = !v.mask;
                        } else {
                          value.mask = false;
                        }
                        return value;
                      }
                    );
                    this.setState({
                      applicationData,
                    });
                  }}
                  key={v + k}
                >
                  <div className="item-row">
                    <img alt="" src={require("../img/ddbh.svg")}></img>
                    <span>{v.salesBarCode}</span>
                    {/* 状态 */}
                    <span
                      className="item-status"
                      style={{
                        backgroundColor: "#0089FFDB",
                        display: "flex",
                        padding: "0.03rem 0.05rem",
                        marginLeft: "0.1rem",
                        borderRadius: "0.05rem",
                        color: "white",
                        fontSize: "0.1rem"
                      }}
                    >
                      状态：{this.getBatchStatus(v.status, v.processStatus)}
                    </span>
                    {v.processStatus && v.processStatus.toUpperCase().indexOf("REJECT") > 0 ? (
                      <>
                        <span
                          className="item-status"
                          style={{
                            backgroundColor: "#FFA500",
                            display: "flex",
                            padding: "0.03rem 0.05rem",
                            marginLeft: "0.1rem",
                            borderRadius: "0.05rem",
                            color: "white",
                            fontSize: "0.1rem"
                          }}
                        >
                          拒绝原因：{v.rejectReason}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <div className="content">
                    {v.patientName ? <span>{v.patientName}</span> : null}
                    {v.hospital ? <span>{v.hospital}</span> : null}
                    {v.sampleInfos && v.sampleInfos.length ? (
                      <span>
                        {(() => {
                          let sampleInfos = v.sampleInfos.map((element) => {
                            return (
                              `(${element.sampleCode})` + element.sampleTypeName
                            );
                          });
                          return sampleInfos.join("，");
                        })()}
                      </span>
                    ) : null}
                    {v.inspectionItems && v.inspectionItems.length ? (
                      <span>
                        {(() => {
                          let inspectionItems = v.inspectionItems.map(
                            (element) => {
                              return element.productName;
                            }
                          );
                          return inspectionItems.join("，");
                        })()}
                      </span>
                    ) : null}
                    {v.createdTime ? <span>{v.createdTime}</span> : null}
                  </div>
                  {v.mask ? <div className="mask"></div> : null}
                  <div className="foot">
                    {/* 状态为折扣审核通过、样本待寄送、样本已寄送，可以结算 */}
                    {v.mask ? (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push({
                            pathname: "/payment",
                            query: v,
                            flag: '1'
                          });
                        }}
                        style={{ backgroundColor: "rgb(248,19,0" }}
                      >
                        结算
                      </span>
                    ) : null}

                   
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.payManageReducer,
  };
}

export default connect(mapStateToProps, { payManageAction })(PayManage);
