import React, { lazy, Suspense } from "react";
import { Router, Route, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { createHashHistory } from "history";
import Home from "../pages/home/home";
import Mine from "../pages/mine/mine";
import ApplyListInfo from "../pages/apply/applyListInfo";
import SendLab from "../pages/apply/sendLab";
import ExpressModification from "../pages/apply/expressModification";
import ExpressModificationDetail from "../pages/apply/expressModificationDetail";
import HySendLab from "../pages/apply/hySendLab";
import ApplicationDetails from "../pages/apply/applicationDetails";
import HyApplicationDetails from "../pages/apply/hyApplicationDetails";
import BrochureApplyDetails from "../pages/apply/brochureApplyDetails";
import OrderInquiry from "../pages/apply/orderInquiry";
import OrderCompleted from "../pages/apply/orderCompleted";
import PayManage from "../pages/payment/payManage";
import OnlineOrderModification from "../pages/apply/onlineOrderModification";
import CheckExpress from "../pages/logistics/checkExpress";
import InvoiceList from "../pages/invoice/invoiceList";
import CreateApplication from "../pages/apply/createApplication";
import Payment from "../pages/payment/payment";
import QuickCreation from "../pages/apply/quickCreation";
import SampleDelivery from "../pages/logistics/sampleDelivery";
import DeliveryInformation from "../pages/logistics/deliveryInformation";
import LogisticsInformation from "../pages/logistics/logisticsInformation";
import SatisfactionSurvey from "../pages/other/satisfactionSurvey";
import ReportQuery from "../pages/report/reportQuery";
import ExceptionHandling from "../pages/exception/exceptionHandling";
import ExceptionHandlingDetail from "../pages/exception/exceptionHandlingDetail";
import QRcode from "../pages/payment/QRcode";
import InvoiceDetails from "../pages/invoice/invoiceDetails";
import PaymentSuccessful from "../pages/payment/paymentSuccessful";
import PayComplete from "../pages/payment/payComplete";
import PayModifyComplete from "../pages/payment/payModifyComplete";
import CancelDelivery from "../pages/logistics/cancelDelivery";
import CancelDeliveryDetail from "../pages/logistics/cancelDeliveryDetail";
import Mailed from "../pages/logistics/mailed";
import Login from "../pages/login/login"; 
import ChangePassword from "../pages/mine/changePassword"; 
import InspectionRequestInquiry from "../pages/other/inspectionRequestInquiry";
import CancelApplication from "../pages/apply/cancelApplication";
import QuickApplyListInfo from "../pages/apply/quickApplyListInfo";
import ViewProgress from "../pages/apply/viewProgress";
import Discount from "../pages/discount/discount";
import Cancel from "../pages/cancel/cancel";
import Sample from "../pages/sample/addSample";
import OnlineModifiyDetail from "../pages/apply/onlineModifyDetail";
import PendingInputsample from "../pages/sample/pendingInputSampleList";
import SupplementSampleList from "../pages/sample/supplementSampleList";
import BrochureApplyList from "../pages/sample/brochureApplyList";
import BrochureApply from "../pages/apply/brochureApply";
import HospitalOrder from "../pages/hospital/hospitalOrder";
import HospitalSample from "../pages/hospital/hospitalSample";
import OrderInfo from "../pages/hospital/orderInfo";
import CreateSampleBoxApplication from "../pages/samplebox/createSampleBoxApplication";
import ApplySampleBoxInfo from "../pages/samplebox/applySampleBoxInfo";
import PathologyTodo from "../pages/todo/pathologyTodo";

const history = createHashHistory();
const ReportDownload = lazy(() => import("../pages/report/reportDownload")); 
class RouterConfig extends React.Component {
  render() {
    const Routes = withRouter(RouteModule);
    return (
      <Router history={history}>
        <Routes></Routes>
      </Router>
    );
  }
}
export default RouterConfig;

const RouteModule = function (props) {
  let token = localStorage.getItem('token')
  return (
    <TransitionGroup
      style={{ position: "relative", width: "100%", height: "100%" }}
      childFactory={(child) =>
        React.cloneElement(child, {
          classNames: props.history.action === "PUSH" ? "app-push" : "app-pop",
        })
      }
    >
      <CSSTransition
        key={props.location.pathname}
        timeout={250}
        classNames={props.history.action === "PUSH" ? "app-push" : "app-pop"}
      >
        <Suspense fallback={<div>数据加载中,请稍等...</div>}>
          <Switch location={props.location}>
            <Route
              path="/cancelApplication"
              exact
              component={CancelApplication}
            /> 
            <Route path="/viewProgress"   component={ViewProgress} />
            <Route path="/quickApplyListInfo"   component={QuickApplyListInfo} />
            <Route path="/home"   component={Home} />
            <Route path="/changePassword"   component={ChangePassword} />
            <Route path="/mine"   component={Mine} />
            <Route path="/applyListInfo" component={ApplyListInfo} />
            <Route path="/sendLab" component={SendLab} />
            <Route path="/hySendLab" component={HySendLab} />
            <Route path="/orderInquiry" component={OrderInquiry} />
            <Route path="/expressModification" component={ExpressModification} />
            <Route path="/expressModificationDetail" component={ExpressModificationDetail} />
            <Route path="/orderCompleted" component={OrderCompleted} />
            <Route path="/orderInfo" component={OrderInfo} />
            <Route path="/hospitalSample" component={HospitalSample} />
            <Route
              path="/onlineOrderModification"
              component={OnlineOrderModification}
            />
            <Route path="/checkExpress" component={CheckExpress} />
            <Route path="/invoiceList" component={InvoiceList} />
            <Route path="/createApplication" component={CreateApplication} />
            <Route path="/payment" component={Payment} />
            <Route path="/quickCreation" component={QuickCreation} />
            <Route path="/sampleDelivery" component={SampleDelivery} />
            <Route
              path="/deliveryInformation"
              component={DeliveryInformation}
            />
            <Route
              path="/logisticsInformation"
              component={LogisticsInformation}
            />
            <Route path="/satisfactionSurvey" component={SatisfactionSurvey} />
            <Route path="/pathologyTodo" component={PathologyTodo} />
            <Route path="/reportQuery" component={ReportQuery} />
            <Route path="/reportDownload" component={ReportDownload} />
            <Route 
              path="/exceptionHandling" 
              exact
              component={ExceptionHandling} 
            />
		    <Route path="/payManage" component={PayManage} />
            <Route path="/exceptionHandlingDetail" component={ExceptionHandlingDetail} />
            <Route path="/QRcode" component={QRcode} />
            <Route path="/invoiceDetails" component={InvoiceDetails} />
            <Route path="/paymentSuccessful" component={PaymentSuccessful} />
		    <Route path="/payComplete" component={PayComplete} />
            <Route path="/payModifyComplete" component={PayModifyComplete} />
            <Route path="/cancelDelivery" component={CancelDelivery} />
            <Route path="/cancelDeliveryDetail" component={CancelDeliveryDetail} />
            <Route path="/mailed" component={Mailed} />
            <Route path="/applicationDetails" component={ApplicationDetails} />
            <Route path="/hyApplicationDetails" component={HyApplicationDetails} />
            <Route path="/brochureApplyDetails" component={BrochureApplyDetails} />
            
            <Route
              path="/inspectionRequestInquiry"
              component={InspectionRequestInquiry}
            />
            <Route path="/discount" component={Discount} />
            <Route path="/cancel" component={Cancel} />
            <Route path="/sample" component={Sample} />
            <Route path="/onlineModifiyDetail" component={OnlineModifiyDetail} />
            <Route path="/pendingInputSampleList" component={PendingInputsample} />
            <Route path="/supplementSampleList" component={SupplementSampleList} />
            <Route path="/createSampleBoxApplication" component={CreateSampleBoxApplication} />
            <Route path="/applySampleBoxInfo" component={ApplySampleBoxInfo} />
            <Route path="/hospitalOrder" component={HospitalOrder} />
            <Route path="/brochureApplyList" component={BrochureApplyList} />
            <Route path="/brochureApply" component={BrochureApply} />
            <Route path="/login" component={Login} />
            <Route path="/" component={ !window.isMicromessenger && token ? Home : Login } />
            
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};
