import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  DatePicker,
  Modal,
  Accordion,
  Picker,
  Drawer,
  Toast,
  SearchBar,
  TextareaItem,
} from "antd-mobile";
import { nationData } from "../data/static";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import wx from "weixin-js-sdk";
import { connect } from "react-redux";
import "./style/applyListInfo.css";
import { applyListInfoAction } from "../../action/apply/applyListInfo";
const alert = Modal.alert;
class SendLab extends React.Component {
  constructor(props) {
    super(props);
    this.applyListInfo = {
      status: this.props.location.status,
      salesBarCode: "",
      patientName: "",
      idNumber: "",
      patientBirth: "",
      gender: "",
      paperReport: "",
      hospital: "",
      dept: "",
      customerName: "",
      // reportType: "院内",
      diagonsis: "",
      targetedTherapyHistory: "",
      familyHistory: "",
      selectSetMeals: "",
      tumorType: "",
      testLabs:"",
      testLab:"",
      pathologyType: "",
      projectNo: "",
      sampleInfos: [],
      // showHospital: true,
      // showDept: false,
      // showCustomerName: false,
      receiveAmount: 0,
      firstDiagnosisTime: "",
      clinicalStages: "",
      transferFlag: "",
      treatmentMethod: "",
      cancerType: "",
      payStatus: "",
      originalBatchId: "",
      remark: "",
    };
    this.submitVerificationData = [
      "salesBarCode", // 条码
      "patientName", // 姓名
      "patientBirth",  //出生年月
      "gender", // 性别
      "nation", // 民族
      // "caseNumber", // 病案号
      "reportReceiver", // 报告接收人
      "reportPhone", // 报告接收人电话
      "reportAddress", // 报告接收地址
      "hospital", // 送检医药
      "dept", // 送检科室
      "customerName", // 送检医生
      "clinicalStage", // 临床分期
      "firstDiagnosisTime", // 初次确诊时间
      "transferBench", // 是否转移
      "primarySite", // 原发部位
      "diseaseHistory", // 既往病史
      "familyHistory", // 
      "firstDiagnosisTime",
      "clinicalStage",
      "familyHistory",
      "geneMutationFlag",
    ];
    this.submitCheckOnHealthScreen = [
      "salesBarCode", // 条码
      "patientName", // 姓名
      "patientBirth",  //出生年月
      "gender", // 性别
      "nation", // 民族
      // "caseNumber", // 病案号
      "reportReceiver", // 报告接收人
      "reportPhone", // 报告接收人电话
      "reportAddress", // 报告接收地址
      "hospital", // 送检医药
      "dept", // 送检科室
      "customerName", // 送检医生
    ];
    const query =
      (this.props.location && this.props.location.query) ||
      this.props.applyListInfo ||
      {};
    this.state = {
      applyListInfo: Object.assign({}, this.applyListInfo, query),
      tumorType: [],
      testLabs:[],
      pathologyType: [],
      nationData: JSON.parse(JSON.stringify(nationData)),
      packageIndex: [], // 套餐
      packageIndex2: [], // 样本类型
      packageIndex3: [], // 检测项目
      setMealData: [],
      setMealInfo: [],
      inspectionUnitData: [],
      // inspectionItemsData : [],
      currentSetMeal: {},
      sampleInfos: query.sampleInfos || [],
      selectSetMeals: query.selectSetMeals || [],
      reportTypeData: [
        {
          value: "院内",
          label: "院内",
        },
        {
          value: "院外",
          label: "院外",
        },
      ],
      agentInfo:
        query.paperReport === "受检者"
          ? {
            personName: query.patientName,
            mobile: query.contactNumber,
          }
          : {
            personName: query.salesRepresentative,
            mobile: query.salesManPhone,
            email: query.salesEmail,
          },
    };
    //手风琴索引
    this.packageIndex = [];
    this.packageIndex2 = [];
    this.packageIndex3 = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
    this.sampleInfos = [];
    //当前修改地址
    this.currentModificationAddress = null;
  }

  setRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.applyListInfoAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  itIsHealthScreen = () => {
    const tumourList = this.state.applyListInfo.tumourList;
    var flg = false;
    if (undefined != tumourList) {
      for (var i = 0; i < tumourList.length; i++) {
        if (tumourList[i].tumorName === "健康风险筛查") {
          flg = false;
          return flg;
        } else {
          flg = true;
        }
      }
    }else {
      flg = true;
    }
    return flg;

  };

  clearRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.applyListInfoAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  submitVerification = () => {
    const applyListInfo = this.state.applyListInfo;
    applyListInfo.selectSetMeals = this.state.selectSetMeals;
    this.setState({
      applyListInfo,
    });
    var checkField = this.submitCheckOnHealthScreen;
    var flag = this.itIsHealthScreen();
    if (flag) {
      checkField = this.submitVerificationData;
    }
    for (let pop of checkField) {
      if (!applyListInfo[pop]) {
        return false;
      }
      if (JSON.stringify(applyListInfo[pop]) === "[]") {
        return false;
      }
    }

    if (flag) {	
	if (
      (applyListInfo.familyHistory !== "无" &&
        !applyListInfo.familyHistoryInfo) ||
      (applyListInfo.familyHistory !== "无" &&
        !applyListInfo.familyHistoryInfo.length)
    ) {
      return false;
    }
      // 是否转移
      if (applyListInfo.transferBench === "1" && !applyListInfo.metastases) {
        return false;
      }

      // 家系中亲属是否存在特定基因胚系突变
      if (applyListInfo.geneMutationFlag === "1" && (!applyListInfo.geneMutation || !applyListInfo.carrierRelation)) {
        return false;
      }
      // 突变鉴定是否为本公司检测产品
      if (applyListInfo.selfProductFlag === "1" && applyListInfo.geneMutationFlag === "1" && !applyListInfo.hisProductName) {
        return false;
      }
      // 历史病史中含乳腺癌
      if (applyListInfo.hisTumorType && applyListInfo.hisTumorType.indexOf("乳腺癌") > -1 && !applyListInfo.hisBreastCancerType) {
        return false;
      }
      // 其他病史
      if (applyListInfo.diseaseHistory === "其他" && !applyListInfo.otherDiseaseHistory) {
        return false;
      }
    }

    return true;
  };

  //返回首页
  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  //录入输入框信息
  inputChange = (v, k) => {
    let newApplyListInfo = this.state.applyListInfo;
    newApplyListInfo[k] = v;
    this.setState({
      applyListInfo: newApplyListInfo,
    });
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== "/payment" &&
      this.props.history.location.pathname !== "/discount"
    ) {
      this.clearRedux();
    }
  }

  //选择样本类型
  choiceSampleType = (value, arr, k, e) => {
    let setMealData = this.state.setMealData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex2 = this.state.packageIndex2;
    const indexOf = packageIndex2.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex2.push(value.id);
    } else {
      packageIndex2.splice(indexOf, 1);
    }
    this.setState({
      packageIndex2,
      setMealData,
    });
  };

  //选择检测项目
  choiceInspectionItem = (value, arr, k, e) => {
    let setMealData = this.state.inspectionItemsData.map((val) => {
      val.checked = false;
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex3 = this.state.packageIndex3;
    const indexOf = packageIndex3.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex3.push(value.id);
    } else {
      packageIndex3.splice(indexOf, 1);
    }
    this.setState({
      packageIndex3,
      setMealData,
    });
  };

  //选择治疗方式
  choiceTreatMethodItem = (value, arr, k, e) => {
    let setMealData = this.state.treatmentMethodSelectData.map((val) => {
      val.checked = false;
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex3 = this.state.packageIndex3;
    const indexOf = packageIndex3.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex3.push(value.id);
    } else {
      packageIndex3.splice(indexOf, 1);
    }
    this.setState({
      packageIndex3,
      setMealData,
    });
  };

  renderTitle = () => {
    let title;
    switch (this.state.modalType) {
      case "targetedTherapyHistoryInfo":
        title = "靶向治疗";
        break;
      case "historyChemotherapyInfo":
        title = "化疗";
        break;
      case "historyRadiationInfo":
        title = "放疗治疗";
        break;
      default:
        break;
    }
    return title;
  };

  choosePackage = (value, item, select) => {
    let setMealInfo = this.state.setMealInfo;
    setMealInfo = setMealInfo.map((v) => {
      if (value === v) {
        v.checked = !v.checked;
      }
      return v;
    });

    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.setMealCode + value.templateId);
    if (indexOf < 0) {
      packageIndex.push(value.setMealCode + value.templateId);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      setMealInfo,
    });
  };

  //搜索套餐
  searchSetmeal = () => {
    const applyListInfo = this.state.applyListInfo;
    let params = [];
    applyListInfo.sampleInfos &&
      applyListInfo.sampleInfos.forEach((element, index) => {
        if (!element.checked) {
          params.push(element.id);
        }
      });
    if (!params.length) {
      return;
    } else {
      params.join(",");
    }
    this.setState({
      searchSetmealLoad: true,
    });
    axios
      .get(`/open/setmeal/${params}/sysncSetMealInfo`)
      .then((res) => {
        if (res && res.finalwxsetmealdtos) {
          this.setMealInfo = JSON.parse(JSON.stringify(res.finalwxsetmealdtos));
          this.setState({
            setMealInfo: res.finalwxsetmealdtos,
          });
        }
      })
      .finally(() => {
        this.setState({
          searchSetmealLoad: false,
        });
      });
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  formatDateTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()} ${val.getHours() < 10 ? "0" + val.getHours() : val.getHours()}:${val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes()}:${val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds()}`;
  };

  scanQRCode = () => {
    let _this = this;
    wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res) {
        const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        let salesBarCode = '';
        if (result) {
          let arr = result.split(',');
          salesBarCode = arr.length > 1 ? arr[1] : arr[0];
        }
        let newApplyListInfo = _this.state.applyListInfo;
        newApplyListInfo.salesBarCode = salesBarCode;
        _this.setState({
          applyListInfo: newApplyListInfo,
        });
      },
    });
  };


  //打开实验室选择抽屉
  renderTestLabsDrawer = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              testLabsSearchBar: "",
            });
          }}
        >
          检测实验室
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.testLabsSearchBar || ""}
            onChange={(val) => {
              this.setState({
                testLabsSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox1">
            {this.state.testLabs &&
              this.state.testLabs.map((v, k) => {
                let testLabsSearchBar = this.state.testLabsSearchBar;
                if (!testLabsSearchBar  ||  v.labName.indexOf(testLabsSearchBar) > -1) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let testLabs = this.state.testLabs.map((value) => {
                          if (v === value && !v.checked) {
                            value.checked = true;
                          } else {
                            value.checked = false;
                          }
                          return value;
                        });
                        this.setState({
                          testLabs,
                        });
                      }}
                    >
                      {v.labName}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let testLabsItem = this.state.testLabs.find((v) => {
                return v.checked;
              });
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.testLab = testLabsItem
                ? testLabsItem.labName
                : null;
              this.setState({
                drawer: false,
                applyListInfo,
                testLabsSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };


  //打开治疗方式类型选择抽屉
  renderTreatmentMethodDrawer = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              treatmentMethodSearchBar: "",
            });
          }}
        >
         治疗方式
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.treatmentMethodSearchBar || ""}
            onChange={(el) => {
              let treatmentMethodSelectData = JSON.parse(
                JSON.stringify(this.state.treatmentMethodSelectData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                treatmentMethodSearchBar: el,
                treatmentMethodSelectData,
                packageIndex3: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex3}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.treatmentMethodSelectData.map((v, k) => {
              let treatmentMethodSearchBar =
                this.state.treatmentMethodSearchBar &&
                this.state.treatmentMethodSearchBar.toLowerCase();
              if (
                !treatmentMethodSearchBar ||
                (v.value &&
                  v.value
                    .toLowerCase()
                    .indexOf(treatmentMethodSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.value}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceTreatMethodItem(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.value}</span>
                      </div>
                    }
                  >
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let treatmentMethodSelectData = this.state.treatmentMethodSelectData;
              let applyListInfo = this.state.applyListInfo;
              let hisTreatmentMethods = this.state.applyListInfo.hisTreatmentMethods || [];

              treatmentMethodSelectData.forEach((element) => {

                if (element.checked) {
                  hisTreatmentMethods.push({
                    hisTreatmentMethod: element.value,
                  });
                }
              });
              this.state.applyListInfo.hisTreatmentMethods = hisTreatmentMethods;
              this.setState({
                drawer: false,
                applyListInfo,
                treatmentMethodSearchBar: "",
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //打开肿瘤类型选择抽屉
  renderTumourDrawer = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              tumorTypeSearchBar: "",
            });
          }}
        >
          肿瘤类型
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.tumorTypeSearchBar || ""}
            ref={(v) => (this.tumorTypeSearchBar = v)}
            onChange={(val) => {
              this.setState({
                tumorTypeSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox">
            {this.state.tumorType &&
              this.state.tumorType.map((v, k) => {
                let tumorTypeSearchBar = this.state.tumorTypeSearchBar;
                if (
                  !tumorTypeSearchBar ||
                  v.dictTextName.indexOf(tumorTypeSearchBar) > -1
                ) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let tumorType = this.state.tumorType.map((value) => {
                          if (v === value && !v.checked) {
                            value.checked = true;
                          } else {
                            value.checked = false;
                          }
                          return value;
                        });
                        this.setState({
                          tumorType,
                        });
                      }}
                    >
                      {v.dictTextName}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let tumorType = this.state.tumorType.find((v) => {
                return v.checked;
              });
              if (!tumorType) {
                return;
              }
              if (tumorType && tumorType.id) {
                this.getPathologyType(tumorType.id);
              }
              let applyListInfo = this.state.applyListInfo;
              let tumourList = applyListInfo.tumourList || [];
              let itemList = tumourList.filter(function (item) {
                if (item.tumorId == tumorType.id) {
                  return item;
                }
              });
              if (itemList.length == 0) {
                tumourList.push({
                  tumorId: tumorType.id,
                  tumorCode: tumorType.dictTextCode,
                  tumorName: tumorType.dictTextName,
                });

              }
              applyListInfo.tumourList = tumourList;
              this.setState({
                drawer: false,
                applyListInfo,
                tumorTypeSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //打开病理类型选择抽屉
  renderPathologyTypeDrawer = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              pathologyTypeSearchBar: "",
            });
          }}
        >
          病理类型
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.pathologyTypeSearchBar || ""}
            ref={(v) => (this.pathologyTypeSearchBar = v)}
            onChange={(val) => {
              this.setState({
                pathologyTypeSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox">
            {this.state.pathologyType &&
              this.state.pathologyType.map((v, k) => {
                let pathologyTypeSearchBar = this.state.pathologyTypeSearchBar;
                if (
                  !pathologyTypeSearchBar ||
                  v.pathologyName.indexOf(pathologyTypeSearchBar) > -1
                ) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let pathologyType = this.state.pathologyType.map((value) => {
                          if (v === value && !v.checked) {
                            value.checked = true;
                          } else {
                            value.checked = false;
                          }
                          return value;
                        });
                        this.setState({
                          pathologyType,
                        });
                      }}
                    >
                      {v.pathologyName}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let pathologyType = this.state.pathologyType.find((v) => {
                return v.checked;
              });

              if (!pathologyType) {
                return;
              }
              let applyListInfo = this.state.applyListInfo;
              let pathologyList = applyListInfo.pathologyList || [];
              let itemList = pathologyList.filter(function (item) {
                if (item.pathologyId == pathologyType.id) {
                  return item;
                }
              });
              if (itemList.length == 0) {
                pathologyList.push({
                  pathologyId: pathologyType.id,
                  pathologyCode: pathologyType.pathologyCode,
                  pathologyName: pathologyType.pathologyName,
                  tumorId: pathologyType.tumourId
                });
              }
              applyListInfo.pathologyList = pathologyList;
              this.setState({
                drawer: false,
                applyListInfo,
                pathologyTypeSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //打开民族选择抽屉
  renderNation = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              nationSearchBar: "",
            });
          }}
        >
          民族
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.nationSearchBar || ""}
            onChange={(val) => {
              this.setState({
                nationSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox1">
            {this.state.nationData &&
              this.state.nationData.map((v, k) => {
                console.log(v.value.localeCompare());
                let nationSearchBar = this.state.nationSearchBar;
                if (!nationSearchBar || v.value.indexOf(nationSearchBar) > -1) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let nationData = this.state.nationData.map((value) => {
                          if (v === value && !v.checked) {
                            value.checked = true;
                          } else {
                            value.checked = false;
                          }
                          return value;
                        });
                        this.setState({
                          nationData,
                        });
                      }}
                    >
                      {v.value}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let nationDataItem = this.state.nationData.find((v) => {
                return v.checked;
              });
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.nation = nationDataItem
                ? nationDataItem.value
                : null;
              this.setState({
                drawer: false,
                applyListInfo,
                nationData: JSON.parse(JSON.stringify(nationData)),
                nationSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //打开送检机构选择抽屉
  renderInspectionUnitDrawer = () => {
    return (
      <div className="applyListInfo-tumourDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              inspectionUnitSearchBar: "",
            });
          }}
        >
          送检医院
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.inspectionUnitSearchBar || ""}
            onChange={(val) => {
              this.setState({
                inspectionUnitSearchBar: val,
              });
            }}
          />
        </div>
        <div className="tumourDrawer-center">
          <div className="checkbox1">
            {this.state.inspectionUnitData &&
              this.state.inspectionUnitData.map((v, k) => {
                const inspectionUnitSearchBar = this.state
                  .inspectionUnitSearchBar;
                if (
                  !inspectionUnitSearchBar ||
                  v.hospitalName.indexOf(inspectionUnitSearchBar) > -1
                ) {
                  return (
                    <span
                      key={k}
                      className={`item ${v.checked ? "select" : ""}`}
                      onClick={() => {
                        let inspectionUnitData = this.state.inspectionUnitData.map(
                          (value) => {
                            if (v === value && !v.checked) {
                              value.checked = true;
                            } else {
                              value.checked = false;
                            }
                            return value;
                          }
                        );
                        this.setState({
                          inspectionUnitData,
                        });
                      }}
                    >
                      {v.hospitalName}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
        <div className="tumourDrawer-foot">
          <span
            onClick={() => {
              let inspectionUnitData = this.state.inspectionUnitData.find(
                (v) => {
                  return v.checked;
                }
              );
              let applyListInfo = this.state.applyListInfo;
              if (inspectionUnitData) {
                applyListInfo.hospital = inspectionUnitData
                  ? inspectionUnitData.hospitalName
                  : null;
                // this.getReportTypeData(inspectionUnitData);
              } else {
                // applyListInfo.inspectionOrganization = "";
                // const reportTypeData = [
                //   {
                //     value: "院内",
                //     label: "院内",
                //   },
                //   {
                //     value: "院外",
                //     label: "院外",
                //   },
                // ];
                // this.setState({
                //   reportTypeData,
                // });
              }

              this.setState({
                drawer: false,
                applyListInfo,
                inspectionUnitSearchBar: "",
                inspectionUnitData: JSON.parse(
                  JSON.stringify(this.inspectionUnitData)
                ),
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  boolSwitch = (val) => {
    let show = true;
    if (val) {
      if (typeof val === "string") {
        if (val === "false") {
          show = false;
        }
      } else {
        return val;
      }
    } else {
      show = false;
    }
    return show;
  };

  setSetmealInfo = (v, key, val) => {
    let setMealInfo = this.state.setMealInfo;
    setMealInfo.map((element) => {
      if (element === v) {
        element[key] = val;
      }
      return element;
    });
    this.setState({
      setMealInfo,
    });
  };

  //打开检测项目抽屉
  renderInspectionItems = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              inspectionItemsSearchBar: "",
              submitInspectionItems: false,
            });
          }}
        >
          添加检测项目
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.inspectionItemsSearchBar || ""}
            onChange={(el) => {
              let inspectionItemsData = JSON.parse(
                JSON.stringify(this.inspectionItemsData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                inspectionItemsSearchBar: el,
                inspectionItemsData,
                packageIndex3: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex3}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.inspectionItemsData.map((v, k) => {
              let inspectionItemsSearchBar =
                this.state.inspectionItemsSearchBar &&
                this.state.inspectionItemsSearchBar.toLowerCase();
              if (
                !inspectionItemsSearchBar ||
                (v.productName &&
                  v.productName
                    .toLowerCase()
                    .indexOf(inspectionItemsSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceInspectionItem(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.productName}</span>
                      </div>
                    }
                  >
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let inspectionItemsData = this.state.inspectionItemsData;
              let applyListInfo = this.state.applyListInfo;
              let inspectionItems = [];

              inspectionItemsData.forEach((element) => {
                if (element.checked) {
                  inspectionItems.push({
                    productId: element.id,
                    productCode: element.productCode,
                    productName: element.productName,
                    productType: (element.ext$ && element.ext$.producttype) || "",
                    amount: element.chargeAmount,
                  });
                }
              });
              applyListInfo.inspectionItems = inspectionItems;
              this.setState({
                drawer: false,
                submitInspectionItems: false,
                applyListInfo,
                inspectionItemsSearchBar: "",
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  //打开套餐抽屉
  rendersetMealDrawer = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              setMealSearch: "",
              setMealInfo: [],
              submitSampleInfos: false,
            });
          }}
        >
          添加套餐
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.setMealSearch}
                  onChange={(el) => {
                    let setMealInfo = JSON.parse(
                      JSON.stringify(this.setMealInfo)
                    ).map((v) => {
                      v.checked = false;
                      return v;
                    });
                    this.setState({
                      setMealSearch: el,
                      setMealInfo,
                      packageIndex: [],
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="search-bar-top-text"
            >
              <span>搜索</span>
            </div>
          </div>
        </div>
        <div className="setMealDrawer-center" style={{ overflow: "hidden" }}>
          <div className="setMealDrawer-center-top">
            {this.state.applyListInfo.sampleInfos &&
              this.state.applyListInfo.sampleInfos.length ? (
              <>
                <div className="search-bar">
                  <div
                    style={{ padding: "unset", justifyContent: "center" }}
                    className="search-bar-bottom"
                  >
                    <div className="option">
                      {this.state.applyListInfo.sampleInfos.map(
                        (value, key) => {
                          return (
                            <div
                              className="option-item"
                              key={key}
                              onClick={() => {
                                let applyListInfo = this.state.applyListInfo;
                                applyListInfo.sampleInfos = applyListInfo.sampleInfos.map(
                                  (v, k) => {
                                    if (v === value) {
                                      v.checked = !v.checked;
                                    }
                                    return v;
                                  }
                                );
                                this.setState(
                                  {
                                    applyListInfo,
                                    setMealInfo: [],
                                    packageIndex: [],
                                  },
                                  () => {
                                    this.searchSetmeal();
                                  }
                                );
                              }}
                            >
                              <img
                                alt=""
                                src={
                                  value.checked
                                    ? require("../img/wxz.svg")
                                    : require("../img/xz.svg")
                                }
                              ></img>
                              <span>
                                ({value.sampleCode}){value.sampleTypeName}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div
            id="setMealDrawer_center_right"
            className="setMealDrawer-center-bottom"
          >
            {this.state.searchSetmealLoad ? (
              <div
                id="setMealDrawer_anima"
                style={{
                  display: "flex",
                  flex: "1",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <BoxLoading></BoxLoading>
              </div>
            ) : this.state.setMealInfo && this.state.setMealInfo.length ? (
              <Accordion
                activeKey={this.state.packageIndex}
                className="my-accordion"
                onChange={this.onChange}
              >
                {this.state.setMealInfo.map((v, k) => {
                  let setMealSearch = this.state.setMealSearch;
                  if (
                    !setMealSearch ||
                    (v.setMealName &&
                      v.setMealName
                        .toLowerCase()
                        .indexOf(setMealSearch.toLowerCase()) > -1)
                  ) {
                    return (
                      <Accordion.Panel
                        key={v.setMealCode + v.templateId}
                        header={
                          <div
                            onClick={(e) => {
                              this.choosePackage(v, this.state.currentSampType);
                            }}
                            className="accordion2"
                          >
                            <img
                              alt=""
                              style={{ width: "0.15rem" }}
                              src={
                                v.checked
                                  ? require("../img/xz.svg")
                                  : require("../img/wxz.svg")
                              }
                            ></img>
                            <span>{v.setMealName}</span>
                          </div>
                        }
                      >
                        <div className="showItem InspectionTimes">
                          <List>
                            <InputItem
                              placeholder="终身版必填次数"
                              type="number"
                              value={v.InspectionTimes || ""}
                              onChange={(val) => {
                                this.setSetmealInfo(v, "InspectionTimes", val);
                              }}
                            >
                              终身版送检次数
                            </InputItem>
                            {v.setMealCode === "A0033" ? (
                              <>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "eR", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.eR]}
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.eR &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    雌激素受体ER
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "pR", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.pR]}
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.pR &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    孕激素受体PR
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "hER2", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.hER2]}
                                  error={
                                    v.setMealCode === "A0033" &&
                                    !v.hER2 &&
                                    this.state.setMealSubmit
                                  }
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                    {
                                      value: "不确定",
                                      label: "不确定",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.hER2 &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    HER2扩充
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(
                                      v,
                                      "lymphTransfer",
                                      val[0]
                                    );
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.lymphTransfer]}
                                  data={[
                                    {
                                      value: "阴性",
                                      label: "阴性",
                                    },
                                    {
                                      value: "阳性",
                                      label: "阳性",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.lymphTransfer &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    淋巴结转移
                                  </List.Item>
                                </Picker>
                                <Picker
                                  onOk={(val) => {
                                    this.setSetmealInfo(v, "menopause", val[0]);
                                  }}
                                  extra="21基因检测必填"
                                  value={[v.menopause]}
                                  data={[
                                    {
                                      value: "是",
                                      label: "是",
                                    },
                                    {
                                      value: "否",
                                      label: "否",
                                    },
                                  ]}
                                  cols={1}
                                >
                                  <List.Item
                                    arrow="horizontal"
                                    error={
                                      v.setMealCode === "A0033" &&
                                      !v.menopause &&
                                      this.state.setMealSubmit
                                    }
                                  >
                                    {v.setMealCode === "A0033" ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : null}
                                    绝经
                                  </List.Item>
                                </Picker>
                              </>
                            ) : null}
                          </List>
                        </div>
                      </Accordion.Panel>
                    );
                  } else {
                    return null;
                  }
                })}
              </Accordion>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ width: "40%" }}
                  alt=""
                  src={require("../img/kk.svg")}
                ></img>
                <span style={{ marginTop: "0.1rem", color: "#ddd" }}>
                  请搜索
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="setMealDrawer-foot2">
          <span
            onClick={() => {
              let applyListInfo = this.state.applyListInfo;
              let sampleInfos = this.state.applyListInfo.sampleInfos || [];
              sampleInfos = sampleInfos.map((element) => {
                element.checked = true;
                return element;
              });
              applyListInfo.sampleInfos = sampleInfos;
              this.setState({
                drawer: false,
                applyListInfo,
                setMealInfo: [],
                setMealSearch: "",
              });
            }}
            className="submit"
            style={{
              background: "white",
              border: "1px solid #ddd",
              color: "black",
            }}
          >
            取消
          </span>
          <span
            onClick={() => {
              let selectSetMeals = this.state.selectSetMeals || [];
              let setMealInfo = this.state.setMealInfo || [];
              let applyListInfo = this.state.applyListInfo;
              let sampleInfos = this.state.applyListInfo.sampleInfos || [];
              let comboSamInfo = [];
              let sampleTypeName = [];
              let submit = true;
              setMealInfo.forEach((element) => {
                if (element.checked) {
                  if (element.setMealCode === "A0033") {
                    if (
                      !(
                        element.lymphTransfer &&
                        element.pR &&
                        element.menopause &&
                        element.hER2 &&
                        element.eR
                      )
                    ) {
                      submit = false;
                    }
                  }
                }
              });
              if (!submit) {
                Toast.info("请填写完整信息！", 1);
                this.setState({
                  setMealSubmit: true,
                });
                return;
              }
              sampleInfos = sampleInfos.map((element) => {
                if (!element.checked) {
                  comboSamInfo.push({
                    id: element.id,
                    setMealCode: element.sampleCode,
                  });
                  sampleTypeName.push(
                    `(${element.sampleCode})${element.sampleTypeName}`
                  );
                }
                element.checked = true;
                return element;
              });
              setMealInfo.forEach((element) => {
                if (element.checked) {
                  selectSetMeals.push({
                    sampleTypeName: sampleTypeName.join("，"),
                    eR: element.eR,
                    menopause: element.menopause,
                    hER2: element.hER2,
                    pR: element.pR,
                    lymphTransfer: element.lymphTransfer,
                    InspectionTimes: element.InspectionTimes,
                    setMealName: element.setMealName,
                    setMealCode: element.setMealCode,
                    setMealId: element.templateId,
                    amount: element.amount,
                    comboSamInfo: comboSamInfo,
                  });
                }
              });
              applyListInfo.sampleInfos = sampleInfos;
              this.setState({
                drawer: false,
                selectSetMeals,
                applyListInfo,
                setMealInfo: [],
                setMealSearch: "",
              });
            }}
            className="submit"
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  setSampleInput = (id, key, val) => {
    let setMealData = this.state.setMealData.map((value) => {
      if (value.id.toString() === id) {
        value[key] = val;
      }
      return value;
    });
    this.setState({
      setMealData,
    });
  };

  //打开样本类型抽屉
  renderSampleInformation = () => {
    return (
      <div className="applyListInfo-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              sampleInformationSearchBar: "",
              submitSampleInfos: false,
            });
          }}
        >
          添加样本
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.sampleInformationSearchBar || ""}
            onChange={(el) => {
              let setMealData = JSON.parse(
                JSON.stringify(this.setMealData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                sampleInformationSearchBar: el,
                setMealData,
                packageIndex2: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex2}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.setMealData.map((v, k) => {
              let sampleInformationSearchBar =
                this.state.sampleInformationSearchBar &&
                this.state.sampleInformationSearchBar.toLowerCase();
              if (
                !sampleInformationSearchBar ||
                (v.sampleTypeName &&
                  v.sampleTypeName
                    .toLowerCase()
                    .indexOf(sampleInformationSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceSampleType(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.sampleTypeName}</span>
                      </div>
                    }
                  >
                    <div className="showItem">
                      <List>
                        <InputItem
                          placeholder="请填写样本数量"
                          value={v.sampleNum || ""}
                          onChange={(val) => {
                            this.setSampleInput(v.id, "sampleNum", val);
                          }}
                          error={
                            v.checked &&
                            this.state.submitSampleInfos &&
                            !v.sampleNum
                          }
                          type="number"
                        >
                          <div>
                            <span style={{ color: "red" }}>*</span>样本数量
                          </div>
                        </InputItem>
                        <Picker
                          onOk={(val) => {
                            this.setSampleInput(v.id, "numberUnit", val[0]);
                          }}
                          extra="请选择"
                          value={[v.numberUnit || ""]}
                          data={[
                            {
                              value: "管",
                              label: "管",
                            },
                            {
                              value: "片",
                              label: "片",
                            },
                            {
                              value: "块",
                              label: "块",
                            },
                            {
                              value: "瓶",
                              label: "瓶",
                            },
                            {
                              value: "袋",
                              label: "袋",
                            },
                            {
                              value: "管(RNA Later)",
                              label: "管(RNA Later)",
                            },
                            {
                              value: "管(福尔马林)",
                              label: "管(福尔马林)",
                            },
                          ]}
                          cols={1}
                        >
                          <List.Item
                            arrow="horizontal"
                          >
                            <span style={{ color: "red" }}>*</span>
                            单位
                          </List.Item>
                        </Picker>
                        {v.sampleTypeCode === "Streck" || v.sampleTypeCode === "EDTA" ? (
                          <InputItem
                            placeholder="请填写规格"
                            value={v.spec || ""}
                            extra="ml"
                            onChange={(val) => {
                              this.setSampleInput(v.id, "spec", val + "ml");
                            }}
                            error={
                              v.checked &&
                              this.state.submitSampleInfos &&
                              !v.spec
                            }
                            type="number"
                          >
                            <div>
                              <span style={{ color: "red" }}>*</span>每管容量
                          </div>
                          </InputItem>
                        ) : null}
                        {v.sampleCategory === "YB001" ? (
                          <DatePicker
                            mode="date"
                            minDate={new Date(1900, 0, 1, 0, 0, 0)}
                            maxDate={new Date()}
                            value={v.collectTime && new Date(v.collectTime)}
                            onOk={(val) => {
                              this.setSampleInput(
                                v.id,
                                "collectTime",
                                this.formatTime(val)
                              );
                            }}
                          >
                            <List.Item
                              arrow="horizontal"
                              error={
                                v.checked &&
                                this.state.submitSampleInfos &&
                                !v.collectTime
                              }
                            >
                              采样日期
                            </List.Item>
                          </DatePicker>
                        ) : null}
                        {v.sampleTypeCode === "PSPT" || v.sampleTypeCode === "PSST" || v.sampleTypeCode === "FBA" ? (
                          <InputItem
                            placeholder="请填写病理号"
                            value={v.diagonsisNo || ""}
                            error={
                              v.checked &&
                              this.state.submitSampleInfos &&
                              !v.diagonsisNo
                            }
                            onChange={(val) => {
                              this.setSampleInput(v.id, "diagonsisNo", val);
                            }}
                          >
                            <div>
                              <span style={{ color: "red" }}>*</span>病理号
                            </div>
                          </InputItem>
                        ) : null}
                        {v.sampleCategory === "ZZ001" ? (
                          <DatePicker
                            mode="datetime"
                            minDate={new Date(1900, 0, 1, 0, 0, 0)}
                            maxDate={new Date()}
                            value={v.tissueFixationTime && new Date(v.tissueFixationTime)}
                            onOk={(val) => {
                              this.setSampleInput(
                                v.id,
                                "tissueFixationTime",
                                this.formatDateTime(val)
                              );
                            }}
                          >
                            <List.Item
                              arrow="horizontal"
                              error={
                                v.checked &&
                                this.state.submitSampleInfos &&
                                !v.tissueFixationTime
                              }
                            >
                              组织固定开始时间
                            </List.Item>
                          </DatePicker>
                        ) : null}
                        {v.sampleCategory === "ZZ001" ? (
                          <DatePicker
                            mode="date"
                            minDate={new Date(1900, 0, 1, 0, 0, 0)}
                            maxDate={new Date()}
                            value={v.surgicalTime && new Date(v.surgicalTime)}
                            onOk={(val) => {
                              this.setSampleInput(
                                v.id,
                                "surgicalTime",
                                this.formatTime(val)
                              );
                            }}
                          >
                            <List.Item
                              arrow="horizontal"
                              error={
                                v.checked &&
                                this.state.submitSampleInfos &&
                                !v.surgicalTime
                              }
                            >
                              <span style={{ color: "red" }}>*</span>样本手术日期
                            </List.Item>
                          </DatePicker>
                        ) : null}
                        {v.sampleCategory === "ZZ001" ? (
                          <InputItem
                            placeholder="请填写取样部位"
                            value={v.collectLocation || ""}
                            onChange={(val) => {
                              this.setSampleInput(v.id, "collectLocation", val);
                            }}
                            error={
                              v.checked &&
                              this.state.submitSampleInfos &&
                              !v.collectLocation &&
                              (v.sampleCategory === "ZZ001" ||
                                v.sampleCategory === "FFPE001")
                            }
                          >
                            <div>
                              <span style={{ color: "red" }}>*</span>
                              取样部位
                          </div>
                          </InputItem>
                        ) : null}
                      </List>
                    </div>
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let setMealData = this.state.setMealData;
              let applyListInfo = this.state.applyListInfo;
              let sampleInfos = applyListInfo.sampleInfos || [];
              let submit = false;
              setMealData.forEach((element) => {
                if (element.checked) {
                  if (!element["sampleNum"] || !element["numberUnit"]) {
                    submit = true;
                  }
                  if (
                    (element.sampleTypeCode === "Streck" || element.sampleTypeCode === "EDTA") &&
                    !element.spec
                  ) {
                    submit = true;
                  }
                  if (
                    element.sampleCategory === "ZZ001" &&
                    !(element.collectLocation && element.surgicalTime)
                  ) {
                    submit = true;
                  }
                }
              });
              if (submit) {
                Toast.info("请填写必填信息！", 1);
                this.setState({
                  submitSampleInfos: true,
                });
                return;
              }

              setMealData.forEach((element) => {
                if (element.checked) {
                  sampleInfos.push({
                    ...element,
                    sampleCode: this.letter.pop(),
                  });
                }
              });
              applyListInfo.sampleInfos = sampleInfos;
              this.setState({
                drawer: false,
                submitSampleInfos: false,
                applyListInfo,
                sampleInformationSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 1:
        return this.renderTumourDrawer();
      case 2:
        return this.rendersetMealDrawer();
      case 3:
        return this.renderInspectionUnitDrawer();
      case 4:
        return this.renderSampleInformation();
      case 5:
        return this.renderNation();
      case 6:
        return this.renderInspectionItems();
      case 7:
        return this.renderTreatmentMethodDrawer();
      case 8:
        return this.renderPathologyTypeDrawer();
      case 9:
        return this.renderTestLabsDrawer();
      default:
        return <div></div>;
    }
  };

  componentDidMount() {
    Toast.loading("正在加载", 2);
    axios
      .get("/open/setmeal/getSampleTypeInfo")
      .then((res) => {
        if (res && res.lsisampletypebeans) {
          this.setMealData = JSON.parse(JSON.stringify(res.lsisampletypebeans));
          this.setState(
            {
              setMealData: res.lsisampletypebeans,
            },
            () => Toast.hide()
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });

    this.setState({

      familyHistoryInfo:
        this.state.applyListInfo.familyHistoryInfo &&
        this.state.applyListInfo.familyHistoryInfo.map((v) => {
          v.checked = true;
          return v;
        }),
      hisDrugs:
        this.state.applyListInfo.hisDrugs &&
        this.state.applyListInfo.hisDrugs.map((v) => {
          v.checked = true;
          return v;
        }),
      historyRadiationInfo:
        this.state.applyListInfo.historyRadiationInfo &&
        this.state.applyListInfo.historyRadiationInfo.map((v) => {
          v.checked = true;
          return v;
        }),
    });
    this.getInspectionItems();
    this.getInspectionUnit();
    this.getTumorType();
    this.getTestLabs();
    this.getTreatmentMethod();
    this.getPathologyList();
    let sampleInfos = this.state.applyListInfo.sampleInfos;
    let idNumber = this.state.applyListInfo.idNumber;
    if (sampleInfos) {
      sampleInfos.forEach((element) => {
        this.letter.splice(this.letter.indexOf(element.sampleCode), 1);
      });
    }
    // this.getPersonInfo();
    this.getCancerType();
    this.wxConfig();
  }

  wxConfig() {
    let url = window.location.href.split("#")[0];
    axios.get(`/wx/mp/selectJsSign?url=${url}`)
      .then((res) => {
        if (!res) return;
        wx.config({
          debug: false,
          appId: 'wx5837d46889da5d7f',
          timestamp: res.weixin.timestamp,
          nonceStr: res.weixin.noncestr,
          signature: res.weixin.jsSignature,
          jsApiList: ['scanQRCode']
        });
        // 权限验证出错
        wx.error(function (err) {
          Toast.info("获取验证出错了:" + JSON.stringify(err), 3)
        })
      })
      .finally(() => { });
  }

  getCancerType = () => {
    const codeCategoryIdList = ["cancerType"];
    axios
      .post("/open/setmeal/wxpush/core-codes/queries", codeCategoryIdList, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res && res.corecodelist) {
          const cancerList = res.corecodelist.map((item) => {
            const cancer = {
              value: item.code,
              label: item.codeName
            };
            return cancer;
          });
          this.cancerList = cancerList;
        }
      })
      .catch((e) => {
        Toast.fail("癌种类型加载失败", 2);
      });
  }

  getPersonInfo = () => {
    let applyListInfo = this.state.applyListInfo;
    let salesBarCode = applyListInfo.salesBarCode || "-1";
    axios
      .get(
        `/open/setmeal/b/${applyListInfo.id ? applyListInfo.id : "-1"
        }/s/${salesBarCode}`
      )
      .then((res) => {
        if (!res) {
        } else {
          if (!res || !res.person) return;
          let applyListInfo = this.state.applyListInfo;
          applyListInfo.salesManPhone = res.person.mobile;
          applyListInfo.salesEmail = res.person.email;
          applyListInfo.salesRepresentative = res.person.personName;
          applyListInfo.contactadress = res.person.contactadress;
          this.setState({
            salesBarCodeError: false,
            applyListInfo,
          });
          if (applyListInfo.paperReport === "迈景经办人") {
            this.setState({
              agentInfo: {
                personName: res.person.personName,
                mobile: res.person.mobile,
                email: res.person.email,
                contactadress: res.person.contactadress,
              },
            });
          }
        }
      })
      .finally(() => {
        Toast.hide();
      });
  };

  getTreatmentMethod = () => {
    axios
      .get("/open/basedata/dict/treatmentMethod")
      .then((res) => {
        if (res && res.data) {
          var data = [];
          res.data.map(function (item) {
            data.push({
              value: item.dictTextCode,
              label: item.dictTextName,
            })
          })
          this.setState({
            // treatmentMethod: res.data,
            treatmentMethodSelectData: data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getPathologyList = () => {
    const requestParam = {
      p: {},
      b: ["lsiBatchTumourServiceImpl", this.state.applyListInfo.tumourList],
    }
    axios
      .post(`/open/basedata/pathologies/queries`,
        requestParam,
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      )
      .then((res) => {
        if (res && res.data) {
          this.setState({
            pathologyType: res.data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getTumorType = () => {
    axios
      .get("/open/basedata/dict/肿瘤类型")
      .then((res) => {
        if (res && res.data) {
          this.setState({
            tumorType: res.data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getTestLabs = () => {
    axios
      .get("/open/basedata/labs/queries")
      .then((res) => {
        if (res && res.data) {
          this.setState({
            testLabs: res.data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getPathologyType = (id) => {
    axios
      .get(`/open/basedata/pathologies/${id}/queries`)
      .then((res) => {
        if (res && res.data) {
          this.setState({
            pathologyType: res.data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getInspectionUnit = () => {
    axios
      .get("/open/setmeal/getInspectionUnit")
      .then((res) => {
        if (res && res.lsihospitalbean) {
          this.setState(
            {
              inspectionUnitData: res.lsihospitalbean,
            },
            () => {
              Toast.hide();
              this.inspectionUnitData = JSON.parse(
                JSON.stringify(res.lsihospitalbean)
              );
              // const applyListInfo = this.state.applyListInfo;
              // if (applyListInfo.inspectionOrganization) {
              //   const inspectionOrganizationItem = this.inspectionUnitData.find(
              //     (element) =>
              //       element.orgName === applyListInfo.inspectionOrganization
              //   );
              //   this.getReportTypeData(inspectionOrganizationItem);
              // }
            }
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getInspectionItems = () => {
    axios
      .get("/open/setmeal/getInspectionItems")
      .then((res) => {
        if (res && res.lsiproductmainbeans) {
          this.setState(
            {
              inspectionItemsData: res.lsiproductmainbeans,
            },
            () => {
              Toast.hide();
              this.inspectionItemsData = JSON.parse(
                JSON.stringify(res.lsiproductmainbeans)
              );
            }
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  getReportTypeData = (inspectionOrganizationItem) => {
    let reportTypeData;
    let applyListInfo = this.state.applyListInfo;
    if (inspectionOrganizationItem.isHospitalTemplate === "0") {
      applyListInfo.reportType = "院外";
      reportTypeData = [
        {
          value: "院外",
          label: "院外",
        },
      ];
    } else {
      reportTypeData = [
        {
          value: "院内",
          label: "院内",
        },
        {
          value: "院外",
          label: "院外",
        },
      ];
    }
    applyListInfo.isMonthlyStatement =
      inspectionOrganizationItem.isMonthlyStatement;
    this.setState({
      reportTypeData,
      applyListInfo,
    });
  };

  submit = (res, applyListInfo) => {
    axios
      .post(
        `/open/setmeal/wxpush/${res.id}/submitBatchInfoByWx`,
        applyListInfo,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        Toast.success("提交成功", 2);
      })
      .catch(() => {
        Toast.info("加载失败！", 2);
      });
  };

  render() {
    return (
      <div className="applyListInfo">
        <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            if (this.state.drawerType === 2) {
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.selectSetMeals = this.selectSetMeals;
              this.setState({
                applyListInfo,
              });
            }
            this.setState({
              drawer: false,
              setMealSearchBar: "",
              sampleInformationSearchBar: "",
              nationSearchBar: "",
              inspectionUnitSearchBar: "",
              testLabsSearchBar: "",
              inspectionItemsSearchBar: "",
              tumorTypeSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexShrink: "0",
            flexGrow: "0",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
            >
              在线申请
            </NavBar>
            <div className="center">
              <div style={{ width: "100%" }}>
                <List>
                  <div className="item">
                    {/* <span className="title">患者信息</span> */}
                    <div className="form">
                      <InputItem
                        extra={
                          <div className="camare">
                            <img
                              style={{ marginLeft: "0.1rem" }}
                              src={require("../img/sm.png")}
                              alt=""
                              onClick={this.scanQRCode}
                            ></img>
                          </div>
                        }
                        disabled={true}
                        value={
                          this.state.applyListInfo.salesBarCode || ""
                        }
                        onChange={(val) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.salesBarCode = val;
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        onErrorClick={() => {
                          Toast.info("申请单条码有误！", 1);
                        }}
                        onBlur={(e) => {
                          if (!e) return;
                          let applyListInfo = this.state.applyListInfo;
                          let salesBarCode = applyListInfo.salesBarCode || "-1";
                          axios
                            .get(
                              `/open/setmeal/b/${applyListInfo.id ? applyListInfo.id : "-1"
                              }/s/${salesBarCode}`
                            )
                            .then((res) => {
                              if (!res) {
                                this.setState({
                                  salesBarCodeError: true,
                                });
                              }
                            
                            });
                        }}
                        onExtraClick={() => { }}
                        error={
                          (this.state.submit &&
                            !this.state.applyListInfo.salesBarCode) ||
                          this.state.salesBarCodeError
                        }
                        placeholder="请输入或扫描申请单条码"
                      >
                        <span style={{ color: "red" }}>*</span>
                        申请单条码
                      </InputItem>
                      <InputItem
                        placeholder="请填写姓名"
                        ref={(el) => (this.patientName = el)}
                        value={this.state.applyListInfo.patientName}
                        onChange={(el) => {
                          this.inputChange(el, "patientName");
                        }}
                        disabled={true}
                        error={
                          this.state.submit &&
                          !this.state.applyListInfo.patientName
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.patientName.focus()}
                        >
                          {true ? <span className="sign">*</span> : null}
                          姓名
                        </div>
                      </InputItem>
                      <Picker
                        onOk={(v) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.gender = v[0];
                          this.setState({
                            applyListInfo,
                          });
                        }}
                        value={[this.state.applyListInfo.gender]}
                        data={[
                          {
                            value: "男",
                            label: "男",
                          },
                          {
                            value: "女",
                            label: "女",
                          },
                          {
                            value: "未知",
                            label: "未知",
                          },
                        ]}
                        disabled={true}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.gender
                          }
                          disabled={true}
                        >
                          {true ? <span className="sign">*</span> : null}
                          性别
                        </List.Item>
                      </Picker>
                      <DatePicker
                          mode="date"
                          minDate={new Date(1900, 0, 1, 0, 0, 0)}
                          maxDate={new Date()}
                          value={
                            this.state.applyListInfo.patientBirth &&
                            new Date(this.state.applyListInfo.patientBirth)
                          }
                          onOk={(val) => {
                            this.inputChange(this.formatTime(val), "patientBirth");
                          }}
                          disabled={true}
                        >
                          <List.Item 
                          disabled={true}
                          arrow="horizontal">
                            <span className="sign">*</span>
                          出生年月
                       </List.Item>
                        </DatePicker>
                      <List.Item
                        extra={this.state.applyListInfo.nation || "请选择"}
                        arrow="horizontal"
                        disabled={true}
                      >
                        {true ? <span className="sign">*</span> : null}
                        民族
                      </List.Item>
                      <InputItem
                        placeholder="请填写籍贯"
                        type="text"
                        maxLength={50}
                        ref={(el) => (this.nativePlace = el)}
                        value={this.state.applyListInfo.nativePlace}
                        onChange={(el) => {
                          this.inputChange(el, "nativePlace");
                        }}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.nativePlace.focus()}
                        >
                          籍贯
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写病案号"
                        type="text"
                        maxLength={64}
                        ref={(el) => (this.caseNumber = el)}
                        value={this.state.applyListInfo.caseNumber}
                        onChange={(el) => {
                          this.inputChange(el, "caseNumber");
                        }}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.caseNumber.focus()}
                        >
                          {false ? <span className="sign">*</span> : null}
                          病案号
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写身份证号"
                        ref={(el) => (this.idNumber = el)}
                        type="text"
                        maxLength={18}
                        value={this.state.applyListInfo.idNumber}
                        onChange={(el) => {
                          this.inputChange(el, "idNumber");
                        }}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.idNumber.focus()}
                        >
                          身份证号
                        </div>
                      </InputItem>
                      <List.Item
                          extra={
                            this.state.applyListInfo.testLab ||
                            "请选择"
                          }
                          error={
                            this.state.submit &&
                            (!this.state.applyListInfo.testLab
                              || this.state.applyListInfo.testLab.length == 0)
                          }
                          arrow="horizontal"
                          disabled={true}
                        >
                         {true ? <span className="sign">*</span> : null}
                         检测实验室
                      </List.Item>
                    
                      <InputItem
                        placeholder="请填写报告接收人"
                        ref={(el) => (this.reportReceiver = el)}
                        type="text"
                        maxLength={36}
                        value={this.state.applyListInfo.reportReceiver}
                        onChange={(el) => {
                          this.inputChange(el, "reportReceiver");
                        }}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span className="sign">*</span>
                          报告接收人
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写接收人电话"
                        type="number"
                        maxLength={11}
                        ref={(el) => (this.reportPhone = el)}
                        value={this.state.applyListInfo.reportPhone}
                       
                        error={this.state.receivingPhoneError}
                        
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.reportPhone.focus()}
                        >
                          <span className="sign">*</span>
                          接收人电话
                        </div>
                      </InputItem>
                      <InputItem
                        placeholder="请填写报告邮寄地址"
                        ref={(el) => (this.reportAddress = el)}
                        type="text"
                        maxLength={60}
                        value={this.state.applyListInfo.reportAddress}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                       
                        >
                          <span className="sign">*</span>
                          邮寄地址
                        </div>
                      </InputItem>
                    </div>
                    <div className="item">
                      {/* <span className="title">检测项目</span> */}
                      <div className="form">
                        <List.Item
                          extra="请选择"
                          arrow="horizontal"
                          error={
                            (!this.state.applyListInfo.inspectionItems &&
                              this.state.submit) ||
                            (this.state.submit &&
                              !this.state.applyListInfo.inspectionItems.length) ||
                            this.state.inspectionItemsError
                          }
                          disabled={true}
                        >
                          {true ? <span className="sign">*</span> : null}
                        添加检测项目
                      </List.Item>
                        {this.state.applyListInfo.inspectionItems &&
                          this.state.applyListInfo.inspectionItems.length ? (
                          <List.Item multipleLine>
                            {this.state.applyListInfo.inspectionItems
                              .map((v, k) => {
                                return (
                                  <div key={k} className="select-info2">
                                    <img
                                      src={require("../img/xx.png")}
                                      alt=""
                                    ></img>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "1",
                                      }}
                                    >
                                      {(!this.state.applyListInfo.readonly) && this.state.applyListInfo.payStatus != '20' ? (
                                        <img
                                          alt=""
                                          className="close"
                                          src={require("../img/close.svg")}
                                          style={{
                                            position: "absolute",
                                            right: "-0.1rem",
                                            top: "-0.1rem",
                                          }}
                                        ></img>
                                      ) : null}
                                      {v.productName ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            检测项目：
                                      </span>
                                          <span className="content-lable">
                                            {v.productName}
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </List.Item>
                        ) : null}
                      </div>
                    </div>
                    <div className="item">
                      {/* <span className="title">患者诊疗信息</span> */}
                      <div className="form">
                        <List.Item
                          multipleLine={true}
                          extra={
                            this.state.applyListInfo.hospital ||
                            "请选择"
                          }
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.hospital
                          }
                          arrow="horizontal"
                          disabled={true}
                        >
                          {true ? <span className="sign">*</span> : null}
                        送检医院
                      </List.Item>
                        {this.state.applyListInfo.hospital === '其它医院' ? (<InputItem
                          placeholder="请填写其它医院名称"
                          ref={(el) => (this.otherHospitalName = el)}
                          type="text"
                          value={this.state.applyListInfo.otherHospitalName}
                         
                          error={
                            this.state.submit && !this.state.applyListInfo.otherHospitalName
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                           
                          >
                            {true ? <span className="sign">*</span> : null}
                            其它医院名称
                        </div>
                        </InputItem>) : null}
                        <InputItem
                          placeholder="请填写送检科室"
                          ref={(el) => (this.dept = el)}
                          type="text"
                          maxLength={64}
                          value={this.state.applyListInfo.dept}
                          error={
                            this.state.submit && !this.state.applyListInfo.dept
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onClick={() => this.dept.focus()}
                          >
                            {true ? <span className="sign">*</span> : null}
                          送检科室
                        </div>
                        </InputItem>
                        <InputItem
                          placeholder="请填写送检医生"
                          type="text"
                          maxLength={64}
                          ref={(el) => (this.customerName = el)}
                          value={this.state.applyListInfo.customerName}
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.customerName
                          }
                        
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                         
                          >
                            {true ? <span className="sign">*</span> : null}
                          送检医生
                        </div>
                        </InputItem>

                        <List.Item
                          extra="请选择"
                          error={
                            this.state.submit &&
                            (!this.state.applyListInfo.tumourList
                              || this.state.applyListInfo.tumourList.length == 0)
                          }
                          arrow="horizontal"
                          disabled={true}
                        >
                         {true ? <span className="sign">*</span> : null}
                          肿瘤类型
                      </List.Item>

                        {this.state.applyListInfo.tumourList &&
                          this.state.applyListInfo.tumourList.length ? (
                          <List.Item multipleLine>
                            {this.state.applyListInfo.tumourList
                              .map((v, k) => {
                                return (
                                  <div key={k} className="select-info2">
                                    <img
                                      src={require("../img/xx.png")}
                                      alt=""
                                    ></img>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "1",
                                      }}
                                    >
                                      {!this.state.applyListInfo.readonly ? (
                                        <img
                                          alt=""
                                          className="close"
                                          src={require("../img/close.svg")}
                                          onClick={() => {
                                            let applyListInfo = this.state
                                              .applyListInfo;
                                            applyListInfo.tumourList.splice(
                                              applyListInfo.tumourList.findIndex(
                                                (value) => value === v
                                              ), 1
                                            );
                                            this.letter.push(v.tumorCode);
                                            this.setState({
                                              applyListInfo,
                                            });
                                          }}
                                          style={{
                                            position: "absolute",
                                            right: "-0.1rem",
                                            top: "-0.1rem",
                                          }}
                                        ></img>
                                      ) : null}
                                      {v.tumorName ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            肿瘤类型：
                                      </span>
                                          <span className="content-lable">
                                            {v.tumorName}
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </List.Item>
                        ) : null}

                        <List.Item
                          extra="请选择"
                          error={
                            this.state.submit &&
                            (!this.state.applyListInfo.pathologyList
                              || this.state.applyListInfo.pathologyList.length == 0)
                          }
                        
                          arrow="horizontal"
                          disabled={true}
                        >
                          病理类型
                      </List.Item>

                        {this.state.applyListInfo.pathologyList &&
                          this.state.applyListInfo.pathologyList.length ? (
                          <List.Item multipleLine>
                            {this.state.applyListInfo.pathologyList
                              .map((v, k) => {
                                return (
                                  <div key={k} className="select-info2">
                                    <img
                                      src={require("../img/xx.png")}
                                      alt=""
                                    ></img>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "1",
                                      }}
                                    >
                                      {!this.state.applyListInfo.readonly ? (
                                        <img
                                          alt=""
                                          className="close"
                                          src={require("../img/close.svg")}
                                    
                                          style={{
                                            position: "absolute",
                                            right: "-0.1rem",
                                            top: "-0.1rem",
                                          }}
                                        ></img>
                                      ) : null}
                                      {v.pathologyName ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            病理类型：
                                      </span>
                                          <span className="content-lable">
                                            {v.pathologyName}
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </List.Item>
                        ) : null}

                        <InputItem
                          placeholder="请填写临床分期"
                          ref={(el) => (this.clinicalStage = el)}
                          type="text"
                          maxLength={64}
                          value={this.state.applyListInfo.clinicalStage}
                         
                          error={
                            this.state.submit && !this.state.applyListInfo.clinicalStage
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          
                          >
                            {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                          临床分期
                        </div>
                        </InputItem>

                        <DatePicker
                          mode="date"
                          minDate={new Date(1900, 0, 1, 0, 0, 0)}
                          maxDate={new Date()}
                          value={
                            this.state.applyListInfo.firstDiagnosisTime &&
                            new Date(this.state.applyListInfo.firstDiagnosisTime)
                          }
                       
                          disabled={true}
                        >
                          <List.Item 
                          disabled={true}
                          arrow="horizontal">
                          {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                        初次确诊时间
                       </List.Item>
                        </DatePicker>

                        <InputItem
                          placeholder="请填写原发部位"
                          type="text"
                          maxLength={255}
                          value={this.state.applyListInfo.primarySite || ""}
                       
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.primarySite
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                           {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                            原发部位
                          </div>
                        </InputItem>

                        <Picker
                        
                          value={[this.state.applyListInfo.transferBench]}
                          data={[
                            {
                              value: "0",
                              label: "否",
                            },
                            {
                              value: "1",
                              label: "是",
                            },

                          ]}
                          cols={1}
                          disabled={true}
                        >
                          <List.Item
                            disabled={true}
                            arrow="horizontal"
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.transferBench
                            }
                          >
                            {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                            是否转移
                          </List.Item>
                        </Picker>

                        {this.state.applyListInfo.transferBench === "1" ? (
                          <InputItem
                            placeholder="请填写转移部位"
                            type="text"
                            maxLength={255}
                            value={this.state.applyListInfo.metastases || ""}
                         
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.metastases
                            }
                            disabled={true}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                            转移部位
                          </div>
                          </InputItem>
                        ) : null}
                      </div>
                      <List.Item
                    extra="请选择"
                    arrow="horizontal"
                    disabled={true}
                    error={
                      this.state.submit &&
                      !this.state.applyListInfo.hisTreatmentMethod
                    }
                  >
                      {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                    既往治疗方式
                      </List.Item>
                  {this.state.applyListInfo.hisTreatmentMethods &&
                    this.state.applyListInfo.hisTreatmentMethods.length ? (
                    <List.Item multipleLine>
                      {this.state.applyListInfo.hisTreatmentMethods
                        .map((v, k) => {
                          return (
                            <div key={k} className="select-info2">
                              <img
                                src={require("../img/xx.png")}
                                alt=""
                              ></img>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  flexDirection: "column",
                                  flex: "1",
                                }}
                              >
                                {!this.state.readonly ? (
                                  <img
                                    alt=""
                                    className="close"
                                    src={require("../img/close.svg")}
                                    style={{
                                      position: "absolute",
                                      right: "0.1rem",
                                      top: "-0.1rem",
                                    }}
                                  ></img>
                                ) : null}
                                {v.hisTreatmentMethod ? (
                                  <div className="content-item">
                                    <span className="content-title">
                                      治疗方式：
                                      </span>
                                    <span className="content-lable">
                                      {v.hisTreatmentMethod}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                    </List.Item>
                  ) : null}

                      <TextareaItem
                        placeholder="请填写手术史"
                        type="text"
                        maxLength={255}
                        value={this.state.applyListInfo.historySurgery || ""}
        
                        rows={3}
                        disabled={true}
                        title={
                          <div>
                            <span>手术史</span>
                          </div>
                        }
                      >
                      </TextareaItem>

                      <DatePicker
                        mode="date"
                        minDate={new Date(1900, 0, 1, 0, 0, 0)}
                        maxDate={new Date()}
                        value={
                          this.state.applyListInfo.hisTransfusionTime &&
                          new Date(this.state.applyListInfo.hisTransfusionTime)
                        }
                        disabled={true}
                      >
                        <List.Item arrow="horizontal"
                           disabled={true}
                        >
                          输血史/输血时间
                          </List.Item>
                      </DatePicker>
                      <div className="item">
                        <List.Item
                          arrow="horizontal"
                          extra="请选择"
                          disabled={true}
                        >
                          既往治疗药物
                          </List.Item>
                      </div>
                      {this.state.applyListInfo.hisDrugs &&
                        this.state.applyListInfo.hisDrugs
                          .length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.hisDrugs.map(
                            (v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div>
                                    {v.medicinePlan ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          用药方案：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicinePlan}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.medicineStartTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          用药开始时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicineStartTime}
                                        </span>
                                      </div>
                                    ) : null}
                                      {v.medicineEndTime ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          用药结束时间：
                                        </span>
                                        <span className="content-lable">
                                          {v.medicineEndTime}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </List.Item>
                      ) : null}

                      <InputItem
                        placeholder="请填写个人基因检测史"
                        type="text"
                        maxLength={256}
                        value={this.state.applyListInfo.hisGenetic || ""}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          基因检测史
                        </div>
                      </InputItem>

                      <Picker
                       
                        value={[this.state.applyListInfo.geneMutationFlag]}
                        data={[
                          {
                            value: "0",
                            label: "否",
                          },
                          {
                            value: "1",
                            label: "是",
                          },
                        ]}
                        cols={1}
                        disabled={true}
                      >
                        <List.Item
                          disabled={true}
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.geneMutationFlag
                          }
                        >
                          {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                            亲属是否存在特定基因胚系突变
                          </List.Item>
                      </Picker>
                      {this.state.applyListInfo.geneMutationFlag === '1' ? (
                        <InputItem
                          placeholder="请填写突变基因"
                          type="text"
                          maxLength={128}
                          value={this.state.applyListInfo.geneMutation || ""}
                        
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.geneMutation
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span className="sign">*</span>
                            突变基因
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.geneMutationFlag === '1' ? (
                        <InputItem
                          placeholder="请填写突变携带者与本人的关系"
                          type="text"
                          maxLength={32}
                          value={this.state.applyListInfo.carrierRelation || ""}
                         
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.carrierRelation
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span className="sign">*</span>
                              与本人关系
                            </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.geneMutationFlag === '1' ? (
                        <Picker
                       
                          value={[this.state.applyListInfo.selfProductFlag]}
                          data={[
                            {
                              value: "0",
                              label: "否",
                            },
                            {
                              value: "1",
                              label: "是",
                            },
                          ]}
                          disabled={true}
                          cols={1}
                        >
                          <List.Item
                            arrow="horizontal"
                        
                          >
                            突变鉴定是否为本公司检测产品
                            </List.Item>
                        </Picker>
                      ) : null}

                      {this.state.applyListInfo.geneMutationFlag === '1' && this.state.applyListInfo.selfProductFlag === '1' ? (
                        <InputItem
                          placeholder="请填写产品名称"
                          type="text"
                          maxLength={128}
                          value={this.state.applyListInfo.hisProductName || ""}
                       
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.hisProductName
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span className="sign">*</span>
                            产品名称
                          </div>
                        </InputItem>
                      ) : null}
                      <Picker
                       
                        value={[this.state.applyListInfo.diseaseHistory]}
                        data={[
                          {
                            value: "无",
                            label: "无",
                          },
                          {
                            value: "肿瘤",
                            label: "肿瘤",
                          },
                          {
                            value: "其他",
                            label: "其他",
                          },
                        ]}
                        cols={1}
                        disabled={true}
                      >
                        <List.Item
                          disabled={true}
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.diseaseHistory
                          }
                        >
                          
                          {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                          既往病史
                        </List.Item>
                      </Picker>
                      {this.state.applyListInfo.diseaseHistory === '其他' ? (
                        <InputItem
                          placeholder="请填写其他病史"
                          type="text"
                          value={this.state.applyListInfo.otherDiseaseHistory || ""}
                       
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.otherDiseaseHistory
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span className="sign">*</span>
                            其他病史
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                        <InputItem
                          placeholder="请填写肿瘤类型"
                          type="text"
                          value={this.state.applyListInfo.hisTumorType || ""}
                       
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.hisTumorType
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            肿瘤类型
                        </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                        <InputItem
                          placeholder="请填写病理类型"
                          type="text"
                          maxLength={128}
                          value={this.state.applyListInfo.hisPathologicalType || ""}
                          onChange={(el) => {
                            this.inputChange(el, "hisPathologicalType");
                          }}
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.hisPathologicalType
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            病理类型
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                        <InputItem
                          placeholder="请填写临床分期"
                          type="text"
                          maxLength={64}
                          value={this.state.applyListInfo.hisClinicalStages || ""}
                       
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.hisClinicalStages
                          }
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            临床分期
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.diseaseHistory === '肿瘤' ? (
                        <InputItem
                          placeholder="请填写确诊年龄"
                          type="number"
                          value={this.state.applyListInfo.hisDiagnosisAge || ""}
                          onChange={(el) => {
                            this.inputChange(el, "hisDiagnosisAge");
                          }}
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.hisDiagnosisAge
                          }
                          disabled={this.state.applyListInfo.readonly}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            确诊年龄
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.hisTumorType && this.state.applyListInfo.hisTumorType.indexOf("乳腺癌") > -1 ? (
                        <Picker
                          onOk={(v) => {
                            let applyListInfo = this.state.applyListInfo;
                            applyListInfo.hisBreastCancerType = v[0];
                            this.setState({
                              applyListInfo,
                            });
                          }}
                          value={[this.state.applyListInfo.hisBreastCancerType]}
                          data={[
                            {
                              value: "单侧",
                              label: "单侧",
                            },
                            {
                              value: "双侧",
                              label: "双侧",
                            },
                            {
                              value: "三阴性乳腺癌",
                              label: "三阴性乳腺癌",
                            },
                          ]}
                          cols={1}
                          disabled={this.state.applyListInfo.readonly}
                        >
                          <List.Item
                            arrow="horizontal"
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.hisBreastCancerType
                            }
                          >
                            <span className="sign">*</span>
                          乳腺癌类型
                        </List.Item>
                        </Picker>
                      ) : null}

                      <Picker
                        value={[this.state.applyListInfo.familyHistory]}
                        data={[
                          {
                            value: "无",
                            label: "无",
                          },
                          {
                            value: "肿瘤",
                            label: "肿瘤",
                          },
                          {
                            value: "未提供",
                            label: "未提供",
                          },
                        ]}
                        cols={1}
                        disabled={true}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.familyHistory
                          }
                          disabled={true}
                        >
                         {this.itIsHealthScreen() ? <span className="sign">*</span> : null}
                          亲属中患有明确诊断的疾病
                        </List.Item>
                      </Picker>
                      {this.state.applyListInfo.familyHistory &&
                        this.state.applyListInfo.familyHistory === "肿瘤" ? (
                        <div className="child-item">
                          <List.Item
                            arrow="horizontal"
                            extra="请选择"
                            error={
                              this.state.submit &&
                              !this.state.applyListInfo.familyHistoryInfo
                            }
                            onClick={() => {
                              if (this.state.applyListInfo.readonly) return false;
                              this.familyHistoryInfoDak = JSON.parse(
                                JSON.stringify(
                                  this.state.familyHistoryInfo || []
                                )
                              );
                              this.setState({
                                familyHistoryModal: true,
                              });
                            }}
                            disabled={this.state.applyListInfo.readonly}
                          >
                            {true ? <span className="sign">*</span> : null}
                            家族史信息
                          </List.Item>
                        </div>
                      ) : null}
                      {this.state.applyListInfo.familyHistoryInfo &&
                        this.state.applyListInfo.familyHistoryInfo.length ? (
                        <List.Item multipleLine>
                          {this.state.applyListInfo.familyHistoryInfo.map(
                            (v, k) => {
                              return (
                                <div key={k} className="select-info2">
                                  <img
                                    src={require("../img/xx.png")}
                                    alt=""
                                  ></img>
                                  <div>
                                    {v.patientRelationship ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          患肿瘤亲属：
                                        </span>
                                        <span className="content-lable">
                                          {v.patientRelationship}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.otherTumorType ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          肿瘤类型：
                                        </span>
                                        <span className="content-lable">
                                          {v.otherTumorType}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.otherBreastCancerType ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          乳腺癌类型：
                                        </span>
                                        <span className="content-lable">
                                          {v.otherBreastCancerType}
                                        </span>
                                      </div>
                                    ) : null}
                                    {v.otherPatientAge ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          确诊年龄：
                                        </span>
                                        <span className="content-lable">
                                          {v.otherPatientAge}
                                        </span>
                                      </div>
                                    ) : null}

                                    {v.otherClinicalStages ? (
                                      <div className="content-item">
                                        <span className="content-title">
                                          临床分期：
                                        </span>
                                        <span className="content-lable">
                                          {v.otherClinicalStages}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </List.Item>
                      ) : null}
                      {this.state.applyListInfo && this.state.applyListInfo.originalBatchId ? (
                        <TextareaItem
                          placeholder="请填写备注信息(原订单号)"
                          type="text"
                          maxLength={255}
                          value={this.state.applyListInfo.remark || ""}
                          onChange={(el) => {
                            this.inputChange(el, "remark");
                          }}
                          rows={3}
                          disabled={this.state.applyListInfo.readonly}
                          title={
                            <div>
                              <span>备注</span>
                            </div>
                          }
                        >
                        </TextareaItem>
                      ) : null}
                    </div>
                  </div>
                </List>
              </div>
            </div>
            <div className="foot">
            {this.state.applyListInfo.pathologyTodo ? (
                <span
                  className="submit"
                  onClick={() => {

                    let applyListInfo = this.state.applyListInfo;
                    if (!applyListInfo.salesBarCode) {
                      this.setState({
                        salesBarCodeError: true,
                      });
                      Toast.info("申请单条码不能为空！", 2);
                      return;
                    }
                    if (this.state.receivingPhoneError) {
                      Toast.info("报告接收人电话有误！", 2);
                      return;
                    }
                    if (applyListInfo.idNumber.length != 18) {
                      Toast.info("请填写18位身份证号！", 2);
                      return;
                    }

                    if (applyListInfo.reportPhone && applyListInfo.reportPhone.length != 11) {
                      Toast.info("请填写11位接收人电话！", 2);
                      return;
                    }

                    if (!applyListInfo.patientBirth) {
                      Toast.info("请填写出生年月！", 2);
                      return;
                    }
                    if (!applyListInfo.pathologyList) {
                      Toast.info("请选择病理类型", 2);
                      return;
                    }
                    alert("提示", "请确认是否提交?", [
                      { text: "否" },
                      {
                        text: "是",
                        onPress: () => {
                          Toast.loading("正在提交", 0);
                          let salesBarCode = applyListInfo.salesBarCode || "-1";
                          axios
                            .get(
                              `/open/setmeal/b/${applyListInfo.id ? applyListInfo.id : "-1"
                              }/s/${salesBarCode}`
                            )
                            .then((res) => {
                              if (!res) {
                                this.setState({
                                  salesBarCodeError: true,
                                });
                              } else {
                                this.setState(
                                  {
                                    salesBarCodeError: false,
                                  },
                                  () => {
                                    axios
                                      .post(
                                        "/open/setmeal/wxpush/saveBatchPathologyByWx",
                                        applyListInfo,
                                        {
                                          headers: {
                                            "Content-Type": "application/json",
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        if (res) {
                                          applyListInfo.id = res.id;
                                          this.setState({
                                            applyListInfo,
                                          });
                                          Toast.info("提交成功！", 1);
                                        }
                                      })
                                      .catch(() => {
                                        Toast.info("提交失败！", 1);
                                      });
                                  }
                                );
                              }
                            });
                        }

                      }
                    ])                    
                  }}
                >
                  提交
                </span>
              ) :null}
              {(this.state.applyListInfo.status === "Draft_Save" || this.state.applyListInfo.status === "Waiting_For_Send" || this.state.applyListInfo.status === "Already_Been_Sent" || this.state.applyListInfo.status === "Pending_Sample")
                && this.state.applyListInfo.action !== "pushToLab" ? (
                <span
                  className="submit"
                  onClick={() => {

                    let applyListInfo = this.state.applyListInfo;
                    if (!applyListInfo.salesBarCode) {
                      this.setState({
                        salesBarCodeError: true,
                      });
                      Toast.info("申请单条码不能为空！", 2);
                      return;
                    }
                    if (this.state.receivingPhoneError) {
                      Toast.info("报告接收人电话有误！", 2);
                      return;
                    }
                    if (applyListInfo.idNumber.length != 18) {
                      Toast.info("请填写18位身份证号！", 2);
                      return;
                    }

                    if (!applyListInfo.testLab) {
                      Toast.info("请选择检测实验室！", 2);
                      return;
                    }

                    if (applyListInfo.reportPhone && applyListInfo.reportPhone.length != 11) {
                      Toast.info("请填写11位接收人电话！", 2);
                      return;
                    }

                    if (!applyListInfo.patientBirth) {
                      Toast.info("请填写出生年月！", 2);
                      return;
                    }

                    Toast.loading("正在保存", 0);
                    let salesBarCode = applyListInfo.salesBarCode || "-1";
                    axios
                      .get(
                        `/open/setmeal/b/${applyListInfo.id ? applyListInfo.id : "-1"
                        }/s/${salesBarCode}`
                      )
                      .then((res) => {
                        if (!res) {
                          this.setState({
                            salesBarCodeError: true,
                          });
                        } else {
                          this.setState(
                            {
                              salesBarCodeError: false,
                            },
                            () => {
                              axios
                                .post(
                                  "/open/setmeal/wxpush/getBatchInfoByWx",
                                  applyListInfo,
                                  {
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                .then((res) => {
                                  if (res) {
                                    applyListInfo.id = res.id;
                                    this.setState({
                                      applyListInfo,
                                    });
                                    Toast.info("保存成功！", 1);
                                  }
                                })
                                .catch(() => {
                                  Toast.info("保存失败！", 1);
                                });
                            }
                          );
                        }
                      });
                  }}
                >
                  保存
                </span>
              ) : null}
              {this.state.applyListInfo.status === "Draft_Save" ? (
                <span
                  className="submit"
                  onClick={() => {
                    this.setState({
                      submit: true,
                    });
                    const submitVerification = this.submitVerification();
                    // if (!submitVerification ) {
                    //   Toast.info("请填写完整信息！", 2);
                    //   return;
                    // }
                    let applyListInfo = this.state.applyListInfo;
                    if (!applyListInfo.salesBarCode) {
                      this.setState({
                        salesBarCodeError: true,
                      });
                      Toast.info("申请单条码不能为空！", 2);
                      return;
                    }
                    if (applyListInfo.idNumber.length != 18) {
                      Toast.info("请填写18位身份证号！", 2);
                      return;
                    }

                    if (!applyListInfo.reportPhone || applyListInfo.reportPhone.length != 11) {
                      Toast.info("请填写11位接收人电话！", 2);
                      return;
                    }
                    let salesBarCode = applyListInfo.salesBarCode || "-1";
                    axios
                    .get(
                      `/open/setmeal/b/${applyListInfo.id ? applyListInfo.id : "-1"
                      }/s/${salesBarCode}`
                    )
                    .then((res) => {
                      if (!res) {
                        this.setState({
                          salesBarCodeError: true,
                        });
                      } else {
                        this.setState(
                          {
                            salesBarCodeError: false,
                          },
                          () => {
                          
                            Toast.loading("正在保存", 0);
                            applyListInfo.submitFlag = 1;
                           this.setState({
                               applyListInfo,
                              });
                            axios
                              .post(
                                 "/open/setmeal/wxpush/getBatchInfoByWx",
                                 applyListInfo,
                                {
                                   headers: {
                                 "Content-Type": "application/json",
                                 },
                               }
                             )
                              .then((res) => {
                                if (!res) return;
                                this.props.history.push({
                                 pathname: "/discount",
                                  query: {
                                    ...res,
                                    ...res.batchinfodto,
                                    needAdd: true,
                                  },
                                });
                                this.setRedux();
                              })
                              .catch(() => {
                                Toast.info("保存失败！", 2);
                              });
                          }
                        );
                      }
                    });
                }}
              >
              特殊申请
              </span>
              ) : null}
              {this.state.applyListInfo.status === "Draft_Save" ||
                this.state.applyListInfo.status === "Approval" ? (
                <span
                  className="submit"
                  onClick={() => {
                    this.setState({
                      submit: true,
                    });
                    const submitVerification = this.submitVerification();
                    // if (!submitVerification) {
                    //   Toast.info("请填写完整信息！", 2);
                    //   return;
                    // }
                    let applyListInfo = this.state.applyListInfo;
                    if (!applyListInfo.salesBarCode) {
                      this.setState({
                        salesBarCodeError: true,
                      });
                      Toast.info("申请单条码不能为空！", 2);
                      return;
                    }
                    if (!applyListInfo.idNumber) {
                      Toast.info("身份证号码不能为空！", 2);
                      return;
                    }
                    if (applyListInfo.discountApplyStatus === 'Discount_Approve') {
                      Toast.info("当前订单处于折扣申请流程中！", 4);
                      return;
                    }
                    let salesBarCode = applyListInfo.salesBarCode || "-1";
                    Toast.loading("正在结算", 0);
                    axios
                      .get(
                        `/open/setmeal/b/${applyListInfo.id ? applyListInfo.id : "-1"
                        }/s/${salesBarCode}`
                      )
                      .then((res) => {
                        if (!res) {
                          this.setState({
                            salesBarCodeError: true,
                          });
                        } else {
                          this.setState(
                            {
                              salesBarCodeError: false,
                            },
                            () => {
                              if (
                                !applyListInfo.sampleInfos ||
                                !applyListInfo.sampleInfos.length
                              ) {
                                this.setState({
                                  sampleInfosError: true,
                                });
                              }
                              if (
                                !applyListInfo.inspectionItems ||
                                !applyListInfo.inspectionItems.length
                              ) {
                                this.setState({
                                  inspectionItemsError: true,
                                });
                              }

                              this.setState({
                                applyListInfo,
                              });
                              applyListInfo.submitFlag = 1;
                              axios
                                .post(
                                  "/open/setmeal/wxpush/getBatchInfoByWx",
                                  applyListInfo,
                                  {
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                .then((res) => {
                                  if (!res) return;
                                  this.props.history.push({
                                    pathname: "/payment",
                                    query: {
                                      ...res.batchinfodto,
                                      needAdd: true,
                                    },
                                  });
                                  this.setRedux();
                                })
                                .catch(() => {
                                  Toast.info("结算失败！", 3);
                                });
                            }
                          );
                        }
                      });
                  }}
                >
                  结算
                </span>
              ) : null}
              {this.state.applyListInfo.action === "pushToLab" ? (<span
                className="submit"
                onClick={(e) => {
                  this.setState({
                    submit: true,
                  });
                   const submitVerification = this.submitVerification();

                   if (!submitVerification) {
                     Toast.info("请从修改按钮进入详情页面填写完整信息！", 5);
                     return;
                   }
                  let applyListInfo = this.state.applyListInfo;

                  if (!applyListInfo.tumourList || applyListInfo.tumourList.length === 0) {
                    Toast.info("肿瘤类型不能为空！", 3);
                    return;
                  }
                   if (!applyListInfo.sampleCollectComplete || applyListInfo.sampleCollectComplete !== '1') {
                     Toast.info("当前订单样本没有采集完毕！", 3);
                     return;
                   }
                   if (applyListInfo.discountApplyStatus === 'Discount_Approve') {
                     Toast.info("当前订单处于折扣申请流程中！", 4);
                     return;
                   }

                   if (this.state.applyListInfo.familyHistory === "肿瘤") {
                    var fh = this.state.applyListInfo.familyHistoryInfo;
                    if(!fh[0].otherTumorType){
                      Toast.info("家族史信息不能为空！", 3);
                      return;
                    }
                  }

  
                  if (applyListInfo.payStatus !== 20) {
                    Toast.info("请完成支付", 3);
                    return;
                  }
                  alert("提示", "请确认已完成支付", [
                    { text: "否" },
                    {
                      text: "是",
                      onPress: () => {
                        Toast.loading("正在发送实验室", 0);
                        axios
                          .post(
                            "/open/setmeal/wxpush/batches/action/push",
                            applyListInfo,
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                           }
                          )
                          .then((res) => {
                            if (res && res.return_code === '200') {
                              Toast.info("发送实验室成功！", 2, () => {
                                this.props.history.push({
                                  pathname: "/createApplication",
                                });
                              });
                            } else {
                              Toast.info( res.return_msg, 2);
                            }
                          })
                          .catch(() => {
                            Toast.info("发送实验室失败！", 2);
                          });
                      },
                    },
                  ]);
                }}
                style={{ backgroundColor: "rgb(255,107,45" }}
              >
                发送实验室
              </span>) : null}

            </div>
            <Modal
              popup
              visible={this.state.addressModal}
              onClose={() => {
                this.setState({ addressModal: false, address: this.address });
              }}
              animationType="slide-up"
            >
              <div className="applyListInfo-addressModal">
                <div className="head">
                  <div
                    onClick={() => {
                      this.setState({
                        addressModal: false,
                        address: this.address,
                      });
                    }}
                    className="close"
                  >
                    <span>取消</span>
                  </div>
                  <span>选择地址</span>
                  <div
                    className="close"
                    onClick={() => {
                      let applyListInfo = this.state.applyListInfo;
                      applyListInfo.address = [];
                      this.state.address &&
                        this.state.address.forEach((v) => {
                          if (v.checked) {
                            applyListInfo.address.push(v);
                          }
                        });
                      this.setState({
                        applyListInfo,
                        addressModal: false,
                      });
                    }}
                  >
                    <span
                      style={{
                        justifyContent: "flex-end",
                        marginRight: ".15rem",
                      }}
                    >
                      确定
                    </span>
                  </div>
                </div>
                <div className="center">
                  {this.state.address &&
                    this.state.address.map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="item"
                          onClick={() => {
                            let address = this.state.address.map((value) => {
                              if (v === value) {
                                value.checked = v.checked ? false : true;
                              }
                              return value;
                            });
                            this.setState({
                              address,
                            });
                          }}
                        >
                          <img
                            src={
                              v.checked
                                ? require("../img/selectm(2).png")
                                : require("../img/selectm(1).png")
                            }
                            alt=""
                          ></img>
                          <div className="right">
                            <div className="content">
                              <div className="info">
                                <span>{v.consigneeName}</span>
                                <span>{v.receivingPhone}</span>
                              </div>
                              <span className="address">{v.location}</span>
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.currentModificationAddress = v;
                                this.setState({
                                  addAddressModal: true,
                                });
                              }}
                              className="update"
                            >
                              <img src={require("../img/xg.png")} alt=""></img>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="foot">
                  <div
                    onClick={() => {
                      this.setState({
                        addAddressModal: true,
                      });
                    }}
                    className="content"
                  >
                    <img alt="" src={require("../img/tj.svg")}></img>
                    <span>新增地址</span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal visible={this.state.addAddressModal}>
              <div className="applyListInfo-add-addressModal">
                <NavBar
                  mode="light"
                  onClick={() => {
                    this.setState(
                      {
                        addAddressModal: false,
                      },
                      () => {
                        this.currentModificationAddress = null;
                      }
                    );
                  }}
                  icon={<Icon type="left" />}
                >
                  新增地址
                </NavBar>
                <div className="center">
                  <List>
                    <InputItem
                      placeholder="请填写报告接收人"
                      ref={(v) => (this.reportReceiver = v)}
                      error={
                        this.state.consigneeNameError &&
                        !this.reportReceiver.state.value
                      }
                      onChange={(el) => {
                        this.setState({
                          consigneeNameError: false,
                        });
                      }}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.consigneeName) ||
                        (this.state.agentInfo &&
                          this.state.agentInfo.personName)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ color: "red" }}>*</span>
                        报告接收人
                      </div>
                    </InputItem>
                    <InputItem
                      type="number"
                      placeholder="请填写收件人电话"
                      error={
                        this.state.currentModificationAddressError &&
                        !this.receivingPhone.state.value
                      }
                      ref={(v) => (this.receivingPhone = v)}
                      onChange={() => {
                        this.setState({
                          currentModificationAddressError: false,
                        });
                      }}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.receivingPhone) ||
                        (this.state.agentInfo && this.state.agentInfo.mobile)
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      收件人电话
                    </InputItem>
                    <TextareaItem
                      placeholder="详细地址,例1层101室"
                      ref={(ref) => {
                        this.houseNumber = ref;
                      }}
                      error={
                        this.state.houseNumberError &&
                        !this.houseNumber.state.value
                      }
                      onChange={() => {
                        this.setState({
                          houseNumberError: false,
                        });
                      }}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.location) ||
                        (this.state.agentInfo &&
                          this.state.agentInfo.contactadress)
                      }
                      rows={3}
                      title={
                        <div>
                          <span style={{ color: "red" }}>*</span>
                          <span>收件人地址</span>
                        </div>
                      }
                    ></TextareaItem>
                    <InputItem
                      placeholder="请填写邮寄份数"
                      ref={(v) => (this.mailCopies = v)}
                      defaultValue={
                        this.currentModificationAddress &&
                        this.currentModificationAddress.mailCopies
                      }
                      type="number"
                      extra="份"
                    >
                      邮寄份数
                    </InputItem>
                    <TextareaItem
                      placeholder="请填写邮寄备注"
                      ref={(ref) => {
                        this.mailingNotes = ref;
                      }}
                      defaultValue={
                        this.currentModificationAddress &&
                        this.currentModificationAddress.mailingNotes
                      }
                      rows={3}
                      title="邮寄备注"
                    ></TextareaItem>
                    <InputItem
                      placeholder="请填写电子邮箱"
                      ref={(v) => (this.email = v)}
                      defaultValue={
                        (this.currentModificationAddress &&
                          this.currentModificationAddress.email) ||
                        (this.state.agentInfo && this.state.agentInfo.email)
                      }
                    >
                      电子邮箱
                    </InputItem>
                  </List>
                  <div className="submit">
                    <span
                      onClick={() => {
                        let address = this.state.address || [];
                        if (
                          !(
                            this.receivingPhone.state.value &&
                            this.consigneeName.state.value &&
                            this.houseNumber.state.value
                          )
                        ) {
                          Toast.info("请填写完整信息！", 1);
                          this.setState({
                            consigneeNameError: true,
                            houseNumberError: true,
                            currentModificationAddressError: true,
                          });
                          return;
                        }
                        if (this.currentModificationAddress) {
                          address = address.map((v, k) => {
                            if (v === this.currentModificationAddress) {
                              v = {
                                receivingPhone: this.receivingPhone.state.value,
                                consigneeName: this.consigneeName.state.value,
                                location: this.houseNumber.state.value,
                                email: this.email.state.value,
                                mailingNotes: this.mailingNotes.state.value,
                                mailCopies: this.mailCopies.state.value,
                                checked: v.checked,
                              };
                            }
                            return v;
                          });
                        } else {
                          address.push({
                            receivingPhone: this.receivingPhone.state.value,
                            consigneeName: this.consigneeName.state.value,
                            location: this.houseNumber.state.value,
                            email: this.email.state.value,
                            mailingNotes: this.mailingNotes.state.value,
                            mailCopies: this.mailCopies.state.value,
                          });
                          this.address.push({
                            receivingPhone: this.receivingPhone.state.value,
                            consigneeName: this.consigneeName.state.value,
                            location: this.houseNumber.state.value,
                            email: this.email.state.value,
                            mailingNotes: this.mailingNotes.state.value,
                            mailCopies: this.mailCopies.state.value,
                          });
                        }
                        this.setState(
                          {
                            address,
                            addAddressModal: false,
                          },
                          () => {
                            this.currentModificationAddress = null;
                          }
                        );
                      }}
                    >
                      保存地址
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              popup
              visible={this.state.diagnosisModal}
              onClose={() => {
                this.setState({
                  diagnosisModal: false,
                  [this.state.modalType]: this[this.state.modalType],
                });
              }}
              animationType="slide-up"
            >
              <div className="applyListInfo-diagnosisModal">
                <div className="head">
                  <div
                    onClick={() => {
                      this.setState({
                        diagnosisModal: false,
                        [this.state.modalType]: this[this.state.modalType],
                      });
                    }}
                    className="close"
                  >
                    <span>取消</span>
                  </div>
                  <span>既往治疗药物</span>
                  <div
                    className="close"
                    onClick={() => {
                      let applyListInfo = this.state.applyListInfo;
                      applyListInfo[this.state.modalType] = [];
                      this.state[this.state.modalType] &&
                        this.state[this.state.modalType].forEach((v) => {
                          if (v.checked) {
                            applyListInfo[this.state.modalType].push(v);
                          }
                        });
                      this.setState({
                        applyListInfo,
                        diagnosisModal: false,
                      });
                    }}
                  >
                    <span
                      style={{
                        justifyContent: "flex-end",
                        marginRight: ".15rem",
                      }}
                    >
                      确定
                    </span>
                  </div>
                </div>
                <div className="center">
                  {this.state[this.state.modalType] &&
                    this.state[this.state.modalType].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="item"
                          onClick={() => {
                            let data = this.state[this.state.modalType].map(
                              (value) => {
                                if (v === value) {
                                  value.checked = v.checked ? false : true;
                                }
                                return value;
                              }
                            );
                            this.setState({
                              [this.state.modalType]: data,
                            });
                          }}
                        >
                          <img
                            src={
                              v.checked
                                ? require("../img/selectm(2).png")
                                : require("../img/selectm(1).png")
                            }
                            alt=""
                          ></img>
                          <div className="right">
                            <div className="content">
                              {v.medicinePlan ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    用药方案：
                                  </span>
                                  <span className="content-lable">
                                    {v.medicinePlan}
                                  </span>
                                </div>
                              ) : null}
                              {v.medicineStartTime ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    用药开始时间：
                                  </span>
                                  <span className="content-lable">
                                    {v.medicineStartTime}
                                  </span>
                                </div>
                              ) : null}
                             {v.medicineEndTime ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    用药结束时间：
                                  </span>
                                  <span className="content-lable">
                                    {v.medicineEndTime}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.treatmentInfo = v;
                                this.setState({
                                  diagnosisAddModal: true,
                                  medicineStartTime: v.medicineStartTime,
                                  medicineEndTime: v.medicineEndTime,
                                });
                              }}
                              className="update"
                            >
                              <img src={require("../img/xg.png")} alt=""></img>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="foot">
                  <div
                    onClick={() => {
                      this.setState({
                        diagnosisAddModal: true,
                      });
                    }}
                    className="content"
                  >
                    <img alt="" src={require("../img/tj.svg")}></img>
                    <span>新增信息</span>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal visible={this.state.diagnosisAddModal}>
              <div className="applyListInfo-add-diagnosisModal">
                <NavBar
                  mode="light"
                  onClick={() => {
                    this.setState(
                      {
                        diagnosisAddModal: false,
                        medicineStartTime: null,
                        medicineEndTime: null,
                      },
                      () => {
                        this.treatmentInfo = null;
                      }
                    );
                  }}
                  icon={<Icon type="left" />}
                >
                  新增既往治疗药物
                </NavBar>
                <div
                  className="center"
                  style={{
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                  }}
                >
                  <List>
                    <InputItem
                      placeholder="请填写用药方案"
                      ref={(ref) => {
                        this.medicinePlan = ref;
                      }}
                      defaultValue={
                        this.treatmentInfo && this.treatmentInfo.medicinePlan
                      }
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            medicineError: false,
                          });
                        }
                      }}
                      maxLength={256}
                      error={
                        this.state.medicineError && !this.medicine.state.value
                      }
                    >
                      用药方案
                    </InputItem>
                    <DatePicker
                      mode="date"
                      minDate={new Date(1900, 0, 1, 0, 0, 0)}
                      maxDate={new Date()}
                      value={
                        this.state.medicineStartTime &&
                        new Date(this.state.medicineStartTime)
                      }
                      onOk={(val) => {
                        const medicineStartTime = val;
                        this.setState({
                          medicineStartTime,
                        });
                      }}
                    >
                      <List.Item arrow="horizontal">
                        用药开始时间
                      </List.Item>
                    </DatePicker>
                    <DatePicker
                      mode="date"
                      minDate={new Date(1900, 0, 1, 0, 0, 0)}
                      maxDate={new Date()}
                      value={
                        this.state.medicineEndTime &&
                        new Date(this.state.medicineEndTime)
                      }
                      onOk={(val) => {
                        const medicineEndTime = val;
                        this.setState({
                          medicineEndTime,
                        });
                      }}
                    >
                      <List.Item arrow="horizontal">
                        用药结束时间
                      </List.Item>
                    </DatePicker>
                  </List>
                  <div className="submit">
                    <span
                      onClick={() => {
                        let hisDrugs =
                          this.state[this.state.modalType] || [];
                        if (this.treatmentInfo) {
                          hisDrugs = hisDrugs.map((v, k) => {
                            if (v === this.treatmentInfo) {
                              v = {
                                medicinePlan: this.medicinePlan.state.value,
                                medicineStartTime: this.formatTime(
                                  this.state.medicineStartTime
                                ),
                                medicineEndTime: this.formatTime(
                                  this.state.medicineEndTime
                                ),
                                checked: v.checked,
                              };
                            }
                            return v;
                          });
                        } else {
                          hisDrugs.push({
                            medicinePlan: this.medicinePlan.state.value,
                            medicineStartTime: this.formatTime(
                              this.state.medicineStartTime
                            ),
                            medicineEndTime: this.formatTime(
                              this.state.medicineEndTime
                            ),
                          });
                          this[this.state.modalType].push({
                            medicinePlan: this.medicinePlan.state.value,
                            medicineStartTime: this.formatTime(
                              this.state.medicineStartTime
                            ),
                            medicineEndTime: this.formatTime(
                              this.state.medicineEndTime
                            ),
                          });
                        }
                        this.setState(
                          {
                            [this.state.modalType]: hisDrugs,
                            diagnosisAddModal: false,
                            medicineStartTime: null,
                            medicineEndTime: null,
                          },
                          () => {
                            this.treatmentInfo = null;
                          }
                        );
                      }}
                    >
                      保存信息
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              popup
              visible={this.state.familyHistoryModal}
              onClose={() => {
                this.setState({
                  familyHistoryModal: false,
                  familyHistoryInfo: this.familyHistoryInfoDak,
                });
              }}
              animationType="slide-up"
            >
              <div className="applyListInfo-diagnosisModal">
                <div className="head">
                  <div
                    onClick={() => {
                      this.setState({
                        familyHistoryModal: false,
                        familyHistoryInfo: this.familyHistoryInfoDak,
                      });
                    }}
                    className="close"
                  >
                    <span>取消</span>
                  </div>
                  <span>家族史信息</span>
                  <div
                    className="close"
                    onClick={() => {
                      let applyListInfo = this.state.applyListInfo;
                      applyListInfo.familyHistoryInfo = [];
                      this.state.familyHistoryInfo &&
                        this.state.familyHistoryInfo.forEach((v) => {
                          if (v.checked) {
                            applyListInfo.familyHistoryInfo.push(v);
                          }
                        });
                      this.setState({
                        applyListInfo,
                        familyHistoryModal: false,
                      });
                    }}
                  >
                    <span
                      style={{
                        justifyContent: "flex-end",
                        marginRight: ".15rem",
                      }}
                    >
                      确定
                    </span>
                  </div>
                </div>
                <div className="center">
                  {this.state.familyHistoryInfo &&
                    this.state.familyHistoryInfo.map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="item"
                          onClick={() => {
                            let familyHistoryInfo = this.state.familyHistoryInfo.map(
                              (value) => {
                                if (v === value) {
                                  value.checked = v.checked ? false : true;
                                }
                                return value;
                              }
                            );
                            this.setState({
                              familyHistoryInfo,
                            });
                          }}
                        >
                          <img
                            src={
                              v.checked
                                ? require("../img/selectm(2).png")
                                : require("../img/selectm(1).png")
                            }
                            alt=""
                          ></img>
                          <div className="right">
                            <div className="content">
                              <div className="content-item">
                                <span className="content-title">
                                  患肿瘤亲属：
                                </span>
                                <span className="content-lable">
                                  {v.patientRelationship}
                                </span>
                              </div>
                              <div className="content-item">
                                <span className="content-title">
                                  肿瘤类型：
                                </span>
                                <span className="content-lable">
                                  {v.otherTumorType}
                                </span>
                              </div>
                              {v.otherBreastCancerType ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    乳腺癌类型：
                                  </span>
                                  <span className="content-lable">
                                    {v.otherBreastCancerType}
                                  </span>
                                </div>
                              ) : null}
                              {v.otherPatientAge ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    确诊年龄：
                                  </span>
                                  <span className="content-lable">
                                    {v.otherPatientAge}
                                  </span>
                                </div>
                              ) : null}
                              {v.otherClinicalStages ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    临床分期：
                                  </span>
                                  <span className="content-lable">
                                    {v.otherClinicalStages}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.familyHistoryInfo = v;
                                this.setState({
                                  familyHistoryAddModal: true,
                                });
                              }}
                              className="update"
                            >
                              <img src={require("../img/xg.png")} alt=""></img>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="foot">
                  <div
                    onClick={() => {
                      this.setState({
                        familyHistoryAddModal: true,
                      });
                    }}
                    className="content"
                  >
                    <img alt="" src={require("../img/tj.svg")}></img>
                    <span>新增信息</span>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal visible={this.state.familyHistoryAddModal}>
              <div className="applyListInfo-add-diagnosisModal">
                <NavBar
                  mode="light"
                  onClick={() => {
                    this.setState(
                      {
                        familyHistoryAddModal: false,
                      },
                      () => {
                        this.familyHistoryInfo = null;
                      }
                    );
                  }}
                  icon={<Icon type="left" />}
                >
                  新增家族史信息
                </NavBar>
                <div className="center">
                  <List>
                    <InputItem
                      placeholder="患肿瘤亲属"
                      maxLength={60}
                      ref={(ref) => {
                        this.patientRelationship = ref;
                      }}
                      defaultValue={
                        this.familyHistoryInfo &&
                        this.familyHistoryInfo.patientRelationship
                      }
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            familyHistoryInfoSubmit: false,
                          });
                        }
                      }}
                      error={
                        this.state.familyHistoryInfoSubmit &&
                        !this.patientRelationship.state.value
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      患肿瘤亲属
                    </InputItem>
                    <InputItem
                      placeholder="亲属确诊肿瘤类型"
                      maxLength={60}
                      error={
                        this.state.familyHistoryInfoSubmit &&
                        !this.otherTumorType.state.value
                      }
                      ref={(v) => (this.otherTumorType = v)}
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            familyHistoryInfoSubmit: false,
                          });
                          if (el.indexOf('乳腺癌') > -1) {
                            this.state.tumorTypeFlag = '1'
                          }
                        }
                      }}
                      defaultValue={
                        this.familyHistoryInfo &&
                        this.familyHistoryInfo.otherTumorType
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      肿瘤类型
                    </InputItem>
                    <InputItem
                      placeholder="亲属确诊年龄"
                      extra="岁"
                      type="number"
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            familyHistoryInfoSubmit: false,
                          });
                        }
                      }}
                      ref={(v) => (this.otherPatientAge = v)}
                      defaultValue={
                        this.familyHistoryInfo &&
                        this.familyHistoryInfo.otherPatientAge
                      }
                    >
                      亲属确诊年龄
                    </InputItem>

                    <InputItem
                      placeholder="临床分期"
                      type="text"
                      onChange={(el) => {
                        if (el) {
                          this.setState({
                            familyHistoryInfoSubmit: false,
                          });
                        }
                      }}
                      ref={(v) => (this.otherClinicalStages = v)}
                      defaultValue={
                        this.familyHistoryInfo &&
                        this.familyHistoryInfo.otherClinicalStages
                      }
                    >
                      临床分期
                    </InputItem>
                  </List>
                  <div className="submit">
                    <span
                      onClick={() => {
                        this.setState({
                          familyHistoryInfoSubmit: true,
                        });
                        if (
                          this.patientRelationship.state.value &&
                          this.otherTumorType.state.value
                        ) {
                          let familyHistoryInfo =
                            this.state.familyHistoryInfo || [];
                          if (this.familyHistoryInfo) {
                            familyHistoryInfo = familyHistoryInfo.map(
                              (v, k) => {
                                if (v === this.familyHistoryInfo) {
                                  v = {
                                    patientRelationship: this.patientRelationship.state.value,
                                    otherPatientAge: this.otherPatientAge.state.value,
                                    otherTumorType: this.otherTumorType.state.value,
                                    otherClinicalStages: this.otherClinicalStages.state.value,
                                    // otherBreastCancerType: this.state.applyListInfo.otherBreastCancerType,
                                    ageUnit: "岁",
                                    checked: v.checked,
                                  };
                                }
                                return v;
                              }
                            );
                          } else {
                            familyHistoryInfo.push({
                              patientRelationship: this.patientRelationship.state.value,
                              otherPatientAge: this.otherPatientAge.state.value,
                              otherTumorType: this.otherTumorType.state.value,
                              otherClinicalStages: this.otherClinicalStages.state.value,
                              // otherBreastCancerType: this.state.applyListInfo.otherBreastCancerType,
                              ageUnit: "岁",
                            });
                            this.familyHistoryInfoDak.push({
                              patientRelationship: this.patientRelationship.state.value,
                              otherPatientAge: this.otherPatientAge.state.value,
                              ageUnit: "岁",
                              otherTumorType: this.otherTumorType.state.value,
                              otherClinicalStages: this.otherClinicalStages.state.value,
                              // otherBreastCancerType: this.state.applyListInfo.otherBreastCancerType,
                            });
                          }
                          this.setState(
                            {
                              familyHistoryInfo,
                              familyHistoryAddModal: false,
                            },
                            () => {
                              this.familyHistoryInfo = null;
                            }
                          );
                        } else {
                          Toast.info("请填写完整信息", 1);
                        }
                      }}
                    >
                      保存信息
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </Drawer>
        {this.state.showImage ? (
          <div
            className="show-image"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              zIndex: "999",
              top: "0",
              left: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            id="video"
            onClick={() => this.setState({ showImage: false })}
          >
            <img
              alt=""
              style={{ width: "100%" }}
              src={this.state.uploadImage}
            ></img>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.applyListInfoReducer,
  };
}

export default connect(mapStateToProps, { applyListInfoAction })(SendLab);
