

import React from "react";
import {
  InputItem,
  NavBar,
  Icon,
  List,
  Toast,
  Picker,
  TextareaItem,
} from "antd-mobile";
import axios from "axios";
import "./style/discount.css";
import { discountAction } from "../../action/discount/discount";
import { connect } from "react-redux";


class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.batchinfo = {
      discount: "",
      applyReason: "",
    }
    const params =
      (this.props.location && this.props.location.query && this.props.location.query.batchinfodto) || props || {};

    const batchinfodto = Object.assign({}, this.batchinfo, this.props.location && this.props.location.query && this.props.location.query.batchinfodto);
    this.state = {
      batchinfodto: batchinfodto,
      id: batchinfodto.id || props.id || "",
      salesBarCode: params.salesBarCode || props.salesBarCode || "",
      status: params.status || "",
      patientName: params.patientName || props.patientName || "",
      caseNumber: params.caseNumber || props.caseNumber || "",
      idNumber: params.idNumber || "",
      v: this.props.location.query || props.v,
      inspectionItems: params.inspectionItems || props.inspectionItems || "",
      discount: params.discount || props.discount || "",
      applyReason: params.applyReason || props.applyReason || "",
    };
    this.check = ["discount"];
  }

  setRedux = () => {
    const reduxData = [
      "patientName",
      "salesBarCode",
      "idNumber",
      "v",
      "status",
      "discount",
      "applyReason",
      "needAdd",
    ];
    for (let pop in reduxData) {
      this.props.discountAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  dateFormat = (fmt, date) => {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  };

  clearRedux = () => {
    const reduxData = [
      "patientName",
      "salesBarCode",
      "status",
      "idNumber",
      "discount",
      "applyReason",
      "v",
    ];
    for (let pop in reduxData) {
      this.props.discountAction({
        type: reduxData[pop],
        data: null
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location
    ) {
      this.clearRedux();
    }
  }

  componentDidMount() {
    this.getDiscountSelectData();
  }

  inputChange = (v, k) => {
    let batchinfodto = this.state.batchinfodto;
    batchinfodto[k] = v;
    this.setState({
      batchinfodto: batchinfodto,
    });
  };

  getDiscountSelectData = () => {
    axios
      .get("/open/basedata/dict/discount")
      .then((res) => {
        if (res && res.data) {
          var data = [];
          res.data.map(function (item) {
            data.push({
              value: item.dictTextCode,
              label: item.dictTextName,
            })
          })
          this.setState({
            // treatmentMethod: res.data,
            discountSelectData: data,
          });
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  render() {
    return (
      <div className="discount">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <NavBar
            mode="light"
            icon={<Icon type="left" />}
            onLeftClick={this.goBack}
          >
            折扣申请
        </NavBar>
          <div className="center">
            <div style={{ width: "100%" }}>
              <List>
                <InputItem
                  extra={
                    <img
                      style={{ marginLeft: "0.1rem" }}
                      src={require("../img/sm.png")}
                      alt=""
                    ></img>
                  }
                  disabled={true}

                  defaultValue={this.state.salesBarCode || ""}
                  onChange={(val) => {
                    this.setState({
                      salesBarCode: val,
                      salesRepresentative: "",
                    });
                  }}
                  error={
                    (this.state.submit && !this.state.salesBarCode) ||
                    this.state.salesBarCodeError
                  }
                >
                  <span style={{ color: "red" }}>*</span>
              申请单条码
            </InputItem>
                <InputItem
                  error={this.state.submit && !this.state.patientName}
                  defaultValue={this.state.patientName || ""}
                  onChange={(val) => {
                    this.setState({
                      patientName: val,
                    });
                  }}
                  disabled={true}
                >
                  <span style={{ color: "red" }}>*</span>
              姓名
            </InputItem>
                <InputItem
                  error={this.state.submit && !this.state.caseNumber}
                  defaultValue={this.state.caseNumber || ""}
                  onChange={(val) => {
                    this.setState({
                      caseNumber: val,
                    });
                  }}
                  disabled={true}
                >
                  {/* <span style={{ color: "red" }}>*</span> */}
              病案号
            </InputItem>
                <InputItem
                  ref={(el) => (this.idNumber = el)}
                  type="text"
                  maxLength={18}
                  value={this.state.idNumber}
                  onChange={(el) => {
                    this.setState({
                      idNumber: el,
                    });
                  }}
                  disabled={true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onClick={() => this.idNumber.focus()}
                  >
                    身份证号
              </div>
                </InputItem>
                <List.Item
                  extra="请选择"
                  arrow="horizontal"
                  disabled={true}
                >
                  {true ? <span style={{ color: "red" }}>*</span> : null}
              检测项目
            </List.Item>
                {this.state.inspectionItems &&
                  this.state.inspectionItems.length ? (
                  <List.Item multipleLine>
                    {this.state.inspectionItems
                      .map((v, k) => {
                        return (
                          <div key={k} className="select-info2">
                            <img
                              src={require("../img/xx.png")}
                              alt=""
                            ></img>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                              }}
                            >
                              {v.productName ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    检测项目：
                            </span>
                                  <span className="content-lable">
                                    {v.productName}
                                  </span>
                                </div>
                              ) : null}
                              {v.amount ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    价格：
                            </span>
                                  <span className="content-lable">
                                    {v.amount}
                                  </span>
                                </div>
                              ) : null}
                              {v.hisFlag ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    序惯检测：
                            </span>
                                  <span className="content-lable">
                                    {v.hisFlag === '1' ? ('是') : ('否')}
                                  </span>
                                </div>
                              ) : null}
                              {v.inHospitalFlag ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    院内检测：
                            </span>
                                  <span className="content-lable">
                                    {v.inHospitalFlag === '1' ? ('是') : ('否')}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                  </List.Item>
                ) : null}
                <Picker
                  onOk={(val) => {
                    let batchinfodto = this.state.batchinfodto;
                    batchinfodto.discount = val[0];
                    if (val[0] !== 'sequential') {
                      batchinfodto.hisTestNo = '';
                      batchinfodto.hisPatientName = '';
                      batchinfodto.hisHospital = '';
                      batchinfodto.hisCaseNumber = '';
                      batchinfodto.hisTestItem = '';
                    }
                    this.setState({
                      batchinfodto,
                    });
                  }}
                  value={[this.state.batchinfodto.discount]}
                  data={this.state.discountSelectData}
                  cols={1}

                >
                  <List.Item
                    arrow="horizontal"
                    error={this.state.submit && !this.state.batchinfodto.discount}
                  >
                    <span style={{ color: "red" }}>*</span>折扣
              </List.Item>
                </Picker>

                {this.state.batchinfodto.discount === "sequential" ? (
                  <InputItem
                    placeholder="请填写检测编号"
                    type="text"
                    value={this.state.batchinfodto.hisTestNo || ""}
                    onChange={(el) => {
                      let batchinfodto = this.state.batchinfodto;
                      batchinfodto.hisTestNo = el;
                      this.setState({
                        batchinfodto,
                      });
                    }}
                    error={
                      this.state.submit &&
                      !this.state.batchinfodto.hisTestNo
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      检测编号
                          </div>
                  </InputItem>
                ) : null}

                {this.state.batchinfodto.discount === "sequential" ? (
                  <InputItem
                    placeholder="请填写姓名"
                    type="text"
                    value={this.state.batchinfodto.hisPatientName || ""}
                    onChange={(el) => {
                      let batchinfodto = this.state.batchinfodto;
                      batchinfodto.hisPatientName = el;
                      this.setState({
                        batchinfodto,
                      });
                    }}
                    error={
                      this.state.submit &&
                      !this.state.batchinfodto.hisPatientName
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      姓名
                          </div>
                  </InputItem>
                ) : null}

                {this.state.batchinfodto.discount === "sequential" ? (
                  <InputItem
                    placeholder="请填写医院"
                    type="text"
                    value={this.state.batchinfodto.hisHospital || ""}
                    onChange={(el) => {
                      let batchinfodto = this.state.batchinfodto;
                      batchinfodto.hisHospital = el;
                      this.setState({
                        batchinfodto,
                      });
                    }}
                    error={
                      this.state.submit &&
                      !this.state.batchinfodto.hisHospital
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      医院
                          </div>
                  </InputItem>
                ) : null}

                {this.state.batchinfodto.discount === "sequential" ? (
                  <InputItem
                    placeholder="请填写病案号"
                    type="text"
                    value={this.state.batchinfodto.hisCaseNumber || ""}
                    onChange={(el) => {
                      let batchinfodto = this.state.batchinfodto;
                      batchinfodto.hisCaseNumber = el;
                      this.setState({
                        batchinfodto,
                      });
                    }}
                    error={
                      this.state.submit &&
                      !this.state.batchinfodto.hisCaseNumber
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      病案号
                          </div>
                  </InputItem>
                ) : null}

                {this.state.batchinfodto.discount === "sequential" ? (
                  <InputItem
                    placeholder="请填写检测项目"
                    type="text"
                    value={this.state.batchinfodto.hisTestItem || ""}
                    onChange={(el) => {
                      let batchinfodto = this.state.batchinfodto;
                      batchinfodto.hisTestItem = el;
                      this.setState({
                        batchinfodto,
                      });
                    }}
                    error={
                      this.state.submit &&
                      !this.state.batchinfodto.hisTestItem
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      检测项目
                          </div>
                  </InputItem>
                ) : null}

                <TextareaItem
                  title={
                    <div>
                      <span style={{ color: "red" }}>*</span><span>申请原因</span>
                    </div>
                  }
                  rows={3}
                  maxLength={256}
                  onChange={(val) => {
                    let batchinfodto = this.state.batchinfodto;
                    batchinfodto.applyReason = val;
                    this.setState({
                      batchinfodto,
                    });
                  }}
                  value={this.state.batchinfodto.applyReason}
                  placeholder="请填写申请原因"
                ></TextareaItem>
              </List>

              <div className="foot">
                <span
                  className="submit"
                  onClick={() => {

                    if (!this.state.batchinfodto.discount) {
                      this.setState({
                        submit: true,
                      });
                      Toast.info("请填写折扣信息！", 2);
                      return;
                    }
                    if (!this.state.batchinfodto.applyReason) {
                      this.setState({
                        submit: true,
                      });
                      Toast.info("请填写申请原因！", 2);
                      return;
                    }
                    this.state.batchinfodto.id = this.state.v.id;
                    const requestParam = {
                      p: {},
                      b: ["lsiBatchServiceImpl", [this.state.batchinfodto]],
                    }
                    Toast.loading("正在提交", 0);

                    axios
                      .post(
                        `/open/setmeal/wxpush/${this.state.v.id}/applyDiscountByWx`,
                        requestParam,
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                      .then((res) => {
                        if (!res) return;
                        this.props.discountAction({
                          type: "page",
                          data: this.state.needAdd ? -3 : -2,
                        });
                        Toast.info("提交成功！", 2, () => {
                          this.props.history.push({
                            pathname: "/createApplication",
                          });
                        });
                      })
                      .catch(() => {
                        Toast.info("提交失败！", 1);
                      });

                  }}
                >
                  提交
            </span>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.discountReducer,
    page: state.applyListInfoReducer.page,
  };
}

export default connect(mapStateToProps, { discountAction })(Discount);
