import React from "react";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import "./style/inspectionRequestInquiry.css";
import { NavBar, Icon, InputItem } from "antd-mobile";

export default class InspectionRequestInquiry extends React.Component {
  constructor() {
    super();
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      startTime: startTime,
      endTime: new Date(),
      searchStr: "",
      loading: false,
      setMealData: [],
    };
  }

  getSetMealData = (searchStr) => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        "/open/basedata/inspection-requirements",
        { combo: searchStr },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (!res || !res.inspecrequire) return;
        this.setState({
          setMealData: res.inspecrequire,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  search = () => {
    this.getSetMealData(this.state.searchStr);
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${
      val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
    }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="inspectionRequestInquiry">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          送检要求查询
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div onClick={() => this.search()} className="search-bar-top-text">
              <span>搜索</span>
            </div>
          </div>
          {/* <div className="search-bar-bottom"> */}
          {/* <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(2000, 1, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(2000, 1, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div> */}
          {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          {/* </div> */}
        </div>
        {this.state.setMealData && this.state.setMealData.length ? (
          <div className="center">
            {this.state.setMealData.map((value, key) => {
              return (
                <div className="item" key={key}>
                  <div className="item-content" style={{alignItems:'center' }}>
                    <img alt="" src={require("../img/yb.svg")}></img>
                    <span>{value.combo}</span>
                  </div>
                  <div className="item-content">
                    <span
                      style={{
                        width: "0.5rem",
                        display: "flex",
                        flexShrink: "0",
                        flexGrow: "0",
                      }}
                    >
                      液体：
                    </span>
                    <div className="item-content-split">
                      {value.fluid &&
                        value.fluid.split("□").map((element, sk) => {
                          if (element) {
                            return <span key={sk}>□{element}</span>;
                          } else {
                            return null;
                          }
                        })}
                    </div>
                  </div>

                  <div className="item-content">
                    <span
                      style={{
                        width: "0.5rem",
                        display: "flex",
                        flexShrink: "0",
                        flexGrow: "0",
                      }}
                    >
                      组织：
                    </span>
                    <div className="item-content-split">
                      {value.tissue &&
                        value.tissue.split("□").map((element, sk) => {
                          if (element) {
                            return <span key={sk}>□{element}</span>;
                          } else {
                            return null;
                          }
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
