import React from "react";
import { NavBar, Icon, InputItem, DatePicker, Toast } from "antd-mobile";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import "./style/sampleDelivery.css";
import { connect } from "react-redux";
import { sampleDeliveryAction } from "../../action/logistics/sampleDelivery";

class SampleDelivery extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      applicationData: this.props.applicationData || [],
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      searchStr: this.props.searchStr || "",
      selectAll: false,
      active: false,
      selectData: [],
      loading: true,
      needSearch: props.needSearch || true,
    };
    this.applicationData = [];
  }

  setRedux = () => {
    const reduxData = ["startTime", "endTime", "searchStr", "needSearch"];
    for (let pop in reduxData) {
      this.props.sampleDeliveryAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["startTime", "endTime", "needSearch", "searchStr"];
    for (let pop in reduxData) {
      this.props.sampleDeliveryAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    const status = ["Waiting_For_Send"];
    axios
      .post("/open/setmeal/wxpush/selectSendingSample", status, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.batchinfodtos) return;
        this.applicationData = res.batchinfodtos;
        this.setState(
          {
            applicationData: res.batchinfodtos,
          },
          // () => {
          //   if (this.state.needSearch) {
          //     this.search();
          //   }
          // }
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  search = () => {
    let applicationData = [];
    const allData = JSON.parse(JSON.stringify(this.applicationData));

    if (allData && this.state.startTime && this.state.endTime) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );

      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );

      allData.forEach((element) => {
        if (element && element.createdTime) {
          const createdTime = parseInt(
            this.formatTime(element.createdTime && element.createdTime.substring(0, 10)).replace(/-/g, "")
          );

          if (createdTime <= endTime && createdTime >= startTime) {
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                const searchStr = this.state.searchStr || "";
                if (
                  element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1
                ) {
                  applicationData.push(element);
                  break;
                }
              }
            }
          }
        }
      });

      this.setState(
        {
          applicationData,
          selectData: [],
          active: false,
          selectAll: false,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      (this.props.history.location.pathname === "/" ||
        this.props.history.location.pathname === "/home")
    ) {
      this.clearRedux();
    }
  }

  render() {
    return (
      <div className="sampleDelivery">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          样本寄送
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div onClick={() => this.search()} className="search-bar-top-text">
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
            {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          </div>
        </div>
        {this.state.applicationData && this.state.applicationData.length ? (
          <>
            <div className="center">
              {this.state.applicationData.map((v, k) => {
                return (
                  <div
                    className="item"
                    onClick={() => {
                      const applicationData = this.state.applicationData.map(
                        (value) => {
                          if (value === v) {
                            value.check = !value.check;
                          }
                          return value;
                        }
                      );
                      this.setState(
                        {
                          applicationData,
                        },
                        () => {
                          this.setState({
                            selectAll:
                              applicationData.findIndex((v) => !v.check) < 0,
                            active:
                              applicationData.findIndex((v) => v.check) > -1,
                          });
                        }
                      );
                    }}
                    key={k}
                  >
                    <div className="check">
                      <img
                        alt=""
                        src={
                          v.check
                            ? require("../img/xz.svg")
                            : require("../img/wxz.svg")
                        }
                      ></img>
                    </div>
                    <div className="right">
                      <div className="title">
                        <img
                          className="logo"
                          alt=""
                          src={require("../img/ddbh.svg")}
                        ></img>
                        <span style={{ width: "70%" }}> {v.salesBarCode} </span>
                      </div>
                      <div className="content">
                        {v.patientName ? <span>{v.patientName}</span> : null}
                        {v.hospital ? <span>{v.hospital}</span> : null}
                        {v.ext$ && v.ext$.sampletypenames ? (
                          <span>{v.ext$.sampletypenames}</span>
                        ) : null}
                        {v.ext$ && v.ext$.combonames ? (
                          <span>{v.ext$.combonames}</span>
                        ) : null}
                        {v.createdTime ? (
                          <span>{v.createdTime.substring(0, 11)}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="foot">
              
              <span
                className="submit"
                style={{
                  backgroundColor: `${this.state.active ? " #EE4943" : "rgb(220,220,220)"
                    }`,
                }}
                onClick={() => {
                  if (!this.state.active) return;
                  this.setRedux();
                  const applicationData = this.state.applicationData;
                  const selections = applicationData.filter((item) => {
                    if (item.check) {
                      return item;
                    }
                    return false;
                  });
                  this.props.history.push({
                    pathname: "mailed",
                    query: selections,
                  });
                }}
              >
                已邮寄填单
              </span>
              <span
                className="submit"
                style={{
                  backgroundColor: `${this.state.active ? " #EE4943" : "rgb(220,220,220)"
                    }`,
                }}
                onClick={() => {
                  if (!this.state.active) return;
                  this.setRedux();
                  const applicationData = this.state.applicationData;
                  const selections = applicationData.filter((item) => {
                    if (item.check) {
                      return item;
                    }
                    return null;
                  });
                  this.props.history.push({
                    pathname: "deliveryInformation",
                    query: selections,
                  });
                }}
              >
                去寄送
              </span>
            </div>
          </>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.sampleDeliveryReducer,
  };
}

export default connect(mapStateToProps, { sampleDeliveryAction })(
  SampleDelivery
);
