export const paymentReducer = (state = {}, action) => {
  switch (action.type) {
    case "salesBarCode":
      return {
        ...state,
        salesBarCode: action.data,
      };
    case "patientName":
      return {
        ...state,
        patientName: action.data,
      };
    case "invoiceType":
      return {
        ...state,
        invoiceType: action.data,
      };
    case "invoiceTitle":
      return {
        ...state,
        invoiceTitle: action.data,
      };
    case "dutyParagraph":
      return {
        ...state,
        dutyParagraph: action.data,
      };
    case "money":
      return {
        ...state,
        money: action.data,
      };
    case "paymentType":
      return {
        ...state,
        paymentType: action.data,
      };
    case "czfs":
      return {
        ...state,
        czfs: action.data,
      };
    case "salesRepresentative":
      return {
        ...state,
        salesRepresentative: action.data,
      };
    case "v":
      return {
        ...state,
        v: action.data,
      };
    case "status":
      return {
        ...state,
        status: action.data,
      };
    case "paidRemark":
      return {
        ...state,
        paidRemark: action.data,
      };
    case "isMonthlyStatement":
      return {
        ...state,
        isMonthlyStatement: action.data,
      };
    case "page":
      return {
        ...state,
        page: action.data,
      };
    case "idNumber":
      return {
        ...state,
        idNumber: action.data,
      };
    default:
      return state;
  }
};
