import React from "react";
import { NavBar, Icon, Toast, InputItem, DatePicker } from "antd-mobile";
import axios from "axios";
import { connect } from "react-redux";
import "./style/orderInquiry.css";
import { orderInquiryAction } from "../../action/apply/orderInquiry";
import { BoxLoading } from "react-loadingg";

class OrderInquiry extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      orderData: this.props.orderData || [],
      searchStr: this.props.searchStr || "",
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      loading: true,
    };
    this.orderData = [];
  }

  search = () => {
    let orderData = [];
    const allData = JSON.parse(JSON.stringify(this.orderData));
    if (allData) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );
      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );
      allData.forEach((element) => {
        if (element && element.createdTime) {
          let createdTime = parseInt(
            this.formatTime(element.createdTime).replace(/-/g, "")
          );

          if (createdTime <= endTime && createdTime >= startTime) {
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                 const searchStr = this.state.searchStr || '';
                if (element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1) {
                  orderData.push(element);
                  break;
                }
              }
            }
          }
        }
      });
      this.setState(
        {
          orderData,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  setRedux = () => {
    const reduxData = ["searchStr", "orderData", "startTime", "endTime"];

    for (let pop in reduxData) {
      this.props.orderInquiryAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["searchStr", "orderData", "startTime", "endTime"];

    for (let pop in reduxData) {
      this.props.orderInquiryAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${
      val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
    }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  componentDidMount() {
    this.selectBatchByDraftSave();
  }

  selectBatchByDraftSave = () => {
    const status = ["Order_Inquiry"];
    axios
      .post("/open/setmeal/wxpush/selectBatchByDraftSave", status, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.batchinfodtos) return;
        this.orderData = res.batchinfodtos;
        this.state.orderData = res.batchinfodtos;
        this.setState({
          loading: false,
        });
      });
  };

  showDetail = (e, value) => {
    const data = this.state.orderData;
    let orderData = data.map((v) => {
      if (value === v) {
        v.open = value.open ? false : true;
      }
      return v;
    });
    this.setState({
      orderData,
    });
  };

  componentWillUnmount() {
    Toast.hide();
  }

  render() {
    return (
      <div className="orderInquiry">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          申请单查询
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div onClick={() => this.search()} className="search-bar-top-text">
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
            {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          </div>
        </div>
        {this.state.orderData && this.state.orderData.length ? (
          <div className="center">
            {this.state.orderData.map((v, k) => {
              return (
                <div
                  className="item"
                  onClick={() => {
                    this.setRedux();
                    this.props.history.push({
                      pathname: "applicationDetails",
                      query: v,
                    });
                  }}
                  key={k}
                >
                  <div className="item-row">
                    <div style={{ display: "flex",flexShrink:'0',flexGrow:'0',height:'.44rem',alignItems:'center' }}>
                      <img alt="" src={require("../img/ddbh.svg")}></img>
                      <span> {v.salesBarCode} </span>
                      {v.status === "Logged" ? (
                        <span  style={{ backgroundColor: "#57c3ff" }} className="item-status">检测中</span>
                      ) : null}
                    </div>
                    <div
                      className="title-pj"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setRedux();
                        this.props.history.push({
                          pathname: "viewProgress",
                          query: {
                            id: v.id,
                          },
                        });
                      }}
                    >
                      <span>查看进度</span>
                      <img
                        alt=""
                        style={{ margin: "0 0 0 0.07rem" }}
                        src={require("../img/right.svg")}
                      ></img>
                    </div>
                  </div>
                  <div className="content">
                  {v.patientName ? <span>{v.patientName}</span> : null}
                        {v.hospital ? <span>{v.hospital}</span> : null}
                        {v.sampleInfos && v.sampleInfos.length ? (
                          <span>
                            {(() => {
                              let sampleInfos = v.sampleInfos.map((element) => {
                                return (
                                  `(${element.sampleCode})` + element.sampleTypeName
                                );
                              });
                              return sampleInfos.join("，");
                            })()}
                          </span>
                        ) : null}
                        {v.selectSetMeals && v.selectSetMeals.length ? (
                          <span>
                            {(() => {
                              let selectSetMeals = v.selectSetMeals.map(
                                (element) => {
                                  return element.setMealName;
                                }
                              );
                              return selectSetMeals.join("，");
                            })()}
                          </span>
                        ) : null}
                        {v.createdTime ? <span>{v.createdTime}</span> : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.orderInquiryReducer,
  };
}

export default connect(mapStateToProps, { orderInquiryAction })(OrderInquiry);
