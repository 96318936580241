import React, { useState } from "react";
import { NavBar, Icon, InputItem, Toast } from "antd-mobile";
import md5 from "js-md5";
import axios from "axios";
import { createHashHistory } from "history";
import "./style/changePassword.css";
import { homeAction } from "../../action/home/home";
import { connect } from "react-redux";
const history = createHashHistory();

function ChangePassword(props) {
  const [type1, setType1] = useState(true);
  const [type2, setType2] = useState(true);
  const [type3, setType3] = useState(true);
  const [oldPassword, setPassword] = useState("");
  const [notice1, setNotice1] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [notice2, setNotice2] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [notice3, setNotice3] = useState("");

  function goBack() {
    props.history.goBack();
  }

  function clearRedux() {
    const reduxData = ["selectedTab"];
    for (let pop in reduxData) {
      props.homeAction({
        type: reduxData[pop],
        data: null,
      });
    }
  }

  return (
    <div className="changePassword">
      <NavBar mode="light" icon={<Icon type="left" />} onLeftClick={goBack}>
        修改密码
      </NavBar>
      <div className="center">
        <div className="center-input" style={{ marginTop: "0.5rem" }}>
          <InputItem
            type={type1 ? "password" : "text"}
            placeholder="原密码"
            onBlur={(e) => {
              if(!e){
                setNotice1("原密码不能为空！");
                return;
              }
              const password = localStorage.getItem("password");
              if (md5(oldPassword) !== password) {
                setNotice1("原密码输入错误！");
              } else {
                setNotice1("");
              }
            }}
            value={oldPassword}
            onChange={(el) => {
              setPassword(el.trim());
            }}
            extra={
              <img
                onClick={() => setType1(!type1)}
                alt=""
                src={
                  type1 ? require("../img/by1.svg") : require("../img/by2.svg")
                }
              ></img>
            }
          ></InputItem>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "5%",
            height: "0.4rem",
          }}
        >
          <span
            style={{
              color: "red",
              marginTop: "0.1rem",
              display: "flex",
              marginLeft: "0.05rem",
            }}
          >
            {notice1}
          </span>
        </div>
        <div className="center-input">
          <InputItem
            type={type2 ? "password" : "text"}
            placeholder="新的密码"
            onChange={(el) => setNewPassword1(el.trim())}
            onBlur={() => {
              if (!newPassword1) {
                setNotice2("新密码不能为空！");
              } else {
                setNotice2("");
              }
            }}
            value={newPassword1}
            extra={
              <img
                alt=""
                onClick={() => setType2(!type2)}
                src={
                  type2 ? require("../img/by1.svg") : require("../img/by2.svg")
                }
              ></img>
            }
          ></InputItem>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "5%",
            height: "0.4rem",
          }}
        >
          <span
            style={{
              color: "red",
              marginTop: "0.1rem",
              display: "flex",
              marginLeft: "0.05rem",
            }}
          >
            {notice2}
          </span>
        </div>
        <div className="center-input">
          <InputItem
            type={type3 ? "password" : "text"}
            placeholder="确认密码"
            onChange={(el) => setNewPassword2(el.trim())}
            onBlur={() => {
              if (!newPassword2) {
                setNotice3("确认密码不能为空！");
              } else if (md5(newPassword1) !== md5(newPassword2)) {
                setNotice3("两次输入密码不一致");
              }
            }}
            value={newPassword2}
            extra={
              <img
                alt=""
                onClick={() => setType3(!type3)}
                src={
                  type3 ? require("../img/by1.svg") : require("../img/by2.svg")
                }
              ></img>
            }
          ></InputItem>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "5%",
            height: "0.4rem",
          }}
        >
          <span
            style={{
              color: "red",
              marginTop: "0.1rem",
              display: "flex",
              marginLeft: "0.05rem",
            }}
          >
            {notice3}
          </span>
        </div>
        <div
          className="submit"
          onClick={() => {
            if (!oldPassword) {
              setNotice1("原密码不能为空！");
              return;
            }

            const password = localStorage.getItem("password");
            if (md5(oldPassword) !== password) {
              setNotice1("原密码输入错误！");
              return;
            }

            if (!newPassword1) {
              setNotice2("新密码不能为空！");
              return;
            }
            if (!newPassword2) {
              setNotice3("确认密码不能为空！");
              return;
            }

            if (md5(newPassword1) !== md5(newPassword2)) {
              setNotice3("两次输入密码不一致");
              return;
            }
            Toast.loading("正在修改", 0);
            axios
              .post(
                `/open/basedata/updatepassword`,
                {
                  userid: localStorage.getItem("userId"),
                  oldpassword: md5(oldPassword),
                  newpassword: md5(newPassword2),
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                if (!res) return;
                Toast.hide();
                localStorage.removeItem("token");
                localStorage.removeItem("password");
                clearRedux();
                history.push("login");
              })
              .catch((e) => {
                Toast.info("系统异常！", 2);
              });
          }}
        >
          <span>更改密码</span>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state.homeReducer,
  };
}

export default connect(mapStateToProps, { homeAction })(ChangePassword);
