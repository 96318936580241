import React from "react";
import axios from "axios";
import { NavBar, Icon, Toast } from "antd-mobile";
import "../logistics/style/logisticsInformation.css";

export default class ViewProgress extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.batchStatusDisplay();
  }

  componentWillUnmount() {
    Toast.hide();
  }

  batchStatusDisplay = () => {
    Toast.loading("正在加载", 0);
    let id =
      (this.props.location &&
        this.props.location.query &&
        this.props.location.query.id) ||
      "-1";
    // let id = "485819191313664";
    axios
      .post(
        `/open/setmeal/b/${id}/batchStatusDisplay`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (!res) return;
        Toast.hide();
        if (res.productlist) {
          this.setState({
            data: res.productlist,
          });
        }

      })
      .catch(() => {
        Toast.info("加载异常！", 2);
      });
  };

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let data = this.state.data;
    data.map((v) => {
      return v;
    });
    return (
      <div className="logisticsInformation">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          查看进度
        </NavBar>
        {this.state.data.length ? (
          <div
            className="center"
            style={{ borderTop: "0.01rem solid #f1f5f8" }}
          >
            {data.map((v, k) => {
              if (v.statusDisplay === "-1") return null;
              return (
                <div
                  className="detaile-info"
                  key={k}
                  style={{
                    marginTop: "0",
                    marginBottom: "0.1rem",
                    flexShrink: "0",
                    flexFlow: "0",
                  }}
                >
                  <div className="detaile-info-title">
                    {v.productType === 'combo' ? (
                    <span style={{ marginBottom: "unset" }}>
                      套餐名称: {v.productName}
                      <span style={{ display: "block" }}>
                        检测进度: {v.testProgress}
                      </span>
                    </span>) : (
                    <span style={{ marginBottom: "unset" }}>
                      检测项目: {v.productName}
                      <span style={{ display: "block" }}>
                        检测进度: {v.testProgress}
                      </span>
                    </span>)}

                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="center"
            style={{ overflow: "hidden", borderTop: "0.01rem solid #f1f5f8" }}
          >
            <div className="empty">
              <img alt="" src={require("../img/kk.svg")}></img>
              <span>暂无进度</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
