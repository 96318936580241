import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  DatePicker,
  Accordion,
  Picker,
  Drawer,
  Toast,
  SearchBar,
  Modal,
} from "antd-mobile";
import { cityData } from "../data/cityData";
import axios from "axios";
import { connect } from "react-redux";
import "./style/sample.css";
import { sampleAction } from "../../action/sample/sample";

const alert = Modal.alert;
class SampleInfo extends React.Component {
  constructor(props) {
    super(props);
    const query =
      (this.props.location && this.props.location.query) ||
      this.props.applyListInfo ||
      {};
    this.applyListInfo = {
      salesBarCode: "",
      patientName: "",
      idNumber: "",
      patientAge: "",
      ageUnit: "岁",
      gender: "",
      paperReport: "",
      hospital: "",
      dept: "",
      customerName: "",
      // reportType: "院内",
      diagonsis: "",
      targetedTherapyHistory: "",
      familyHistory: "",
      selectSetMeals: "",
      tumorType: "",
      projectNo: "",
      sampleInfos: [],
      receiveAmount: 0,
      firstDiagnosisTime: "",
      clinicalStages: "",
      transferFlag: "",
      treatmentMethod: "",
      sampleBoxNo: "",
      status: query.status,
      sampleReturnFlag: "",
      sampleReturnReceiver: "",
      sampleReturnPhone: "",
      sampleReturnAddress: "",
      sampleReturnProvince: "",
      sampleReturnCity: "",
      sampleReturnDistrict: "",
    };
    this.submitVerificationData = [
      "sampleInfos",
    ];

    this.state = {
      applyListInfo: Object.assign({}, this.applyListInfo, query),
      packageIndex: [], // 样本类型
      inspectionUnitData: [],
      // inspectionItemsData : [],
      currentSetMeal: {},
      hisSampleInfos: [],
      addressPicker: ["110000", "110000", "110113"],
    };
    
    if (this.state.applyListInfo.action === 'supplementSample') {
      this.state.applyListInfo.sampleBoxNo = '';
    }
    
    //手风琴索引
    this.packageIndex = [];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
    this.sampleInfos = [];
    //当前修改地址
    this.currentModificationAddress = null;
    var _this = this;
    this.state.applyListInfo.sampleInfos && this.state.applyListInfo.sampleInfos.map(function (k, v) {
      _this.state.hisSampleInfos.push(k);
    })
    if (this.state.applyListInfo.action === 'supplementSample') {
      this.state.applyListInfo.sampleInfos = [];
    }
    //this.state.applyListInfo.sampleInfos = this.sampleInfos;
    // this.state.applyListInfo.sampleInfos = (this.state.applyListInfo.action === 'supplementSample' ? [] : this.state.applyListInfo.sampleInfos);
  }

  setRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.sampleAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  getCityData = (val, data) => {
    //设置结果
    let result;
    if (!data) {
      return; //如果data传空，直接返回
    }
    for (var i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.value === val) {
        result = data[i].label;
        //找到id相等的则返回父id
        this.doneResult = result;
        return result;
      } else if (item.children && item.children.length > 0) {
        //如果有子集，则把子集作为参数重新执行本方法
        result = this.getCityData(val, item.children);
        //关键，千万不要直接return本方法，不然即使没有返回值也会将返回return，导致最外层循环中断，直接返回undefined,要有返回值才return才对
        if (result) {
          return result;
        }
      }
    }
    //如果执行循环中都没有return，则在此return
    return result;
  };

  clearRedux = () => {
    const reduxData = ["applyListInfo"];
    for (let pop in reduxData) {
      this.props.sampleAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  submitVerification = () => {
    const applyListInfo = this.state.applyListInfo;
    this.setState({
      applyListInfo,
    });
    for (let pop of this.submitVerificationData) {
      if (JSON.stringify(applyListInfo[pop]) === "[]") {
        return false;
      }
    }
    return true;
  };

  //录入输入框信息
  inputChange = (v, k) => {
    let newApplyListInfo = this.state.applyListInfo;
    newApplyListInfo[k] = v;
    this.setState({
      applyListInfo: newApplyListInfo,
    });
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== "/payment"
    ) {
      this.clearRedux();
    }
  }

  choosePackage = (value, item, select) => {
    let setMealInfo = this.state.setMealInfo;
    setMealInfo = setMealInfo.map((v) => {
      if (value === v) {
        v.checked = !v.checked;
      }
      return v;
    });

    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.setMealCode + value.templateId);
    if (indexOf < 0) {
      packageIndex.push(value.setMealCode + value.templateId);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      setMealInfo,
    });
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  formatDateTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()} ${val.getHours() < 10 ? "0" + val.getHours() : val.getHours()}:${val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes()}:${val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds()}`;
  };


  boolSwitch = (val) => {
    let show = true;
    if (val) {
      if (typeof val === "string") {
        if (val === "false") {
          show = false;
        }
      } else {
        return val;
      }
    } else {
      show = false;
    }
    return show;
  };

  setSampleInput = (id, key, val) => {
    let sampleData = this.state.sampleData.map((value) => {
      if (value.id.toString() === id) {
        value[key] = val;
      }
      return value;
    });
    this.setState({
      sampleData,
    });
  };

  submitSample = () => {
    axios
      .post(
        `/open/setmeal/wxpush/${this.state.applyListInfo.id}/saveSampleInfoByWX`,
        this.state.applyListInfo,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        
        if (res) {
          let pathname;
          if (this.state.applyListInfo.action === 'supplementSample') {
            pathname = '/supplementSampleList';
          } else if (this.state.applyListInfo.action === 'inputSample') {
            pathname = '/pendingInputSampleList';
          } else if (this.state.applyListInfo.action === 'orderDetail') {
            pathname = '/createApplication';
          } else if (this.state.applyListInfo.action === 'hospitalOrder') {
           
          } else if (this.state.applyListInfo.action === 'hysampleCollectionCompleted') {
            
          }


          Toast.info("提交成功！", 1);
          this.props.history.push({
            pathname: pathname,
            //query: this.state
          });
        }
      })
      .catch(() => {
        Toast.info("提交失败！", 1);
      }); 
  }

  //选择样本类型
  choiceSampleType = (value, arr, k, e) => {
    let sampleData = this.state.sampleData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex.push(value.id);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      sampleData,
    });
  };

  //打开样本类型抽屉
  renderSampleInformation = () => {
    return (
      <div className="sample-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              sampleInformationSearchBar: "",
              submitSampleInfos: false,
            });
          }}
        >
          添加样本
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.sampleInformationSearchBar || ""}
            onChange={(el) => {
              let sampleData = JSON.parse(
                JSON.stringify(this.sampleData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                sampleInformationSearchBar: el,
                sampleData,
                packageIndex: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.sampleData.map((v, k) => {
              let sampleInformationSearchBar =
                this.state.sampleInformationSearchBar &&
                this.state.sampleInformationSearchBar.toLowerCase();
              if (
                !sampleInformationSearchBar ||
                (v.sampleTypeName &&
                  v.sampleTypeName
                    .toLowerCase()
                    .indexOf(sampleInformationSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceSampleType(v);
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.sampleTypeName}</span>
                      </div>
                    }
                  >
                    {v.sampleTypeName != '原样本' ? (
                      <div className="showItem">
                        <List>
                          <InputItem
                            placeholder="请填写样本数量"
                            value={v.sampleNum || ""}
                            onChange={(val) => {
                              this.setSampleInput(v.id, "sampleNum", val);
                            }}
                            error={
                              v.checked &&
                              this.state.submitSampleInfos &&
                              !v.sampleNum
                            }
                            type="number"
                          >
                            <div>
                              <span style={{ color: "red" }}>*</span>样本数量
                          </div>
                          </InputItem>
                          <Picker
                            onOk={(val) => {
                              this.setSampleInput(v.id, "numberUnit", val[0]);
                            }}
                            extra="请选择"
                            value={[v.numberUnit || ""]}
                            data={[
                              {
                                value: "管",
                                label: "管",
                              },
                              {
                                value: "片",
                                label: "片",
                              },
                              {
                                value: "块",
                                label: "块",
                              },
                              {
                                value: "瓶",
                                label: "瓶",
                              },
                              {
                                value: "袋",
                                label: "袋",
                              }
                            ]}
                            cols={1}
                          >
                            <List.Item
                              arrow="horizontal"
                            >
                              <span style={{ color: "red" }}>*</span>
                              单位
                            </List.Item>
                          </Picker>
                          {v.sampleTypeCode === "外周血（Streck 采血管）" || v.sampleTypeCode === "外周血（EDTA 采血管）" ? (
                            <InputItem
                              placeholder="请填写规格"
                              value={v.spec || ""}
                              extra="ml"
                              onChange={(val) => {
                                this.setSampleInput(v.id, "spec", val);
                              }}
                              error={
                                v.checked &&
                                this.state.submitSampleInfos &&
                                !v.spec
                              }
                              type="number"
                            >
                              <div>
                                <span style={{ color: "red" }}>*</span>每管容量
                            </div>
                            </InputItem>
                          ) : null}
                          {v.sampleCategory !== "ZZ001" ? (
                          <DatePicker
                            mode="date"
                            minDate={new Date(1900, 0, 1, 0, 0, 0)}
                            maxDate={new Date()}
                            value={v.collectTime && new Date(v.collectTime)}
                            onOk={(val) => {
                              this.setSampleInput(
                                v.id,
                                "collectTime",
                                this.formatTime(val)
                              );
                            }}
                          >
                            <List.Item
                              arrow="horizontal"
                              error={
                                v.checked &&
                                this.state.submitSampleInfos &&
                                !v.collectTime
                              }
                            >
                              <div>
                                <span style={{ color: "red" }}>*</span>采样日期
                              </div>
                            </List.Item>
                          </DatePicker>
                           ) : null}
                          {v.sampleTypeCode === "手术组织石蜡切片（白片）" || v.sampleTypeCode === "穿刺组织石蜡切片（白片）" || v.sampleTypeCode === "蜡块（手术组织）" || v.sampleTypeCode === "蜡块（穿刺组织）" ? (
                            <InputItem
                              placeholder="请填写病理号"
                              value={v.diagonsisNo || ""}
                              error={
                                v.checked &&
                                this.state.submitSampleInfos &&
                                !v.diagonsisNo
                              }
                              onChange={(val) => {
                                this.setSampleInput(v.id, "diagonsisNo", val);
                              }}
                            >
                              <div>
                                <span style={{ color: "red" }}>*</span>病理号
                            </div>
                            </InputItem>
                          ) : null}
                          {v.sampleCategory === "ZZ001" ? (
                            <DatePicker
                              mode="datetime"
                              minDate={new Date(1900, 0, 1, 0, 0, 0)}
                              maxDate={new Date()}
                              value={v.tissueFixationTime && new Date(v.tissueFixationTime)}
                              onOk={(val) => {
                                this.setSampleInput(
                                  v.id,
                                  "tissueFixationTime",
                                  this.formatDateTime(val)
                                );
                              }}
                            >
                              <List.Item
                                arrow="horizontal"
                                error={
                                  v.checked &&
                                  this.state.submitSampleInfos &&
                                  !v.tissueFixationTime
                                }
                              >
                                组织固定开始时间
                            </List.Item>
                            </DatePicker>
                          ) : null}
                          {v.sampleCategory === "ZZ001" ? (
                            <DatePicker
                              mode="date"
                              minDate={new Date(1900, 0, 1, 0, 0, 0)}
                              maxDate={new Date()}
                              value={v.surgicalTime && new Date(v.surgicalTime)}
                              onOk={(val) => {
                                this.setSampleInput(
                                  v.id,
                                  "surgicalTime",
                                  this.formatTime(val)
                                );
                              }}
                            >
                              <List.Item
                                arrow="horizontal"
                                error={
                                  v.checked &&
                                  this.state.submitSampleInfos &&
                                  !v.surgicalTime
                                }
                              >
                                <span style={{ color: "red" }}>*</span>样本手术日期
                            </List.Item>
                            </DatePicker>
                          ) : null}
                          {v.sampleCategory === "ZZ001" ? (
                            <InputItem
                              placeholder="请填写取样部位"
                              value={v.collectLocation || ""}
                              onChange={(val) => {
                                this.setSampleInput(v.id, "collectLocation", val);
                              }}
                              error={
                                v.checked &&
                                this.state.submitSampleInfos &&
                                !v.collectLocation &&
                                (v.sampleCategory === "ZZ001" ||
                                  v.sampleCategory === "FFPE001")
                              }
                            >
                              <div>
                                <span style={{ color: "red" }}>*</span>
                                取样部位
                            </div>
                            </InputItem>
                          ) : null}

                        </List>
                      </div>
                    ) : null}
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let sampleData = this.state.sampleData;
              let applyListInfo = this.state.applyListInfo;
              let sampleInfos = applyListInfo.sampleInfos || [];
              let submit = false;
              sampleData.forEach((element) => {
           
                if (element.checked && element.sampleTypeName != '原样本') {

                  if (!element.collectTime) {
                    submit = true;
                    if(element.sampleCategory === "ZZ001"){
                      submit = false;
                    }
                  }

                  if (!element["sampleNum"] || !element["numberUnit"]) {
                    submit = true;
                  }
                  if (
                    (element.sampleTypeCode === "外周血（Streck 采血管）" || element.sampleTypeCode === "外周血（EDTA 采血管）") &&
                    !element.spec
                  ) {
                    submit = true;
                  }
                  if (
                    element.sampleCategory === "ZZ001" &&
                    !(element.collectLocation && element.surgicalTime)
                  ) {
                    submit = true;
                  }
  
                }
              });
              if (submit) {
                Toast.info("请填写必填信息！", 1);
                this.setState({
                  submitSampleInfos: true,
                });
                return;
              }

              sampleData.forEach((element) => {
                if (element.checked) {
                  if (this.state.applyListInfo.action === 'supplementSample') {
                    element.supplementFlag = 1;
                  }
                  sampleInfos.push({
                    ...element,
                    sampleCode: this.letter.pop(),
                  });
                }
              });
              applyListInfo.sampleInfos = sampleInfos;
              this.setState({
                drawer: false,
                submitSampleInfos: false,
                applyListInfo,
                sampleInformationSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 1:
        return this.renderSampleInformation();
      case 2:
        return this.rendersetMealDrawer();
      default:
        return <div></div>;
    }
  };

  componentDidMount() {
    Toast.loading("正在加载", 0);
    axios
      .get("/open/setmeal/getSampleTypeInfo")
      .then((res) => {
        if (res && res.lsisampletypebeans) {
          this.sampleData = JSON.parse(JSON.stringify(res.lsisampletypebeans));
          this.setState(
            {
              sampleData: res.lsisampletypebeans,
            },
            () => Toast.hide()
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
    // this.getInspectionItems();
    let sampleInfos = this.state.applyListInfo.sampleInfos;

    if (sampleInfos) {
      sampleInfos.forEach((element) => {
        this.letter.splice(this.letter.indexOf(element.sampleCode), 1);
      });
    }
  }

  submit = (res, applyListInfo) => {
    axios
      .post(
        `/open/setmeal/wxpush/${res.id}/submitBatchInfoByWx`,
        applyListInfo,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        Toast.success("提交成功", 2);
      })
      .catch(() => {
        Toast.info("加载失败！", 2);
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="sample">
        <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            if (this.state.drawerType === 2) {
              let applyListInfo = this.state.applyListInfo;
              applyListInfo.selectSetMeals = this.selectSetMeals;
              this.setState({
                applyListInfo,
              });
            }
            this.setState({
              drawer: false,
              setMealSearchBar: "",
              sampleInformationSearchBar: "",
              nationSearchBar: "",
              inspectionUnitSearchBar: "",
              inspectionItemsSearchBar: "",
              tumorTypeSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexShrink: "0",
            flexGrow: "0",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
            >
              添加样本
            </NavBar>
            <div className="center">
              <div style={{ width: "100%" }}>
                <List>
                  <div className="item">
                    {/* <span className="title">患者信息</span> */}
                    <div className="form">
                      <InputItem
                        disabled={true}
                        defaultValue={
                          this.state.applyListInfo.salesBarCode || ""
                        }

                      >
                        <span style={{ color: "red" }}>*</span>
                        申请单条码
                      </InputItem>
                      <InputItem
                        ref={(el) => (this.patientName = el)}
                        value={this.state.applyListInfo.patientName}
                        onChange={(el) => {
                          this.inputChange(el, "patientName");
                        }}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.patientName.focus()}
                        >
                          {true ? <span className="sign">*</span> : null}
                          姓名
                        </div>
                      </InputItem>
                      <Picker
                        value={[this.state.applyListInfo.gender]}
                        data={[
                          {
                            value: "男",
                            label: "男",
                          },
                          {
                            value: "女",
                            label: "女",
                          },
                          {
                            value: "未知",
                            label: "未知",
                          },
                        ]}
                        disabled={true}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.submit &&
                            !this.state.applyListInfo.gender
                          }
                        >
                          {true ? <span className="sign">*</span> : null}
                          性别
                        </List.Item>
                      </Picker>
                      <DatePicker
                          mode="date"
                          minDate={new Date(1900, 0, 1, 0, 0, 0)}
                          maxDate={new Date()}
                          value={
                            this.state.applyListInfo.patientBirth &&
                            new Date(this.state.applyListInfo.patientBirth)
                          }
                          onOk={(val) => {
                            this.inputChange(this.formatTime(val), "patientBirth");
                          }}
                          disabled={true}
                        >
                          <List.Item 
                          disabled={true}
                          arrow="horizontal">
                            <span className="sign">*</span>
                          出生年月
                       </List.Item>
                        </DatePicker>
                      <InputItem
                        type="text"
                        maxLength={50}
                        ref={(el) => (this.nation = el)}
                        value={this.state.applyListInfo.nation}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          民族
                        </div>
                      </InputItem>
                      <InputItem
                        type="text"
                        maxLength={50}
                        ref={(el) => (this.nativePlace = el)}
                        value={this.state.applyListInfo.nativePlace}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          籍贯
                        </div>
                      </InputItem>
                      <InputItem
                        type="text"
                        maxLength={64}
                        ref={(el) => (this.caseNumber = el)}
                        value={this.state.applyListInfo.caseNumber}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => this.caseNumber.focus()}
                        >
                          {true ? <span className="sign">*</span> : null}
                          病案号
                        </div>
                      </InputItem>
                      <InputItem
                        ref={(el) => (this.idNumber = el)}
                        type="text"
                        maxLength={18}
                        value={this.state.applyListInfo.idNumber}
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          身份证号
                        </div>
                      </InputItem>
                    </div>
                    <div className="item">
                      {/* <span className="title">检测项目</span> */}
                      <div className="form">
                        <List.Item
                          arrow="horizontal"
                          disabled={true}
                          extra="请选择"
                        >
                          {true ? <span className="sign">*</span> : null}
                        检测项目
                      </List.Item>
                        {this.state.applyListInfo.inspectionItems &&
                          this.state.applyListInfo.inspectionItems.length ? (
                          <List.Item multipleLine>
                            {this.state.applyListInfo.inspectionItems
                              .map((v, k) => {
                                return (
                                  <div key={k} className="select-info2">
                                    <img
                                      src={require("../img/xx.png")}
                                      alt=""
                                    ></img>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "1",
                                      }}
                                    >
                                      {v.productName ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            检测项目：
                                      </span>
                                          <span className="content-lable">
                                            {v.productName}
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </List.Item>
                        ) : null}
                      </div>
                    </div>
                    <div className="item">
                      {/* <span className="title">样本信息</span> */}
                      <div className="form">
                        {this.state.applyListInfo.action === 'supplementSample' &&
                          this.state.hisSampleInfos &&
                          this.state.hisSampleInfos.length ? (
                          <List.Item
                            onClick={() => {
                              if (this.state.applyListInfo.action === 'supplementSample') return;
                              this.setState({
                                drawer: true,
                                drawerType: 1,
                                packageIndex: [],
                                sampleData: JSON.parse(
                                  JSON.stringify(this.sampleData)
                                ),
                                sampleInfosError: false,
                              });
                            }}
                            extra="请选择"
                            arrow="horizontal"
                            disabled={this.state.applyListInfo.action === 'supplementSample'}
                          >
                            {true ? <span className="sign">*</span> : null}
                            现有样本
                          </List.Item>
                        ) : null}
                        {this.state.applyListInfo.action === 'supplementSample' &&
                          this.state.hisSampleInfos &&
                          this.state.hisSampleInfos.length ? (
                          <List.Item multipleLine>
                            {this.state.hisSampleInfos
                              .sort((a, b) =>
                                a.sampleCode.localeCompare(b.sampleCode)
                              )
                              .map((v, k) => {
                                return (
                                  <div key={k} className="select-info2">
                                    <img
                                      src={require("../img/xx.png")}
                                      alt=""
                                    ></img>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "1",
                                      }}
                                    >
                                      <span>样本{v.sampleCode}</span>
                                      {v.sampleTypeName ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            样本类型：
                                          </span>
                                          <span className="content-lable">
                                            {v.sampleTypeName}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.sampleNum ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            数量：
                                          </span>
                                          <span className="content-lable">
                                            {v.sampleNum + v.numberUnit}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.spec ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            容量：
                                          </span>
                                          <span className="content-lable">
                                            {v.spec + "ml"}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.collectTime ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            采样时间：
                                          </span>
                                          <span className="content-lable">
                                            {v.collectTime}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.diagonsisNo ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            病理号：
                                          </span>
                                          <span className="content-lable">
                                            {v.diagonsisNo}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.tissueFixationTime ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            组织固定开始时间：
                                          </span>
                                          <span className="content-lable">
                                            {v.tissueFixationTime}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.surgicalTime ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            样本手术日期：
                                          </span>
                                          <span className="content-lable">
                                            {v.surgicalTime}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.collectLocation ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            取样部位：
                                          </span>
                                          <span className="content-lable">
                                            {v.collectLocation}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.sampleReturnFlag ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            归还样本：
                                          </span>
                                          <span className="content-lable">
                                            {v.sampleReturnFlag}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.sampleReturnReceiver ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            归还联系人：
                                          </span>
                                          <span className="content-lable">
                                            {v.sampleReturnReceiver}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.sampleReturnPhone ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            联系人电话：
                                          </span>
                                          <span className="content-lable">
                                            {v.sampleReturnPhone}
                                          </span>
                                        </div>
                                      ) : null}
                                      {v.sampleReturnAddress ? (
                                        <div className="content-item">
                                          <span className="content-title">
                                            归还地址：
                                          </span>
                                          <span className="content-lable">
                                            {v.sampleReturnAddress}
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </List.Item>
                        ) : null}
                      </div>
                      <div className="item">
                        {/* <span className="title">样本信息</span> */}
                        <div className="form">
                          <List.Item
                            onClick={() => {
                              this.setState({
                                drawer: true,
                                drawerType: 1,
                                packageIndex: [],
                                sampleData: JSON.parse(
                                  JSON.stringify(this.sampleData)
                                ),
                                sampleInfosError: false,
                              });
                            }}
                            extra="请选择"
                            arrow="horizontal"
                            error={
                              (!this.state.applyListInfo.sampleInfos &&
                                this.state.submit) ||
                              (this.state.submit &&
                                !this.state.applyListInfo.sampleInfos.length) ||
                              this.state.sampleInfosError
                            }
                          >
                            {true ? <span className="sign">*</span> : null}
                            添加样本
                          </List.Item>
                          {this.state.applyListInfo.sampleInfos &&
                            this.state.applyListInfo.sampleInfos.length ? (
                            <List.Item multipleLine>
                              {this.state.applyListInfo.sampleInfos
                                .sort((a, b) =>
                                  a.sampleCode.localeCompare(b.sampleCode)
                                )
                                .map((v, k) => {
                                  return (
                                    <div key={k} className="select-info2">
                                      <img
                                        src={require("../img/xx.png")}
                                        alt=""
                                      ></img>
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          flexDirection: "column",
                                          flex: "1",
                                        }}
                                      >

                                        <img
                                          alt=""
                                          className="close"
                                          src={require("../img/close.svg")}
                                          onClick={() => {
                                            let applyListInfo = this.state.applyListInfo;
                                            applyListInfo.sampleInfos.splice(
                                              applyListInfo.sampleInfos.findIndex(
                                                (value) => value === v
                                              ),
                                              1
                                            );
                                            this.letter.push(v.sampleCode);
                                            this.setState({
                                              applyListInfo,
                                            });
                                          }}
                                          style={{
                                            position: "absolute",
                                            right: "-0.1rem",
                                            top: "-0.1rem",
                                          }}
                                        ></img>
                                        <span>样本{v.sampleCode}</span>
                                        {v.sampleTypeName ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              样本类型：
                                            </span>
                                            <span className="content-lable">
                                              {v.sampleTypeName}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.sampleNum ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              数量：
                                            </span>
                                            <span className="content-lable">
                                              {v.sampleNum + v.numberUnit}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.spec ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              容量：
                                            </span>
                                            <span className="content-lable">
                                              {v.spec + "ml"}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.collectTime ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              采样时间：
                                            </span>
                                            <span className="content-lable">
                                              {v.collectTime}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.diagonsisNo ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              病理号：
                                            </span>
                                            <span className="content-lable">
                                              {v.diagonsisNo}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.tissueFixationTime ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              组织固定开始时间：
                                            </span>
                                            <span className="content-lable">
                                              {v.tissueFixationTime}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.surgicalTime ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              样本手术日期：
                                            </span>
                                            <span className="content-lable">
                                              {v.surgicalTime}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.collectLocation ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              取样部位：
                                            </span>
                                            <span className="content-lable">
                                              {v.collectLocation}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.sampleReturnFlag ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              归还样本：
                                            </span>
                                            <span className="content-lable">
                                              {v.sampleReturnFlag}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.sampleReturnReceiver ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              归还联系人：
                                            </span>
                                            <span className="content-lable">
                                              {v.sampleReturnReceiver}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.sampleReturnPhone ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              联系人电话：
                                            </span>
                                            <span className="content-lable">
                                              {v.sampleReturnPhone}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.sampleReturnAddress ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              归还地址：
                                            </span>
                                            <span className="content-lable">
                                              {v.sampleReturnAddress}
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })}
                            </List.Item>
                          ) : null}
                        </div>
                        <InputItem
                          placeholder="请填写样本盒编号"
                          ref={(el) => (this.sampleBoxNo = el)}
                          maxLength={9}
                          type="text"
                          value={this.state.applyListInfo.sampleBoxNo}
                          onChange={(el) => {
                            let newApplyListInfo = this.state.applyListInfo;
                            newApplyListInfo.sampleBoxNo = el;
                            this.setState({
                              applyListInfo: newApplyListInfo,
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onClick={() => this.sampleBoxNo.focus()}
                          >
                            <span className="sign">*</span>
                            样本盒编号
                        </div>
                        </InputItem>
                      </div>
                    </div>

                    <div className="item">
                      <Picker
                        placeholder="请选择"
                        value={[this.state.applyListInfo.sampleReturnFlag]}
                        onChange={(val) => {
                          let applyListInfo = this.state.applyListInfo;
                          applyListInfo.sampleReturnFlag = val[0];
                          if (val[0] === '0') {
                            applyListInfo.sampleReturnReceiver = '';
                            applyListInfo.sampleReturnPhone = '';
                            applyListInfo.sampleReturnAddress = '';
                            applyListInfo.sampleReturnProvince = '';
                            applyListInfo.sampleReturnCity = '';
                            applyListInfo.sampleReturnDistrict = '';
                          }
                          this.setState({
                            applyListInfo: applyListInfo,
                          });
                        }}
                        data={[
                          {
                            value: "0",
                            label: "否",
                          },
                          {
                            value: "1",
                            label: "是",
                          },
                        ]}
                        cols={1}
                      >
                        <List.Item
                          arrow="horizontal"
                          error={
                            this.state.returnSubmit &&
                            !this.state.applyListInfo.sampleReturnFlag
                          }
                        >
                          <span style={{ color: "red" }}>*</span>
                            归还样本
                          </List.Item>
                      </Picker>
                      {this.state.applyListInfo.sampleReturnFlag === "1" ? (
                        <InputItem
                          placeholder="请填写归还联系人"
                          value={this.state.applyListInfo.sampleReturnReceiver || ""}
                          onChange={(val) => {
                            let applyListInfo = this.state.applyListInfo;
                            applyListInfo.sampleReturnReceiver = val;
                            this.setState({
                              applyListInfo: applyListInfo,
                            });
                          }}
                          error={
                            this.state.returnSubmit &&
                            !this.state.applyListInfo.sampleReturnReceiver
                          }
                        >
                          <div>
                            <span style={{ color: "red" }}>*</span>
                              归还联系人
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.sampleReturnFlag === "1" ? (
                        <InputItem
                          placeholder="请填写联系人电话"
                          value={this.state.applyListInfo.sampleReturnPhone || ""}
                          type="number"
                          maxLength={12}
                          onChange={(val) => {
                            let applyListInfo = this.state.applyListInfo;
                            applyListInfo.sampleReturnPhone = val;
                            this.setState({
                              applyListInfo: applyListInfo,
                            });
                          }}
                          error={
                            this.state.returnSubmit &&
                            !this.state.applyListInfo.sampleReturnPhone
                          }
                        >
                          <div>
                            <span style={{ color: "red" }}>*</span>
                              联系人电话
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applyListInfo.sampleReturnFlag === "1" ? (
                        <Picker
                          data={cityData}
                          value={this.state.senderPicker}
                          onOk={(val) => {
                            let applyListInfo = this.state.applyListInfo;
                            applyListInfo.sampleReturnProvince = this.getCityData(val[0], cityData);
                            applyListInfo.sampleReturnCity = this.getCityData(val[1], cityData);
                            applyListInfo.sampleReturnDistrict = this.getCityData(val[2], cityData);
                            this.setState({
                              senderPicker: val,
                              applyListInfo: applyListInfo,
                            });
                          }}
                        >
                          <List.Item
                            error={this.state.returnSubmit && !this.state.senderPicker}
                            arrow="horizontal"
                          >
                            <span style={{ color: "red" }}>*</span>归还地区
                        </List.Item>
                        </Picker>
                      ) : null}
                      {this.state.applyListInfo.sampleReturnFlag === "1" ? (
                        <InputItem
                          placeholder="请填写归还地址"
                          value={this.state.applyListInfo.sampleReturnAddress || ""}
                          type="text"
                          maxLength={256}
                          onChange={(val) => {
                            let applyListInfo = this.state.applyListInfo;
                            applyListInfo.sampleReturnAddress = val;
                            this.setState({
                              applyListInfo: applyListInfo,
                            });
                          }}
                          error={
                            this.state.returnSubmit &&
                            !this.state.applyListInfo.sampleReturnAddress
                          }
                        >
                          <div>
                            <span style={{ color: "red" }}>*</span>
                              归还地址
                          </div>
                        </InputItem>
                      ) : null}
                    </div>

                  </div>
                </List>
              </div>
            </div>
            <div className="foot">
              <span
                className="save"
                onClick={() => {
                  if (this.state.applyListInfo.sampleInfos.length === 0) {
                    Toast.info("请先选择样本！", 3);
                    return;
                  }
                  
                  if (!this.state.applyListInfo.sampleBoxNo) {
                    Toast.info("请填写样本盒编号！", 3);
                    return;
                  }

                  var reg= /(^[A-Za-z0-9]+$)/;
                  if (!reg.test(this.state.applyListInfo.sampleBoxNo)){
                    Toast.info("样本盒编号只能包含数字或字母！", 3);
                    return;
                  }

                  if (this.state.applyListInfo.sampleReturnFlag === '1') {
                    if (!(this.state.applyListInfo.sampleReturnAddress &&
                      this.state.senderPicker &&
                      this.state.applyListInfo.sampleReturnReceiver &&
                      this.state.applyListInfo.sampleReturnPhone)) {
                      Toast.info("请完整填写归还信息!", 3);
                      this.setState({
                        returnSubmit: true,
                      });
                      return;
                    }
                  }

                  alert("提示", "请确认是否选完样本?", [
                    { text: "否" },
                    {
                      text: "是",
                      onPress: () => {
                        Toast.loading("正在提交", 1);
                      
      
                          if(this.state.applyListInfo.action === 'supplementSample') {
                            this.state.applyListInfo.supplementFlag = 1;
                          }
                          let sampleBoxNo = this.state.applyListInfo.sampleBoxNo;
                          axios
                            .get(
                              `/open/setmeal/validate/sampleBoxNo/${sampleBoxNo}`
                            )
                            .then((res) => {
                              if (!res) {
                                this.setState({
                                  sampleBoxNoError: true,
                                });
                              } else {
                                this.setState({
                                  sampleBoxNoError: false,                                
                                },
                                () => {
                                    this.submitSample();
                                });
                              }
                            });
                       
                      },
                    },
                  ]);
                }}
              >
                提交
              </span>
            </div>
          </div>
        </Drawer>
        {this.state.showImage ? (
          <div
            className="show-image"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              zIndex: "999",
              top: "0",
              left: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            id="video"
            onClick={() => this.setState({ showImage: false })}
          >
            <img
              alt=""
              style={{ width: "100%" }}
              src={this.state.uploadImage}
            ></img>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.applyListInfoReducer,
  };
}

export default connect(mapStateToProps, { sampleAction })(SampleInfo);
