import React from "react";
import { Carousel, Grid, TabBar, Modal } from "antd-mobile";
import Mine from "../mine/mine";
import "./style/home.css";
import { Toast } from "antd-mobile";
import { createHashHistory } from "history";
import axios from "axios";
import { homeAction } from "../../action/home/home";
import { connect } from "react-redux";
import weixin from "weixin-js-sdk";

const alert = Modal.alert;
const history = createHashHistory();

let badgeSetInterval;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    //请求头加上token
    config.headers.token = token;
  }
  return config;
});

axios.interceptors.response.use((config) => {
  if (config && config.data && config.data.return_code === "400") {
    Toast.info(config.data.return_msg, 2);
  } else if (config && config.data && config.data.code === 400) {
    Toast.info(config.data.data.message, 2);
  } else if (config && config.data && config.data.return_code === "401") {
    Toast.hide();
    if (badgeSetInterval) {
      clearInterval(badgeSetInterval);
    }
    alert("提示", "登录已过期，是否前往登录！", [
      { text: "否" },
      {
        text: "是",
        onPress: () => {
          history.push("login");
        },
      },
    ]);
  } else if (
    config &&
    config.data &&
    config.data.result &&
    config.data.result.failResult
  ) {
    Toast.info("提交失败！", 2);
  } else if (config.data && config.data.ext$) {
    return config.data && config.data.ext$;
  } else if (config.data && config.data.success) {
    return config.data;
  } else {
    return config;
  }
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherData: [],
      optionOrderData: [],
      optionSampleData: [],
      optionHospitalData: [],
      optionExpressData: [],
      reportData: [],
      exceptionData: [],
      selectedTab: this.props.selectedTab || "sy",
    };

    this.loopData = [
      {
        icon: require("../img/a.png"),
      },
      {
        icon: require("../img/b.png"),
      }
    ];

    this.optionOrderData = [
      {
        icon: require("../img/sqdcj.png"),
        text: "订单创建",
        router: "createApplication",
        value: 1,
      },

      {
        icon: require("../img/ddcx.png"),
        text: "订单查询",
        router: "orderInquiry",
        value: 3,
      },
      {
        icon: require("../img/ddxg3.png"),
        text: "订单修改",
        router: "onlineOrderModification",
        value: 4,
      },
      {
        icon: require("../img/sqdth.png"),
        text: "订单取消",
        router: "cancelApplication",
        value: 5,
      },
      {
        icon: require("../img/ddcx.png"),
        text: "已完成订单查询",
        router: "orderCompleted",
        value: 3,
      },
      {
        icon: require("../img/yc.png"),
        text: "异常处理",
        value: 3,
        router: "exceptionHandling",
      },  
	  {
        icon: require("../img/ddxg3.png"),
        text: "支付管理",
        value: 3,
        router: "payManage",
      },
    ];

    this.optionSampleData = [
      {
        icon: require("../img/sqdcj.png"),
        text: "样本录入",
        router: "pendingInputSampleList",
        value: 1,
      },
      {
        icon: require("../img/ddxg3.png"),
        text: "再次送样",
        router: "supplementSampleList",
        value: 2,
      },
      {
        icon: require("../img/ddxg3.png"),
        text: "宣传册",
        router: "brochureApplyList",
        value: 2,
      },
      {
        icon: require("../img/sqdcj.png"),
        text: "样本盒申请",
        router: "createSampleBoxApplication",
        value: 3,
      }
    ];

    this.optionHospitalData = [
      {
        icon: require("../img/sqdcj.png"),
        text: "订单管理",
        router: "hospitalOrder",
        value: 1,
      }
    ];

    this.optionExpressData = [
      {
        icon: require("../img/qpjs.png"),
        text: "样本寄送",
        router: "sampleDelivery",
        value: 1,
      },
      {
        icon: require("../img/ddxg3.png"),
        text: "快递单号修改",
        router: "expressModification",
        value: 2,
      },
      {
        icon: require("../img/qxjs.png"),
        text: "取消寄送",
        router: "cancelDelivery",
        value: 3,
      },
      {
        icon: require("../img/ybjs.png"),
        text: "查快递",
        router: "checkExpress",
        value: 4,
      },
    ];

    this.reportData = [
      {
        icon: require("../img/bgxz.png"),
        text: "报告单查询",
        router: "reportQuery",
        value: 4,
      },
    ];

    this.otherData = [
      {
        icon: require("../img/dzfp.png"),
        text: "电子发票下载",
        router: "invoiceList",
        value: 5,
      },
      {
        icon: require("../img/myd.png"),
        text: "满意度调查",
        router: "satisfactionSurvey",
        value: 6,
      },
    ];
 
  this.exceptionData = [
      {
        icon: require("../img/bgxz.png"),
        text: "病理待办",
        router: "pathologyTodo",
        value: 3,
      },
      {
        icon: require("../img/bgxz.png"),
        text: "报告单查询",
        router: "reportQuery",
        value: 4,
      },
      {
        icon: require("../img/dzfp.png"),
        text: "电子发票下载",
        router: "invoiceList",
        value: 5,
      },
      {
        icon: require("../img/sjcx.png"),
        text: "送检要求查询",
        router: "inspectionRequestInquiry",
        value: 6,
      },
    ];
  }
  getBadge = () => {
    axios
      .get("/open/setmeal/getTodoQuantity")
      .then((res) => {
        if (!res) return;
        this.setState({
          quick: res.draft_quick,
          reject: res.draft_reject,
          excep: res.draft_excep,
          save: res.draft_save,
          sampleEntry: res.sample_entry,
          hospital: res.hospital_entry,
          sampleSend: res.sample_send,
		      payManage: res.pay_manage,
          pathologyTodo: res.todo_logged
        });
      })
      .finally(() => {});
  };

  setRedux = () => {
    const reduxData = ["selectedTab"];
    for (let pop in reduxData) {
      this.props.homeAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["selectedTab"];
    for (let pop in reduxData) {
      this.props.homeAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  setBadgeSetInterval = () => {
    if (!badgeSetInterval) {
      badgeSetInterval = setInterval(() => {
        this.getBadge();
      }, 30000);
    }
  };

  option = (v) => {
    this.props.history.push(v.router);
  };

  goRouter = (v) => {
    this.props.history.push(v);
  };

  componentDidMount() {
    this.getBadge();
    this.setBadgeSetInterval();
    this.setState({
      otherData: this.otherData,
      reportData: this.reportData,
      optionOrderData: this.optionOrderData,
      optionSampleData: this.optionSampleData,
      optionHospitalData: this.optionHospitalData,
      optionExpressData: this.optionExpressData,
      exceptionData: this.exceptionData,
      materialManagement: this.materialManagement,
      isCarousel: true,
    });
    //this.wxConfig();
  }

  componentWillUnmount() {
    if (badgeSetInterval) {
      clearInterval(badgeSetInterval);
    }
  }

  wxConfig() {
    let url = window.location.href.split("#")[0];
    axios.get(`/wx/mp/selectJsSign?url=${url}`)
    .then((res) => {
      if (!res) return;
      weixin.config({
        debug: false,
        appId: 'wxaef1628edc72b44f',
        timestamp: res.weixin.timestamp,
        nonceStr: res.weixin.nonceStr,
        signature: res.weixin.jsSignature,
        jsApiList: ['scanQRCode']
      });
      // 权限验证出错
      weixin.error(function(err){
        Toast.info("获取验证出错了:" + res.errMsg, 3)
      })
    })
    .finally(() => {});
  }

  render() {
    return (
      <TabBar
        unselectedTintColor="black"
        tintColor="#1296db"
        barTintColor="#f7f7f7"
      >
        <TabBar.Item
          title="首页"
          key="sy"
          icon={
            <img
              alt=""
              style={{ width: "0.2rem" }}
              src={require("../img/sy2.svg")}
            ></img>
          }
          selectedIcon={
            <img
              alt=""
              style={{ width: "0.2rem" }}
              src={require("../img/sy.svg")}
            ></img>
          }
          selected={this.state.selectedTab === "sy"}
          onPress={() => {
            this.clearRedux();
            this.setState({
              selectedTab: "sy",
            });
          }}
        >
          <div className="home">
            <div className="loop">
              <Carousel
                autoplay={true}
                infinite
                autoplayInterval={2500}
                dotStyle={{
                  backgroundColor: "white",
                }}
                dotActiveStyle={{
                  backgroundColor: "rgb(65,131,244)",
                }}
              >
                {this.loopData.map((val) => (
                  <div key={val} className="content">
                    <img
                      src={val.icon}
                      alt=""
                      style={{ width: "100%", verticalAlign: "top" }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="center">
              <div className="title">
                <div className="line"></div>
                <span>申请单管理</span>
              </div>
              <div className="option">
                <Grid
                  hasLine={false}
                  renderItem={(e) => {
                    return (
                      <div className="grid">
                        <img alt="" src={e.icon}></img>
                        <span>{e.text}</span>
                        {e.router === "quickCreation" &&
                        this.state.quick > 0 ? (
                          <span className="badge">{this.state.quick}</span>
                        ) : null}
                        {e.router === "createApplication" &&
                        this.state.save > 0 ? (
                          <span className="badge">{this.state.save}</span>
                        ) : null}
                        {e.router === "exceptionHandling" &&
                        this.state.excep > 0 ? (
                          <span className="badge">{this.state.excep}</span>
                        ) : null}
                        {e.router === "pendingInputSampleList" &&
                        this.state.sampleEntry > 0 ? (
                          <span className="badge">{this.state.sampleEntry}</span>
                        ) : null}
                        {e.router === "sampleDelivery" &&
                        this.state.sampleSend > 0 ? (
                          <span className="badge">{this.state.sampleSend}</span>
                        ) : null}
						            {e.router === "payManage" &&
                        this.state.payManage > 0 ? (
                          <span className="badge">{this.state.payManage}</span>
                        ) : null}
                      </div>
                    );
                  }}
                  data={this.state.optionOrderData}
                  carouselMaxRow={1}
                  columnNum={4}
                  onClick={(v) => this.option(v)}
                  activeStyle={false}
                />
              </div>
              <div className="title">
                <div className="line"></div>
                <span>样本管理</span>
              </div>
              <div className="option" style={{ border: "0.01rem solid white" }}>
                <Grid
                  hasLine={false}
                  data={this.state.optionSampleData}
                  isCarousel={this.state.isCarousel}
                  carouselMaxRow={1}
                  columnNum={4}
                  renderItem={(e) => {
                    return (
                      <div className="grid">
                        <img alt="" src={e.icon}></img>
                        <span>{e.text}</span>
                        {e.router === "pendingInputSampleList" &&
                        this.state.sampleEntry > 0 ? (
                          <span className="badge">{this.state.sampleEntry}</span>
                        ) : null}
                      </div>
                    );
                  }}
                  onClick={(v) => this.option(v)}
                  activeStyle={false}
                />
              </div>

            

              <div className="title">
                <div className="line"></div>
                <span>快递单管理</span>
              </div>
              <div className="option" style={{ border: "0.01rem solid white" }}>
                <Grid
                  hasLine={false}
                  data={this.state.optionExpressData}
                  isCarousel={this.state.isCarousel}
                  carouselMaxRow={1}
                  columnNum={4}
                  renderItem={(e) => {
                    return (
                      <div className="grid">
                        <img alt="" src={e.icon}></img>
                        <span>{e.text}</span>
                        {e.router === "sampleDelivery" &&
                        this.state.sampleSend > 0 ? (
                          <span className="badge">{this.state.sampleSend}</span>
                        ) : null}
                      </div>
                    );
                  }}
                  onClick={(v) => this.option(v)}
                  activeStyle={false}
                />
              </div>
              <div className="title">
                <div className="line"></div>
                <span>综合处理</span>
              </div>
              <div className="option" style={{ border: "0.01rem solid white" }}>
                <Grid
                  hasLine={false}
                  data={this.state.exceptionData}
                  isCarousel={this.state.isCarousel}
                  carouselMaxRow={1}
                  columnNum={4}
                  renderItem={(e) => {
                    return (
                      <div className="grid">
                        <img alt="" src={e.icon}></img>
                        <span>{e.text}</span>
                        {e.router === "pathologyTodo" &&
                        this.state.pathologyTodo > 0 ? (
                          <span className="badge">{this.state.pathologyTodo}</span>
                        ) : null}
                      </div>
                    );
                  }}
                  onClick={(v) => this.option(v)}
                  activeStyle={false}
                />
              </div>
            </div>
          </div>
        </TabBar.Item>
        <TabBar.Item
          icon={
            <img
              alt=""
              style={{ width: "0.2rem" }}
              src={require("../img/wd.svg")}
            ></img>
          }
          selectedIcon={
            <img
              alt=""
              style={{ width: "0.2rem" }}
              src={require("../img/wd2.svg")}
            ></img>
          }
          title="我的"
          key="wd"
          selected={this.state.selectedTab === "wd"}
          onPress={() => {
            this.setState({
              selectedTab: "wd",
            });
          }}
        >
          <Mine clearRedux={this.clearRedux} setRedux={this.setRedux}></Mine>
        </TabBar.Item>
      </TabBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.homeReducer,
  };
}

export default connect(mapStateToProps, { homeAction })(Home);
