export const batchCheckBox =
{
    tumorType: [{
        checked: false,
        text: '非小细胞肺癌',
        value: '0'
    }, {
        checked: false,
        text: '小细胞肺癌',
        value: '1'
    }, {
        checked: false,
        text: '结直肠癌',
        value: '2'
    }, {
        checked: false,
        text: '乳腺癌',
        value: '3'
    }, {
        checked: false,
        text: '胃癌',
        value: '4'
    }, {
        checked: false,
        text: '胃肠道间质瘤',
        value: '5'
    }, {
        checked: false,
        text: '卵巢癌',
        value: '6'
    }, {
        checked: false,
        text: '胰腺癌',
        value: '7'
    }, {
        checked: false,
        text: '前列腺癌',
        value: '8'
    }, {
        checked: false,
        text: '肝胆肿瘤',
        value: '9'
    }, {
        checked: false,
        text: '头颈部肿瘤',
        value: '10'
    }, {
        checked: false,
        text: '其他',
        value: '11'
    },
    ], diagonsis: [
        {
            checked: 0,
            text: '未确诊',
            value: '0'
        },
        {
            checked: 0,
            text: '确诊',
            value: '1'
        },
    ], targetedTherapyHistory: [
        {
            checked: 0,
            text: '未知',
            value: '0'
        }, {
            checked: 0,
            text: '无',
            value: '1'
        }, {
            checked: 0,
            text: '药物名',
            value: '2'
        },
    ], historyChemotherapy: [
        {
            checked: 0,
            text: '未知',
            value: '0'
        }, {
            checked: 0,
            text: '无',
            value: '1'
        }, {
            checked: 0,
            text: '药物名',
            value: '2'
        },
    ], historyRadiation: [
        {
            checked: 0,
            text: '未知',
            value: '0'
        }, {
            checked: 0,
            text: '无',
            value: '1'
        }, {
            checked: 0,
            text: '药物名',
            value: '2'
        },
    ], familyHistory: [
        {
            checked: 0,
            text: '无',
            value: '0'
        }, {
            checked: 0,
            text: '有',
            value: '1'
        },
    ], smokeHistory: [
        {
            checked: 0,
            text: '无',
            value: '0'
        }, {
            checked: 0,
            text: '有',
            value: '1'
        },
    ], ER: [
        {
            checked: 0,
            text: '阴性',
            value: '0'
        }, {
            checked: 0,
            text: '阳性',
            value: '1'
        },
    ], PR: [
        {
            checked: 0,
            text: '阴性',
            value: '0'
        }, {
            checked: 0,
            text: '阳性',
            value: '1'
        },
    ], HER2: [
        {
            checked: 0,
            text: '阴性',
            value: '0'
        }, {
            checked: 0,
            text: '阳性',
            value: '1'
        }, {
            checked: 0,
            text: '不确定',
            value: '2'
        },
    ], lymphTransfer: [
        {
            checked: 0,
            text: '阴性',
            value: '0'
        }, {
            checked: 0,
            text: '阳性',
            value: '1'
        },
    ], menopause: [
        {
            checked: 0,
            text: '阴性',
            value: '0'
        }, {
            checked: 0,
            text: '阳性',
            value: '1'
        },
    ], tissueSections: [
        {
            checked: 0,
            text: '蜡块',
            value: '0'
        }, {
            checked: 0,
            text: '切片',
            value: '1'
        }, {
            checked: 0,
            text: '蜡卷',
            value: '2'
        },
    ], samplingMode: [
        {
            checked: 0,
            text: '手术',
            value: '0'
        }, {
            checked: 0,
            text: '穿刺',
            value: '1'
        }, {
            checked: 0,
            text: '支气管镜',
            value: '2'
        }, {
            checked: 0,
            text: '肠镜',
            value: '3'
        }, {
            checked: 0,
            text: '胃镜',
            value: '4'
        }, {
            checked: 0,
            text: '其他',
            value: '5'
        },
    ], freshTissue: [
        {
            checked: 0,
            text: '福尔马林固定',
            value: '0'
        }, {
            checked: 0,
            text: '冷冻',
            value: '1'
        }, {
            checked: 0,
            text: '其他',
            value: '2'
        },
    ], samplingMode2: [
        {
            checked: 0,
            text: '手术',
            value: '0'
        }, {
            checked: 0,
            text: '穿刺',
            value: '1'
        }, {
            checked: 0,
            text: '支气管镜',
            value: '2'
        }, {
            checked: 0,
            text: '肠镜',
            value: '3'
        }, {
            checked: 0,
            text: '胃镜',
            value: '4'
        }, {
            checked: 0,
            text: '其他',
            value: '5'
        },
    ], blood: [
        {
            checked: 0,
            text: '全血',
            value: '0'
        }, {
            checked: 0,
            text: '血浆',
            value: '1'
        }, {
            checked: 0,
            text: '血清',
            value: '2'
        },
    ], nucleicAcid: [
        {
            checked: 0,
            text: 'DNA',
            value: '0'
        }, {
            checked: 0,
            text: 'RND',
            value: '1'
        }, {
            checked: 0,
            text: 'DNA+RNA',
            value: '2'
        }, {
            checked: 0,
            text: '其他',
            value: '3'
        },
    ], bodyFluid: [
        {
            checked: 0,
            text: '胸水',
            value: '0'
        }, {
            checked: 0,
            text: '腹水',
            value: '1'
        }, {
            checked: 0,
            text: '脑脊液',
            value: '2'
        },
    ], project: [
        {
            checked: 0,
            text: '肺癌',
            value: '0',
            name: 'lungCancer'
        }, {
            checked: 0,
            text: '乳腺癌',
            value: '1',
            name: 'mammaryCancer'

        }, {
            checked: 0,
            text: '卵巢癌/乳腺癌/胰腺癌/前列腺癌',
            value: '2',
            name: 'LRYQ'
        }, {
            checked: 0,
            text: '前列腺癌',
            value: '3',
            name: 'prostaticCancer'
        }, {
            checked: 0,
            text: '胃肠道间质瘤',
            value: '4',
            name: 'gastrointestinalStromalTumor'
        }, {
            checked: 0,
            text: '儿童肿瘤',
            value: '5',
            name: 'TumorInChildren'
        }, {
            checked: 0,
            text: '结直肠癌',
            value: '6',
            name: 'colorectalCancer'
        }, {
            checked: 0,
            text: '实体瘤',
            value: '7',
            name: 'solidTumor'
        },
    ], lungCancer: [
        {

            text: 'EGFR突变检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        }, {

            text: 'EGFR T790M突变检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        }, {

            text: 'EGRF+ALK+ROS1+BRAF+PD-L1联合检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '12基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '25基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '52基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '161基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        },
    ], mammaryCancer: [
        {

            text: 'HER2基因扩增检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '乳腺癌21基因表达检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '乳腺癌多基因遗传筛查',
            label: [
                {
                    checked: 0,
                    text: 'EDTA全血',
                    value: '0'
                }
            ]
        }, {

            text: '乳腺癌综合基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        },
    ], LRYQ: [
        {

            text: 'BRCA1/2基因检测(NGS)(组织版)',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: 'BRCA1/2基因检测(NGS)(胚系突变检测)',
            label: [
                {
                    checked: 0,
                    text: 'EDTA全血',
                    value: '0'
                }
            ]
        }, {

            text: 'BRCA1/2基因综合检测(NGS+MLPA)',
            label: [
                {
                    checked: 0,
                    text: 'EDTA全血',
                    value: '0'
                }
            ]
        },
    ], prostaticCancer: [
        {

            text: '前列腺癌综合基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        },
    ], gastrointestinalStromalTumor: [
        {

            text: '10基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        },
    ], TumorInChildren: [
        {

            text: '203基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        },
    ], colorectalCancer: [
        {

            text: '错配修复蛋白MMR检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '微卫星不稳定MSI检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: 'RDTA全血',
                    value: '2'
                },
            ]
        }, {

            text: '10基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        }, {

            text: '22基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: '血液',
                    value: '2'
                },
            ]
        }, {

            text: '结肠癌多基因遗传筛查',
            label: [
                {
                    checked: 0,
                    text: 'EDTA全血',
                    value: '0'
                }
            ]
        },
    ], solidTumor: [
        {

            text: 'EGFR基因扩增检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: 'HER2基因扩增检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: 'PD-L1蛋白表达检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '错配修复蛋白MMR检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '微卫星不稳定MSI检测',
            label: [
                {
                    checked: 0,
                    text: '组织+EDTA全血',
                    value: '0'
                }
            ]
        }, {

            text: '人类化疗药物相关基因SNP检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }, {
                    checked: 0,
                    text: 'EDTA全血',
                    value: '1'
                }
            ]
        }, {

            text: '52基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '161基因检测',
            label: [
                {
                    checked: 0,
                    text: '组织',
                    value: '0'
                }
            ]
        }, {

            text: '575基因检测(组织版)',
            label: [
                {
                    checked: 0,
                    text: '组织+血液',
                    value: '0'
                }
            ]
        }, {

            text: '575基因检测(血液版)',
            label: [
                {
                    checked: 0,
                    text: '血液',
                    value: '0'
                }
            ]
        }, {

            text: '肿瘤遗传综合筛查',
            label: [
                {
                    checked: 0,
                    text: 'EDTA全血',
                    value: '0'
                }
            ]
        }, {

            text: '人类全外显子组测序分析',
            label: [
                {
                    checked: 0,
                    text: '组织+EDTA全血',
                    value: '0'
                }
            ]
        },
    ],
};

export const nationData = [
    {
        value:'不详',
        label:'不详'
    },{
        value:'汉族',
        label:'汉族'
    },{
        value:'藏族',
        label:'藏族'
    },{
        value:'维吾尔族',
        label:'维吾尔族'
    },{
        value:'苗族',
        label:'苗族'
    },{
        value:'布依族',
        label:'布依族'
    },{
        value:'朝鲜族',
        label:'朝鲜族'
    },{
        value:'侗族',
        label:'侗族'
    },{
        value:'瑶族',
        label:'瑶族'
    },{
        value:'白族',
        label:'白族'
    },{
        value:'土家族',
        label:'土家族'
    },{
        value:'哈尼族',
        label:'哈尼族'
    },{
        value:'哈萨克族',
        label:'哈萨克族'
    },{
        value:'傣族',
        label:'傣族'
    },{
        value:'黎族',
        label:'黎族'
    },{
        value:'僳僳族',
        label:'僳僳族'
    },{
        value:'佤族',
        label:'佤族'
    },{
        value:'畲族',
        label:'畲族'
    },{
        value:'高山族',
        label:'高山族'
    },{
        value:'拉祜族',
        label:'拉祜族'
    },{
        value:'水族',
        label:'水族'
    },{
        value:'东乡族',
        label:'东乡族'
    },{
        value:'纳西族',
        label:'纳西族'
    },{
        value:'景颇族',
        label:'景颇族'
    },{
        value:'柯尔克孜族',
        label:'柯尔克孜族'
    },{
        value:'土族',
        label:'土族'
    },{
        value:'达斡尔族',
        label:'达斡尔族'
    },{
        value:'仫佬族',
        label:'仫佬族'
    },{
        value:'羌族',
        label:'羌族'
    },{
        value:'布朗族',
        label:'布朗族'
    },{
        value:'撒拉族',
        label:'撒拉族'
    },{
        value:'毛南族',
        label:'毛南族'
    },{
        value:'仡佬族',
        label:'仡佬族'
    },{
        value:'锡伯族',
        label:'锡伯族'
    },{
        value:'阿昌族',
        label:'阿昌族'
    },{
        value:'普米族',
        label:'普米族'
    },{
        value:'塔吉克族',
        label:'塔吉克族'
    },{
        value:'怒族',
        label:'怒族'
    },{
        value:'乌孜别克族',
        label:'乌孜别克族'
    },{
        value:'俄罗斯族',
        label:'俄罗斯族'
    },{
        value:'鄂温克族',
        label:'鄂温克族'
    },{
        value:'德昂族',
        label:'德昂族'
    },{
        value:'保安族',
        label:'保安族'
    },{
        value:'裕固族',
        label:'裕固族'
    },{
        value:'京族',
        label:'京族'
    },{
        value:'塔塔尔族',
        label:'塔塔尔族'
    },{
        value:'独龙族',
        label:'独龙族'
    },{
        value:'鄂伦春族',
        label:'鄂伦春族'
    },{
        value:'赫哲族',
        label:'赫哲族'
    },{
        value:'门巴族',
        label:'门巴族'
    },{
        value:'珞巴族',
        label:'珞巴族'
    },{
        value:'基诺族',
        label:'基诺族'
    },{
        value:'回族',
        label:'回族'
    },{
        value:'壮族',
        label:'壮族'
    },{
        value:'满族',
        label:'满族'
    },{
        value:'彝族',
        label:'彝族'
    },{
        value:'蒙古族',
        label:'蒙古族'
    },
] 