import React from "react";
import {
  NavBar,
  Icon,
  InputItem,
  List,
  Modal,
  Accordion,
  Picker,
  Drawer,
  Toast,
  SearchBar,
  DatePicker,
} from "antd-mobile";
import { cityData } from "../data/cityData";
import axios from "axios";
import { connect } from "react-redux";
import "./style/applySampleBoxInfo.css";
import { applySampleBoxInfoAction } from "../../action/samplebox/applySampleBoxInfo";
const alert = Modal.alert;
class ApplySampleBoxInfo extends React.Component {
  constructor(props) {
    super(props);
    this.applySampleBoxInfo = {
      applyNo: "",
      applyDate: "",
      applyUserId: "",
      applyUserName: "",
      receiver: "",
      receiverPhone: "",
      receiverProvince: "",
      receiverCity: "",
      receiverDistrict: "",
      receiverAddress: "",
      sampleBoxInfos: []
    };
    this.submitVerificationData = [
      "applyDate", // 申请日期
      "applicant", // 申请人
      "receiver",  // 收件人
      "receiverPhone", // 收件电话
      "receiverAddress", // 收件地址
      "sampleBoxInfos", // 样本盒
    ];
    this.letter = [
      "Z",
      "Y",
      "X",
      "U",
      "V",
      "W",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ];
    const query =
      (this.props.location && this.props.location.query) ||
      this.props.applySampleBoxInfo ||
      {};

    this.state = {
      applySampleBoxInfo: Object.assign({}, this.applySampleBoxInfo, query),
      packageIndex: [],
      senderPicker: [],
    }
  }

  submitVerification = () => {
    const applySampleBoxInfo = this.applySampleBoxInfo;
    for (let pop of this.submitVerificationData) {
      if (!applySampleBoxInfo[pop]) {
        return false;
      }
      if (JSON.stringify(applySampleBoxInfo[pop]) === "[]") {
        return false;
      }
    }
    return true;
  };

  setRedux = () => {
    const reduxData = ["applySampleBoxInfo"];
    for (let pop in reduxData) {
      this.props.applySampleBoxInfoAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["applySampleBoxInfo"];
    for (let pop in reduxData) {
      this.props.applySampleBoxInfoAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

   //返回首页
   goBack = () => {
    this.clearRedux();
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  formatDateTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()} ${val.getHours() < 10 ? "0" + val.getHours() : val.getHours()}:${val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes()}:${val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds()}`;
  };

  getCityData = (val, data) => {
    //设置结果
    let result;
    if (!data) {
      return; //如果data传空，直接返回
    }
    for (var i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.value === val) {
        result = data[i].label;
        //找到id相等的则返回父id
        this.doneResult = result;
        return result;
      } else if (item.children && item.children.length > 0) {
        //如果有子集，则把子集作为参数重新执行本方法
        result = this.getCityData(val, item.children);
        //关键，千万不要直接return本方法，不然即使没有返回值也会将返回return，导致最外层循环中断，直接返回undefined,要有返回值才return才对
        if (result) {
          return result;
        }
      }
    }
    //如果执行循环中都没有return，则在此return
    return result;
  };

  getCityValue = (val, data) => {
    //设置结果
    let result;
    if (!data) {
      return; //如果data传空，直接返回
    }
    if (!val) {
      return;
    }
    for (var i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.label === val) {
        result = data[i].value;
        this.doneResult = result;
        return result;
      } else if (item.children && item.children.length > 0) {
        //如果有子集，则把子集作为参数重新执行本方法
        result = this.getCityValue(val, item.children);
        //关键，千万不要直接return本方法，不然即使没有返回值也会将返回return，导致最外层循环中断，直接返回undefined,要有返回值才return才对
        if (result) {
          return result;
        }
      }
    }
    //如果执行循环中都没有return，则在此return
    return result;
  }

   //录入输入框信息
   inputChange = (v, k) => {
    let newApplyListInfo = this.state.applySampleBoxInfo;
    newApplyListInfo[k] = v;
    this.setState({
      applySampleBoxInfo: newApplyListInfo,
    });
  };

  getSwicth = (selectType) => {
    switch (selectType) {
      case 1:
        return this.renderSampleBoxInformation();
      default:
        return <div></div>;
    }
  };

  setSampleInput = (id, key, val) => {
    let sampleData = this.state.sampleData.map((value) => {
      if (value.id.toString() === id) {
        value[key] = val;
      }
      return value;
    });
    this.setState({
      sampleData,
    });
  };

  //选择样本类型
  choiceSampleType = (value, arr, k, e) => {
    let sampleData = this.state.sampleData.map((val) => {
      if (val === value) {
        val.checked = !val.checked;
      }
      return val;
    });
    let packageIndex = this.state.packageIndex;
    const indexOf = packageIndex.indexOf(value.id);
    if (indexOf < 0) {
      packageIndex.push(value.id);
    } else {
      packageIndex.splice(indexOf, 1);
    }
    this.setState({
      packageIndex,
      sampleData,
    });
  };

  renderSampleBoxInformation() {
    return (
      <div className="sample-setMealDrawer">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.setState({
              drawer: false,
              sampleInformationSearchBar: "",
              submitSampleInfos: false,
            });
          }}
        >
          添加样本盒
        </NavBar>
        <div className="search">
          <SearchBar
            placeholder="搜索"
            value={this.state.sampleInformationSearchBar || ""}
            onChange={(el) => {
              let sampleData = JSON.parse(
                JSON.stringify(this.sampleData)
              ).map((v) => {
                v.checked = false;
                return v;
              });
              this.setState({
                sampleInformationSearchBar: el,
                sampleData,
                packageIndex: [],
              });
            }}
          />
        </div>
        <div className="setMealDrawer-center">
          <Accordion
            activeKey={this.state.packageIndex}
            className="my-accordion"
            onChange={this.onChange}
          >
            {this.state.sampleData.map((v, k) => {
              let sampleInformationSearchBar =
                this.state.sampleInformationSearchBar &&
                this.state.sampleInformationSearchBar.toLowerCase();
              if (
                !sampleInformationSearchBar ||
                (v.sampleTypeName &&
                  v.sampleTypeName
                    .toLowerCase()
                    .indexOf(sampleInformationSearchBar.toLowerCase()) > -1)
              ) {
                return (
                  <Accordion.Panel
                    key={v.id}
                    header={
                      <div
                        onClick={(e) => {
                          this.choiceSampleType(v);                                                 
                        }}
                        className="accordion2"
                      >
                        <img
                          alt=""
                          style={{ width: "0.15rem" }}
                          src={
                            v.checked
                              ? require("../img/xz.svg")
                              : require("../img/wxz.svg")
                          }
                        ></img>
                        <span>{v.dictTextName}</span>
                      </div>
                    }
                  >
                    <div className="showItem">
                    <List>
                      <InputItem
                        placeholder="请填写数量"
                        value={v.sampleBoxNum || ""}
                        onChange={(val) => {
                          this.setSampleInput(v.id, "sampleBoxNum", val);
                        }}
                        error={
                          v.checked &&
                          this.state.submitSampleInfos &&
                          !v.sampleBoxNum
                        }
                        type="number"
                      >
                        <div>
                          <span style={{ color: "red" }}>*</span>数量
                        </div>
                      </InputItem>
                    </List>
                  </div>                
                  </Accordion.Panel>
                );
              } else {
                return null;
              }
            })}
          </Accordion>
        </div>
        <div className="setMealDrawer-foot">
          <span
            onClick={() => {
              let sampleData = this.state.sampleData;
              let applySampleBoxInfo = this.state.applySampleBoxInfo;
              let sampleBoxInfos = applySampleBoxInfo.sampleBoxInfos || [];
              let submit = false;
              sampleData.forEach((element) => {
                if (element.checked) {
                  element["sampleBoxName"] = element["dictTextName"];
                  if (!element["sampleBoxNum"]) {
                    submit = true;
                  }
                }                
              });
              if (submit) {
                Toast.info("请填写必填信息！", 1);
                this.setState({
                  submitSampleInfos: true,
                });
                return;
              }

              sampleData.forEach((element) => {
                if (element.checked) {            
                  sampleBoxInfos.push({
                    ...element,
                    sampleCode: this.letter.pop(),
                  });
                }
              });
              applySampleBoxInfo.sampleBoxInfos = sampleBoxInfos;
              this.setState({
                drawer: false,
                submitSampleInfos: false,
                applySampleBoxInfo,
                sampleInformationSearchBar: "",
              });
            }}
          >
            确定
          </span>
        </div>
      </div>
    );
  };

  componentDidMount() {
    Toast.loading("正在加载", 0);
    axios
      .get("/open/sample/box/getSampleBoxInfo")
      .then((res) => {
        if (res && res.lsisampleboxinfos) {
          this.sampleData = JSON.parse(JSON.stringify(res.lsisampleboxinfos));
          this.setState(
            {
              sampleData: res.lsisampleboxinfos,
            },
            () => Toast.hide()
          );
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });
    // this.getInspectionItems();
    let sampleInfos = this.state.applySampleBoxInfo.sampleBoxInfos;

    if (sampleInfos) {
      sampleInfos.forEach((element) => {
        this.letter.splice(this.letter.indexOf(element.sampleCode), 1);
      });
    }
    var senderPicker = [];
    var applySampleBoxInfo = this.state.applySampleBoxInfo;
    senderPicker.push(this.getCityValue(applySampleBoxInfo.receiverProvince, cityData));
    senderPicker.push(this.getCityValue(applySampleBoxInfo.receiverCity, cityData));
    senderPicker.push(this.getCityValue(applySampleBoxInfo.receiverDistrict, cityData));
    this.setState({
      senderPicker: senderPicker
    })
  }

  render() {
    return (
      <Drawer
          open={this.state.drawer}
          onOpenChange={() => {
            this.setState({
              drawer: false,
              setMealSearchBar: "",
              sampleInformationSearchBar: "",
              nationSearchBar: "",
              inspectionUnitSearchBar: "",
              inspectionItemsSearchBar: "",
              tumorTypeSearchBar: "",
            });
          }}
          position="right"
          sidebarStyle={{
            backgroundColor: "white",
            width: "100vw",
            borderRadius: ".01rem 0 0 .01rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexShrink: "0",
            flexGrow: "0",
          }}
          sidebar={this.getSwicth(this.state.drawerType)}
        >
        <div className="applySampleBoxInfo">
          <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
          >
            <NavBar
                mode="light"
                icon={<Icon type="left" />}
                onLeftClick={this.goBack}
              >
                样本盒申请
            </NavBar>
            <div className="center">
              <div style={{ width: "100%" }}>
                <List>
                  <div className="item">
                    <div className="form">
                      {this.state.applySampleBoxInfo.applyNo ? (
                        <InputItem
                          type="text"
                          maxLength={64}
                          ref={(el) => (this.caseNumber = el)}
                          value={this.state.applySampleBoxInfo.applyNo}
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {true ? <span className="sign">*</span> : null}
                            申请编号
                          </div>
                        </InputItem>
                      ) : null}
                      {this.state.applySampleBoxInfo.applyDate ? (
                        <DatePicker
                        mode="date"
                        value={
                          this.state.applySampleBoxInfo.applyDate &&
                          new Date(this.state.applySampleBoxInfo.applyDate)
                        }
                        disabled={true}
                      >
                        <List.Item arrow="horizontal">
                          {true ? <span className="sign">*</span> : null}
                          申请日期                          
                          </List.Item>
                      </DatePicker>
                      ) : null}
                      {this.state.applySampleBoxInfo.applyUserName ? (
                        <InputItem
                          placeholder="请填写申请人"
                          ref={(el) => (this.applyUserName = el)}
                          type="text"
                          maxLength={36}
                          value={this.state.applySampleBoxInfo.applyUserName}
                          disabled = {this.state.applySampleBoxInfo.readonly}
                          onChange={(el) => {
                            this.inputChange(el, "applyUserName");
                          }}
                          disabled={true}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onClick={() => this.applyUserName.focus()}
                          >
                            <span className="sign">*</span>
                            申请人
                          </div>
                        </InputItem>
                      ) : null}                       
                      <InputItem
                          placeholder="请填写收件人"
                          ref={(el) => (this.receiver = el)}
                          type="text"
                          maxLength={36}
                          value={this.state.applySampleBoxInfo.receiver}
                          disabled = {this.state.applySampleBoxInfo.readonly}
                          onChange={(el) => {
                            this.inputChange(el, "receiver");
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onClick={() => this.receiver.focus()}
                          >
                            <span className="sign">*</span>
                            收件人
                          </div>
                      </InputItem>
                      <InputItem
                          placeholder="请填写收件人电话"
                          type="number"
                          maxLength={11}
                          ref={(el) => (this.receiverPhone = el)}
                          value={this.state.applySampleBoxInfo.receiverPhone}
                          disabled = {this.state.applySampleBoxInfo.readonly}
                          onChange={(el) => {
                            const reqSmallPhone = /^1\d{10}$/;
                            const phone = /^0\d{2,3}-?\d{7,8}$/;
                            if (reqSmallPhone.test(el) || phone.test(el)) {
                              this.setState({
                                receiverPhoneError: false,
                              });
                            } else {
                              this.setState({
                                receiverPhoneError: true,
                              });
                            }
                            this.inputChange(el, "receiverPhone");
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onClick={() => this.receiverPhone.focus()}
                          >
                            <span className="sign">*</span>
                            收件人电话
                          </div>
                      </InputItem>
                      <Picker
                          data={cityData}
                          value={ this.state.senderPicker }
                          disabled = {this.state.applySampleBoxInfo.readonly}
                          onOk={(val) => {
                            let applySampleBoxInfo = this.state.applySampleBoxInfo;
                            applySampleBoxInfo.receiverProvince = this.getCityData(val[0], cityData);
                            applySampleBoxInfo.receiverCity = this.getCityData(val[1], cityData);
                            applySampleBoxInfo.receiverDistrict = this.getCityData(val[2], cityData);
                            this.setState({
                              senderPicker: val,
                              applySampleBoxInfo: applySampleBoxInfo,
                            });
                          }}
                        >
                          <List.Item
                            error={this.state.returnSubmit && !this.state.senderPicker}
                            arrow="horizontal"
                          >
                            <span style={{ color: "red" }}>*</span>收件地区
                          </List.Item>
                        </Picker>
                      <InputItem
                          placeholder="请填写收件详细地址"
                          ref={(el) => (this.receiverAddress = el)}
                          type="text"
                          maxLength={36}
                          value={this.state.applySampleBoxInfo.receiverAddress}
                          disabled = {this.state.applySampleBoxInfo.readonly}
                          onChange={(el) => {
                            this.inputChange(el, "receiverAddress");
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            onClick={() => this.receiverAddress.focus()}
                          >
                            <span className="sign">*</span>
                            收件详细地址
                          </div>
                      </InputItem>
                      <div className="item">
                        <div className="form">
                          <List.Item
                            onClick={() => {
                              if (this.state.applySampleBoxInfo.readonly) {
                                return
                              }
                              this.setState({
                                drawer: true,
                                drawerType: 1,
                                sampleData: JSON.parse(
                                  JSON.stringify(this.sampleData)
                                ),
                                sampleInfosError: false,
                              });
                            }}
                            disabled = {this.state.applySampleBoxInfo.readonly}
                            extra="请选择"
                            arrow="horizontal"
                          >
                            {true ? <span className="sign">*</span> : null}
                            添加样本盒
                          </List.Item>
                          {this.state.applySampleBoxInfo.sampleBoxInfos &&
                            this.state.applySampleBoxInfo.sampleBoxInfos.length ? (
                            <List.Item multipleLine>
                              {this.state.applySampleBoxInfo.sampleBoxInfos
                                .sort((a, b) =>
                                  a.sampleCode.localeCompare(b.sampleCode)
                                )
                                .map((v, k) => {
                                  return (
                                    <div key={k} className="select-info2">
                                      <img
                                        src={require("../img/xx.png")}
                                        alt=""
                                      ></img>
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          flexDirection: "column",
                                          flex: "1",
                                        }}
                                      >
                                        {!this.state.applySampleBoxInfo.readonly ? (
                                          <img
                                            alt=""
                                            className="close"
                                            src={require("../img/close.svg")}
                                            onClick={() => {
                                              if (this.state.applySampleBoxInfo.readonly) {
                                                return
                                              }
                                              let applySampleBoxInfo = this.state.applySampleBoxInfo;
                                              applySampleBoxInfo.sampleBoxInfos.splice(
                                                applySampleBoxInfo.sampleBoxInfos.findIndex(
                                                  (value) => value === v
                                                ),
                                                1
                                              );
                                              this.letter.push(v.sampleCode);
                                              this.setState({
                                                applySampleBoxInfo,
                                              });
                                            }}
                                            style={{
                                              position: "absolute",
                                              right: "-0.1rem",
                                              top: "-0.1rem",
                                            }}
                                          ></img>
                                        ) : null}                                        
                                        <span>样本盒{v.sampleCode}</span>
                                        {v.sampleBoxName ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              样本盒名称：
                                          </span>
                                            <span className="content-lable">
                                              {v.sampleBoxName}
                                            </span>
                                          </div>
                                        ) : null}
                                        {v.sampleBoxNum ? (
                                          <div className="content-item">
                                            <span className="content-title">
                                              样本盒数量：
                                            </span>
                                            <span className="content-lable">
                                              {v.sampleBoxNum}
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })}
                            </List.Item>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </List>
              </div>
            </div>
          </div>
          {!this.state.applySampleBoxInfo.readonly ? (
            <div className="foot">
              <span
                className="save"
                onClick={() => {
                  var applySampleBoxInfo = this.state.applySampleBoxInfo;
                  applySampleBoxInfo.action = "save";
                  if (!applySampleBoxInfo.receiver) {
                    Toast.info("请填写收件人", 2);
                    return;
                  }
                  if (this.state.receiverPhoneError) {
                    Toast.info("请填写正确接收人电话", 2);
                    return;
                  }
                  if (!applySampleBoxInfo.receiverProvince && !applySampleBoxInfo.receiverCity) {
                    Toast.info("请选择收件地区", 2);
                    return;
                  }
                  if (!applySampleBoxInfo.receiverAddress) {
                    Toast.info("请填写收件人详细地址", 2);
                    return;
                  }
                  if (!applySampleBoxInfo.sampleBoxInfos.length) {
                    Toast.info("请选择样本盒", 2);
                    return;
                  }                  
                  alert("提示", "请确认是否选完样本盒?", [
                    { text: "否" },
                    {
                      text: "是",
                      onPress: () => {
                        Toast.loading("正在提交", 1);
                        axios
                          .post(
                            `/open/sample/box/wxpush/saveSampleBoxInfoByWX`,
                            this.state.applySampleBoxInfo,
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          )
                          .then((res) => {
                            if (res) {                          
                              Toast.info("保存成功！", 1);
                              this.props.history.push({
                                pathname: "createSampleBoxApplication",
                                //query: this.state
                              });
                            }
                          })
                          .catch(() => {
                            Toast.info("保存失败！", 1);
                          });
                      },
                    },
                  ]);
                }}
              >
                保存
              </span>
              <span
                className="submit"
                onClick={() => {
                  var applySampleBoxInfo = this.state.applySampleBoxInfo;
                  applySampleBoxInfo.action = "submit";
                  if (!applySampleBoxInfo.receiver) {
                    Toast.info("请填写收件人", 2);
                    return;
                  }
                  if (this.state.receiverPhoneError) {
                    Toast.info("请填写正确接收人电话", 2);
                    return;
                  }
                  if (!applySampleBoxInfo.receiverProvince && !applySampleBoxInfo.receiverCity) {
                    Toast.info("请选择收件地区", 2);
                    return;
                  }
                  if (!applySampleBoxInfo.receiverAddress) {
                    Toast.info("请填写收件人详细地址", 2);
                    return;
                  }
                  if (!applySampleBoxInfo.sampleBoxInfos.length) {
                    Toast.info("请选择样本盒", 2);
                    return;
                  }                  
                  alert("提示", "请确认是否选完样本盒?", [
                    { text: "否" },
                    {
                      text: "是",
                      onPress: () => {
                        Toast.loading("正在提交", 1);
                        axios
                          .post(
                            `/open/sample/box/wxpush/saveSampleBoxInfoByWX`,
                            this.state.applySampleBoxInfo,
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          )
                          .then((res) => {
                            if (res) {                          
                              Toast.info("提交成功！", 1);
                              this.props.history.push({
                                pathname: "createSampleBoxApplication",
                                //query: this.state
                              });
                            }
                          })
                          .catch(() => {
                            Toast.info("提交失败！", 1);
                          });
                      },
                    },
                  ]);
                }}
              >
                提交
              </span>
            </div>
          ) : null}          
        </div>
      </Drawer>
    );
  }

}

function mapStateToProps(state) {
  return {
    ...state.applySampleInfoReducer,
  };
}

export default connect(mapStateToProps, { applySampleBoxInfoAction })(ApplySampleBoxInfo);