import React from "react";
import { NavBar, Icon, Toast, InputItem, DatePicker, Modal } from "antd-mobile";
import axios from "axios";
import "./style/createSampleBoxApplication.css";
import { connect } from "react-redux";
import { BoxLoading } from "react-loadingg";
import { createSampleBoxApplicationAction } from "../../action/samplebox/createSampleBoxApplication";
const alert = Modal.alert;
class CreateSampleBoxApplication extends React.Component {
    constructor(props) {
        super(props);
        const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
        this.state = {
          applicationData: [],
          searchStr: this.props.searchStr || "",
          startTime: this.props.startTime || startTime,
          endTime: this.props.endTime || new Date(),
          loading: true,
          needSearch: props.needSearch || false,
        };
        this.applicationData = [];
    }

    setRedux = () => {
        const reduxData = [
          "searchStr",
          "applicationData",
          "startTime",
          "endTime",
          "needSearch",
        ];
        for (let pop in reduxData) {
          this.props.createSampleBoxApplicationAction({
            type: reduxData[pop],
            data: this.state[reduxData[pop]],
          });
        }
    };

    clearRedux = () => {
        const reduxData = [
          "searchStr",
          "applicationData",
          "startTime",
          "endTime",
          "needSearch",
        ];
        for (let pop in reduxData) {
          this.props.createSampleBoxApplicationAction({
            type: reduxData[pop],
            data: null,
          });
        }
    };

    search = () => {
        let applicationData = [];
        const allData = JSON.parse(JSON.stringify(this.applicationData));
        if (allData) {
          Toast.success("正在加载", 0);
          const startTime = parseInt(
            this.formatTime(this.state.startTime).replace(/-/g, "")
          );
          const endTime = parseInt(
            this.formatTime(this.state.endTime).replace(/-/g, "")
          );
          allData.forEach((element) => {
            if (element && element.createdTime) {
              let createdTime = parseInt(
                this.formatTime(element.createdTime).replace(/-/g, "")
              );
    
              if (createdTime <= endTime && createdTime >= startTime) {
                for (let pop in element) {
                  if (typeof element[pop] === "string") {
                    const searchStr = this.state.searchStr || '';
                    if (element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1) {
                      applicationData.push(element);
                      break;
                    }
                  }
                }
              }
            }
          });
          this.setState(
            {
              applicationData,
            },
            () => {
              Toast.hide();
            }
          );
        }
    };

    componentWillUnmount() {
        Toast.hide();
        if (
          this.props.history &&
          this.props.history.location &&
          (this.props.history.location.pathname === "/" ||
            this.props.history.location.pathname === "/home")
        ) {
          this.clearRedux();
        }
    };

    formatTime = (val) => {
        if (!val) return;
        if (typeof val === "string") {
          val = new Date(val);
        }
        return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
          }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
    };

    getSampleBoxStatus = (v) => {
        let status;
        switch (v) {
          case "draft":
            status = "新建";
            break;
          case "approve":
            status = "审核中";
            break;
          case "reject":
            status = "审核拒绝";
            break;
          case "logged":
            status = "审核通过";
            break;
          case "Waiting_For_Send":
            status = "待寄送";
            break;
          case "already_been_sent":
            status = "已寄送";
            break;
          case "done":
            status = "已完成";
            break;
          default:
            break;
        }
        return status;
    };

    selectSampleBoxByDraftSave = () => {
        const status = ["draft", "approve", "reject", "logged","Waiting_For_Send", "Already_Been_Sent", "done"];
        axios
          .post("/open/sample/box/wxpush/selectSampleBoxByStatus", status, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (!res || !res.sampleboxinfos) return;
            this.applicationData = res.sampleboxinfos;
            this.setState(
              {
                applicationData: res.sampleboxinfos,
              },
              () => {
                if (this.state.needSearch) {
                  this.search();
                }
              }
            );
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
    };

    componentDidMount() {
        this.selectSampleBoxByDraftSave();
    };
    
    //返回首页
    goBack = () => {
        this.clearRedux();
        this.props.history.goBack();
    };

    render() {
      return (
          <div
            className="createSampleBoxApplication"
            onClick={() => {
            let applicationData = this.state.applicationData.map((value) => {
                value.mask = false;
                return value;
            });
            this.setState({
                applicationData,
            });
            }}
          >
            <NavBar
              mode="light"
              icon={<Icon type="left" />}
              onLeftClick={this.goBack}
              rightContent={
                  <span
                    style={{
                      fontSize: "0.14rem",
                      display: "flex",
                      width: "0.5rem",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      paddingRight: "0.15rem",
                    }}
                    onClick={() => {
                      this.clearRedux();
                      var applySampleBoxInfo = {};
                      applySampleBoxInfo.applyDate = this.formatTime(new Date());
                      applySampleBoxInfo.applyUserId = localStorage.userId;
                      applySampleBoxInfo.applyUserName = localStorage.username;
                      this.props.history.push({
                        pathname: "applySampleBoxInfo",
                        query: applySampleBoxInfo,
                      });
                    }}
                  >
                    新建
                  </span>
                }
                >
                样本盒申请单
            </NavBar>
            <div className="search-bar">
                <div className="search-bar-top">
                    <div
                    className="search-bar-top-input"
                    onClick={() => {
                        if (this.inputItem) {
                        this.inputItem.focus();
                        }
                    }}
                    >
                    <form
                        style={{ width: "100%" }}
                        onSubmit={(e) => {
                        this.search();
                        e.preventDefault();
                        }}
                    >
                        <InputItem
                        placeholder="搜索"
                        ref={(el) => {
                            this.inputItem = el;
                        }}
                        value={this.state.searchStr}
                        onChange={(el) => {
                            this.setState({
                            searchStr: el,
                            });
                        }}
                        ></InputItem>
                    </form>
                    <img
                        alt=""
                        className="search-bar-top-input-img"
                        src={require("../img/ss.png")}
                    ></img>
                    </div>
                    <div
                    onClick={(e) => {
                        e.stopPropagation();
                        this.search();
                    }}
                    className="search-bar-top-text"
                    >
                    <span>搜索</span>
                    </div>
                </div>
                <div className="search-bar-bottom">
                    <div className="time">
                    <span>时间：</span>
                    <DatePicker
                        mode="date"
                        minDate={new Date(1900, 0, 1, 0, 0, 0)}
                        maxDate={new Date()}
                        value={this.state.startTime}
                        onOk={(el) => {
                        this.setState({
                            startTime: el,
                        });
                        }}
                    >
                        <div className="search-bar-bottom-item">
                        <span>{this.formatTime(this.state.startTime)}</span>
                        <img alt="" src={require("../img/xia.png")}></img>
                        </div>
                    </DatePicker>
                    <span style={{ margin: "0 0.05rem" }}>-</span>
                    <DatePicker
                        mode="date"
                        minDate={new Date(1900, 0, 1, 0, 0, 0)}
                        maxDate={new Date()}
                        value={this.state.endTime}
                        onOk={(el) => {
                        this.setState({
                            endTime: el,
                        });
                        }}
                    >
                        <div className="search-bar-bottom-item">
                        <span>{this.formatTime(this.state.endTime)}</span>
                        <img alt="" src={require("../img/xia.png")}></img>
                        </div>
                    </DatePicker>
                    </div>
                </div>
            </div>
            {this.state.applicationData && this.state.applicationData.length ? (
                <div className="center">
                    {this.state.applicationData.map((v, k) => {
                    return (
                        <div
                        className="item"
                        onClick={(e) => {
                            e.stopPropagation();
                            let applicationData = this.state.applicationData.map(
                            (value) => {
                                if (v === value) {
                                value.mask = !v.mask;
                                } else {
                                value.mask = false;
                                }
                                return value;
                            }
                            );
                            this.setState({
                            applicationData,
                            });
                        }}
                        key={v + k}
                        >
                        <div className="item-row">
                          <img alt="" src={require("../img/ddbh.svg")}></img>
                            <span>申请编号：{v.applyNo}</span>                            
                            {/* 状态 */}
                            <span
                            className="item-status"
                            style={{
                                backgroundColor: "#0089FFDB",
                                display: "flex",
                                padding: "0.03rem 0.05rem",
                                marginLeft: "0.1rem",
                                borderRadius: "0.05rem",
                                color: "white",
                                fontSize: "0.1rem"
                            }}
                            >
                            状态：{this.getSampleBoxStatus(v.processStatus)}
                            </span>
                            {v.processStatus && v.processStatus.toUpperCase().indexOf("REJECT") > 0 ? (
                            <>
                                <span
                                className="item-status"
                                style={{
                                    backgroundColor: "#FFA500",
                                    display: "flex",
                                    padding: "0.03rem 0.05rem",
                                    marginLeft: "0.1rem",
                                    borderRadius: "0.05rem",
                                    color: "white",
                                    fontSize: "0.1rem"
                                }}
                                >
                                拒绝原因：{v.rejectReason}
                                </span>
                            </>
                            ) : null}                                                      
                        </div>
                        <div className="content">
                                <span>申请日期：{v.applyDate}</span>
                            </div>  
                        {v.mask ? <div className="mask"></div> : null}
                        <div className="foot">
                          {v.mask && v.processStatus === "draft" ? (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setRedux();
                                  this.props.history.push({
                                      pathname: "/applySampleBoxInfo",
                                      query: v,
                                  });
                                }}
                                style={{ backgroundColor: "rgb(30, 179, 69)" }}
                              >
                                修改
                              </span>
                          ) : null}                    
                          {/* 查看详情 */}
                          {v.mask && v.processStatus != "draft" ? (
                            <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setRedux();
                                  v.readonly = true;
                                  this.props.history.push({
                                      pathname: "/applySampleBoxInfo",
                                      query: v,
                                  });
                                }}
                                style={{ backgroundColor: "rgb(254,202,18" }}
                            >
                                详情
                            </span>
                          ) : null}                            
                          {v.mask && v.processStatus === "draft" ? (
                          <span
                              onClick={(e) => {
                              e.stopPropagation();
                              alert("提示", "是否确认删除？", [
                                  { text: "否" },
                                  {
                                  text: "是",
                                  onPress: () => {
                                      Toast.loading("正在删除", 0);
                                      axios
                                      .post(
                                          `/open/sample/box/wxpush/${v.id}/deleteSampleBoxByWx`
                                      )
                                      .then((res) => {
                                          Toast.success("删除成功", 2);
                                          this.selectSampleBoxByDraftSave();
                                      })
                                      .catch(() => {
                                          Toast.info("删除失败！", 2);
                                      });
                                  },
                                  },
                              ]);
                              }}
                              style={{ backgroundColor: "rgb(255,107,45" }}
                          >
                              删除
                          </span>
                          ) : null}
                        </div>
                        </div>
                    );
                    })}
                </div>
                ) : (
                <div className="center" style={{ overflow: "hidden" }}>
                    <div className="empty">
                    {this.state.loading ? (
                        <BoxLoading />
                    ) : (
                        <>
                        <img alt="" src={require("../img/kk.svg")}></img>
                        <span>请搜索</span>
                        </>
                    )}
                    </div>
                </div>
                )}


          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
      ...state.createSampleBoxApplicationReducer,
    };
  }
  
  export default connect(mapStateToProps, { createSampleBoxApplicationAction })(
    CreateSampleBoxApplication
  );