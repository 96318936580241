import React from "react";
import {
  NavBar,
  Icon,
  Toast,
  InputItem,
  DatePicker,
  Modal,
} from "antd-mobile";
import "./style/onlineOrderModification.css";
import { connect } from "react-redux";
import { BoxLoading } from "react-loadingg";
import axios from "axios";
import { expressModificationAction } from "../../action/apply/expressModificationAction";

class ExpressModification extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      orderData: this.props.orderData || [],
      searchStr: this.props.searchStr || "",
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      loading: true,
      selectAll: this.props.selectAll || false,
      active: this.props.active || false,
      selectData: [],
    };
    this.orderData = [];
  }

  goBack = () => {
    this.props.history.goBack();
  };

  setRedux = () => {
    const reduxData = [
      "otmsOrderId",
      "createdTime"
    ];
    for (let pop in reduxData) {
      this.props.expressModificationCation({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = [
      "otmsOrderId",
      "createdTime"
    ];

    for (let pop in reduxData) {
      this.props.expressModificationCation({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  search = () => {

    let orderData = [];
    const allData = JSON.parse(JSON.stringify(this.state.orderData));
    if (allData) {
      Toast.success("正在加载", 0);
      allData.forEach((element) => {
        for (let pop in element) {
          if (typeof element[pop] === "string") {
            const searchStr = this.state.searchStr || "";
            if (
              element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1
            ) {
              orderData.push(element);
              break;
            }
          }
        }
      });
      this.setState(
        {
          orderData,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  
  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${
      val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
    }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  componentDidMount() {
    this.selectBatchByDraftSave();
  }

  getBatchStatus = (v) => {
    let status;
    switch (v) {
      case "Logged":
        status = "检测中";
        break;
      case "Modify_Approve":
        status = "修改信息审核中";
        break;
      case "Modify_Approval":
        status = "修改申请审核通过";
        break;
      case "Pending_Sample":
        status = "样本待处理";
        break;
      case "Waiting_For_Send":
        status = "样本待寄送";
        break;
      case "Already_Been_Sent":
        status = "样本已寄送";
        break;
      default:
        break;
    }
    return status;
  };

  selectBatchByDraftSave = (fn) => {
    this.setState({
      loading: true,
    });
    const status = ["Logged"];
    axios
      .post("/open/setmeal/wxpush/selectSendSample", status, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (!res || !res.samplelist) return;
        this.setState({
          orderData: res.samplelist,
        });
        fn && fn();
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <div className="onlineOrderModification">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          快递单号修改
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.search();
              }}
              className="search-bar-top-text"
            >
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
          </div>
        </div>
        {this.state.orderData && this.state.orderData.length ? (
          <div className="center">
            {this.state.orderData.map((v, k) => {
              return (
                <div
                  className="item"
                  onClick={(e) => {
                    e.stopPropagation();
                    let orderData = this.state.orderData.map(
                      (value) => {
                        if (v === value) {
                          value.mask = !v.mask;
                        } else {
                          value.mask = false;
                        }
                        return value;
                      }
                    );
                    this.setState({
                      orderData,
                    });
                  }}
                  key={v + k}
                >
                  <div className="item-row">
                    <img alt="" src={require("../img/ddbh.svg")}></img>
                    <span>{v.ext$.salesbarcode}</span>
                    {/* 状态 */}
                    <span
                      className="item-status"
                      style={{ 
                        backgroundColor: "#0089FFDB",
                        display: "flex",
                        padding: "0.03rem 0.05rem",
                        marginLeft: "0.1rem",
                        borderRadius: "0.05rem",
                        color: "white",
                        fontSize: "0.1rem"
                      }}
                    >
                      状态：{this.getBatchStatus(v.ext$.batchstatus)}
                    </span>
                  
                  </div>
                  <div className="content">
                    {v.ext$.patientname ? <span>{v.ext$.patientname}</span> : null}
                    {v.ext$.sampletypename ? <span>{v.ext$.sampletypename}</span> : null}
                    {v.createdTime ? <span>{v.createdTime}</span> : null}
                  </div>
                  {v.mask ? <div className="mask"></div> : null}
                  <div className="foot">
                 
                    {v.mask ? (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push({
                            pathname: "/expressModificationDetail",
                            query: v,
                          });
                        }}
                        style={{ backgroundColor: "#f15a22" }}
                      >
                        修改
                      </span>
                    ) : null }
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
        <Modal visible={this.state.modal1} className="onlineOrderModification">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="center"
              style={{
                borderTop: "1px solid #f1f5f8",
              }}
            >
              {this.state.selectData.map((v, k) => {
                return (
                  <div className="item">
                    <div className="right">
                      <div
                        className="content"
                        style={{ paddingLeft: "0.16rem" }}
                      >
                        {v.patientName ? <span>{v.patientName}</span> : null}
                        {v.hospital ? <span>{v.hospital}</span> : null}
                        {v.sampleInfos && v.sampleInfos.length ? (
                          <span>
                            {(() => {
                              let sampleInfos = v.sampleInfos.map((element) => {
                                return (
                                  `(${element.sampleCode})` +
                                  element.sampleTypeName
                                );
                              });
                              return sampleInfos.join("，");
                            })()}
                          </span>
                        ) : null}
                        {v.selectSetMeals && v.selectSetMeals.length ? (
                          <span>
                            {(() => {
                              let selectSetMeals = v.selectSetMeals.map(
                                (element) => {
                                  return element.setMealName;
                                }
                              );
                              return selectSetMeals.join("，");
                            })()}
                          </span>
                        ) : null}
                        {v.createdTime ? <span>{v.createdTime}</span> : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
             
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.onlineOrderModificationReducer,
  };
}

export default connect(mapStateToProps, { expressModificationAction })(
  ExpressModification
);
