import React from "react";
import { NavBar, Icon, InputItem, DatePicker, Toast } from "antd-mobile";
import "./style/invoiceList.css";
import axios from "axios";
import { BoxLoading } from "react-loadingg";
import { connect } from "react-redux";
import { invoiceListAction } from "../../action/invoice/invoiceList";

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    const startTime = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
    this.state = {
      orderData: this.props.orderData || [],
      startTime: this.props.startTime || startTime,
      endTime: this.props.endTime || new Date(),
      searchStr: this.props.searchStr || "",
      loading: true,
    };
    this.orderData = [];
  }

  selectInvoiceList = () => {
    const status = ["Logged"];
    axios
      .post("/open/common/invoice/batchEInvoice", status, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let orderData = [];
        (res.batcheinvoicedtos || []).forEach((element) => {
          if (element.fileName) {
            orderData.push(element);
          }
        });
        this.orderData = orderData;
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  setRedux = () => {
    const reduxData = ["startTime", "endTime", "searchStr", "orderData"];
    for (let pop in reduxData) {
      this.props.invoiceListAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  clearRedux = () => {
    const reduxData = ["startTime", "endTime", "searchStr", "orderData"];
    for (let pop in reduxData) {
      this.props.invoiceListAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentDidMount() {
    this.selectInvoiceList();
  }

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      (this.props.history.location.pathname === "/" ||
        this.props.history.location.pathname === "/home")
    ) {
      this.clearRedux();
    }
  }

  search = () => {
    let orderData = [];
    const allData = JSON.parse(JSON.stringify(this.orderData));
    if (allData) {
      Toast.success("正在加载", 0);
      const startTime = parseInt(
        this.formatTime(this.state.startTime).replace(/-/g, "")
      );
      const endTime = parseInt(
        this.formatTime(this.state.endTime).replace(/-/g, "")
      );
      allData.forEach((element) => {
        if (element && element.createdTime) {
          let createdTime = parseInt(
            this.formatTime(element.createdTime).replace(/-/g, "")
          );
          if (createdTime <= endTime && createdTime >= startTime) {
            for (let pop in element) {
              if (typeof element[pop] === "string") {
                const searchStr = this.state.searchStr || "";
                if (
                  element[pop].toLowerCase().indexOf(searchStr.toLowerCase()) >
                  -1
                ) {
                  orderData.push(element);
                  break;
                }
              }
            }
          }
        }
      });
      this.setState(
        {
          orderData,
        },
        () => {
          Toast.hide();
        }
      );
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${
      val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
    }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  renderFileTypeImg = (filename) => {
    let src;
    if (!filename)
      return (
        <img
          style={{ width: "0.2rem" }}
          src={require("../img/wj.svg")}
          alt=""
        ></img>
      );
    if (filename.indexOf(".pdf") > -1) {
      src = require("../img/pdf.svg");
    } else if (
      filename.indexOf(".doc") > -1 ||
      filename.indexOf(".docx") > -1
    ) {
      src = require("../img/word.svg");
    } else if (filename.indexOf(".png") > -1 || filename.indexOf(".jpg") > -1) {
      src = require("../img/zp.svg");
    } else if (filename.indexOf(".xls") > -1) {
      src = require("../img/xls.svg");
    } else if (filename.indexOf(".txt") > -1) {
      src = require("../img/txt.svg");
    } else {
      src = require("../img/wj.svg");
    }
    return <img style={{ width: "0.2rem" }} src={src} alt=""></img>;
  };

  render() {
    return (
      <div className="invoiceList">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.clearRedux();
            this.goBack();
          }}
        >
          电子发票
        </NavBar>
        <div className="search-bar">
          <div className="search-bar-top">
            <div
              className="search-bar-top-input"
              onClick={() => {
                if (this.inputItem) {
                  this.inputItem.focus();
                }
              }}
            >
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  this.search();
                  e.preventDefault();
                }}
              >
                <InputItem
                  placeholder="搜索"
                  ref={(el) => {
                    this.inputItem = el;
                  }}
                  value={this.state.searchStr}
                  onChange={(el) => {
                    this.setState({
                      searchStr: el,
                    });
                  }}
                ></InputItem>
              </form>
              <img
                alt=""
                className="search-bar-top-input-img"
                src={require("../img/ss.png")}
              ></img>
            </div>
            <div onClick={() => this.search()} className="search-bar-top-text">
              <span>搜索</span>
            </div>
          </div>
          <div className="search-bar-bottom">
            <div className="time">
              <span>时间：</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.startTime}
                onOk={(el) => {
                  this.setState({
                    startTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.startTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
              <span style={{ margin: "0 0.05rem" }}>-</span>
              <DatePicker
                mode="date"
                minDate={new Date(1900, 0, 1, 0, 0, 0)}
                maxDate={new Date()}
                value={this.state.endTime}
                onOk={(el) => {
                  this.setState({
                    endTime: el,
                  });
                }}
              >
                <div className="search-bar-bottom-item">
                  <span>{this.formatTime(this.state.endTime)}</span>
                  <img alt="" src={require("../img/xia.png")}></img>
                </div>
              </DatePicker>
            </div>
            {/* <div className='status'>
                <span>状态：</span>
                <div className='search-bar-bottom-item'><span>全部</span> <img alt='' src={require('../img/xia.png')} ></img></div>
             </div> */}
          </div>
        </div>
        {this.state.orderData && this.state.orderData.length ? (
          <div className="center">
            <div className="content">
              {this.state.orderData.map((v, k) => {
                return (
                  <div
                    className="item"
                    key={v + k}
                    onClick={() => {
                      this.setRedux();
                      this.props.history.push({
                        pathname: "reportDownload",
                        query: {
                          reportName: v.fileName,
                          title: "电子发票下载",
                          reportPath: v.fileUrl,
                        },
                      });
                    }}
                  >
                    <div className="head">
                      <div className="left" style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            width: "100%",
                            marginBottom: "0.1rem",
                          }}
                        >
                          {this.renderFileTypeImg(v.fileName)}
                          <span
                            style={{
                              display: "flex",
                              width: "70%",
                              marginLeft: "0.05rem",
                            }}
                          >
                            {" "}
                            {v.fileName}
                          </span>
                          <span className="title-pj ">查看</span>
                          {/* <div
                          className="right"
                          style={{ position: "absolute", right: "0.1rem" }}
                        >
                          <img src={require("../img/sc.svg")} alt=""></img>
                        </div> */}
                        </div>
                        <div className="head-content">
                          {v.salesBarcode ? (
                            <span>{v.salesBarcode}</span>
                          ) : null}
                          {v.patientName ? <span>{v.patientName}</span> : null}
                          {v.hospital ? <span>{v.hospital}</span> : null}
                          {v.comboNames ? <span>{v.comboNames}</span> : null}
                          {v.createdTime ? <span>{v.createdTime}</span> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="center" style={{ overflow: "hidden" }}>
            <div className="empty">
              {this.state.loading ? (
                <BoxLoading />
              ) : (
                <>
                  <img alt="" src={require("../img/kk.svg")}></img>
                  <span>请搜索</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.invoiceListReducer,
  };
}

export default connect(mapStateToProps, { invoiceListAction })(InvoiceList);
