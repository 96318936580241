import { combineReducers } from "redux";
import { reportQueryReducer } from "./report/reportQuery";
import { invoiceListReducer } from "./invoice/invoiceList";
import { quickCreationReducer } from "./apply/quickCreation";
import { orderInquiryReducer } from "./apply/orderInquiry";
import { createApplicationReducer } from "./apply/createApplication";
import { cancelApplicationReducer } from "./apply/cancelApplication";
import { applyListInfoReducer } from "./apply/applyListInfo";
import { onlineOrderModificationReducer } from "./apply/onlineOrderModification";
import { paymentReducer } from "./payment/payment";
import { homeReducer } from "./home/home";
import { checkExpressReducer } from "./logistics/checkExpress";
import { sampleDeliveryReducer } from "./logistics/sampleDelivery";
import { discountReducer } from "./discount/discount";
import { pendingInputSampleReducer } from "./sample/pendingInputSample";
import { applicationDetailReducer } from "./apply/applicationDetail";
import { exceptionHandlingReducer } from "./exception/exceptionHandling";
import { exceptionHandlingDetailReducer } from "./exception/exceptionHandlingDetail";


const rootReducer = combineReducers({
  reportQueryReducer,
  invoiceListReducer,
  quickCreationReducer,
  orderInquiryReducer,
  createApplicationReducer,
  cancelApplicationReducer,
  onlineOrderModificationReducer,
  applyListInfoReducer,
  paymentReducer,
  checkExpressReducer,
  homeReducer,
  sampleDeliveryReducer,
  discountReducer,
  pendingInputSampleReducer,
  applicationDetailReducer,
  exceptionHandlingReducer,
  exceptionHandlingDetailReducer,

});

export default rootReducer;
