import React from "react";
import { NavBar, Icon, Toast, Modal } from "antd-mobile";
import { mySocket } from "../../utils/global";
import "./style/QRcode.css";
import axios from "axios";
const alert = Modal.alert;

export default class QRcode extends React.Component {
  goBack = () => {

    this.props.history.goBack();
    // alert("提示", "确定要取消支付吗？", [
    //   { text: "否" },
    //   {
    //     text: "是",
    //     onPress: () => {
    //       this.props.history.goBack();
    //     },
    //   },
    // ]);
  };

  cancelBatchPay = () => {
    Toast.loading("取消订单", 1);
    const query = (this.props.location && this.props.location.query) || {};
    let orderNo = query.v && query.v.id,
      money = query.money;
    axios
      .post(
        `/open/payapi/cancelBatchPay/o/${orderNo}/m/${money}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        Toast.hide();
        this.props.history.goBack();
      })
      .catch((e) => {
        this.props.history.goBack();
      });
  };

  componentDidMount() {
    if (
      this.props.location.query &&
      this.props.location.query.v &&
      this.props.location.query.v.id
    ) {
      mySocket.connect(this.props.location.query.v.id, this.paymentSuccessful);
    }
  }

  paymentSuccessful = () => {
    this.props.history.push({
      pathname: "/paymentSuccessful",
      query: {
        salesBarCode: this.props.location.query.v.salesBarCode,
        money: this.props.location.query.money,
      },
    });
    mySocket.disconnect();
  };

  render() {
    const query = this.props.location.query || {};
    return (
      <div className="QRcode">
        <NavBar
          mode="light"
          icon={<Icon type="left" style={{ color: "white" }} />}
          onLeftClick={this.goBack}
        >
          收钱
        </NavBar>
        <div className="center">
          <div className="qrcode">
            <div className="title">
              <img alt="" src={require("../img/qian.svg")}></img>
              <span>商家收钱</span>
            </div>
            <span style={{ marginTop: "0.3rem" }}>
              {(() => {
                switch (query.czfs) {
                  case 0:
                    return "扫一扫，向普瑞付款";
                  case 1:
                    return "扫一扫，向普瑞付款";
                  case 2:
                    return "云闪付/支付宝/微信，扫以下付款码，向普瑞付款";
                  case 3:
                    return `请使用POS机的"订单支付"，扫以下订单码，向普瑞付款`;
                  default:
                    return "";
                }
              })()}
            </span>
            {this.props.location.query && this.props.location.query.img ? (
              <img alt="" src={this.props.location.query.img}></img>
            ) : null}
            {this.props.location.query && this.props.location.query.money ? (
              <div className="money">
                <span>支付金额</span>
                <span>{this.props.location.query.money}元</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
