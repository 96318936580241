import React from "react";
import { withRouter } from "react-router";
import "antd-mobile";
import { Toast } from "antd-mobile";
import "./style/mine.css";
import axios from "axios";

class Mine extends React.Component {
  render() {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    return (
      <div className="mine">
        <div className="head">
          <div className="tx">{username && username.substring(0, 1)}</div>
          <div className="info">
            <span>{username}</span>
            {/* <span>4564afsasf@sfdfa.com</span> */}
          </div>
        </div>
        <div
          className="xgmm"
          onClick={() => {
            this.props.setRedux && this.props.setRedux();
            this.props.history.push("changePassword");
          }}
        >
          <div className="left">
            <img alt="" src={require("../img/xgmm.svg")}></img>
            <span>修改密码</span>
          </div>
          <img alt="" src={require("../img/right.png")}></img>
        </div>
        <div
          className="submit"
          onClick={() => {
            if (username && password) {
              Toast.loading("正在注销", 0);
              axios
                .post(
                  `/open/wx/logout?userName=${username}&password=${password}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  Toast.hide();
                  localStorage.removeItem("token");
                  localStorage.removeItem("username");
                  localStorage.removeItem("password");
                  this.props.history.push("login");
                })
                .catch(() => {
                  Toast.info("系统异常！", 2);
                });
            } else {
              this.props.history.push("login");
            }
            this.props.clearRedux && this.props.clearRedux();
          }}
        >
          <span>退出</span>
        </div>
      </div>
    );
  }
}
export default withRouter(Mine);
