import React from "react";
import {
  InputItem,
  NavBar,
  Icon,
  List,
  Picker,
  Toast,
  Modal
} from "antd-mobile";
import axios from "axios";
import "./style/cancel.css";
import { cancelAction } from "../../action/cancel/cancel";
import { connect } from "react-redux";
import { cityData } from "../data/cityData";
const alert = Modal.alert;

class Cancel extends React.Component {
  constructor(props) {
    super(props);
    const params =
      (this.props.location && this.props.location.query) || props || {};
    this.state = {
      id: params.id || props.id || "",
      salesBarCode: params.salesBarCode || props.salesBarCode || "",
      status: params.status || "",
      patientName: params.patientName || props.patientName || "",
      caseNumber: params.caseNumber || props.caseNumber || "",
      idNumber: params.idNumber || "",
      inspectionItems: params.inspectionItems || props.inspectionItems || "",
      refunder: params.refunder || props.refunder || "",
      cancelReason: params.cancelReason || props.cancelReason || "",
      redundAccount: params.redundAccount || props.redundAccount || "",
      redundOpenBank: params.redundOpenBank || props.redundOpenBank || "",
      redundPhone: params.redundPhone || props.redundPhone || "",
      inspectionStage: params.inspectionStage || props.inspectionStage || "病理评估",
      paidAmount: params.paidAmount || props.paidAmount || "",
      refundAmount: params.refundAmount || props.refundAmount || "",
      submitFlag: "",
      inspectionStage: "",
      estimatedRefundAmount: "",
      batchCode: params.batchCode || props.batchCode || "",
      sampleReturnFlag: params.sampleReturnFlag || props.sampleReturnFlag || "0",
      sampleReturnReceiver: params.sampleReturnReceiver || props.sampleReturnReceiver || "",
      sampleReturnPhone: params.sampleReturnPhone || props.sampleReturnPhone || "",
      sampleReturnAddress: params.sampleReturnAddress || props.sampleReturnAddress || "",
      sampleReturnProvince: params.sampleReturnProvince || props.sampleReturnProvince || "",
      sampleReturnCity: params.sampleReturnCity || props.sampleReturnCity || "",
      sampleReturnDistrict: params.sampleReturnDistrict || props.sampleReturnDistrict || "",
      action: params.action || props.action || "",
    };
    this.check = ["refunder", "redundAccount", "redundOpenBank", "redundPhone"];
    this.amountCheck = ["refundAmount"];
  }

  componentDidMount() {
    const requestParam = {
      p: {},
      b: ["lsiBatchServiceImpl", [this.state]]
    }
    Toast.loading("正在加载", 2);
    axios
      .post(
        `/open/setmeal/wxpush/batch-estimated-refund-amounts/queries`,
        requestParam,
        {
          headers: {
            "Content-Type": "application/json",
          },
        })
      .then((res) => {
        if (res) {
          this.setState(
            {
              estimatedRefundAmount: res.estimatedrefundamount,
            },
            () => Toast.hide()
          );
          this.state.estimatedRefundAmount = res.estimatedrefundamount;
          this.state.refundAmount = res.estimatedrefundamount;
        }
        if (res) {
          this.setState(
            {
              submitFlag: true,
            },
            () => Toast.hide()
          );
          this.state.submitFlag = true;
        }
        if (res) {
          this.setState(
            {
              inspectionStage: res.inspectionstage,
            },
            () => Toast.hide()
          );
          this.state.inspectionStage = res.inspectionstage;
        }
      })
      .catch((e) => {
        Toast.fail("加载失败", 2);
      });

    this.setState({


    });
  }

  setRedux = () => {
    const reduxData = [
      "patientName",
      "salesBarCode",
      "idNumber",
      "v",
      "status",
    ];
    for (let pop in reduxData) {
      this.props.cancelAction({
        type: reduxData[pop],
        data: this.state[reduxData[pop]],
      });
    }
  };

  getCityData = (val, data) => {
    //设置结果
    let result;
    if (!data) {
      return; //如果data传空，直接返回
    }
    for (var i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.value === val) {
        result = data[i].label;
        //找到id相等的则返回父id
        this.doneResult = result;
        return result;
      } else if (item.children && item.children.length > 0) {
        //如果有子集，则把子集作为参数重新执行本方法
        result = this.getCityData(val, item.children);
        //关键，千万不要直接return本方法，不然即使没有返回值也会将返回return，导致最外层循环中断，直接返回undefined,要有返回值才return才对
        if (result) {
          return result;
        }
      }
    }
    //如果执行循环中都没有return，则在此return
    return result;
  };

  dateFormat = (fmt, date) => {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  };

  clearRedux = () => {
    const reduxData = [
      "patientName",
      "salesBarCode",
      "status",
      "idNumber",
      "isMonthlyStatement",
    ];
    for (let pop in reduxData) {
      this.props.cancelAction({
        type: reduxData[pop],
        data: null,
      });
    }
  };

  componentWillUnmount() {
    Toast.hide();
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== "/QRcode"
    ) {
      this.clearRedux();
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  formatTime = (val) => {
    if (!val) return;
    if (typeof val === "string") {
      val = new Date(val);
    }
    return `${val.getFullYear()}-${val.getMonth() + 1 < 10 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1
      }-${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}`;
  };

  render() {
    return (
      <div className="cancel">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <NavBar
            mode="light"
            icon={<Icon type="left" />}
            onLeftClick={this.goBack}
          >
            订单取消
        </NavBar>
          <div className="center">
            <div style={{ width: "100%" }}>
              <List>
                <InputItem
                  extra={
                    <img
                      style={{ marginLeft: "0.1rem" }}
                      src={require("../img/sm.png")}
                      alt=""
                    ></img>
                  }
                  disabled={true}
                  defaultValue={this.state.salesBarCode || ""}
                  onChange={(val) => {
                    this.setState({
                      salesBarCode: val,
                      salesRepresentative: "",
                    });
                  }}
                  error={
                    (this.state.submit && !this.state.salesBarCode) ||
                    this.state.salesBarCodeError
                  }
                >
                  <span style={{ color: "red" }}>*</span>
              申请单条码
            </InputItem>
                <InputItem
                  error={this.state.submit && !this.state.patientName}
                  defaultValue={this.state.patientName || ""}
                  onChange={(val) => {
                    this.setState({
                      patientName: val,
                    });
                  }}
                  disabled={true}
                >
                  <span style={{ color: "red" }}>*</span>
              姓名
            </InputItem>
                <InputItem
                  error={this.state.submit && !this.state.caseNumber}
                  defaultValue={this.state.caseNumber || ""}
                  onChange={(val) => {
                    this.setState({
                      caseNumber: val,
                    });
                  }}
                  disabled={true}
                >
                  <span style={{ color: "red" }}>*</span>
              病案号
            </InputItem>
                <InputItem
                  ref={(el) => (this.idNumber = el)}
                  type="text"
                  maxLength={18}
                  value={this.state.idNumber}
                  onChange={(el) => {
                    this.setState({
                      idNumber: el,
                    });
                  }}
                  disabled={true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onClick={() => this.idNumber.focus()}
                  >
                    身份证号
              </div>
                </InputItem>
                <List.Item
                  extra="请选择"
                  arrow="horizontal"
                  disabled={true}
                >
                  {true ? <span style={{ color: "red" }}>*</span> : null}
              检测项目
            </List.Item>
                {this.state.inspectionItems &&
                  this.state.inspectionItems.length ? (
                  <List.Item multipleLine>
                    {this.state.inspectionItems
                      .map((v, k) => {
                        return (
                          <div key={k} className="select-info2">
                            <img
                              src={require("../img/xx.png")}
                              alt=""
                            ></img>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                flex: "1",
                              }}
                            >
                              {v.productName ? (
                                <div className="content-item">
                                  <span className="content-title">
                                    检测项目：
                            </span>
                                  <span className="content-lable">
                                    {v.productName}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                  </List.Item>
                ) : null}
                <Picker
                  placeholder="请选择"
                  value={[this.state.sampleReturnFlag]}
                  onChange={(val) => {
                    if (val[0] === '0') {
                      this.setState({                        
                        sampleReturnReceiver : '',
                        sampleReturnPhone : '',
                        sampleReturnAddress : '',
                        sampleReturnProvince : '',
                        sampleReturnCity : '',
                        sampleReturnDistrict : '',
                      })
                    }
                    this.setState({
                      sampleReturnFlag : val[0]
                    })
                  }}
                  data={[
                    {
                      value: "0",
                      label: "否",
                    },
                    {
                      value: "1",
                      label: "是",
                    },
                  ]}
                  cols={1}
                >
                  <List.Item
                      arrow="horizontal"
                      error={
                        this.state.returnSubmit &&
                        !this.state.sampleReturnFlag
                      }
                  >
                      <span style={{ color: "red" }}>*</span>
                        归还样本
                  </List.Item>
                </Picker>
                {this.state.sampleReturnFlag === "1" ? (
                  <InputItem
                    placeholder="请填写归还联系人"
                    value={this.state.sampleReturnReceiver || ""}
                    onChange={(val) => {
                      this.setState({
                        sampleReturnReceiver : val
                      })
                    }}
                    error={
                      this.state.returnSubmit &&
                      !this.state.sampleReturnReceiver
                    }
                  >
                    <div>
                      <span style={{ color: "red" }}>*</span>
                        归还联系人
                    </div>
                  </InputItem>
                ) : null}
                {this.state.sampleReturnFlag === "1" ? (
                  <InputItem
                    placeholder="请填写联系人电话"
                    value={this.state.sampleReturnPhone || ""}
                    type="number"
                    maxLength={12}
                    onChange={(val) => {
                      this.setState({
                        sampleReturnPhone : val
                      })
                    }}
                    error={
                      this.state.returnSubmit &&
                      !this.state.sampleReturnPhone
                    }
                  >
                    <div>
                      <span style={{ color: "red" }}>*</span>
                        联系人电话
                    </div>
                  </InputItem>
                ) : null}
                {this.state.sampleReturnFlag === "1" ? (
                  <Picker
                    data={cityData}
                    value={this.state.senderPicker}
                    onOk={(val) => {
                      this.setState({
                        senderPicker: val,
                        sampleReturnProvince : this.getCityData(val[0], cityData),
                        sampleReturnCity : this.getCityData(val[1], cityData),
                        sampleReturnDistrict : this.getCityData(val[2], cityData),
                      });
                    }}
                  >
                    <List.Item
                      error={this.state.returnSubmit && !this.state.senderPicker}
                      arrow="horizontal"
                    >
                      <span style={{ color: "red" }}>*</span>归还地区
                  </List.Item>
                  </Picker>
                ) : null}
                {this.state.sampleReturnFlag === "1" ? (
                  <InputItem
                    placeholder="请填写归还地址"
                    value={this.state.sampleReturnAddress || ""}
                    type="text"
                    maxLength={256}
                    onChange={(val) => {
                      this.setState({
                        sampleReturnAddress : val
                      })
                    }}
                    error={
                      this.state.returnSubmit &&
                      !this.state.sampleReturnAddress
                    }
                  >
                    <div>
                      <span style={{ color: "red" }}>*</span>
                        归还地址
                    </div>
                  </InputItem>
                ) : null}
                <InputItem
                  //type="text"
                  value={this.state.estimatedRefundAmount}
                  disabled={true}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    预计退款金额
                  </div>
                </InputItem>
                <InputItem
                  placeholder="输入实际退款金额"
                  error={this.state.submit && this.state.refundAmount == null}
                  extra="元"
                  type="money"
                  value={this.state.refundAmount}
                  onVirtualKeyboardConfirm={(val) => {
                    this.setState({ refundAmount: parseFloat(val) });
                  }}
                  onFocus={(val) => {
                    this.setState({ refundAmount: null });
                  }}
                  onChange={(val) => {
                    let values = val.split(".");
                    if (values.length > 2) {
                      val = val.substring(0, val.length - 1);
                      this.setState({ refundAmount: val });
                      return;
                    }
                    if (val.indexOf(".") === 0) {
                      val = "0.";
                      this.setState({ refundAmount: val });
                      return;
                    }
                    if (val.indexOf(".") > -1) {
                      let dotNum = val.substring(val.indexOf("."), val.length);
                      if (dotNum.length > 2) {
                        val = val.substring(0, val.indexOf(".") + 3);
                      }
                      this.setState({ refundAmount: val });
                      return;
                    }
                    this.setState({ refundAmount: val });
                  }}
                >
                  <span style={{ color: "red" }}>*</span>退款金额
            </InputItem>

            <InputItem
                  placeholder="请填写取消原因"
                  type="text"
                  value={this.state.cancelReason || ""}
                  onChange={(el) => {
                    this.setState({
                      cancelReason: el,
                    });
                  }}
                  error={
                    this.state.submit &&
                    !this.state.refunder
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
              取消原因
            </div>
                </InputItem>




                <InputItem
                  placeholder="请填写收款人姓名"
                  type="text"
                  value={this.state.refunder || ""}
                  onChange={(el) => {
                    this.setState({
                      refunder: el,
                    });
                  }}
                  error={
                    this.state.submit &&
                    !this.state.refunder
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
              收款人姓名
            </div>
                </InputItem>
                <InputItem
                  placeholder="请填写银行账号"
                  type="text"
                  value={this.state.redundAccount || ""}
                  onChange={(el) => {
                    this.setState({
                      redundAccount: el,
                    });
                  }}
                  error={
                    this.state.submit &&
                    !this.state.redundAccount
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
              银行账号
            </div>
                </InputItem>
                <InputItem
                  placeholder="请填写开户行信息"
                  type="text"
                  value={this.state.redundOpenBank || ""}
                  onChange={(el) => {
                    this.setState({
                      redundOpenBank: el,
                    });
                  }}
                  error={
                    this.state.submit &&
                    !this.state.redundOpenBank
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
              开户行信息
            </div>
                </InputItem>
                <InputItem
                  placeholder="请填写收款人电话"
                  type="number"
                  value={this.state.redundPhone || ""}
                  onChange={(el) => {
                    this.setState({
                      redundPhone: el,
                    });
                  }}
                  error={
                    this.state.submit &&
                    !this.state.redundPhone
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
              收款人电话
            </div>
                </InputItem>
                {/* <TextareaItem
              title={
                <div>
                  <span>取消原因</span>
                </div>
              }
              rows={3}
              
              onChange={(val) => {
                this.setState({
                  applyReason: val,
                });
              }}
              value={this.state.applyReasoun}
              placeholder="请填写取消原因"
            ></TextareaItem> */}
              </List>
            </div>
          </div>
          <div className="foot">
            <span
              className="submit"
              onClick={() => {
                let submit = false;
                this.setState({
                  submit: true,
                });
                this.check.forEach((element) => {
                  if (!this.state[element]) {
                    submit = true;
                  }
                });
                this.amountCheck.forEach((element) => {
                  if (this.state[element] == null) {
                    submit = true;
                  }
                });
                if (submit) {
                  Toast.info("请填写完整信息！", 2);
                  return;
                }
                const requestParam = {
                  p: {},
                  b: ["lsiBatchServiceImpl", [this.state]]
                }
                alert("提示", "是否要取消该申请单？", [
                  { text: "否" },
                  {
                    text: "是",
                    onPress: () => {
                      Toast.loading(`正在提交`, 2);
                      axios
                        .post(
                          `/open/setmeal/wxpush/${this.state.id}/cancelBatchByWX`,
                          requestParam,
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          if (!res) return;
                          Toast.info("提交成功！", 1, () => {
                            this.props.history.push({
                              pathname: "/cancelApplication",
                            });
                          });
                        })
                        .catch(() => {
                          Toast.info("提交失败！", 1);
                        });
                    },
                  },
                ]);

              }}
            >
              提交
            </span>
          </div>
          {this.state.showImage ? (
            <div
              className="show-image"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                zIndex: "999",
                top: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              onClick={() => this.setState({ showImage: false })}
            >
              <img
                alt=""
                style={{ width: "100%" }}
                src={this.state.uploadImage}
              ></img>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.cancelReducer,
    page: state.applyListInfoReducer.page,
  };
}

export default connect(mapStateToProps, { cancelAction })(Cancel);
