import React from "react";
import { NavBar, Icon, Toast } from "antd-mobile";
import axios from "axios";
import "./style/logisticsInformation.css";

export default class LogisticsInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logisticsInformationData: [],
      expressStatus: "",
    };
    this.applicationData = this.props.location.query;
  }

  componentDidMount() {
    this.getdata();
  }

  getdata = () => {
    let query = this.props.location && this.props.location.query;
    if (!query) return;
    Toast.loading("正在加载", 0);
    const requestParam = {
      p: {},
      b: ["lsiBatchSampleSendServiceImpl", [this.applicationData]]
    }
    axios
      .post("/open/setmeal/wxpush/expresses/queries", requestParam, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        Toast.hide();
        if (res && res.result) {
          this.setState({
            logisticsInformationData: res.result
          });
        }
      })
      .catch(() => {
        Toast.info("系统异常", 1);
      });
  };

  //返回首页
  goBack = () => {
    this.props.history.goBack();
  };

  getDateTime = (timeStamps) => {
    //转毫秒
    var date = new Date(timeStamps);
    //返回数据
    var retData = {
      nYear: date.getFullYear(),
      nMonth: date.getMonth() + 1,
      nDay: date.getDate(),
      nHour: date.getHours(),
      nMinutes: date.getMinutes(),
      nSeconds: date.getSeconds()
    };
    return retData.nYear + '-' + retData.nMonth + '-' + retData.nDay + '   ' + retData.nHour + ':' + retData.nMinutes + ':' + retData.nSeconds;
  }

  render() {
    let query = (this.props.location && this.props.location.query) || {};
    const { logisticsInformationData } = this.state;
    return (
      <div className="logisticsInformation">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={this.goBack}
        >
          物流信息
        </NavBar>
        <div className="center">
          <div className="center-head">
            <span className="title">收货地址</span>
            <div className="user-info">
              <span>{query.consignee}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span>{query.consigneePhone}</span>
            </div>
            <span className="address">{query.consigneeAddress}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="address">{query.consigneeDetailAddress}</span>
            <img alt="" src={require("../img/xhx.png")} />
          </div>
          <div className="detaile-info">
            <div className="detaile-info-title">
              <span>运单号: {this.state.logisticsInformationData.waybillNo ? (this.state.logisticsInformationData.waybillNo) : null} </span>
              {!this.state.logisticsInformationData.route_list || this.state.logisticsInformationData.route_list.length === 0 ? (
                <span
                  style={{
                    color: "gold",
                    marginTop: "0.1rem",
                  }}
                >
                  快递小哥接单中~
                </span>
              ) : null}
            </div>
            {this.state.logisticsInformationData.route_list && this.state.logisticsInformationData.route_list.length > 0 && this.state.logisticsInformationData.route_list.map((v, k) => {
              return (
                <div key={k} className="detaile-info-item">
                  <div className="left">
                    {k === 0 ? null : <span className="line1"></span>}
                    {k === 0 ? (
                      <div className="dot1">
                        <span></span>
                      </div>
                    ) : (
                      <span className="dot"></span>
                    )}

                    {k ===
                      this.state.logisticsInformationData.length - 1 ? null : (
                      <span className="line2"></span>
                    )}
                  </div>
                  <div className="right">
                    {v.barScanTm ? <span>操作时间: {this.getDateTime(v.barScanTm)}</span> : null} 
                    {v.courierCode ? <span>操作人姓名: {v.courierCode}</span> : null}
                    {v.empMobile ? <span>操作人手机号: {v.empMobile}</span> : null}
                    {v.distName ? <span>操作站点城市: {v.distName}</span> : null}
                    {v.zoneName ? <span>操作站点名称: {v.zoneName}</span> : null}
                    {v.remark ? <span>物流状态: {v.remark}</span> : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
