import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
var stompClient = null; 
export const mySocket = {
    connect: function connect(id,fn) { 
        var socket = new SockJS('https://csspc.puruijizhun.com/ws');  
        stompClient = Stomp.over(socket);  
        stompClient.connect({}, function (frame) {  
            stompClient.subscribe('/topic/getResponse', function (respnose) { 
            });
            //4通过stompClient.subscribe（）订阅服务器的目标是'/user/' + userId + '/msg'接收一对一的推送消息,其中userId由服务端传递过来,用于表示唯一的用户,通过此值将消息精确推送给一个用户
            stompClient.subscribe('/user/' + id + '/msg', function (respnose) {
                 fn && fn();
            });
        });
    },

    disconnect: function disconnect() {
        if (stompClient != null) {
            stompClient.disconnect();
        } 
    },

    sendName: function sendName() {
        var name = "67";
        //通过stompClient.send（）向地址为"/welcome"的服务器地址发起请求，与@MessageMapping里的地址对应。因为我们配置了registry.setApplicationDestinationPrefixes(Constant.WEBSOCKETPATHPERFIX);所以需要增加前缀/ws-push/
        stompClient.send("/app/welcome", {}, JSON.stringify({ 'name': name }));
    }
}

 